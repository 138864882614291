import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form, FormControl, Modal } from 'react-bootstrap';
import cls from './CreateGiftModal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import Button from '../../../UIComponents/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useCreateNewGiftMutation } from '../gifts.service';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { modalExtra } from '../../../selectors/selectors';
import { MdOutlinePhotoSizeSelectActual } from 'react-icons/md';

const CreateGiftModal = () => {
  const extraModal = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const validationSchema = yup.object().shape({
    image: yup.string().url('Некорректная ссылка').required('Ссылка на изображение обязательна'),
    name: yup.string().required('Название обязательно'),
    cost: yup.string().required('Количество баллов обязательно'),
  });
  const [disableButton, setDisableButton] = useState(true);
  const [createGift] = useCreateNewGiftMutation();
  const formik = useFormik({
    initialValues: {
      image: '',
      name: '',
      cost: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setDisableButton(true);
        await createGift({ ...values, cost: Number(values.cost) }).unwrap();
        closeHandler();
        toast.success('Подарок успешно добавлен');
        if (extraModal?.handler) {
          extraModal.handler();
        }
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            toast.error('Неверно введены данные');
          }
          if (error.status === 403) {
            toast.error('Запрещено');
          }
        }
      }
    },
  });
  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
  };
  useEffect(() => {
    if (formik.values.image && formik.values.name && formik.values.cost) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formik.values.image, formik.values.name, formik.values.cost]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Добавить подарок</h3>
      </Modal.Header>
      <Modal.Body>
        <Form className={cls.Form} onSubmit={formik.handleSubmit}>
          <div className={cls.FieldsContainer}>
            <div className={cls.ImgContainer}>
              {formik.values.image ? (
                <img src={formik.values.image} alt={formik.values.name} />
              ) : (
                <MdOutlinePhotoSizeSelectActual className={cls.Icon} />
              )}
            </div>
            <div className={cls.InputsContainer}>
              <FloatingLabel label="Ссылка на изображение*">
                <FormControl
                  type="text"
                  name="image"
                  id="image"
                  placeholder="Ссылка на изображение*"
                  onChange={onChangeHandle}
                  isInvalid={!!formik.errors.image}
                  value={formik.values.image}
                  className={cls.Input}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel label="Название подарка*">
                <FormControl
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Название подарка*"
                  onChange={onChangeHandle}
                  isInvalid={!!formik.errors.name}
                  value={formik.values.name}
                  className={cls.Input}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel label="Количество баллов для обмена*">
                <FormControl
                  type="text"
                  name="cost"
                  id="cost"
                  placeholder="Количество баллов для обмена*"
                  onChange={onChangeHandle}
                  isInvalid={!!formik.errors.cost}
                  value={formik.values.cost}
                  className={cls.Input}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.cost}</Form.Control.Feedback>
              </FloatingLabel>
            </div>
          </div>
          <div className={cls.Buttons}>
            <button type="submit" className={cls.Button} disabled={disableButton}>
              Сохранить
            </button>
            <Button
              type="button"
              className={cls.CancelButton}
              text="Отмена"
              clickHandler={closeHandler}
            />
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default CreateGiftModal;

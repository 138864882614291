import React, { FC } from 'react';
import { Consumer } from '../consumer.types';
import cls from './ConsumerPanel.module.scss';
import ConsumerPanelItem from '../ConsumerPanelItem/ConsumerPanelItem';
import { getConsumerFilters } from '../../../selectors/selectors';
import { useAppSelector } from '../../../hooks/defaultHooks';

type ConsumerPanelProps = {
  setSearch: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number;
    }>
  >;
  search: { [key: string]: string | number };
  setScrollConsumers: React.Dispatch<React.SetStateAction<Consumer[]>>;
};

const ConsumerPanel: FC<ConsumerPanelProps> = (props) => {
  const { setSearch, search, setScrollConsumers } = props;
  const filters = useAppSelector(getConsumerFilters);
  if (!Array.isArray(filters))
    return (
      <div>
        <ConsumerPanelItem
          key={filters.value}
          filter={filters}
          setSearch={setSearch}
          search={search}
          setScrollConsumers={setScrollConsumers}
        />
      </div>
    );
  return (
    <div className={cls.Panel}>
      {filters.map((filter) => {
        return (
          <ConsumerPanelItem
            key={filter.value}
            filter={filter}
            setSearch={setSearch}
            search={search}
            setScrollConsumers={setScrollConsumers}
          />
        );
      })}
    </div>
  );
};

export default ConsumerPanel;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rBkXbizsaSaqrUyyJ8Lw{width:50px;height:50px;border-radius:100%;display:flex;justify-content:center;align-items:center;font-size:1.5rem;font-weight:bold;color:#fff}.rBkXbizsaSaqrUyyJ8Lw:hover{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/UIComponents/CabinetIconPreview/CabinetIconPreview.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA,CACA,gBAAA,CACA,UAAA,CACA,4BACE,cAAA","sourcesContent":[".CabinerPreview {\n  width: 50px;\n  height: 50px;\n  border-radius: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.5rem;\n  font-weight: bold;\n  color: #fff;\n  &:hover {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CabinerPreview": `rBkXbizsaSaqrUyyJ8Lw`
};
export default ___CSS_LOADER_EXPORT___;

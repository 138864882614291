import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { InvoiceType } from './invoices.types';

type InvoicesResponce = {
  countAll: number;
  invoices: InvoiceType[];
};
type InvoiceRequest = {
  page: number;
  size: number;
  startDateTime?: string;
  endDateTime?: string;
  consumerId?: string;
  businessInn?: string;
  businessName?: string;
  status?: string;
  sortField?: string;
  sortOrder?: 0 | 1;
};

type UpdateInvoiceStatusRequest = {
  id: string;
  status: 'NEW' | 'PAID' | 'CANCELED' | 'EXPIRED';
  numberPaid?: number;
  datePaid?: string;
};

type UpdateInvoiceStatusResponse = {
  status: string;
};

const invoicesApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoices: builder.query<InvoicesResponce, InvoiceRequest>({
      query: (params) => ({
        url: routes.api.invoices(),
        params,
      }),
      providesTags: ['Invoices'],
    }),
    updateInvoiceStatus: builder.mutation<UpdateInvoiceStatusResponse, UpdateInvoiceStatusRequest>({
      query: ({ id, ...body }) => ({
        url: routes.api.setInvoiceStatus(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Invoices', 'Invoice'],
    }),
    resendInvoice: builder.mutation<{ success: boolean }, string>({
      query: (invoiceId) => ({
        url: routes.api.resendInvoiceById(invoiceId),
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetAllInvoicesQuery, useUpdateInvoiceStatusMutation, useResendInvoiceMutation } =
  invoicesApi;

import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../UIComponents/Button';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { useDeleteFeatureByIdMutation } from '../../tariff/tariff.service';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import cls from './DeleteFeatureModal.module.scss';

const DeleteFeatureModal = () => {
  const extra = useAppSelector(modalExtra);
  const [deleteFeature] = useDeleteFeatureByIdMutation();
  const { tariffId } = useParams();
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  const deleteFeatureHandler = async () => {
    try {
      await deleteFeature({ tariffId: tariffId ?? '', featureId: extra?.id ?? '' }).unwrap();
      toast.success('Фича удалена');
      dispatch(closeModal());
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error('Упс. Что-то пошло не так');
      }
    }
  };
  return (
    <>
      <Modal.Header className={cls.Header} closeButton>
        <h3 className="title-3">Удалить фичу?</h3>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center gap-3">
        <p>
          Вы уверены что хотите удалить &nbsp;
          <span className="title-6">{extra?.textInformation}</span>
        </p>
        <div className="d-flex gap-3">
          <Button
            text="Удалить"
            type="button"
            className={cls.DeleteButton}
            clickHandler={deleteFeatureHandler}
          />
          <Button text="Отмена" clickHandler={closeHandler} className={cls.RevokeButton} />
        </div>
      </Modal.Body>
    </>
  );
};

export default DeleteFeatureModal;

export const createConsumerIcon = (name: string | undefined) => {
  if (!name) {
    return 'Λ';
  } else {
    if (name.split(' ').length > 1) {
      const firstNameFirstLetter = name.split(' ')[0][0].toUpperCase();
      const secondNameFirstLetter = name.split(' ')[1][0].toUpperCase();
      return `${firstNameFirstLetter}${secondNameFirstLetter}`;
    } else {
      return name[0].toUpperCase();
    }
  }
};

import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks/defaultHooks';
import { getUserRole } from '../../../selectors/selectors';
import {
  useGetConsumerBalanceQuery,
  useLazyGetAllConsumerOperationByIdQuery,
  useLazyGetConsumerCabinetsQuery,
  useLazyGetConsumerIntegrationsQuery,
} from '../consumer.service';
import { Consumer, ConsumerBilling } from '../consumer.types';
import { useLazyGetCoursesQuery } from '../../courses/courses.service';
import { Course } from '../../courses/courses.types';
import ConsumerInfo from '../ConsumerInfo';
import ConsumerTabsPanel from '../ConsumerTabsPanel';
import cls from './ConsumerCard.module.scss';
import ConsumerCardSubInfo from '../ConsumerCardSubInfo';

type ConsumerCardProps = {
  consumer: Consumer;
  consumerId: string;
};

const ConsumerCard: FC<ConsumerCardProps> = ({ consumer, consumerId }) => {
  const [operationsPage, setOperationsPage] = useState<number>(0);
  const [integrationsPage, setIntegrationsPage] = useState<number>(0);
  const role = useAppSelector(getUserRole) as string;
  const [scrollOperationsList, setScrollOperationsList] = useState<ConsumerBilling[]>([]);
  const [scrollCourses, setScrollCourses] = useState<Course[]>([]);
  const [coursesPage, setCoursesPage] = useState<number>(0);
  const size = 10;
  const [operationTrigger, { data: consumerBillingList, isLoading: consumerBillingListLoading }] =
    useLazyGetAllConsumerOperationByIdQuery();
  const [
    integrationsTrigger,
    { data: consumerIntegrationsList, isLoading: consumerIntegrationsLoading },
  ] = useLazyGetConsumerIntegrationsQuery();
  const [trigger, { data: consumerCabinetsList, isLoading: consumerCabinetLoading }] =
    useLazyGetConsumerCabinetsQuery();
  const [getCoursesTrigger, { data: consumerCoursesList, isLoading: consumerCoursesLoading }] =
    useLazyGetCoursesQuery();
  const resetHandler = () => {
    setOperationsPage(0);
    setScrollOperationsList([]);
  };
  const [balance, setBalance] = useState<string>('0');
  const { data: consumerBalance } = useGetConsumerBalanceQuery(consumerId);
  const selectedHandler = (value: string | null) => {
    if (value === 'cabinets') {
      trigger({
        id: consumerId as string,
        page: 0,
        size: 10,
      });
    } else if (value === 'integrations') {
      integrationsTrigger({
        consumerId: consumerId as string,
        page: integrationsPage,
        size: 10,
      });
    } else if (value === 'operations') {
      setOperationsPage(0);
      setScrollOperationsList([]);
      operationTrigger({
        id: consumerId as string,
        page: operationsPage,
        size,
      });
    } else if (value === 'courses') {
      getCoursesTrigger({
        consumerId: consumerId as string,
        page: coursesPage,
        size: 10,
        status: 'ACTIVE',
      });
    }
  };
  useLayoutEffect(() => {
    operationTrigger({
      id: consumerId as string,
      page: operationsPage,
      size,
    });
  }, [role, operationTrigger, consumerId, operationsPage]);
  useEffect(() => {
    if (consumerCoursesList) {
      setCoursesPage(Math.ceil(consumerCoursesList.countAll / size));
    }
  }, [consumerCoursesList]);
  useLayoutEffect(() => {
    if (consumerBalance && consumerBalance.amount > 0) {
      setBalance((consumerBalance.amount / 100).toFixed(2));
    } else {
      setBalance('0');
    }
  }, [consumerBalance]);
  return (
    <div className={cls.Container}>
      <ConsumerInfo consumer={consumer} />
      <ConsumerCardSubInfo
        consumer={consumer}
        balance={balance}
        resetHandler={resetHandler}
        userRole={role}
      />
      <ConsumerTabsPanel
        consumerBillingList={consumerBillingList}
        consumerCabinetLoading={consumerCabinetLoading}
        consumerBillingListLoading={consumerBillingListLoading}
        consumerCabinetsList={consumerCabinetsList}
        consumerCoursesList={consumerCoursesList?.courses}
        consumerCoursesLoading={consumerCoursesLoading}
        consumerIntegrationsList={consumerIntegrationsList}
        consumerIntegrationsLoading={consumerIntegrationsLoading}
        coursesPage={coursesPage}
        selectedHandler={selectedHandler}
        scrollCourses={scrollCourses}
        setCoursesPage={setCoursesPage}
        scrollOperationsList={scrollOperationsList}
        setIntegrationsPage={setIntegrationsPage}
        setOperationsPage={setOperationsPage}
        setScrollCourses={setScrollCourses}
        setScrollOperationsList={setScrollOperationsList}
        integrationsPage={integrationsPage}
        operationsPage={operationsPage}
      />
    </div>
  );
};

export default ConsumerCard;

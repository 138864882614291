// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoUqQR4TQHWEw8DiCUiA{padding:2rem;border-radius:1.5rem;background-color:var(--white-color);display:flex;flex-direction:column;gap:1.5rem}.MefFGS6rrmKlFYGmpLhl{display:flex;flex-direction:column;gap:1.5rem}`, "",{"version":3,"sources":["webpack://./src/features/tariff/CreateTariffFormSettings/CreateTariffFormSettings.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oBAAA,CACA,mCAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":[".Container {\n  padding: 2rem;\n  border-radius: 1.5rem;\n  background-color: var(--white-color);\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n.SettingsSwitches {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `NoUqQR4TQHWEw8DiCUiA`,
	"SettingsSwitches": `MefFGS6rrmKlFYGmpLhl`
};
export default ___CSS_LOADER_EXPORT___;

import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../Button';
import { SearchType } from '../../features/search/search.types';
import { Lead } from '../../features/lead/lead.types';
import { convertDateToNeedFormat } from '../../utils/convertDateHandlers';
import { Log } from '../../features/log/logs.types';
import * as yup from 'yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit/dist/createAction';
import { useAppDispatch } from '../../hooks/defaultHooks';
import { themes } from '../../store/slices/themeSlice';

interface DataPickerRangeProps {
  search: SearchType;
  setSearch: React.Dispatch<React.SetStateAction<SearchType>>;
  setScrollElement:
    | React.Dispatch<React.SetStateAction<Lead[]>>
    | React.Dispatch<React.SetStateAction<Log[]>>;
  showTodayPeriod: boolean;
  field: string;
  setField: React.Dispatch<React.SetStateAction<string>>;
  setInfoPannelField: React.Dispatch<React.SetStateAction<string>>;
  setInfoPannelValue: React.Dispatch<React.SetStateAction<string>>;
  setShowInfoPannel: React.Dispatch<React.SetStateAction<boolean>>;
  theme?: themes;
  writeToStore: ActionCreatorWithPayload<
    SearchType,
    'search/updateConsumersSearch' | 'search/updateLeadsSearch' | 'search/updateLogsSearch'
  >;
}

const DataPickerRange: FC<DataPickerRangeProps> = ({
  search,
  setSearch,
  setScrollElement,
  showTodayPeriod,
  setInfoPannelField,
  setInfoPannelValue,
  setShowInfoPannel,
  writeToStore,
  field,
  setField,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const validationSchema = yup.object().strict().shape({
    startDateTime: yup.string().trim(),
    endDateTime: yup.string().trim(),
  });
  const formik = useFormik({
    initialValues: {
      startDateTime: '',
      endDateTime: '',
    },
    validationSchema: field === 'period' ? validationSchema : false,
    validateOnChange: true,
    onSubmit: (values, { resetForm }) => {
      setDisabled(false);
      try {
        if (field === 'period') {
          if (values.startDateTime.length === 0 && values.endDateTime.length === 0) {
            setDisabled(true);
          } else {
            setScrollElement([]);
            setSearch({
              ...search,
              startDateTime:
                values.startDateTime.length !== 0
                  ? convertDateToNeedFormat({ date: values?.startDateTime, format: 'startRange' })
                  : '',
              endDateTime:
                values.endDateTime.length !== 0
                  ? convertDateToNeedFormat({ date: values?.endDateTime, format: 'endRange' })
                  : '',
            });
            dispatch(
              writeToStore({
                ...search,
                startDateTime:
                  values.startDateTime.length !== 0
                    ? convertDateToNeedFormat({ date: values?.startDateTime, format: 'startRange' })
                    : '',
                endDateTime:
                  values.endDateTime.length !== 0
                    ? convertDateToNeedFormat({ date: values?.endDateTime, format: 'endRange' })
                    : '',
              }),
            );
            if (values.startDateTime.length !== 0 && values.endDateTime.length !== 0) {
              setInfoPannelField('period');
              setInfoPannelValue(
                `c ${convertDateToNeedFormat({
                  date: values?.startDateTime,
                  format: 'datetime',
                })} по ${convertDateToNeedFormat({
                  date: values?.endDateTime,
                  format: 'datetime',
                })}`,
              );
            } else if (values.startDateTime.length !== 0) {
              setInfoPannelField('start');
              setInfoPannelValue(
                `c ${convertDateToNeedFormat({
                  date: values?.startDateTime,
                  format: 'datetime',
                })}`,
              );
            } else {
              setInfoPannelField('end');
              setInfoPannelValue(
                `до ${convertDateToNeedFormat({
                  date: values?.endDateTime,
                  format: 'datetime',
                })}`,
              );
            }

            setShowInfoPannel(true);
            resetForm();
          }
        } else {
          setScrollElement([]);
          setSearch({
            ...search,
            startDateTime: convertDateToNeedFormat({
              date: new Date().toString(),
              format: 'startRange',
            }),
            endDateTime: convertDateToNeedFormat({
              date: new Date().toString(),
              format: 'endRange',
            }),
          });
          dispatch(
            writeToStore({
              ...search,
              startDateTime: convertDateToNeedFormat({
                date: new Date().toString(),
                format: 'startRange',
              }),
              endDateTime: convertDateToNeedFormat({
                date: new Date().toString(),
                format: 'endRange',
              }),
            }),
          );
          setInfoPannelField('today');
          setInfoPannelValue(
            `за ${convertDateToNeedFormat({ date: new Date().toString(), format: 'datetime' })}`,
          );
          setShowInfoPannel(true);
          resetForm();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDisabled(false);
      }
    },
  });
  const onChangeHandle = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'today') {
      setField('today');
      setShowInfoPannel(false);
      formik.handleChange(event);
    } else {
      setField('period');
      setShowInfoPannel(false);
      formik.handleChange(event);
    }
  };
  return (
    <Form
      className="d-flex justify-content-end search-form gap-2"
      onSubmit={formik.handleSubmit}
      data-testid="date-picker-range"
      noValidate
    >
      {field === 'period' ? (
        <>
          <Form.Group className="d-flex">
            <Form.Control
              type="date"
              id="startDateTime"
              name="startDateTime"
              onChange={formik.handleChange}
              value={formik.values.startDateTime}
              placeholder="Введите значение"
              data-testid="start-date"
              isInvalid={!!formik.errors.startDateTime}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.startDateTime}
            </Form.Control.Feedback>
          </Form.Group>
          <div></div>
          <Form.Group className="d-flex">
            <Form.Control
              type="date"
              id="endDateTime"
              name="endDateTime"
              onChange={formik.handleChange}
              value={formik.values.endDateTime}
              placeholder="Введите значение"
              data-testid="end-date"
              isInvalid={!!formik.errors.endDateTime}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.endDateTime}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      ) : null}
      {showTodayPeriod ? (
        <Form.Select
          onChange={onChangeHandle}
          id="filterField"
          name="filterField"
          data-testid="date-select"
        >
          <option value="period">За период</option>
          <option value="today">За сегодня</option>
        </Form.Select>
      ) : null}
      <Button text="Показать" type="submit" disabled={disabled} />
    </Form>
  );
};

export default DataPickerRange;

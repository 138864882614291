import React, { FC, memo } from 'react';
import { createIconByFullName } from '../../utils/createIconByFullName';
import cls from './CabinetIconPreview.module.scss';

type CabinetIconPreviewProps = {
  url?: string | null;
  color?: string | null;
  firstName?: string;
  secondName?: string;
};

const CabinetIconPreview: FC<CabinetIconPreviewProps> = memo((props) => {
  const { url, firstName, secondName, color } = props;
  if (url && url.length > 0) {
    return (
      <img
        src={url}
        className={`${cls.CabinerPreview} cursor-pointer`}
        alt="Кабинет"
        data-testid="cabinet-icon"
      />
    );
  }
  return (
    <div
      data-testid="cabinet-icon"
      className={`${cls.CabinerPreview}`}
      style={{
        backgroundColor: `#${color}`,
      }}
    >
      {createIconByFullName(firstName, secondName)}
    </div>
  );
});

export default CabinetIconPreview;

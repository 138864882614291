import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import {
  Course,
  CourseStatus,
  Feedback,
  Lesson,
  NewCourse,
  NewLesson,
  UpdateCourse,
  UpdateLesson,
} from './courses.types';

type CourseRequestWithPagination = PaginationRequest & {
  status: CourseStatus;
  consumerId?: string;
};
type LessonsRequestWithPagination = PaginationRequest & {
  courseId: string;
};

type ManipulationRequest = {
  id: string;
  status: string;
};

type UpdateLessonRequest = {
  courseId: string;
  lessonId: string;
  lesson: UpdateLesson;
};

type GetCourseResponse = {
  countAll: number;
  courses: Course[];
};

type GetLessonsResponse = {
  countAll: number;
  lessons: Lesson[];
};

type LessonRequest = { courseId: string; lessonId: string };

type FeedBackRequest = PaginationRequest & {
  courseId?: string;
  lessonId?: string;
  consumerId?: string;
  startDate?: string;
  endDate?: string;
};

type FeedBackResponse = {
  countAll: number;
  feedbacks: Feedback[];
};
const coursesApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getCourses: builder.query<GetCourseResponse, CourseRequestWithPagination>({
      query: ({ page, size = 10, status = 'ACTIVE', consumerId }) => ({
        url: routes.api.courses(),
        params: {
          page,
          size,
          status,
          consumerId,
        },
      }),
      providesTags: ['Course', 'Courses'],
    }),
    createCourse: builder.mutation<Course, NewCourse>({
      query: (course) => ({
        url: routes.api.courses(),
        method: 'POST',
        body: course,
      }),
      invalidatesTags: ['Course'],
    }),
    getCourseLessons: builder.query<GetLessonsResponse, LessonsRequestWithPagination>({
      query: ({ courseId, page, size = 10 }) => ({
        url: routes.api.lessonsByCourseId(courseId),
        params: {
          page,
          size,
        },
      }),
      providesTags: ['Course', 'Lesson', 'Lessons'],
    }),
    createNewLesson: builder.mutation<Lesson, { lesson: NewLesson; courseId: string }>({
      query: ({ lesson, courseId }) => ({
        url: routes.api.lessonsByCourseId(courseId),
        method: 'POST',
        body: lesson,
      }),
      invalidatesTags: ['Course', 'Lesson', 'Lessons', 'Courses'],
    }),
    updateCourse: builder.mutation<Course, { courseId: string; course: Required<UpdateCourse> }>({
      query: ({ courseId, course }) => ({
        url: routes.api.updateCourse(courseId),
        method: 'PATCH',
        body: course,
      }),
      invalidatesTags: ['Course', 'Courses'],
    }),
    restoreCourse: builder.mutation<ManipulationRequest, string>({
      query: (courseId) => ({
        url: routes.api.restoreCourse(courseId),
        method: 'PATCH',
      }),
      invalidatesTags: ['Course', 'Courses'],
    }),
    updateLesson: builder.mutation<Lesson, UpdateLessonRequest>({
      query: ({ lessonId, courseId, lesson }) => ({
        url: routes.api.updateLesson(courseId, lessonId),
        method: 'PATCH',
        body: lesson,
      }),
      invalidatesTags: ['Course', 'Lesson'],
    }),
    archiveCourse: builder.mutation<ManipulationRequest, string>({
      query: (courseId) => ({
        url: routes.api.archivingCourse(courseId),
        method: 'PATCH',
      }),
      invalidatesTags: ['Course', 'Courses'],
    }),
    activateCourse: builder.mutation<ManipulationRequest, string>({
      query: (courseId) => ({
        url: routes.api.activatedCourse(courseId),
        method: 'PATCH',
      }),
      invalidatesTags: ['Course'],
    }),
    getCourseById: builder.query<Course, string>({
      query: (courseId) => routes.api.course(courseId),
      providesTags: ['Course', 'Courses'],
    }),
    getLessonById: builder.query<Lesson, LessonRequest>({
      query: ({ courseId, lessonId }) => routes.api.lesson(courseId, lessonId),
      providesTags: ['Lesson'],
    }),
    deleteLesson: builder.mutation<ManipulationRequest, LessonRequest>({
      query: ({ courseId, lessonId }) => ({
        url: routes.api.deleteLesson(courseId, lessonId),
        method: 'DELETE',
      }),
      invalidatesTags: ['Course', 'Lesson', 'Lessons', 'Courses'],
    }),
    getFeedback: builder.query<FeedBackResponse, FeedBackRequest>({
      query: ({ courseId, lessonId, consumerId, startDate, endDate }) => ({
        url: routes.api.courseFeedback(),
        params: {
          courseId,
          lessonId,
          consumerId,
          startDate,
          endDate,
        },
      }),
      providesTags: ['Feedback', 'Lesson'],
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useCreateCourseMutation,
  useGetCourseLessonsQuery,
  useCreateNewLessonMutation,
  useUpdateCourseMutation,
  useRestoreCourseMutation,
  useUpdateLessonMutation,
  useArchiveCourseMutation,
  useActivateCourseMutation,
  useGetCourseByIdQuery,
  useGetLessonByIdQuery,
  useDeleteLessonMutation,
  useLazyGetCoursesQuery,
  useGetFeedbackQuery,
} = coursesApi;

import React from 'react';
import cls from './ConsumerIntegrationsListHeader.module.scss';

const ConsumerIntegrationsListHeader = () => {
  return (
    <ul className={cls.Header} data-testid="integrations-header">
      <li className="regular-text">Сервис</li>
      <li className="regular-text">Создано</li>
      <li className="regular-text">Срок</li>
      <li className="regular-text">Аккаунт</li>
    </ul>
  );
};

export default ConsumerIntegrationsListHeader;

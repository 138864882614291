import React from 'react';
import cls from './ChatIndexRoute.module.scss';
const ChatIndexRoute = () => {
  return (
    <div className={cls.Container} data-testid="index-route">
      <h2 className="title-2">Для начала общения выберите чат из списка слева</h2>
    </div>
  );
};

export default ChatIndexRoute;

import React, { FC, memo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ConsumerBillingList from '../ConsumerBillingList';
import ConsumerCabinetList from '../ConsumerCabinetList';
import ConsumerCoursesList from '../ConsumerCoursesList';
import ConsumerIntegrationsList from '../ConsumerIntegrationsList';
import { ConsumerBilling, ConsumerCabinet, ConsumerIntegration } from '../consumer.types';
import { Course } from '../../courses/courses.types';
import cls from './ConsumerTabsPanel.module.scss';

type ConsumerTabsPanelProps = {
  consumerBillingList: ConsumerBilling[] | undefined;
  consumerCabinetsList: ConsumerCabinet[] | undefined;
  consumerCoursesList: Course[] | undefined;
  consumerIntegrationsList: ConsumerIntegration[] | undefined;
  consumerBillingListLoading: boolean;
  consumerCabinetLoading: boolean;
  consumerCoursesLoading: boolean;
  consumerIntegrationsLoading: boolean;
  operationsPage: number;
  setOperationsPage: React.Dispatch<React.SetStateAction<number>>;
  scrollOperationsList: ConsumerBilling[];
  setScrollOperationsList: React.Dispatch<React.SetStateAction<ConsumerBilling[]>>;
  integrationsPage: number;
  setIntegrationsPage: React.Dispatch<React.SetStateAction<number>>;
  selectedHandler: (value: string | null) => void;
  coursesPage: number;
  setCoursesPage: React.Dispatch<React.SetStateAction<number>>;
  scrollCourses: Course[];
  setScrollCourses: React.Dispatch<React.SetStateAction<Course[]>>;
};
const ConsumerTabsPanel: FC<ConsumerTabsPanelProps> = memo(
  ({
    consumerBillingList,
    consumerCabinetsList,
    consumerBillingListLoading,
    consumerCabinetLoading,
    consumerCoursesList,
    consumerCoursesLoading,
    consumerIntegrationsList,
    consumerIntegrationsLoading,
    operationsPage,
    setOperationsPage,
    scrollOperationsList,
    setScrollOperationsList,
    integrationsPage,
    setIntegrationsPage,
    selectedHandler,
    coursesPage,
    setCoursesPage,
    scrollCourses,
    setScrollCourses,
  }) => {
    return (
      <div className={cls.Container} data-testid="consumer-tabs-panel">
        <Tabs
          defaultActiveKey="logs"
          onSelect={(value) => selectedHandler(value)}
          variant="underline"
        >
          <Tab eventKey="logs" title="История операций" data-testid="consumer-operations-tab">
            <ConsumerBillingList
              consumerBillingList={consumerBillingList}
              operationsPage={operationsPage}
              setOperationsPage={setOperationsPage}
              scrollOperationsList={scrollOperationsList}
              setScrollOperationsList={setScrollOperationsList}
              consumerBillingListLoading={consumerBillingListLoading}
            />
          </Tab>
          <Tab eventKey="cabinets" title="Кабинеты" data-testid="consumer-cabinets-tab">
            <ConsumerCabinetList
              cabinetsList={consumerCabinetsList}
              isLoading={consumerCabinetLoading}
            />
          </Tab>
          <Tab eventKey="integrations" title="Интеграции" data-testid="consumer-integrations-tab">
            <ConsumerIntegrationsList
              consumerIntegrations={consumerIntegrationsList}
              isLoading={consumerIntegrationsLoading}
              page={integrationsPage}
              setPage={setIntegrationsPage}
            />
          </Tab>
          <Tab eventKey="courses" title="Курсы" data-testid="consumer-courses-tab">
            <ConsumerCoursesList
              coursesList={consumerCoursesList}
              isLoading={consumerCoursesLoading}
              page={coursesPage}
              setPage={setCoursesPage}
              scrollCourses={scrollCourses}
              setScrollCourses={setScrollCourses}
              consumerCoursesCount={coursesPage}
            />
          </Tab>
        </Tabs>
      </div>
    );
  },
);

export default ConsumerTabsPanel;

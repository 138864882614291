import React, { FC, memo } from 'react';
import { FormGroup, FormSelect, FormControl, FloatingLabel } from 'react-bootstrap';
import cls from './CreateTariffValidityPart.module.scss';

type CreateTariffValidityPartProps = {
  isNew: boolean;
  onChange: (event: React.ChangeEvent) => void;
  periodTypeValue: string;
  periodAmountValue: number;
  periodAmountError: string | undefined;
  periodTypeError: string | undefined;
  periodExceedHoursValue: number;
  periodExceedHoursError: string | undefined;
};

const CreateTariffValidityPart: FC<CreateTariffValidityPartProps> = memo((props) => {
  const {
    isNew,
    onChange,
    periodTypeValue,
    periodAmountValue,
    periodAmountError,
    periodTypeError,
    periodExceedHoursValue,
    periodExceedHoursError,
  } = props;
  return (
    <div className={cls.Container}>
      <h4 className="title-4">Срок действия</h4>
      <div className={cls.Validity}>
        <FormGroup className={cls.InputWrapper}>
          <FloatingLabel controlId="periodType" label="Период*">
            <FormSelect
              name="periodType"
              onChange={onChange}
              value={periodTypeValue}
              isInvalid={!!periodTypeError}
              disabled={!isNew}
              className={cls.FormField}
            >
              <option>Выберите период</option>
              <option value="DAY">День</option>
              <option value="WEEK">Неделя</option>
              <option value="MONTH">Месяц</option>
              <option value="YEAR">Год</option>
            </FormSelect>
          </FloatingLabel>
          {periodTypeError && <span className={cls.Feedback}>{periodTypeError}</span>}
        </FormGroup>
        <FormGroup className={cls.InputWrapper}>
          <FloatingLabel controlId="periodAmount" label="Количество периодов*">
            <FormControl
              type="number"
              name="periodAmount"
              onChange={onChange}
              value={periodAmountValue}
              isInvalid={!!periodAmountError}
              disabled={!isNew}
              className={cls.FormField}
            />
          </FloatingLabel>
          {periodAmountError && <span className={cls.Feedback}>{periodAmountError}</span>}
        </FormGroup>
        <FormGroup className={cls.InputWrapper}>
          <FloatingLabel controlId="periodExceedHours" label="Часы для продления*">
            <FormControl
              type="number"
              name="periodExceedHours"
              onChange={onChange}
              value={periodExceedHoursValue}
              placeholder="Введите количество часов"
              isInvalid={!!periodExceedHoursError}
              disabled={!isNew}
              className={cls.FormField}
            />
          </FloatingLabel>
          {periodExceedHoursError && <span className={cls.Feedback}>{periodExceedHoursError}</span>}
        </FormGroup>
      </div>
    </div>
  );
});

export default CreateTariffValidityPart;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lI6ueCL9DtDNKDho8BNr{border:none}._aAdhc534qlfEhobuWAs{display:flex;flex-direction:column;gap:1.5rem}.kX6KVeGSm9rzd7oX2Q3r{display:flex;flex-direction:column;gap:1rem}.Y9smu2UW1MCNXfoqyEky{display:flex;gap:14px;width:100%}.Xf9A_szD_nQbuR0wB42A{padding:8px 16px;gap:6px;border-radius:8px;border:none;outline:none;background-color:var(--accent-color);border:1px solid var(--accent-color);width:100%;color:var(--white-color);max-width:482px;font-size:18px;font-weight:700;line-height:23.29px}.Xf9A_szD_nQbuR0wB42A:hover{background-color:rgba(0,0,0,0);border:1px solid var(--accent-color);color:var(--accent-color)}`, "",{"version":3,"sources":["webpack://./src/features/modals/MakeInvoiceExpiredModal/MakeInvoiceExpiredModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,QAAA,CACA,UAAA,CAEF,sBACE,gBAAA,CACA,OAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,oCAAA,CACA,oCAAA,CACA,UAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CAEA,4BACE,8BAAA,CACA,oCAAA,CACA,yBAAA","sourcesContent":[".ModalHeader {\n  border: none;\n}\n.ModalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n.Form {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n.Buttons {\n  display: flex;\n  gap: 14px;\n  width: 100%;\n}\n.ActionButton {\n  padding: 8px 16px;\n  gap: 6px;\n  border-radius: 8px;\n  border: none;\n  outline: none;\n  background-color: var(--accent-color);\n  border: 1px solid var(--accent-color);\n  width: 100%;\n  color: var(--white-color);\n  max-width: 482px;\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 23.29px;\n\n  &:hover {\n    background-color: transparent;\n    border: 1px solid var(--accent-color);\n    color: var(--accent-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalHeader": `lI6ueCL9DtDNKDho8BNr`,
	"ModalBody": `_aAdhc534qlfEhobuWAs`,
	"Form": `kX6KVeGSm9rzd7oX2Q3r`,
	"Buttons": `Y9smu2UW1MCNXfoqyEky`,
	"ActionButton": `Xf9A_szD_nQbuR0wB42A`
};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable no-undef */
import ReactDOM from 'react-dom/client';
import './index.scss';
import * as Sentry from '@sentry/react';
import init from './init';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: process.env.SENTRY_ADMSITE_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({}),
  ],
  enabled: !!process.env.SENTRY_ENABLED,
});

const app = async () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  const vdom = await init();
  root.render(vdom);
};

app();

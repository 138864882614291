import React, { FC, memo, useEffect } from 'react';
import { NewTariff } from '../tariff.types';
import { useFormik } from 'formik';
import { Form, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import cls from './CreateOrChangeTariffForm.module.scss';
import CreateTariffFormValuePart from '../CreateTariffFormValuePart';
import CreateTariffValidityPart from '../CreateTariffValidityPart';
import CreateTariffFormSettings from '../CreateTariffFormSettings';

type CreateOrChangeTariffFormProps = {
  initialValues: NewTariff;
  disabled: boolean;
  handleSubmit: (values: NewTariff) => Promise<void>;
  isNew: boolean;
  setText: React.Dispatch<React.SetStateAction<string>>;
  text: string;
  className?: string;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateOrChangeTariffForm: FC<CreateOrChangeTariffFormProps> = memo(
  ({ initialValues, handleSubmit, isNew, setText, className, setDisabled, disabled, text }) => {
    const validationSchema = yup
      .object()
      .strict()
      .shape({
        name: yup.string().required('Поле является обязательным'),
        type: yup.string().required('Поле является обязательным'),
        cost: yup.number().min(0, 'Минимальное значение 0'),
        label: yup.string().notRequired(),
        teaser: yup
          .string()
          .min(3, 'Минимальная длина 3 символа')
          .max(255, 'Максимальная длина 255 символов')
          .required('Поле является обязательным'),
        description: yup.string(),
        periodType: yup.string().required('Поле является обязательным'),
        periodAmount: yup
          .number()
          .min(1, 'Минимальное значение 1')
          .required('Поле является обязательным'),
      });
    const navigate = useNavigate();
    const formik = useFormik({
      initialValues,
      validationSchema,
      validateOnChange: false,
      onSubmit: async (values) => {
        await handleSubmit(values);
      },
    });
    const onChangeHandler = (event: React.ChangeEvent) => {
      formik.handleChange(event);
      formik.setErrors({});
    };
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handler = (e: any) => {
        if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
          e.stopImmediatePropagation();
        }
      };
      document.addEventListener('focusin', handler);
      return () => document.removeEventListener('focusin', handler);
    }, []);
    useEffect(() => {
      if (
        formik.values.name !== initialValues.name ||
        formik.values.type !== initialValues.type ||
        formik.values.periodType !== initialValues.periodType ||
        formik.values.periodAmount !== initialValues.periodAmount ||
        formik.values.label !== initialValues.label ||
        formik.values.teaser !== initialValues.teaser ||
        formik.values.description !== initialValues.description
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [formik.values, setDisabled, initialValues, text]);
    return (
      <Form
        onSubmit={formik.handleSubmit}
        className={`${className} ${cls.Form}`}
        data-testid="create-or-change-tariff-form"
      >
        <div className="d-flex gap-3">
          <CreateTariffFormValuePart
            nameError={formik.errors.name}
            nameValue={formik.values.name}
            costValue={formik.values.cost}
            labelValue={formik.values.label}
            teaser={formik.values.teaser}
            teaserError={formik.errors.teaser}
            descriptionValue={text}
            typeError={formik.errors.type}
            typeValue={formik.values.type}
            setText={setText}
            onChange={onChangeHandler}
            isNew={isNew}
          />
          <div className={cls.TariffFormRight}>
            <CreateTariffValidityPart
              periodExceedHoursError={formik.errors.periodExceedHours}
              periodExceedHoursValue={formik.values.periodExceedHours}
              periodAmountError={formik.errors.periodAmount}
              periodAmountValue={formik.values.periodAmount}
              periodTypeError={formik.errors.periodType}
              periodTypeValue={formik.values.periodType}
              onChange={onChangeHandler}
              isNew={isNew}
            />
            <CreateTariffFormSettings
              onChange={onChangeHandler}
              isNew={isNew}
              convertibleValue={formik.values.convertable}
              refundableValue={formik.values.refundable}
              renewableValue={formik.values.renewable}
            />
          </div>
        </div>
        <div className="d-flex gap-3">
          <button
            className={
              disabled ? `${cls.ActionButton} ${cls.ActionButtonDisabled}` : cls.ActionButton
            }
            type="submit"
            disabled={disabled}
          >
            {isNew ? 'Создать' : 'Сохранить изменения'}
          </button>
          <button
            type="button"
            onClick={() => {
              navigate(-1);
            }}
            className={cls.RevokeButton}
          >
            Отмена
          </button>
        </div>
      </Form>
    );
  },
);

export default CreateOrChangeTariffForm;

import React from 'react';
import AboutUser from '../../features/user/AboutUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const SettingsPage = () => {
  useDocumentTitle('Настройки');
  return (
    <div className="px-5 py-5">
      <AboutUser />
    </div>
  );
};

export default SettingsPage;

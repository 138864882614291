import React, { FC, memo } from 'react';
import {
  TariffIntegrationLimitType,
  TariffSolutionLimitsType,
  TariffToolLimitsType,
} from '../tariff.types';
import cls from './SetTariffLimitsTab.module.scss';
import { useNavigate } from 'react-router-dom';
import TariffSetConnectionLimits from '../TariffSetConnectionLimits';
import TariffSetSolutionsLimit from '../TariffSetSolutionsLimit';
import TariffSetToolsLimit from '../TariffSetToolsLimit';

type SetTariffLimitsTabProps = {
  integrations: TariffIntegrationLimitType[];
  tools: TariffToolLimitsType[];
  solutions: TariffSolutionLimitsType[];
  setToolsLimits: React.Dispatch<React.SetStateAction<TariffToolLimitsType[]>>;
  setIntegrationsLimits: React.Dispatch<React.SetStateAction<TariffIntegrationLimitType[]>>;
  setSolutionsLimits: React.Dispatch<React.SetStateAction<TariffSolutionLimitsType[]>>;
  updateLimitsHandler: () => Promise<void>;
  isNew: boolean;
};

const SetTariffLimitsTab: FC<SetTariffLimitsTabProps> = memo((props) => {
  const {
    integrations,
    tools,
    solutions,
    setToolsLimits,
    setIntegrationsLimits,
    setSolutionsLimits,
    updateLimitsHandler,
    isNew,
  } = props;
  const navigate = useNavigate();
  return (
    <div className={cls.LimitsContainer}>
      <div className="d-flex flex-column gap-3">
        <TariffSetConnectionLimits
          integrations={integrations}
          setIntegrationsLimits={setIntegrationsLimits}
        />
        <TariffSetSolutionsLimit solutions={solutions} setSolutionsLimits={setSolutionsLimits} />
        <TariffSetToolsLimit tools={tools} setToolsLimits={setToolsLimits} />
      </div>
      <div className="d-flex gap-3">
        <button className={cls.ActionButton} type="button" onClick={updateLimitsHandler}>
          {isNew ? 'Создать' : 'Сохранить изменения'}
        </button>
        <button
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          className={cls.RevokeButton}
        >
          Отмена
        </button>
      </div>
    </div>
  );
});

export default SetTariffLimitsTab;

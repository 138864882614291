// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wdpfsbLeNhnddSVyPwWU{padding:40px;overflow-y:auto;max-height:100vh}.I15mmEww5hT9qQ3BqDlg{margin-bottom:1.5rem;grid-column:1/-1}.MeyU2wLKD87EJl1Ds66j{grid-column:1/-1;margin-bottom:1.5rem}.YAAeZeD82Pvd4xs2TGN4{grid-column:1/-2}::-webkit-scrollbar{display:none}.i05EYoCMGR6Lzz2cMMXu{height:fit-content;padding-right:1rem;margin-left:0}.IyNxHjxxa9HtSE8p8UwA{height:fit-content;margin-top:2rem}`, "",{"version":3,"sources":["webpack://./src/screens/NewTariffPage/NewTariffPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,oBAAA,CACA,gBAAA,CAGF,sBACE,gBAAA,CACA,oBAAA,CAEF,sBACE,gBAAA,CAEF,oBACE,YAAA,CAEF,sBACE,kBAAA,CACA,kBAAA,CACA,aAAA,CAEF,sBACE,kBAAA,CACA,eAAA","sourcesContent":[".Container {\n  padding: 40px;\n  overflow-y: auto;\n  max-height: 100vh;\n}\n\n.BreadCrumbs {\n  margin-bottom: 1.5rem;\n  grid-column: 1/-1;\n}\n\n.Title {\n  grid-column: 1/-1;\n  margin-bottom: 1.5rem;\n}\n.Content {\n  grid-column: 1/-2;\n}\n::-webkit-scrollbar {\n  display: none;\n}\n.Tabs {\n  height: fit-content;\n  padding-right: 1rem;\n  margin-left: 0;\n}\n.Tab {\n  height: fit-content;\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `wdpfsbLeNhnddSVyPwWU`,
	"BreadCrumbs": `I15mmEww5hT9qQ3BqDlg`,
	"Title": `MeyU2wLKD87EJl1Ds66j`,
	"Content": `YAAeZeD82Pvd4xs2TGN4`,
	"Tabs": `i05EYoCMGR6Lzz2cMMXu`,
	"Tab": `IyNxHjxxa9HtSE8p8UwA`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC } from 'react';
import { themes } from '../../../store/slices/themeSlice';
import Button from '../../../UIComponents/Button';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { openModal } from '../../../store/slices/modalSlice';
import { MdOutlineDownload } from 'react-icons/md';

interface InstrumentalPannelProps {
  theme: themes;
  title: string;
  downloadHandler: () => void;
}

const InstrumentalPannel: FC<InstrumentalPannelProps> = ({ theme, title, downloadHandler }) => {
  const dispatch = useAppDispatch();
  const clickHandler = async () => {
    dispatch(openModal({ type: 'exportLogs', extra: { title, handler: downloadHandler } }));
  };
  return (
    <div className="py-3" data-testid="instrumental-pannel">
      <Button
        theme={theme}
        text="Экспорт"
        Icon={<MdOutlineDownload size="1.5em" />}
        clickHandler={clickHandler}
        className="small-button"
      />
    </div>
  );
};

export default InstrumentalPannel;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tseqYj0oAMS5oKdOLdNK{padding-top:2.5rem;padding-left:2rem;padding-right:2rem;padding-bottom:2rem;background-color:var(--offcanvas-bg-color);display:flex;flex-direction:column;gap:1rem;height:120%;overflow-y:auto}.ND6KIld2p2Z2wDFjSFRx{padding:2rem;width:100%;display:flex;gap:2rem;border-radius:1rem;background-color:var(--offcanvas-item-bg-color)}.OHxWFVlmR9STrKJbWmlK{width:178px;height:178px}`, "",{"version":3,"sources":["webpack://./src/features/offcanvas/UpdateUserOffcanvas/UpdateUserOffcanvas.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,mBAAA,CACA,0CAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,UAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CACA,+CAAA,CAEF,sBACE,WAAA,CACA,YAAA","sourcesContent":[".Container {\n  padding-top: 2.5rem;\n  padding-left: 2rem;\n  padding-right: 2rem;\n  padding-bottom: 2rem;\n  background-color: var(--offcanvas-bg-color);\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  height: 120%;\n  overflow-y: auto;\n}\n.UpdateFormField {\n  padding: 2rem;\n  width: 100%;\n  display: flex;\n  gap: 2rem;\n  border-radius: 1rem;\n  background-color: var(--offcanvas-item-bg-color);\n}\n.Icon {\n  width: 178px;\n  height: 178px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `tseqYj0oAMS5oKdOLdNK`,
	"UpdateFormField": `ND6KIld2p2Z2wDFjSFRx`,
	"Icon": `OHxWFVlmR9STrKJbWmlK`
};
export default ___CSS_LOADER_EXPORT___;

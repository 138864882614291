import { createSlice } from '@reduxjs/toolkit';
interface chatState {
  refresh: boolean;
}

const initialState: chatState = {
  refresh: false,
};

const chatMessages = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setRefresh: (state, { payload }) => {
      state.refresh = payload.refresh;
    },
  },
});

export const { setRefresh } = chatMessages.actions;
export default chatMessages.reducer;

import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, FloatingLabel } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import cls from './UpdateAssistantInfoModal.module.scss';
import { closeModal } from '../../../store/slices/modalSlice';
import { useFormik } from 'formik';
import { AssistantToolType, OpenAIModelsType } from '../settings.types';
import {
  useCheckAssistantVerifyByIdQuery,
  useGetAllOpenAIModelsQuery,
  useUpdateAssistantByIdMutation,
} from '../settings.service';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';

const UpdateAssistantInfoModal = () => {
  const extraModal = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [models, setModels] = useState<OpenAIModelsType | null>(null);
  const { data: allModels, isLoading } = useGetAllOpenAIModelsQuery();
  const { data: isVerified } = useCheckAssistantVerifyByIdQuery({
    id: extraModal?.assistantId || '',
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [updateHandler] = useUpdateAssistantByIdMutation();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  const formik = useFormik({
    initialValues: {
      model: extraModal?.assistantModel || '',
      instructions: extraModal?.assistantInstructions || '',
      id: extraModal?.assistantId || '',
    },
    onSubmit: async (values) => {
      try {
        await updateHandler({
          type: extraModal?.assistantType as AssistantToolType,
          id: extraModal?.assistantId || '',
          data: {
            ...values,
            id: extraModal?.id || '',
          },
        }).unwrap();
        if (extraModal?.handler) {
          extraModal?.handler();
        }
        closeHandler();
        toast.success('Информация об ассистенте обновлена');
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            toast.error('Не удалось обновить информацию об ассистенте');
          } else if (error.status === 403) {
            toast.error('Вам запрещенны изменения');
          } else if (error.status === 404) {
            toast.error('Ассистент не найден');
          }
        }
      }
    },
  });
  useEffect(() => {
    if (!isLoading && allModels) {
      setModels(allModels);
    }
  }, [allModels, isLoading]);
  useEffect(() => {
    if (
      formik.values.model === extraModal?.assistantModel &&
      formik.values.instructions === extraModal?.assistantInstructions &&
      formik.values.id === extraModal?.id
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    formik.values,
    extraModal?.id,
    extraModal?.assistantInstructions,
    extraModal?.assistantModel,
  ]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">{extraModal?.title}</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <Form className={cls.Form} onSubmit={formik.handleSubmit}>
          <FormGroup className={cls.FirstElement}>
            <FloatingLabel controlId="id" label="ID Ассистента* ">
              <Form.Control
                type="text"
                placeholder="ID Ассистента"
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.id || !isVerified}
                value={formik.values.id}
                className={isVerified ? cls.VerifiedInput : cls.NotVerifiedInput}
              />
            </FloatingLabel>
            {isVerified && <span className={cls.Info}>Ассистент активен</span>}
            {!isVerified && <span className={cls.ErrorInfo}>Ассистент не подтверждён</span>}
            {formik.errors.id && (
              <span className={cls.ErrorInfo}>Ошибка. Некорректный ID ассистента</span>
            )}
          </FormGroup>
          <FormGroup>
            <FloatingLabel controlId="model" label="Модель*">
              <Form.Select
                aria-label="Floating label select example"
                onChange={formik.handleChange}
                value={formik.values.model}
              >
                <option>Модель*</option>
                {models?.data.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.id}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </FormGroup>
          <FormGroup>
            <Form.Control
              as="textarea"
              onChange={formik.handleChange}
              value={formik.values.instructions}
              placeholder="Промт *"
              cols={30}
              rows={10}
              name="instructions"
            />
          </FormGroup>
          <div className={cls.Buttons}>
            <Button type="submit" className={cls.Button} disabled={disabled}>
              Сохранить
            </Button>
            <Button className={cls.CancelButton} onClick={closeHandler}>
              Отмена
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default UpdateAssistantInfoModal;

import React, { FC, ReactElement, Suspense } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useAppSelector } from '../../hooks/defaultHooks';
import * as Sentry from '@sentry/react';
import routes from '../../routes';
import MainPage from '../../screens/MainPage';
import SettingsPage from '../../screens/SettingsPage';
import SignInPage from '../../screens/SignInPage';
import SignUpPage from '../../screens/SignUpPage';
import UsersPage from '../../screens/UsersPage';
import selectors from '../../selectors';
import ConsumersPage from '../../screens/ConsumersPage';
import MessagesPage from '../../screens/MessagesPage';
import MessagePage from '../../screens/MessagePage';
import ChatIndexRoute from '../message/ChatIndexRoute';
import LeadsPage from '../../screens/LeadsPage';
import LeadPage from '../../screens/LeadPage';
import LogsPage from '../../screens/LogsPage/LogsPage';
import ForgotPasswordPage from '../../screens/ForgotPasswordPage';
import ApplicationSettingsPage from '../../screens/ApplicationSettingsPage/ApplicationSettingsPage';
import { TariffsPageLazy } from '../../screens/TariffsPage';
import { TariffPageLazy } from '../../screens/TariffPage';
import NewTariffPage from '../../screens/NewTariffPage';
import NewTariffFeaturePage from '../../screens/NewTariffFearurePage';
import { UpdateTariffFeaturePageLazy } from '../../screens/UpdateTariffFeaturePage';
import ErrorPage from '../../screens/ErrorPage';
import { CoursesPageLazy } from '../../screens/CoursesPage';
import { UpdateCoursePageLazy } from '../../screens/UpdateCoursePage';
import NewCoursePage from '../../screens/NewCoursePage';
import { CoursePageLazy } from '../../screens/CoursePage';
import { LessonPageLazy } from '../../screens/LessonPage';
import NewLessonPage from '../../screens/NewLessonPage';
import { InvoicesPageLazy } from '../../screens/InvoicesPage';
import Spinner from '../../UIComponents/Spinner';
import { UpdateTariffPageLazy } from '../../screens/UpdateTariffPage';
import { CreateAnnouncementPageLazy } from '../../screens/CreateAnnouncementPage';
import { UpdateAnnouncementPageLazy } from '../../screens/UpdateAnnouncementPage';
import { UpdateLessonPageLazy } from '../../screens/UpdateLessonPage';
import { AdmsiteSettingsPageLazy } from '../../screens/AdmsiteSettingsPage';
import { PortalSettingsPageLazy } from '../../screens/PortalSettingsPage';
import { SolutionsPageLazy } from '../../screens/SolutionsPage';
import { SolutionPageLazy } from '../../screens/SolutionPage';
import SettingsStarterRouter from '../startRoutes/SettingsStarterRouter';
import { UpdateSolutionStrategyPageLazy } from '../../screens/UpdateSolutionStrategyPage';
import { UpdateSolutionPageLazy } from '../../screens/UpdateSolutionPage';
import { ToolsPageLazy } from '../../screens/ToolsPage';
import { ToolPageLazy } from '../../screens/ToolPage';
import { UpdateToolPageLazy } from '../../screens/UpdateToolPage';
import { AssistantSettingsPageLazy } from '../../screens/AssistantSettingsPage';
import { ReferallSettingsPageLazy } from '../../screens/ReferallSettingsPage';
import { GiftOrdersPageLazy } from '../../screens/GiftOrdersPage';

interface RouterProps {
  children: ReactElement;
}

const PrivateRouter: FC<RouterProps> = ({ children }) => {
  const isAuth = useAppSelector(selectors.userAuth);
  return isAuth ? children : <Navigate to={routes.pages.signIn()} />;
};

const AuthRouter: FC<RouterProps> = ({ children }) => {
  const isAuth = useAppSelector(selectors.userAuth);
  return isAuth ? <Navigate to={routes.pages.mainPage()} /> : children;
};
// TODO: refactor router
const router = createBrowserRouter([
  {
    path: routes.pages.mainPage(),
    element: (
      <PrivateRouter>
        <MainPage />
      </PrivateRouter>
    ),
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to={routes.pages.messagesPage()} replace={true} /> },
      {
        path: routes.pages.users(),
        element: <UsersPage />,
      },
      {
        path: routes.pages.invoicments(),
        element: (
          <Suspense fallback={<Spinner />}>
            <InvoicesPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.settings(),
        element: <SettingsPage />,
      },
      {
        path: routes.pages.consumers(),
        element: <ConsumersPage />,
      },
      {
        path: routes.pages.coursesPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <CoursesPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.updateCoursePage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateCoursePageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.updateLessonPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateLessonPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.coursePage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <CoursePageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.newCoursePage(),
        element: <NewCoursePage />,
      },
      {
        path: routes.pages.lessonPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <LessonPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.newLessonPage(),
        element: <NewLessonPage />,
      },
      {
        path: routes.pages.messagesPage(),
        element: <MessagesPage />,
        children: [
          { index: true, element: <ChatIndexRoute /> },
          {
            path: routes.pages.messagePage(),
            element: <MessagePage />,
          },
        ],
      },
      {
        path: routes.pages.leadsPage(),
        element: <LeadsPage />,
      },
      {
        path: routes.pages.leadPage(),
        element: <LeadPage />,
      },
      {
        path: routes.pages.logsPage(),
        element: <LogsPage />,
      },
      {
        path: routes.pages.tariffsPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <TariffsPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.tariffPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <TariffPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.newTariffPage(),
        element: <NewTariffPage />,
      },
      {
        path: routes.pages.updateTariffPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateTariffPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.newTariffFeaturePage(),
        element: <NewTariffFeaturePage />,
      },
      {
        path: routes.pages.updateTariffFeaturePage(),
        element: (
          <Suspense>
            <UpdateTariffFeaturePageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.applicationSettingsPage(),
        element: <ApplicationSettingsPage />,
        children: [
          {
            index: true,
            element: <SettingsStarterRouter />,
          },
          {
            path: routes.pages.applicationSettingsPageForAdmin(),
            element: (
              <Suspense fallback={<Spinner />}>
                <AdmsiteSettingsPageLazy />
              </Suspense>
            ),
          },
          {
            path: routes.pages.applicationSettingsPageForPortal(),
            element: (
              <Suspense fallback={<Spinner />}>
                <PortalSettingsPageLazy />
              </Suspense>
            ),
          },
          {
            path: routes.pages.applicationsAssistantSettingsPage(),
            element: (
              <Suspense fallback={<Spinner />}>
                <AssistantSettingsPageLazy />
              </Suspense>
            ),
          },
          {
            path: routes.pages.applicationsReferallSettingsPage(),
            element: (
              <Suspense>
                <ReferallSettingsPageLazy />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: routes.pages.createAnnouncementPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <CreateAnnouncementPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.updateAnnouncementPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateAnnouncementPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.solutionsPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <SolutionsPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.solutionPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <SolutionPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.updateSolutionStrategyPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateSolutionStrategyPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.updateSolutionPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateSolutionPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.toolsPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <ToolsPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.toolPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <ToolPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.updateToolPage(),
        element: (
          <Suspense fallback={<Spinner />}>
            <UpdateToolPageLazy />
          </Suspense>
        ),
      },
      {
        path: routes.pages.giftOrders(),
        element: (
          <Suspense fallback={<Spinner />}>
            <GiftOrdersPageLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routes.pages.signIn(),
    element: (
      <AuthRouter>
        <SignInPage />
      </AuthRouter>
    ),
    index: true,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.pages.signUp(),
    element: <SignUpPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.pages.forgotPassportPage(),
    element: <ForgotPasswordPage />,
    errorElement: <ErrorPage />,
  },
]);

const App: FC = () => (
  <Sentry.ErrorBoundary showDialog={true}>
    <RouterProvider router={router} />
  </Sentry.ErrorBoundary>
);
export default App;

import { announcementRoutes } from '../features/announcement/announcement.routes';
import { consumerRoutes } from '../features/consumer/consumer.routes';
import { coursesRoutes } from '../features/courses/courses.routes';
import { giftOrdersRoutes } from '../features/giftOrders/giftOrders.routes';
import { giftsRoutes } from '../features/gifts/gifts.routes';
import { invoicesRoutes } from '../features/invoices/invoices.routes';
import { messageRoutes } from '../features/message/message.routes';
import { settingsRoutes } from '../features/settings/settings.routes';
import { solutionsRoutes } from '../features/solutions/solutions.routes';
import { tariffsRoutes } from '../features/tariff/tariff.routes';
import { toolsRoutes } from '../features/tools/tools.routes';
import { userRoutes } from '../features/user/user.routes';

export default {
  pages: {
    mainPage: () => '/',
    signUp: () => '/signup',
    signIn: () => '/signin',
    settings: () => '/settings',
    applicationSettingsPage: () => '/application/settings',
    applicationSettingsPageForAdmin: () => '/application/settings/admin',
    applicationSettingsPageForPortal: () => '/application/settings/portal',
    applicationsAssistantSettingsPage: () => '/application/settings/assistant',
    applicationsReferallSettingsPage: () => '/application/settings/referall',
    users: () => '/users',
    forgotPassportPage: () => '/users/:email/restore/:code',
    userLogsPage: () => '/users/:userId/log',
    logByUserIdPage: (id: string) => `/users/${id}/log`,
    consumers: () => '/consumers',
    consumerLogsPage: () => '/consumer/:consumerId/log',
    logByConsumerIdPage: (id: string) => `/consumer/${id}/log`,
    messagesPage: () => '/messages',
    messagePage: () => '/messages/:consumerId',
    messagePageByConsumerId: (id: string) => `/messages/${id}`,
    leadsPage: () => '/leads',
    leadPage: () => '/leads/:leadId',
    leadpageById: (id: string) => `/leads/${id}`,
    logsPage: () => 'logs',
    tariffsPage: () => '/tariffs',
    tariffPage: () => '/tariffs/:tariffId',
    updateTariffFeaturePage: () => '/tariffs/:tariffId/:featureId/update',
    updateTariffFeaturePageById: (tariffId: string, featureId: string) =>
      `/tariffs/${tariffId}/${featureId}/update`,
    tariffPageById: (id: string) => `/tariffs/${id}`,
    newTariffPage: () => '/tariffs/new',
    updateTariffPage: () => '/tariffs/:tariffId/update',
    updateTariffPageById: (tariffId: string) => `/tariffs/${tariffId}/update`,
    newTariffFeaturePageById: (tariffId: string) => `/tariffs/${tariffId}/new`,
    newTariffFeaturePage: () => '/tariffs/:tariffId/new',
    coursesPage: () => '/courses',
    newCoursePage: () => '/courses/new',
    updateCoursePage: () => '/courses/:courseId/update',
    updateCoursePageById: (courseId: string) => `/courses/${courseId}/update`,
    updateLessonPage: () => '/courses/:courseId/lessons/:lessonId/update',
    updateLessonPageById: (lessonId: string, courseId: string) =>
      `/courses/${courseId}/lessons/${lessonId}/update`,
    coursePage: () => '/courses/:courseId',
    coursePageById: (id: string) => `/courses/${id}`,
    lessonPage: () => '/courses/:courseId/lessons/:lessonId',
    lessonPageById: (lessonId: string, courseId: string) =>
      `/courses/${courseId}/lessons/${lessonId}`,
    newLessonPage: () => '/courses/:courseId/newLesson',
    newLessonPageById: (courseId: string) => `/courses/${courseId}/newLesson`,
    invoicments: () => '/invoicments',
    createAnnouncementPage: () => '/announcements/new',
    updateAnnouncementPage: () => '/announcements/:announcementId/update',
    updateAnnouncementPageById: (announcementId: string) =>
      `/announcements/${announcementId}/update`,
    solutionsPage: () => '/solutions',
    solutionPage: () => '/solutions/:solutionId',
    solutionPageById: (solutionId: string) => `/solutions/${solutionId}`,
    updateSolutionPage: () => '/solutions/:solutionId/update',
    updateSolutionPageById: (solutionId: string) => `/solutions/${solutionId}/update`,
    updateSolutionStrategyPage: () => '/solutions/:solutionId/:strategyId/update',
    updateSolutionPageStrategyById: (solutionId: string, strategyId: string) =>
      `/solutions/${solutionId}/${strategyId}/update`,
    toolsPage: () => '/tools',
    toolPage: () => '/tools/:toolId',
    toolPageById: (toolId: string) => `/tools/${toolId}`,
    updateToolPage: () => '/tools/:toolId/update',
    updateToolPageById: (toolId: string) => `/tools/${toolId}/update`,
    giftOrders: () => '/giftOrders',
  },
  api: {
    getAllLeads: () => '/leads',
    getLeadById: (leadId: string) => `/leads/${leadId}`,
    leadsExport: () => '/leads/export',
    getAllLogs: () => '/logs',
    logsExport: () => '/logs/export',
    getLogsTypes: () => '/logs/types',
    settingsIp: () => '/settings/ip',
    ...userRoutes,
    ...consumerRoutes,
    ...tariffsRoutes,
    ...coursesRoutes,
    ...invoicesRoutes,
    ...announcementRoutes,
    ...messageRoutes,
    ...solutionsRoutes,
    ...toolsRoutes,
    ...settingsRoutes,
    ...giftsRoutes,
    ...giftOrdersRoutes,
    // eslint-disable-next-line no-undef
    basePath: () => process.env.BACKEND_PATH,
  },
};

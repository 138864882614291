import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useGeneratePasswordMutation, useUpdateUserPasswordMutation } from '../user.service';
import { toast } from 'react-toastify';
import cls from './ChangePasswordForm.module.scss';
import { BiSolidShow } from 'react-icons/bi';

interface InitialValues {
  generatePassword: boolean;
  newPassword: string;
  repeatPassword: string;
}

interface ChangePasswordFormProps {
  closeHandler: () => void;
  id: string | undefined;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ closeHandler, id }) => {
  const [error, setError] = useState<string>('');
  const dispatch = useAppDispatch();
  const [changePassword] = useUpdateUserPasswordMutation();
  const [generate] = useGeneratePasswordMutation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const initialValues: InitialValues = {
    generatePassword: false,
    newPassword: '',
    repeatPassword: '',
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const { generatePassword, newPassword, repeatPassword } = values;
      if (generatePassword) {
        try {
          await generate(id as string).unwrap();
          toast.success('Пароль успешно изменен');
          dispatch(closeModal());
        } catch (error) {
          if (isFetchBaseQueryError(error)) {
            if (error.status === 400) {
              toast.error('Попробуйте позже');
            } else if (error.status === 403) {
              toast.error('Недостаточно прав');
            } else if (error.status === 404) {
              toast.error('Пользователь не найден');
            } else if (error.status === 412) {
              toast.error('Пароль не может быть сгенерирован');
            }
          }
        }
      } else if (newPassword === repeatPassword) {
        try {
          await changePassword({
            id: id as string,
            updates: { newPassword },
          }).unwrap();
          toast.success('Пароль успешно изменен');
          dispatch(closeModal());
        } catch (error) {
          if (isFetchBaseQueryError(error)) {
            if (error.status === 400) {
              toast.error('Попробуйте позже');
            } else if (error.status === 403) {
              toast.error('Недостаточно прав');
            } else if (error.status === 404) {
              toast.error('Пользователь не найден');
            }
          }
        }
      } else {
        setError('Пароли не совпадают');
      }
    },
  });
  useEffect(() => {
    if (!formik.values.generatePassword) {
      if (
        formik.values.newPassword !== formik.values.repeatPassword ||
        formik.values.newPassword === ''
      ) {
        setDisabled(true);
      } else {
        setError('');
        setDisabled(false);
      }
    } else {
      setDisabled(false);
    }
  }, [formik.values.generatePassword, formik.values.newPassword, formik.values.repeatPassword]);
  return (
    <Form
      className={cls.Form}
      noValidate
      onSubmit={formik.handleSubmit}
      data-testid="change-password"
    >
      {error && <div className="text-danger">{error}</div>}
      <div className={cls.GenerateField}>
        <Form.Label htmlFor="generatePassword">
          Создать автоматически и отправить на email
        </Form.Label>
        <Form.Check
          name="generatePassword"
          id="generatePassword"
          checked={formik.values.generatePassword}
          onChange={formik.handleChange}
          type="switch"
        />
      </div>
      {!formik.values.generatePassword ? (
        <div className={cls.PasswordFields}>
          <div className={cls.InputWrapper}>
            <Form.Label htmlFor="newPassword">Новый пароль *</Form.Label>
            <div>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                id="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                className={cls.Input}
              />
              {formik.values.newPassword.length !== 0 && (
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className={cls.ShowPassword}
                >
                  <BiSolidShow />
                </button>
              )}
            </div>
          </div>
          <div className={cls.InputWrapper}>
            <Form.Label htmlFor="repeatPassword">Повторите пароль *</Form.Label>
            <div>
              <Form.Control
                type={showRepeatPassword ? 'text' : 'password'}
                name="repeatPassword"
                id="repeatPassword"
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                className={cls.Input}
              />
              {formik.values.repeatPassword.length !== 0 && (
                <button
                  type="button"
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  className={cls.ShowPassword}
                >
                  <BiSolidShow />
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div className={cls.Buttons}>
        <Button
          type="submit"
          className={disabled ? `${cls.Button} ${cls.Disabled}` : `${cls.Button}`}
          disabled={disabled}
        >
          {formik.values.generatePassword ? 'Создать и отправить' : 'Изменить пароль'}
        </Button>
        <Button onClick={closeHandler} className={cls.CancelButton}>
          Отмена
        </Button>
      </div>
    </Form>
  );
};

export default ChangePasswordForm;

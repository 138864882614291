import React, { FC } from 'react';
import { useAboutUserQuery } from '../user.service';
import { User } from '../user.types';
import cls from './UserCard.module.scss';
import UserCardInfoBlock from '../UserCardInfoBlock';
import UserCardSubInfoBlock from '../UserCardSubInfoBlock';

type UserCardProps = {
  user: User | undefined;
};

const UserCard: FC<UserCardProps> = ({ user }) => {
  const { data: actualUser } = useAboutUserQuery();
  if (!user) return null;
  return (
    <div className={cls.Container} data-testid="user-card">
      <UserCardInfoBlock user={user} />
      <UserCardSubInfoBlock user={user} isActualUser={actualUser?.id === user?.id} />
    </div>
  );
};

export default UserCard;

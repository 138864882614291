import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Modal from '../../features/modals/Modals';
import { useAppDispatch, useAppSelector } from '../../hooks/defaultHooks';
import { getTheme } from '../../selectors/selectors';
import NavBar from '../../features/layout/navigation/NavBar';
import { useGetAllMessagesQuery } from '../../features/message/message.service';
import { setLogsTypes } from '../../store/slices/logsSlice';
import { ToastContainer } from 'react-toastify';
import { useGetLogsTypesQuery } from '../../features/log/logs.service';
import { useAboutUserQuery } from '../../features/user/user.service';
import { addUserInfo } from '../../store/slices/userSlice';
import { setUnreadChannels } from '../../store/slices/unreadMessageSlice';
import OffCanvas from '../../features/offcanvas/Offcanvas';

const MainPage: FC = () => {
  const [unClosed, setUnClosed] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const theme = useAppSelector(getTheme);
  const { data: user } = useAboutUserQuery();
  const { data: logsTypesList } = useGetLogsTypesQuery();
  const { data: unClosedDialogs } = useGetAllMessagesQuery(
    { page: 0, size: 20, filterEmail: '', type: 'UNFINALIZED' },
    { pollingInterval: 15000 },
  );
  useEffect(() => {
    setUnClosed(unClosedDialogs?.length !== 0);
    dispatch(setUnreadChannels(unClosedDialogs?.length ?? 0));
  }, [unClosedDialogs, dispatch]);

  useEffect(() => {
    if (logsTypesList) {
      dispatch(setLogsTypes(logsTypesList));
    }
  }, [dispatch, logsTypesList]);
  useEffect(() => {
    if (user) {
      dispatch(addUserInfo(user));
    }
  }, [user, dispatch]);
  return (
    <Container className={`${theme}-root-container`} fluid>
      <Row>
        <Col className="d-flex p-0 height-100">
          <NavBar unClosed={unClosed} />
          <div className="w-100">
            <Outlet />
          </div>
        </Col>
      </Row>
      <Modal />
      <OffCanvas />
      <ToastContainer autoClose={5000} theme={theme} />
    </Container>
  );
};
export default MainPage;

import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import cls from './ConfirmInvoice.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { useUpdateInvoiceStatusMutation } from '../../invoices/invoices.service';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import { closeModal } from '../../../store/slices/modalSlice';

const ConfirmInvoice = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [changeInvoiceStatus] = useUpdateInvoiceStatusMutation();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const formik = useFormik({
    initialValues: {
      number: '',
      date: '',
    },
    onSubmit: async (values) => {
      if (extra?.id) {
        try {
          await changeInvoiceStatus({
            id: extra?.id as string,
            status: 'PAID',
            numberPaid: Number(values.number),
            datePaid: values.date,
          }).unwrap();
          dispatch(closeModal());
          if (extra?.handler !== undefined) extra.handler();
          toast.success('Изменения успешно сохранены');
        } catch (error) {
          if (isFetchBaseQueryError(error)) {
            if (error.status === 400) {
              toast.error('Повторите попытку позже');
            }
            if (error.status === 403) {
              toast.error('Запрещенно');
            }
          }
        }
      }
    },
  });
  useEffect(() => {
    if (formik.values.number && formik.values.date) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formik.values.number, formik.values.date]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Подтвердить оплату счёта</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <Form onSubmit={formik.handleSubmit} className={cls.Form}>
          <FormGroup>
            <FormLabel>Номер входящего платежа</FormLabel>
            <FormControl
              placeholder="№"
              onChange={formik.handleChange}
              value={formik.values.number}
              name="number"
              type="text"
              id="number"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Дата платежа</FormLabel>
            <FormControl
              onChange={formik.handleChange}
              value={formik.values.date}
              name="date"
              type="date"
              id="date"
            />
          </FormGroup>
          <div className={cls.Buttons}>
            <Button type="submit" className="w-100" disabled={disabled}>
              Подтвердить
            </Button>
            <Button className="w-100" variant="outline-dark" onClick={closeHandler}>
              Отмена
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default ConfirmInvoice;

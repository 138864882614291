// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gOTMb1GYKIJFlRyprdBe{display:flex;width:100%;gap:.5rem}.TfVuevt1KBvmdwZJlJv_{background-color:var(--messenger-input-bg-color);max-height:40px;padding:11px 12px 11px 12px;border-radius:1rem;border:none;outline:none;font-size:16px;font-weight:400;line-height:20.7px}.TfVuevt1KBvmdwZJlJv_::placeholder{color:var(--messenger-input-placeholder-color)}.TfVuevt1KBvmdwZJlJv_:active,.TfVuevt1KBvmdwZJlJv_:hover,.TfVuevt1KBvmdwZJlJv_:focus{outline:none;border:none;background-color:var(--messenger-input-bg-color)}.WwD9GpGkjnHqxKVC3Z8d{width:40px;height:40px;padding:3px 8px 3px 8px;border-radius:4rem}`, "",{"version":3,"sources":["webpack://./src/features/chat/SendMessageForm/SendMessage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,SAAA,CAEF,sBACE,gDAAA,CACA,eAAA,CACA,2BAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,mCACE,8CAAA,CAEF,qFAGE,YAAA,CACA,WAAA,CACA,gDAAA,CAGJ,sBACE,UAAA,CACA,WAAA,CACA,uBAAA,CACA,kBAAA","sourcesContent":[".Form {\n  display: flex;\n  width: 100%;\n  gap: 0.5rem;\n}\n.Input {\n  background-color: var(--messenger-input-bg-color);\n  max-height: 40px;\n  padding: 11px 12px 11px 12px;\n  border-radius: 1rem;\n  border: none;\n  outline: none;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 20.7px;\n  &::placeholder {\n    color: var(--messenger-input-placeholder-color);\n  }\n  &:active,\n  &:hover,\n  &:focus {\n    outline: none;\n    border: none;\n    background-color: var(--messenger-input-bg-color);\n  }\n}\n.Button {\n  width: 40px;\n  height: 40px;\n  padding: 3px 8px 3px 8px;\n  border-radius: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": `gOTMb1GYKIJFlRyprdBe`,
	"Input": `TfVuevt1KBvmdwZJlJv_`,
	"Button": `WwD9GpGkjnHqxKVC3Z8d`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import { modalExtra } from '../../../selectors/selectors';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useMakeConsumerTransactionMutation } from '../../consumer/consumer.service';
import { convertTransactionAmount } from '../../../utils/convertTransactionAmount';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import cls from './MakeConsumerTransaction.module.scss';

const MakeConsumerTransaction = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [makeTransactionByAdmin] = useMakeConsumerTransactionMutation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const resetHandler = extra?.handler as () => void;
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const validationSchema = yup
    .object()
    .strict()
    .shape({
      amount: yup
        .number()
        .min(0.01, 'Минимальное значение поля 0.01')
        .required('Поле является обязательным'),
    });

  const formik = useFormik({
    initialValues: { amount: 0, type: 'INCOMING' },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { amount, type } = values;
      try {
        if (amount.toString().includes('.') && amount.toString().split('.')[1].length > 2) {
          setDisabled(true);
          formik.setErrors({ amount: 'Не правильный формат суммы' });
          toast.error('Не правильный формат суммы');
        } else {
          const finallyAmount = convertTransactionAmount(amount);
          setDisabled(false);
          try {
            await makeTransactionByAdmin({
              id: extra?.id as string,
              amount: finallyAmount,
              typeOperation: type,
            }).unwrap();
            resetHandler();
            toast.success('Пополнение прошло успешно');
            dispatch(closeModal());
          } catch (error) {
            if (isFetchBaseQueryError(error)) {
              if (error.status === 400) {
                toast.error('Повторите попытку позже');
              } else if (error.status === 403) {
                toast.error('Запрещено');
              } else if (error.status === 404) {
                toast.error('Пользователь не найден');
              }
            }
          } finally {
            setDisabled(false);
          }
        }
      } catch (error) {
        console.log(error);
        toast.error('Упс. Что-то пошло не так');
      } finally {
        setDisabled(false);
      }
    },
  });
  useEffect(() => {
    if (formik.values.amount === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formik.values.amount]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>
          </p>
        </div>
        <Form onSubmit={formik.handleSubmit} className={cls.Form}>
          <Form.Group className="position-relative">
            <Form.Label htmlFor="amount">Введите сумму</Form.Label>
            <Form.Control
              type="number"
              value={formik.values.amount}
              placeholder="Введите сумму"
              onChange={formik.handleChange}
              name="amount"
              id="amount"
              isInvalid={!!formik.errors.amount}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.amount}
            </Form.Control.Feedback>
          </Form.Group>
          <div>
            <label htmlFor="type">Выберите тип операции</label>
            <Form.Select onChange={(event) => formik.handleChange(event)} name="type" id="type">
              <option disabled>Выбрать транзакцию.</option>
              <option value="INCOMING" id="INCOMING">
                Пополнить
              </option>
              <option value="OUTGOING" id="OUTGOING">
                Списать
              </option>
            </Form.Select>
          </div>
          <div className={cls.Buttons}>
            <Button type="submit" className={cls.Button} disabled={disabled}>
              {extra?.actionButtonText}
            </Button>
            <Button onClick={closeHandler} className={cls.CancelButton}>
              Отмена
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default MakeConsumerTransaction;

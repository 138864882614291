// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mJNrwCbVoasbafqzyVtt{padding:2rem;width:100%;display:flex;flex-direction:column;border-radius:1rem;background-color:var(--offcanvas-item-bg-color)}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerTabsPanel/ConsumerTabsPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,+CAAA","sourcesContent":[".Container {\n  padding: 2rem;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  border-radius: 1rem;\n  background-color: var(--offcanvas-item-bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `mJNrwCbVoasbafqzyVtt`
};
export default ___CSS_LOADER_EXPORT___;

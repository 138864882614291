import React, { useState } from 'react';
import CreateOrChangeCourseForm from '../../features/courses/CreateOrChangeCourseForm';
import { NewCourse, UploadImageType } from '../../features/courses/courses.types';
import { useCreateCourseMutation } from '../../features/courses/courses.service';
import { isFetchBaseQueryError } from '../../utils/helpers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './NewCoursePage.module.scss';
import Breadcrumbs from '../../UIComponents/Breadcrumbs';

const NewCoursePage = () => {
  const initialValues: NewCourse = {
    name: '',
    imageId: '',
    cost: 0,
    descriptionFull: '',
    description: '',
  };
  const [createCourse] = useCreateCourseMutation();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [image, setImage] = useState<UploadImageType>({
    src: '',
    fullName: '',
    id: '',
  });
  const navigate = useNavigate();
  useDocumentTitle('Добавить курс');
  const submitHandler = async (course: NewCourse) => {
    try {
      await createCourse({ ...course, imageId: image.id }).unwrap();
      toast.success('Курс успешно создан');
      navigate(routes.pages.coursesPage());
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Повторите попытку позже');
        }
        if (error.status === 401) {
          toast.error('Создание не удалось');
        }
      }
    }
  };
  return (
    <div className={`${cls.Container} grid-container`}>
      <Breadcrumbs
        elements={[
          { name: 'Курсы', link: routes.pages.coursesPage(), active: false },
          { name: 'Добавить курс', link: '', active: true },
        ]}
        className={cls.BreadCrumbs}
      />

      <h2 className={`${cls.Title} title-1`}>Добавить курс</h2>
      <CreateOrChangeCourseForm
        initialValues={initialValues}
        submitHandler={submitHandler}
        className={cls.CreateOrChangeCourseForm}
        disabled={disabled}
        setImage={setImage}
        image={image}
        setDisabled={setDisabled}
        showImage={showImage}
        setShowImage={setShowImage}
      />
    </div>
  );
};

export default NewCoursePage;

import routes from '../routes';

export const convertBinaryToLink = (
  url: string,
  params?: { [key: string]: string | number | boolean },
) => {
  const user = JSON.parse(localStorage?.getItem('banditto') as string).accessToken;
  console.log(params);
  if (params && Object.keys(params).length > 0) {
    const paramsArray = Object.entries(params).map(([key, value]) => {
      return [key, value.toString()];
    });
    return fetch(`${url}?${new URLSearchParams(paramsArray).toString()}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((response) => {
      return response.blob().then((data) => {
        const fileName = response.headers
          .get('Content-Disposition')
          ?.split('; ')[2]
          .split('="')[1]
          .split('.')[0]
          .replace('"', '');
        console.log(fileName);
        const newBlob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName as string);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
    });
  } else {
    return fetch(`${url}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((response) => {
      return response.blob().then((data) => {
        const fileName = response.headers
          .get('Content-Disposition')
          ?.split('; ')[1]
          .split('="')[1]
          .split('.')[0]
          .replace('"', '');
        const fileType = response.headers.get('Content-Type');
        const newBlob = new Blob([data], {
          type: fileType ?? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName as string);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
    });
  }
};

export const downloadPDFFile = (url: string) => {
  const user = JSON.parse(localStorage?.getItem('banditto') as string).accessToken;
  return fetch(`${routes.api.basePath()}${url}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${user}`,
    },
  }).then((response) => {
    return response.blob().then((data) => {
      const fileName = response.headers
        .get('Content-Disposition')
        ?.split('; ')[2]
        .split('="')[1]
        .split('.')[0];
      const newBlob = new Blob([data], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName as string);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
  });
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QwKn9eyako5bD6ML4cGw{list-style:none;overflow:auto;min-height:fit-content;height:fit-content;max-height:calc(90dvh - 540px);position:relative;padding:0}.AJJ6tHkTTN1PVlotzaK7{display:grid;grid-template-columns:repeat(2, 1fr);padding:4px;gap:1rem;font-size:18px;font-weight:400;line-height:23.29px}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerCoursesList/ConsumerCoursesList.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,8BAAA,CACA,iBAAA,CACA,SAAA,CAEF,sBACE,YAAA,CACA,oCAAA,CACA,WAAA,CACA,QAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":[".List {\n  list-style: none;\n  overflow: auto;\n  min-height: fit-content;\n  height: fit-content;\n  max-height: calc(90dvh - 540px);\n  position: relative;\n  padding: 0;\n}\n.Item {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  padding: 4px;\n  gap: 1rem;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 23.29px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"List": `QwKn9eyako5bD6ML4cGw`,
	"Item": `AJJ6tHkTTN1PVlotzaK7`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R_MNVXqtLHLos1mjuGmx{border:none}.ctZ0TnsWpG8xLjH9epUm{display:flex;flex-direction:column;gap:1.5rem}.WN7iGEqW0HdYvHIF8ZVX{display:flex;gap:14px;width:100%}`, "",{"version":3,"sources":["webpack://./src/features/modals/CancelInvoice/CancelInvoice.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CAEF,sBACE,YAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".ModalHeader {\n  border: none;\n}\n.ModalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n.Buttons {\n  display: flex;\n  gap: 14px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalHeader": `R_MNVXqtLHLos1mjuGmx`,
	"ModalBody": `ctZ0TnsWpG8xLjH9epUm`,
	"Buttons": `WN7iGEqW0HdYvHIF8ZVX`
};
export default ___CSS_LOADER_EXPORT___;

import React, { memo, useEffect, useState } from 'react';
import { useGetAllMessagesQuery } from '../message.service';
import MessageItem from '../MessageItem';
import { Message } from '../message.types';
import { NavLink, Outlet } from 'react-router-dom';
import routes from '../../../routes';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { setRefresh } from '../../../store/slices/chatSlice';
import { addChannel } from '../../../store/slices/messageChannelSlice';
import CustomBadge from '../../../UIComponents/CustomBadge';
import cls from './MessagesContainer.module.scss';
import { FormControl } from 'react-bootstrap';
import { IoSearch } from 'react-icons/io5';
import { getUnreadCountChannel } from '../../../selectors/selectors';
import Spinner from '../../../UIComponents/Spinner';

const MessagesContainer = memo(() => {
  const dispatch = useAppDispatch();
  const [messagesPage, setMessagesPage] = useState<number>(0);
  const [scrollMessages, setScrollMessages] = useState<Message[]>([]);
  const [unansweredCount, setUnansweredCount] = useState<number>(0);
  const unClosedCount = useAppSelector(getUnreadCountChannel);
  const [key, setKey] = useState<'UNREAD' | 'UNFINALIZED' | 'ME'>('UNREAD');
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: allMessages, isLoading } = useGetAllMessagesQuery(
    { page: messagesPage, size: 10, filterEmail: searchValue, type: key },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 15000,
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (event?.target.clientHeight + event.target.scrollTop === event.target.scrollHeight) {
      const nextPage = messagesPage + 1;
      setMessagesPage(nextPage);
    }
  };
  const clickHandler = (message: Message) => {
    dispatch(setRefresh({ refresh: true }));
    dispatch(
      addChannel({
        id: message.consumerId,
        email: message.consumerEmail,
        name: message.consumerName,
      }),
    );
  };
  const changeKeyHandler = (type: 'UNREAD' | 'UNFINALIZED' | 'ME') => {
    setKey(type);
    setMessagesPage(0);
    setScrollMessages([]);
  };

  useEffect(() => {
    if (allMessages && searchValue === '') {
      const uniqueMessages = allMessages.filter(
        (message) => !scrollMessages.some((item) => item.consumerId === message.consumerId),
      );
      setScrollMessages([...scrollMessages, ...uniqueMessages]);
    } else if (allMessages && searchValue !== '') {
      const uniqueMessages = allMessages.filter(
        (message) => !scrollMessages.some((item) => item.consumerId === message.consumerId),
      );
      setScrollMessages([...uniqueMessages]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages]);

  useEffect(() => {
    setUnansweredCount(unClosedCount);
  }, [unClosedCount]);
  useEffect(() => {
    if (unansweredCount === 0) {
      setKey('UNREAD');
    }
  }, [unansweredCount]);
  return (
    <div className={cls.Content} data-testid="users-messages">
      <div className={cls.Badges}>
        <CustomBadge
          handler={key === 'UNREAD' ? undefined : () => changeKeyHandler('UNREAD')}
          isActive={key === 'UNREAD'}
          text="Все"
          className={cls.Badge}
        />
        {unansweredCount > 0 && (
          <CustomBadge
            handler={key === 'UNFINALIZED' ? undefined : () => changeKeyHandler('UNFINALIZED')}
            isActive={key === 'UNFINALIZED'}
            text={`Неотвеченные ${unansweredCount}`}
            className={cls.Badge}
          />
        )}
        <CustomBadge
          handler={key === 'ME' ? undefined : () => changeKeyHandler('ME')}
          isActive={key === 'ME'}
          text="Мои"
          className={cls.Badge}
        />
      </div>
      <div className="d-flex">
        <div className={cls.Aside}>
          <div className={cls.SearchField}>
            <IoSearch className={cls.SearchIcon} />
            <FormControl
              placeholder="Поиск"
              className={cls.Search}
              type="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          {isLoading && <Spinner />}
          {!isLoading && (
            <ul
              className={`messages-list ${cls.List}`}
              data-testid="message-list"
              onScroll={scrollHandler}
            >
              {scrollMessages?.map((message) => (
                <NavLink
                  to={routes.pages.messagePageByConsumerId(message.consumerId)}
                  key={message.consumerId}
                  data-testid="message-consumer"
                  onClick={() => clickHandler(message)}
                  className={({ isActive }) => (isActive ? `${cls.Item} ${cls.Active}` : cls.Item)}
                >
                  {<MessageItem message={message} />}
                </NavLink>
              ))}
            </ul>
          )}
        </div>
        <Outlet />
      </div>
    </div>
  );
});

export default MessagesContainer;

import { configureStore, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import { bandittoApi } from './api/api';
import authReducer from './slices/authSlice';
import modalReducer from './slices/modalSlice';
import themeReducer from './slices/themeSlice';
import chatReducer from './slices/chatSlice';
import userReducer from './slices/userSlice';
import logsTypesReducer from './slices/logsSlice';
import searchReducer from './slices/searchSlice';
import messageChannelReducer from './slices/messageChannelSlice';
import invoicesFilterReducer from './slices/invoicesFilterSlice';
import consumerFilterReducer from './slices/consumerFilterSlice';
import tariffReducer from './slices/tariffSlice';
import unreadMessageReducer from './slices/unreadMessageSlice';
import offcanvasReducer from './slices/offcanvasSlice';
import logsFilterReducer from './slices/logsFilterSlice';
const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  themes: themeReducer,
  chat: chatReducer,
  user: userReducer,
  logsTypes: logsTypesReducer,
  search: searchReducer,
  messageChannel: messageChannelReducer,
  invoicesFilters: invoicesFilterReducer,
  tariff: tariffReducer,
  unreadChannels: unreadMessageReducer,
  offcanvas: offcanvasReducer,
  consumerFilters: consumerFilterReducer,
  logsFilter: logsFilterReducer,
  [bandittoApi.reducerPath]: bandittoApi.reducer,
});
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
        bandittoApi.middleware,
      ),
    preloadedState,
    devTools: true,
  });
}
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

import React from 'react';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getUserInfo } from '../../selectors/selectors';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './ApplicationSettingsPage.module.scss';
import { Outlet } from 'react-router-dom';
import ApplicationSettingsNavigation from '../../features/layout/ApplicationSettingsNavigation';

const ApplicationSettingsPage = () => {
  const role = useAppSelector(getUserInfo)?.roleName ?? 'Пользователь';
  useDocumentTitle('Настройки приложения');
  if (role === 'Пользователь') return null;
  return (
    <div data-testid="settings-container" className="h-100 py-3 px-5 overflow-auto">
      <h2 className={cls.Title} data-testid="settings-title">
        Настройки
      </h2>
      <div>
        <ApplicationSettingsNavigation />
        <Outlet />
      </div>
    </div>
  );
};

export default ApplicationSettingsPage;

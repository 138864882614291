import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';

const SettingsStarterRouter = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(routes.pages.applicationSettingsPageForPortal());
  }, [navigate]);
  return <div></div>;
};

export default SettingsStarterRouter;

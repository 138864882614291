import React, { useState } from 'react';
import ChatContainer from '../../features/chat/ChatContainer';
import { useParams } from 'react-router-dom';
import { useGetMessagesByConsumerIdQuery } from '../../features/consumer/consumer.service';
import { ConsumerMessage } from '../../features/message/message.types';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getActualMessageChannel, getRefreshChat } from '../../selectors/selectors';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const MessagePage = () => {
  const size = 10;
  const { consumerId } = useParams();
  const refresh = useAppSelector(getRefreshChat);
  const messageChannel = useAppSelector(getActualMessageChannel);
  const [allMessages, setAllMessages] = useState<ConsumerMessage[]>([]);
  const [scrollTop, setScrollTop] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const { currentData: consumerMessages, isLoading } = useGetMessagesByConsumerIdQuery(
    {
      id: consumerId as string,
      page: !scrollTop ? 0 : page,
      size,
      loggerAnEvent: refresh,
    },
    {
      pollingInterval: 15000,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: 15000,
      skip: false,
    },
  );
  useDocumentTitle(`Сообщения ${messageChannel.name || messageChannel.email} | Клиенты`);
  return (
    <ChatContainer
      consumerId={consumerId as string}
      allMessages={allMessages as ConsumerMessage[]}
      consumerMessages={consumerMessages as ConsumerMessage[]}
      setAllMessages={setAllMessages}
      setScrollTop={setScrollTop}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
    />
  );
};
export default MessagePage;

import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { useUpdateCabinetsCountMutation } from '../../consumer/consumer.service';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import cls from './ChangeCabinetsCount.module.scss';

const ChangeCabinetsCount = () => {
  const extra = useAppSelector(modalExtra);
  const [cabinetsCount, setCabinetCount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const [updateCabinetCount] = useUpdateCabinetsCountMutation();
  const minusValueHandler = () => {
    if (cabinetsCount > 0) {
      setCabinetCount(cabinetsCount - 1);
    }
  };
  const addValueHandler = () => {
    setCabinetCount(cabinetsCount + 1);
  };
  const sendButtonHandler = async () => {
    try {
      await updateCabinetCount({
        id: extra?.id as string,
        maxCountCabinets: cabinetsCount,
      }).unwrap();
      toast.success('Количество кабинетов изменено');
      dispatch(closeModal());
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Повторите попытку позже');
        } else if (error.status === 403) {
          toast.error('Запрещено');
        } else if (error.status === 404) {
          toast.error('Пользователь не найден');
        }
      }
    }
  };
  useEffect(() => {
    if (extra) {
      setCabinetCount(extra?.cabinetsCount as number);
    }
  }, [extra]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>
          </p>
        </div>
        <div className={cls.FormContainer}>
          <span>Количество кабинетов</span>
          <Form data-testid="change-form" className={cls.Form}>
            <Button onClick={minusValueHandler} className={`${cls.Button} ${cls.FormButton}`}>
              -
            </Button>
            <Form.Control
              type="number"
              value={cabinetsCount}
              readOnly
              className={cls.FormInput}
              data-testid="cabinets-count"
            />
            <Button onClick={addValueHandler} className={`${cls.Button} ${cls.FormButton}`}>
              +
            </Button>
          </Form>
        </div>
        <div className={cls.Buttons}>
          <Button
            type="button"
            className={cls.Button}
            onClick={sendButtonHandler}
            data-testid="send-button"
          >
            {extra?.actionButtonText}
          </Button>
          <Button className={cls.CancelButton} onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default ChangeCabinetsCount;

import React, { FC, useEffect, useState } from 'react';
import { ConsumerIntegration } from '../consumer.types';
import Spinner from '../../../UIComponents/Spinner';
import ConsumerIntegrationItem from '../ConsumerIntegrationItem';
import cls from './ConsumerIntegrationsList.module.scss';
import ConsumerIntegrationsListHeader from '../ConsumerIntegrationsListHeader';

interface ConsumerIntegrationsListProps {
  consumerIntegrations: ConsumerIntegration[] | undefined;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ConsumerIntegrationsList: FC<ConsumerIntegrationsListProps> = ({
  consumerIntegrations,
  isLoading,
  page,
  setPage,
}) => {
  const [scrollIntegrationsList, setScrollIntegrationsList] = useState<ConsumerIntegration[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.transactions-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      scrollIntegrationsList
    ) {
      setPage(++page);
    }
  };
  useEffect(() => {
    if (consumerIntegrations) {
      setScrollIntegrationsList([...scrollIntegrationsList, ...consumerIntegrations]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerIntegrations]);
  if (isLoading) return <Spinner />;
  if (!isLoading && scrollIntegrationsList?.length === 0)
    return (
      <h5 data-testid="without-integrations" className="title-5 mt-3">
        Интеграции не найдены
      </h5>
    );
  return (
    <div>
      <ConsumerIntegrationsListHeader />
      <ul
        className={`${cls.List} integrations-list`}
        onScroll={(event) => scrollHandler(event)}
        data-testid="integrations-list"
      >
        {scrollIntegrationsList.map((integration) => (
          <li key={integration.id}>
            <ConsumerIntegrationItem integration={integration} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConsumerIntegrationsList;

import React, { useState } from 'react';
import CreateOrChangeTariffForm from '../../features/tariff/CreateOrChangeTariffForm';
import {
  useCreateNewTariffMutation,
  useUpdateTariffLimitsMutation,
} from '../../features/tariff/tariff.service';
import { isFetchBaseQueryError } from '../../utils/helpers';
import {
  NewTariff,
  TariffIntegrationLimitType,
  TariffSolutionLimitsType,
  TariffToolLimitsType,
} from '../../features/tariff/tariff.types';
import { toast } from 'react-toastify';
import routes from '../../routes';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './NewTariffPage.module.scss';
import Breadcrumbs from '../../UIComponents/Breadcrumbs';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SetTariffLimitsTab from '../../features/tariff/SetTariffLimitsTab';

const NewTariffPage = () => {
  const [createNewTariff] = useCreateNewTariffMutation();
  const [activeKey, setActiveKey] = useState<string>('INFO');
  const [updateLimits] = useUpdateTariffLimitsMutation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [tariffId, setTariffId] = useState<string>('');
  const [integrationsLimits, setIntegrationsLimits] = useState<TariffIntegrationLimitType[]>([]);
  const [toolsLimits, setToolsLimits] = useState<TariffToolLimitsType[]>([]);
  const [solutionsLimits, setSolutionsLimits] = useState<TariffSolutionLimitsType[]>([]);
  const navigate = useNavigate();
  const initialValues = {
    name: '',
    type: '',
    cost: 0,
    refundable: false,
    convertable: false,
    description: '',
    renewable: false,
    periodType: '',
    periodAmount: 0,
    periodExceedHours: 0,
    label: '',
    teaser: '',
  };
  const [text, setText] = useState<string>('');
  const handleSubmit = async (values: NewTariff) => {
    const { cost } = values;
    setDisabled(true);
    try {
      const result = await createNewTariff({
        ...values,
        cost: cost * 100,
        description: text,
        label: values.label ? values.label : null,
      }).unwrap();
      if (result.id) {
        setTariffId(result.id);
        setIntegrationsLimits(result.tariffIntegrationLimits);
        setToolsLimits(result.tariffToolLimits);
        setSolutionsLimits(result.tariffSolutionLimits);
        setActiveKey('LIMITS');
      }
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        setDisabled(false);
        if (error.status === 400) {
          toast.error('Создание не удалось');
        }
        if (error.status === 401) {
          toast.error('Неверные данные');
        }
        if (error.status === 403) {
          toast.error('Запрещено');
        }
      }
    } finally {
      setDisabled(false);
    }
  };
  const updateLimitsHandler = async () => {
    const updateIntegrations = integrationsLimits.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.limit,
      };
    }, {});
    const updateTools = toolsLimits.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.included,
      };
    }, {});
    const updateSolutions = solutionsLimits.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.limit,
      };
    }, {});
    const limits = {
      tariffIntegrationLimits: updateIntegrations,
      tariffToolLimits: updateTools,
      tariffSolutionLimits: updateSolutions,
    };
    try {
      await updateLimits({
        id: tariffId,
        limits,
      }).unwrap();
      navigate(routes.pages.tariffsPage());
      toast.success('Лимиты успешно обновлены');
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Создание не удалось');
        }
        if (error.status === 403) {
          toast.error('Запрещено');
        }
        if (error.status === 404) {
          toast.error('Тариф не найден');
        }
      }
    }
  };
  useDocumentTitle('Создать новый тариф');
  return (
    <div data-testid="tariffs-container" className={`${cls.Container} grid-container`}>
      <Breadcrumbs
        elements={[
          { name: 'Тарифы', link: routes.pages.tariffsPage(), active: false },
          { name: 'Добавить тариф', link: '', active: true },
        ]}
        className={cls.BreadCrumbs}
      />
      <h2 className={`${cls.Title} title-1`}>Создать новый тариф</h2>
      <div className={cls.Content}>
        <Tabs
          fill={false}
          defaultActiveKey={activeKey}
          variant="pills"
          onSelect={(key) => setActiveKey(key as string)}
          className={cls.Tabs}
          key={activeKey}
        >
          <Tab eventKey="INFO" title="Информация" className={cls.Tab} disabled={tariffId !== ''}>
            <CreateOrChangeTariffForm
              initialValues={initialValues}
              disabled={disabled}
              handleSubmit={handleSubmit}
              isNew={true}
              setText={setText}
              text={text}
              setDisabled={setDisabled}
            />
          </Tab>
          <Tab eventKey="LIMITS" title="Лимиты" className={cls.Tab} disabled={tariffId === ''}>
            <SetTariffLimitsTab
              integrations={integrationsLimits}
              tools={toolsLimits}
              solutions={solutionsLimits}
              updateLimitsHandler={updateLimitsHandler}
              setIntegrationsLimits={setIntegrationsLimits}
              setToolsLimits={setToolsLimits}
              setSolutionsLimits={setSolutionsLimits}
              isNew={true}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default NewTariffPage;

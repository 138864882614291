import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useUnblockUserMutation } from '../../user/user.service';
import { toast } from 'react-toastify';
import cls from './UnlockUser.module.scss';

const UnLockUser = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [unBlock] = useUnblockUserMutation();
  const closeHandler = () => dispatch(closeModal());
  const unBlockHandler = async () => {
    try {
      await unBlock(extra?.id as string).unwrap();
      dispatch(closeModal());
      toast.success('Пользователь успешно разблокирован');
    } catch (error) {
      toast.error('Упс. Что-то пошло не так');
      if (isFetchBaseQueryError(error)) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            toast.error('Повторите попытку позже');
          } else if (error.status === 403) {
            toast.error('Запрещено');
          } else if (error.status === 404) {
            toast.error('Пользователь не найден');
          }
        }
      }
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>? {extra?.subSupportText}
          </p>
        </div>
        <div className={cls.Buttons}>
          <Button type="button" className={cls.Button} onClick={unBlockHandler}>
            {extra?.actionButtonText}
          </Button>
          <Button onClick={closeHandler} className={cls.CancelButton}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default UnLockUser;

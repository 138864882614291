import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getTheme, modalExtra } from '../../../selectors/selectors';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../../UIComponents/Button';
import { closeModal } from '../../../store/slices/modalSlice';
import { useForgotPasswordMutation } from '../../user/user.service';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const extra = useAppSelector(modalExtra);
  const validationSchema = yup
    .object()
    .strict()
    .shape({
      email: yup
        .string()
        .email('Введен не корректный email')
        .required('Поле является обязательным')
        .trim(),
    });
  const theme = useAppSelector(getTheme);
  const dispatch = useAppDispatch();
  const [forgotPassword] = useForgotPasswordMutation();
  const initialValues: { email: string } = {
    email: extra?.email as string,
  };
  const [error, setError] = useState<string>('');
  const [successResponse, setSuccessResponse] = useState<boolean>(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        const response = await forgotPassword({ email }).unwrap();
        if (response) {
          setSuccessResponse(true);
          closeHandler();
          toast.success('Письмо было успешно отправлено');
        }
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          switch (error.status) {
            case 400:
              setError('Некорректный запрос');
              toast.error('Некорректный запрос');
              break;
            case 403:
              setError('Запрещено');
              toast.error('Запрещено');
              break;
            case 404:
              setError('Пользователь с таким email не найден');
              toast.error('Пользователь с таким email не найден');
              break;
            case 429:
              setError('Письмо уже было отправлено. Проверьте вашу почту');
              toast.error('Письмо уже было отправлено. Проверьте вашу почту');
              break;
            default:
              setError('Неизвестный тип ошибки');
              break;
          }
        }
      }
    },
  });
  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
  };
  const closeHandler = () => dispatch(closeModal());
  return (
    <>
      <Modal.Header
        className="bg-dark d-flex justify-content-center text-white"
        data-testid="modal-title"
      >
        Восстановить пароль
      </Modal.Header>
      {!successResponse ? (
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
          <div>Для восстановления пароля введите свой email в форму ниже</div>
          <Form
            data-testid="sign-in-form"
            className="d-flex flex-column justify-content-center gap-3"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            {error && <div className="text-danger">{error}</div>}
            <Form.Group className="position-relative">
              <Form.Control
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                onChange={onChangeHandle}
                className={`${theme}-input border-5 border-0`}
                isInvalid={!!formik.errors.email}
                value={formik.values.email}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex gap-5">
              <Button text="Отмена" clickHandler={closeHandler} theme={theme} />
              <Button
                theme={theme}
                className={`${theme}-export-link btn`}
                type="submit"
                text="Восстановить"
              />
            </div>
          </Form>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <p>Письмо было успешно отправлено на указанную почту</p>
          <Button text="Закрыть" clickHandler={closeHandler} theme={theme} />
        </Modal.Body>
      )}
    </>
  );
};

export default ForgotPassword;

import React, { SetStateAction } from 'react';
import {
  AdditionalMaterialType,
  UpdateCourse,
  UpdateLesson,
} from '../../features/courses/courses.types';
import { secureIP } from '../../features/ip/secure.types';
import { createSlice } from '@reduxjs/toolkit';
import { AssistantToolType } from '../../features/settings/settings.types';
interface ModalState {
  isOpened: boolean;
  type: string | null;
  extra: {
    email?: string;
    id?: string;
    cabinetsCount?: number;
    title?: string;
    supportText?: string;
    subSupportText?: string;
    actionButtonText?: string;
    actionButtonColor?: string;
    data?: secureIP;
    handler?: () => void;
    settingsHandler?: (id: string, checked: boolean) => void;
    imagesHandler?: (id: string) => void;
    link?: string;
    fileName?: string;
    textInformation?: string;
    course?: UpdateCourse;
    lesson?: UpdateLesson;
    materials?: AdditionalMaterialType[];
    materialHandler?: React.Dispatch<SetStateAction<AdditionalMaterialType[]>>;
    userRole?: string;
    checked?: boolean;
    assistantId?: string;
    assistantInstructions?: string;
    assistantModel?: string;
    assistantType?: AssistantToolType;
    giftOrderInfo?: {
      recipientAddress: string;
      recipientName: string;
      recipientPhone: string;
    };
  } | null;
}

const initialState: ModalState = {
  isOpened: false,
  type: null,
  extra: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      const { type, extra } = payload;
      state.isOpened = true;
      state.type = type;
      state.extra = extra ?? null;
    },
    closeModal: (state) => {
      state.isOpened = false;
      state.type = null;
      state.extra = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bB3eIpSE57HIS9Z0XzOp{display:grid;grid-template-columns:repeat(4, 1fr);padding:4px;gap:1rem;font-size:18px;font-weight:400;line-height:23.29px}.CHUlmpuHzoWmOj7ElYV8{word-break:break-all}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerIntegrationItem/ConsumerIntegrationItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oCAAA,CACA,WAAA,CACA,QAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CAEF,sBACE,oBAAA","sourcesContent":[".Item {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  padding: 4px;\n  gap: 1rem;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 23.29px;\n}\n.Account {\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Item": `bB3eIpSE57HIS9Z0XzOp`,
	"Account": `CHUlmpuHzoWmOj7ElYV8`
};
export default ___CSS_LOADER_EXPORT___;

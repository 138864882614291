import React from 'react';
import cls from './DeleteAnnouncementModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import { useDeleteAnnouncementMutation } from '../../announcement/announcement.service';
import { modalExtra } from '../../../selectors/selectors';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';

const DeleteAnnouncementModal = () => {
  const dispatch = useAppDispatch();
  const extraModal = useAppSelector(modalExtra);
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  const deleteAnnouncementHandler = async () => {
    try {
      if (extraModal?.handler) {
        await deleteAnnouncement({ announcementId: extraModal?.id ?? '' }).unwrap();
        extraModal.handler();
        toast.success('Объявление удалено');
        dispatch(closeModal());
      }
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Повторите попытку позже');
        } else if (error.status === 403) {
          toast.error('Запрещенно');
        }
      }
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Удалить объявление?</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div>
          <span>
            {' '}
            Вы уверены что хотите удалить объявление{' '}
            <strong className={cls.TextInformation}>{extraModal?.textInformation}</strong>
            <br />
          </span>
          <span>Пользователи перестанут видеть это объявление.</span>
        </div>
        <div className={cls.Buttons}>
          <Button type="button" className={cls.DeleteButton} onClick={deleteAnnouncementHandler}>
            Удалить
          </Button>
          <Button className={cls.CancelButton} onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default DeleteAnnouncementModal;

import React, { useLayoutEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { getOffcanvasExtra } from '../../../selectors/selectors';
import ConsumerCard from '../../consumer/ConsumerCard';
import { useGetConsumerByIdQuery } from '../../consumer/consumer.service';
import { closeOffcanvas } from '../../../store/slices/offcanvasSlice';

const ConsumerOffcanvas = () => {
  const offcanvasExtra = useAppSelector(getOffcanvasExtra);
  const { data: consumer, isLoading } = useGetConsumerByIdQuery(offcanvasExtra?.id ?? '');
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    if (!isLoading && !consumer) dispatch(closeOffcanvas());
  });
  if (isLoading) return <Spinner />;
  if (!consumer) return null;
  return <ConsumerCard consumer={consumer} consumerId={offcanvasExtra?.id as unknown as string} />;
};

export default ConsumerOffcanvas;

import React from 'react';
import cls from './DangerActionsWithCourseOrLesson.module.scss';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import { modalExtra } from '../../../selectors/selectors';

const DangerActionsWithCourseOrLesson = () => {
  const extraModal = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const actionHandler = () => {
    if (extraModal?.handler) {
      extraModal?.handler();
      closeHandler();
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">{extraModal?.title}</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div>
          <p>
            {extraModal?.supportText}{' '}
            <strong className={cls.TextInformation}>{extraModal?.textInformation}</strong>
            <br />
          </p>
          {extraModal?.subSupportText && <p>{extraModal?.subSupportText}</p>}
        </div>
        <div className={cls.Buttons}>
          <Button type="button" className={cls.DeleteButton} onClick={actionHandler}>
            {extraModal?.actionButtonText}
          </Button>
          <Button className={cls.CancelButton} onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default DangerActionsWithCourseOrLesson;

import React, { FC, memo } from 'react';
import Button from '../Button';
import { MdOutlineDownload } from 'react-icons/md';
import cls from './DownloadReportsButton.module.scss';

type DownloadReportsButtonProps = {
  clickHandler: () => void;
};

const DownloadReportsButton: FC<DownloadReportsButtonProps> = memo(({ clickHandler }) => {
  return (
    <Button
      text="Экспорт"
      Icon={<MdOutlineDownload />}
      clickHandler={clickHandler}
      className={cls.DownloadButton}
    />
  );
});

export default DownloadReportsButton;

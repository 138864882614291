import { RootState } from '../store/store';

export const userAuth = (state: RootState) => state.auth.isAuth;
export const modalIsOpened = (state: RootState) => state.modal.isOpened;
export const modalType = (state: RootState) => state.modal.type;
export const modalExtra = (state: RootState) => state.modal.extra;
export const getIsOffcanvasOpened = (state: RootState) => state.offcanvas.isOpened;
export const getOffcanvasType = (state: RootState) => state.offcanvas.type;
export const getOffcanvasExtra = (state: RootState) => state.offcanvas.extra;
export const getTheme = (state: RootState) => state.themes.theme;
export const getRefreshChat = (state: RootState) => state.chat.refresh;
export const getUserInfo = (state: RootState) => state.user.user;
export const getUserRole = (state: RootState) => state.user.user?.role;
export const getAllLogsTypes = (state: RootState) => state.logsTypes.types;
export const getConsumersSearch = (state: RootState) => state.search.consumers;
export const getLeadsSearch = (state: RootState) => state.search.leads;
export const getLogsSearch = (state: RootState) => state.search.logs;
export const getActualMessageChannel = (state: RootState) => state.messageChannel;
export const getInvoicesFilters = (state: RootState) => state.invoicesFilters;
export const getConsumerFilters = (state: RootState) => state.consumerFilters.filter;
export const getTariffInfo = (state: RootState) => state.tariff;
export const getUnreadCountChannel = (state: RootState) => state.unreadChannels.unreadChannels;
export const getLogsFilters = (state: RootState) => state.logsFilter;

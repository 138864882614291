// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JQuV6M93Z7vH3NFK6c15{padding:40px;overflow-y:auto;max-height:100vh;height:max-content;row-gap:2rem}.GrvpGScGsx32vwKXIdSg{grid-column:1/-1;height:fit-content}.MDJHhahylVv53BE98stQ{grid-column:1/-1;max-height:3rem;height:fit-content;display:flex;justify-content:space-between;align-items:center}.eEXtG3RaETsaauK6kRKz{grid-column:1/-1}`, "",{"version":3,"sources":["webpack://./src/screens/LogsPage/LogsPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,YAAA,CAEF,sBACE,gBAAA,CACA,kBAAA,CAEF,sBACE,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,gBAAA","sourcesContent":[".Container {\n  padding: 40px;\n  overflow-y: auto;\n  max-height: 100vh;\n  height: max-content;\n  row-gap: 2rem;\n}\n.Title {\n  grid-column: 1/-1;\n  height: fit-content;\n}\n.InfoPanel {\n  grid-column: 1/-1;\n  max-height: 3rem;\n  height: fit-content;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.Content {\n  grid-column: 1/-1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `JQuV6M93Z7vH3NFK6c15`,
	"Title": `GrvpGScGsx32vwKXIdSg`,
	"InfoPanel": `MDJHhahylVv53BE98stQ`,
	"Content": `eEXtG3RaETsaauK6kRKz`
};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable no-undef */
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Consumer } from '../consumer.types';
import ConsumersListHeader from '../ConsumersListHeader';
import cls from './ConsumersList.module.scss';
import { classNameMerge } from '../../../utils/classNameMerge';
import { Spinner } from 'react-bootstrap';
import ConsumerPreviewCard from '../ConsumerPreviewCard';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { deleteConsumerFilter } from '../../../store/slices/consumerFilterSlice';

type ConsumersListProps = {
  consumers: Consumer[] | undefined;
  setSearch: Dispatch<SetStateAction<{ [key: string]: string | number }>>;
  search: { [key: string]: string | number };
  scrollConsumers: Consumer[];
  setScrollConsumers: Dispatch<SetStateAction<Consumer[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setExportsPage: React.Dispatch<React.SetStateAction<number>>;
  exportsPage: number;
  exportsSize: number;
  isLoading: boolean;
  className: string;
};

const ConsumersList: FC<ConsumersListProps> = ({
  consumers,
  setSearch,
  search,
  scrollConsumers,
  setScrollConsumers,
  page,
  setPage,
  exportsPage,
  exportsSize,
  setExportsPage,
  isLoading,
  className,
}) => {
  const dispatch = useAppDispatch();
  const changeOrderHandler = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const sortField = event.currentTarget.id;
    const sortOrder = search.sortOrder === 1 ? 0 : 1;
    setPage(0);
    setScrollConsumers([]);
    setSearch({ ...search, page, sortField, sortOrder });
  };
  const resetHandler = () => {
    setSearch({ ...search, page: 0, filter: '' });
    setScrollConsumers([]);
    dispatch(deleteConsumerFilter());
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.consumers-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      scrollConsumers
    ) {
      if (scrollConsumers.length >= exportsSize) setExportsPage(++exportsPage);
      setPage(++page);
      setSearch({ ...search, page });
    }
  };
  useEffect(() => {
    if (consumers) {
      const uniqConsumers = consumers.filter(
        (consumer) => !scrollConsumers.some((item) => item.id === consumer.id),
      );
      setScrollConsumers([...scrollConsumers, ...uniqConsumers]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumers]);
  if (isLoading)
    return (
      <div className={classNameMerge(cls.List, className)}>
        <Spinner />
      </div>
    );
  if (!isLoading && consumers?.length === 0 && scrollConsumers.length === 0)
    return (
      <div className={classNameMerge(cls.ListEmpty, className)}>
        <div className={cls.EmptyText}>
          <h2 className="title-2">Поиск не дал результатов</h2>
          <ul className="longread-text">
            <li>Проверьте фильтр поискового запроса {search.filterField}</li>
            <li>Проверьте правильность ввода поискового запроса {search.filter}</li>
          </ul>
        </div>
        <button className={cls.Button} onClick={resetHandler} type="button">
          Сбросить поиск
        </button>
      </div>
    );
  return (
    <div className={classNameMerge(cls.List, className)}>
      <ConsumersListHeader changeOrderHandler={changeOrderHandler} />
      <div
        data-testid="consumers-list"
        className={`${cls.Consumers} consumers-list`}
        onScroll={scrollHandler}
      >
        {scrollConsumers.map((consumer) => (
          <ConsumerPreviewCard key={consumer.id} consumer={consumer} />
        ))}
      </div>
    </div>
  );
};

export default ConsumersList;

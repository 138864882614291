import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { SearchType } from '../search/search.types';
import { Lead } from './lead.types';

const leadsApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLeads: builder.query<Lead[], SearchType>({
      query: (params) => ({
        url: routes.api.getAllLeads(),
        params: params,
      }),
      providesTags: ['Leads', 'Lead'],
    }),
    getLeadById: builder.query<Lead, string>({
      query: (id: string) => routes.api.getLeadById(id),
      providesTags: ['Leads', 'Lead'],
    }),
  }),
});

export const { useGetAllLeadsQuery, useGetLeadByIdQuery } = leadsApi;

import { convertDateToNeedFormat } from './convertDateHandlers';
export const showDateFromToday = (date: string) => {
  const today = new Date();
  const created = Date.parse(date);
  const needDate = new Date(created);
  const offset = new Date(created).getTimezoneOffset();
  if (
    today.getDate() === needDate.getDate() &&
    today.getMonth() === needDate.getMonth() &&
    today.getFullYear() === needDate.getFullYear()
  ) {
    const hours = needDate.getHours() + Math.abs(offset / 60);
    const minutes =
      needDate.getMinutes() < 10 ? `0${needDate.getMinutes()}` : needDate.getMinutes();
    return `${hours}:${minutes}`;
  } else {
    return convertDateToNeedFormat({ date, format: 'datetime' });
  }
};
const changeMonthValue = (date: string) => {
  let month;
  switch (date) {
    case '1':
      month = 'января';
      break;
    case '2':
      month = 'февраля';
      break;
    case '3':
      month = 'марта';
      break;
    case '4':
      month = 'апреля';
      break;
    case '5':
      month = 'мая';
      break;
    case '6':
      month = 'июня';
      break;
    case '7':
      month = 'июля';
      break;
    case '8':
      month = 'августа';
      break;
    case '9':
      month = 'сентября';
      break;
    case '10':
      month = 'октября';
      break;
    case '11':
      month = 'ноября';
      break;
    case '12':
      month = 'декабря';
      break;
    default:
      month = date;
      break;
  }
  return month;
};

const convertDayView = (date: string) => {
  let day;
  switch (date) {
    case '0':
      day = 'Пн';
      break;
    case '1':
      day = 'Вт';
      break;
    case '2':
      day = 'Ср';
      break;
    case '3':
      day = 'Чт';
      break;
    case '4':
      day = 'Пт';
      break;
    case '5':
      day = 'Сб';
      break;
    case '6':
      day = 'Вс';
      break;
  }
  return day;
};

export const showDateFromTodayUpdate = (date: string) => {
  const today = new Date();
  const created = Date.parse(date);
  const needDate = new Date(created);
  if (
    today.getDate() === needDate.getDate() &&
    today.getMonth() === needDate.getMonth() &&
    today.getFullYear() === needDate.getFullYear()
  ) {
    return 'Сегодня';
  } else if (
    today.getDate() === needDate.getDate() + 1 &&
    today.getMonth() === needDate.getMonth() &&
    today.getFullYear() === needDate.getFullYear()
  ) {
    return 'Вчера';
  } else if (today.getFullYear() === needDate.getFullYear()) {
    return `${needDate.getDate()} ${changeMonthValue(
      `${needDate.getMonth() + 1}`,
    )}, ${convertDayView(`${needDate.getDay()}`)}`;
  } else {
    return `${needDate.getDate()} ${changeMonthValue(
      `${needDate.getMonth() + 1}`,
    )}, ${needDate.getFullYear()}`;
  }
};

import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { SearchType } from '../search/search.types';
import { Consumer, ConsumerBilling, ConsumerCabinet, ConsumerIntegration } from './consumer.types';
import { ConsumerMessage } from '../message/message.types';
import { PaginationRequest, PaginationRequestById } from '../../type';
import { Log } from '../log/logs.types';

export interface BlockUnblockRequest {
  id: string;
  message: string;
}

interface ConsumerTransactionRequest {
  amount: number;
  typeOperation: string;
  id: string;
}
interface ConsumerBalance {
  amount: number;
}

interface ChangeConsumerCabinets {
  id: string;
  maxCountCabinets: number;
}

interface LogsPaginationRequest extends PaginationRequest {
  consumerId: string;
  subjectId?: string;
  objectId?: string;
  startDateTime?: string;
  endDateTime?: string;
}

interface ConsumerIntegrationsRequest extends PaginationRequest {
  consumerId: string;
}
type ConsumerMessagesRequest = PaginationRequestById & { loggerAnEvent: boolean };

const consumerApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllConsumers: builder.query<Consumer[], SearchType>({
      query: ({
        size = 10,
        sortField = '',
        page = 0,
        sortOrder = 1,
        filter = '',
        filterField = 'email',
      }) => ({
        url: routes.api.getAllConsumers(),
        params: {
          size,
          sortField,
          sortOrder,
          filter,
          filterField,
          page,
        },
      }),
      providesTags: ['Consumer', 'Consumers'],
    }),
    getConsumerById: builder.query<Consumer, string>({
      query: (id: string) => routes.api.aboutConsumerById(id),
      providesTags: ['Consumer', 'Consumers'],
    }),
    lockConsumerById: builder.mutation<Consumer, BlockUnblockRequest>({
      query: ({ id, message }) => ({
        url: routes.api.lockConsumerById(id),
        method: 'PATCH',
        body: { message },
      }),
      invalidatesTags: ['Consumer'],
    }),
    unlockConsumerById: builder.mutation<Consumer, BlockUnblockRequest>({
      query: ({ id, message }) => ({
        url: routes.api.unlockConsumerById(id),
        method: 'PATCH',
        body: { message },
      }),
      invalidatesTags: ['Consumer'],
    }),
    getMessagesByConsumerId: builder.query<ConsumerMessage[], ConsumerMessagesRequest>({
      query: ({ id, page = 0, size = 10, loggerAnEvent = false }) => ({
        url: routes.api.consumerMessages(id),
        params: {
          page,
          size,
          loggerAnEvent,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Messages' as const, id })),
              { type: 'Messages', id: 'LIST' },
            ]
          : [{ type: 'Messages', id: 'LIST' }],
    }),
    getAllConsumerOperationById: builder.query<ConsumerBilling[], PaginationRequestById>({
      query: ({ id, page = 0, size = 10 }) => ({
        url: routes.api.consumerOperation(id),
        params: {
          page,
          size,
        },
      }),
      providesTags: ['Operation'],
    }),
    makeConsumerTransaction: builder.mutation<ConsumerBilling, ConsumerTransactionRequest>({
      query: ({ id, amount, typeOperation }) => ({
        url: routes.api.consumerOperation(id),
        method: 'POST',
        body: { amount, typeOperation },
      }),
      invalidatesTags: ['Operation'],
    }),
    getConsumerBalance: builder.query<ConsumerBalance, string>({
      query: (id) => routes.api.consumerBalance(id),
      providesTags: ['Operation'],
    }),
    getConsumerCabinets: builder.query<ConsumerCabinet[], PaginationRequestById>({
      query: ({ id, page = 0, size = 10 }) => ({
        url: routes.api.consumerCabinets(id),
        params: {
          page,
          size,
        },
      }),
      providesTags: ['Cabinet'],
    }),
    getConsumerIntegrations: builder.query<ConsumerIntegration[], ConsumerIntegrationsRequest>({
      query: ({ consumerId, page = 0, size = 10 }) => ({
        url: routes.api.consumerIntegrations(consumerId),
        params: {
          page,
          size,
        },
      }),
      providesTags: ['Integrations'],
    }),
    updateCabinetsCount: builder.mutation<ConsumerCabinet, ChangeConsumerCabinets>({
      query: ({ id, maxCountCabinets }) => ({
        url: routes.api.changeConsumerCabinetCount(id),
        method: 'PATCH',
        body: { maxCountCabinets },
      }),
      invalidatesTags: ['Consumer'],
    }),
    getConsumerLogsById: builder.query<Log[], LogsPaginationRequest>({
      query: (params) => ({
        url: routes.api.getLogsByConsumerId(params.consumerId),
        params,
      }),
      providesTags: ['Logs'],
    }),
    reset2FAForConsumerById: builder.mutation<Consumer, { consumerId: string }>({
      query: ({ consumerId }) => ({
        url: routes.api.resetConsumer2FAById(consumerId),
        method: 'PATCH',
      }),
      invalidatesTags: ['Consumer'],
    }),
    getAllSettings: builder.query<{ id: string; property: string | null }[], void>({
      query: () => routes.api.consumersSettings(),
      providesTags: ['Settings'],
    }),
    getSettingById: builder.query<{ id: string; property: string | null }, string>({
      query: (id) => routes.api.consumersSettingsById(id),
      providesTags: ['Settings'],
    }),
    updateSettingById: builder.mutation<
      { id: string; property: string | null },
      { id: string; property: string | null }
    >({
      query: ({ id, property }) => ({
        url: routes.api.consumersSettingsById(id),
        method: 'PATCH',
        body: { id, property },
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const {
  useGetAllConsumersQuery,
  useGetConsumerByIdQuery,
  useLockConsumerByIdMutation,
  useUnlockConsumerByIdMutation,
  useGetMessagesByConsumerIdQuery,
  useLazyGetAllConsumerOperationByIdQuery,
  useMakeConsumerTransactionMutation,
  useGetConsumerBalanceQuery,
  useLazyGetConsumerCabinetsQuery,
  useUpdateCabinetsCountMutation,
  useGetConsumerLogsByIdQuery,
  useLazyGetConsumerIntegrationsQuery,
  useReset2FAForConsumerByIdMutation,
  useGetAllSettingsQuery,
  useGetSettingByIdQuery,
  useUpdateSettingByIdMutation,
} = consumerApi;

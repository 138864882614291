import { AssistantToolType } from './settings.types';

export const settingsRoutes = {
  operationWithAssistant: (assistantId: string, type: AssistantToolType) =>
    `/settings/openai/assistants/${assistantId}/${type}`,
  allOpenAIModels: () => '/settings/openai/models',
  allOpenAIAssistants: (type: AssistantToolType) => `/settings/openai/assistants/${type}`,
  checkVerifyAssistant: (assistantId: string) =>
    `/settings/openai/assistants/${assistantId}/verify`,
};

import {
  Announcement,
  AnnouncementType,
  NewAnnouncement,
  UpdateAnnouncement,
} from './announcement.types';
import { bandittoApi } from '../../store/api/api';
import routes from '../../routes';

type AnnouncementsRequest = {
  page: number;
  size: number;
  type?: AnnouncementType | 'ALL';
  active?: boolean;
};

type AnnouncementsResponse = {
  countAll: number;
  announcements: Announcement[];
};

const announcementsApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAnnouncements: builder.query<AnnouncementsResponse, AnnouncementsRequest>({
      query: ({ page, size, type, active }) => ({
        url: routes.api.announcements(),
        params: {
          page,
          size,
          type,
          active,
        },
      }),
      providesTags: ['Announcements'],
    }),
    createNewAnnouncement: builder.mutation<Announcement, NewAnnouncement>({
      query: (announcement) => ({
        url: routes.api.announcements(),
        method: 'POST',
        body: announcement,
      }),
      invalidatesTags: ['Announcements'],
    }),
    getAnnouncementById: builder.query<Announcement, string>({
      query: (announcementId) => ({
        url: routes.api.operationsWithAnnouncement(announcementId),
      }),
      providesTags: ['Announcement'],
    }),
    deleteAnnouncement: builder.mutation<{ success: boolean }, { announcementId: string }>({
      query: ({ announcementId }) => ({
        url: routes.api.operationsWithAnnouncement(announcementId),
        method: 'DELETE',
      }),
      invalidatesTags: ['Announcement', 'Announcements'],
    }),
    updateAnnouncement: builder.mutation<Announcement, UpdateAnnouncement>({
      query: (announcement) => ({
        url: routes.api.operationsWithAnnouncement(announcement.id),
        method: 'PATCH',
        body: announcement,
      }),
      invalidatesTags: ['Announcement', 'Announcements'],
    }),
  }),
});

export const {
  useGetAllAnnouncementsQuery,
  useCreateNewAnnouncementMutation,
  useGetAnnouncementByIdQuery,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation,
} = announcementsApi;

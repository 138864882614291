import React, { FC, memo } from 'react';
import { Breadcrumb, BreadcrumbProps } from 'react-bootstrap';
import cls from './Breadcrumbs.module.scss';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps extends BreadcrumbProps {
  elements: {
    name: string;
    link: string;
    active: boolean;
  }[];
  className?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = memo((props) => {
  const { elements, className } = props;
  return (
    <Breadcrumb
      {...props}
      className={`${className} breadcrumb`}
      data-testid="breadcrumb-container"
      bsPrefix="breadcrumb"
    >
      {elements.map((element) => (
        <Breadcrumb.Item
          key={element.name}
          active={element.active}
          className="breadcrumb-item"
          data-testid="breadcrumb-item"
          bsPrefix="breadcrumb-item"
          linkAs="span"
        >
          <Link to={element.link} className={`${cls.BreadCrumbLink} regular-text`}>
            {element.name}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
});

export default Breadcrumbs;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KfukNP6cT9eSwr4srxcM{display:flex;justify-content:center;align-items:center;height:100%;width:100%;background-color:var(--messenger-index-route-bg-color);padding:0 80px;text-align:center;border:1px solid var(--messages-panel-border-color)}`, "",{"version":3,"sources":["webpack://./src/features/message/ChatIndexRoute/ChatIndexRoute.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,sDAAA,CACA,cAAA,CACA,iBAAA,CACA,mDAAA","sourcesContent":[".Container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  background-color: var(--messenger-index-route-bg-color);\n  padding: 0 80px;\n  text-align: center;\n  border: 1px solid var(--messages-panel-border-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `KfukNP6cT9eSwr4srxcM`
};
export default ___CSS_LOADER_EXPORT___;

import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import { ConsumerMessage, Message, SendMessageRequest } from './message.types';

interface CloseDialogResponse {
  id: string;
  consumerId: string;
  finalMessage: boolean;
}

type GetAllMessagesResponse = PaginationRequest & {
  type: 'UNREAD' | 'ME' | 'UNFINALIZED';
  filterEmail: string;
};

const messageApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMessages: builder.query<Message[], GetAllMessagesResponse>({
      query: ({ page = 0, size = 10, type = 'UNREAD', filterEmail = '' }) => ({
        url: routes.api.allMessages(),
        params: { page, size, type, filterEmail },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ consumerId }) => ({ type: 'Messages' as const, consumerId })),
              { type: 'Messages', id: 'LIST' },
              'Message',
            ]
          : [{ type: 'Messages', id: 'LIST' }, 'Message'],
    }),
    sendMessage: builder.mutation<ConsumerMessage, SendMessageRequest>({
      query: (message) => ({
        url: routes.api.allMessages(),
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message', 'Messages'],
    }),
    finalizeMessage: builder.mutation<CloseDialogResponse, string>({
      query: (id) => ({
        url: routes.api.finalizeMessage(),
        method: 'PATCH',
        body: { consumerId: id },
      }),
      invalidatesTags: ['Message', 'Messages'],
    }),
    unfinalizeMessage: builder.mutation<CloseDialogResponse, string>({
      query: (id) => ({
        url: routes.api.unfinalizeMessage(),
        method: 'PATCH',
        body: { consumerId: id },
      }),
      invalidatesTags: ['Message', 'Messages'],
    }),
  }),
});

export const {
  useGetAllMessagesQuery,
  useSendMessageMutation,
  useFinalizeMessageMutation,
  useUnfinalizeMessageMutation,
} = messageApi;

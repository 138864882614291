// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n8kzY2WGYOSQrFZWUOM_{position:relative}.DmU79rp_0SHZ_hQwwi_0{width:max-content;height:40px;padding:0px 28px 0px 24px;border-radius:48px;border:1px 0px 0px 0px;background:#fcfdff;border:1px solid #e9e9ea}.DmU79rp_0SHZ_hQwwi_0:hover{cursor:pointer;color:#0b6efd;border-color:#0b6efd}.p6tGddAVJPhPzbdy5pgu{display:flex;align-items:center;gap:1rem}`, "",{"version":3,"sources":["webpack://./src/features/log/LogsPanel/LogsPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEF,sBACE,iBAAA,CACA,WAAA,CACA,yBAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,wBAAA,CACA,4BACE,cAAA,CACA,aAAA,CACA,oBAAA,CAGJ,sBACE,YAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".DatePicker {\n  position: relative;\n}\n.DatePickerBtn {\n  width: max-content;\n  height: 40px;\n  padding: 0px 28px 0px 24px;\n  border-radius: 48px;\n  border: 1px 0px 0px 0px;\n  background: rgba(252, 253, 255, 1);\n  border: 1px solid rgba(233, 233, 234, 1);\n  &:hover {\n    cursor: pointer;\n    color: rgba(11, 110, 253, 1);\n    border-color: rgba(11, 110, 253, 1);\n  }\n}\n.LogsPanel {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DatePicker": `n8kzY2WGYOSQrFZWUOM_`,
	"DatePickerBtn": `DmU79rp_0SHZ_hQwwi_0`,
	"LogsPanel": `p6tGddAVJPhPzbdy5pgu`
};
export default ___CSS_LOADER_EXPORT___;

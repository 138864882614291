import React, { FC, useEffect, useState } from 'react';
import { FormControl, FloatingLabel } from 'react-bootstrap';
import { useFormik } from 'formik';
import Button from '../../../UIComponents/Button';
import EditorWrapper from '../../editor';
import { NewCourse, UpdateCourse, UploadImageType } from '../courses.types';
import cls from './CreateOrChangeCourseForm.module.scss';
import { MdOutlineAddPhotoAlternate, MdClose } from 'react-icons/md';
import routes from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

type CreateOrChangeCourseFormProps = {
  initialValues: NewCourse | UpdateCourse;
  submitHandler?: (values: NewCourse) => Promise<void>;
  updateHandler?: (values: UpdateCourse) => Promise<void>;
  className?: string;
  disabled: boolean;
  image: UploadImageType;
  setImage: React.Dispatch<React.SetStateAction<UploadImageType>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowImage: React.Dispatch<React.SetStateAction<boolean>>;
  showImage: boolean;
  isChange?: boolean;
};

const CreateOrChangeCourseForm: FC<CreateOrChangeCourseFormProps> = ({
  initialValues,
  submitHandler,
  updateHandler,
  className,
  disabled,
  image,
  setImage,
  setDisabled,
  isChange = false,
  showImage,
  setShowImage,
}) => {
  const [text, setText] = useState<string>(initialValues?.descriptionFull ?? '');
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    name: yup.string().required('Обязательное поле'),
    description: yup.string().required('Обязательное поле'),
    cost: yup
      .number()
      .required('Обязательное поле')
      .min(0, 'Минимальная стоимость 0')
      .max(10000000, 'Максимальная стоимость 10000000'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (submitHandler) {
        await submitHandler({
          ...values,
          descriptionFull: text,
          imageId: image.id,
          cost: Number(values.cost) * 100,
        });
        navigate(-1);
      } else if (updateHandler) {
        await updateHandler({
          ...values,
          descriptionFull: text,
          imageId: image.id,
          cost: Number(values.cost) * 100,
        });
        navigate(-1);
      }
    },
  });
  useEffect(() => {
    if (initialValues.name) {
      formik.setFieldValue('name', initialValues.name);
      formik.setFieldValue('description', initialValues.description);
      formik.setFieldValue('descriptionFull', initialValues.descriptionFull);
      formik.setFieldValue('cost', initialValues.cost);
      formik.setFieldValue('imageId', initialValues.imageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);
  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const reader = new FileReader();
      if (file) {
        const accessToken = JSON.parse(localStorage.getItem('banditto') || '{}').accessToken;
        const response = await fetch(`${routes.api.basePath()}${routes.api.uploadFile()}`, {
          method: 'POST',
          body: formData,
          mode: 'cors',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          reader.readAsDataURL(e.target.files![0]);
          reader.onload = async () => {
            await response.json().then((res) => {
              setImage({
                src: reader.result as string,
                fullName: res.fullName,
                id: res.id,
              });
              setShowImage(true);
            });
          };
        }
      }
    }
  };
  const clearImageHandler = async () => {
    try {
      await fetch(`${routes.api.basePath()}${routes.api.file(image.id)}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('banditto') || '{}').accessToken
          }`,
        },
      });
      setImage({ src: '', fullName: '', id: '' });
      setShowImage(false);
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };
  useEffect(() => {
    if (formik && formik.values.name && formik.values.description?.length && text && image.id) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formik, text, image, setDisabled]);
  return (
    <div className={className}>
      <form className={cls.Form} onSubmit={formik.handleSubmit} data-testid="create-course-form">
        <div className={cls.FirstLine}>
          <div className={cls.ImageBlock}>
            <h4 className="title-4">Обложка курса</h4>
            {showImage && (
              <figure className={cls.LoadedImage} data-testid="course-image">
                <img src={image.src} alt="image" className={cls.Image} />
                <figcaption className={cls.ImageCaption}>
                  <span>{image.fullName}</span>
                  <MdClose
                    onClick={async () => {
                      await clearImageHandler();
                    }}
                    className="cursor-pointer"
                  />
                </figcaption>
              </figure>
            )}
            {!showImage && (
              <>
                <label htmlFor="image" className={cls.DownloadImage} data-testid="course-image">
                  <MdOutlineAddPhotoAlternate size={42} />
                  <span className={cls.ImageLink}>Выберите обложку курса*</span>
                  <span className={cls.ImageInfo}>
                    Поддерживается только формат WEBP. Максимальное разрешение 512×512
                    px,соотношение сторон 1×1.
                  </span>
                </label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  onChange={async (e) => await uploadHandler(e)}
                  hidden
                  accept="image/webp"
                />
              </>
            )}
          </div>
          <div className={cls.InfoBlock}>
            <h4 className="title-4">Данные курса</h4>
            <div className={cls.Name}>
              <div className={cls.InputWithClear} data-testid="course-name-input">
                <FloatingLabel label="Название курса *" className="w-100">
                  <FormControl
                    type="text"
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="w-100"
                  />
                </FloatingLabel>
                {formik.values.name && (
                  <MdClose
                    className={cls.ClearButton}
                    onClick={() => formik.setFieldValue('name', '')}
                  />
                )}
                {formik.errors.name && <div className={cls.Error}>{formik.errors.name}</div>}
              </div>
              <div className={cls.Cost}>
                <div className={cls.InputWithClear}>
                  <FloatingLabel label="Стоимость курса *" className="w-100">
                    <FormControl
                      type="number"
                      name="cost"
                      id="cost"
                      onChange={formik.handleChange}
                      value={formik.values.cost}
                      className="w-100"
                    />
                  </FloatingLabel>
                  {formik?.values?.cost !== 0 && (
                    <MdClose
                      className={cls.ClearButton}
                      onClick={() => formik.setFieldValue('cost', 0)}
                    />
                  )}
                  <span className={cls.CostInfo}>Введите 0 если курс бесплатный</span>
                </div>
              </div>
            </div>
            <div className={cls.DescriptionBlock}>
              <div className={cls.DescriptionWrapper} data-testid="course-description">
                <FloatingLabel label="Краткое описание для превью *" className="h-100">
                  <FormControl
                    as="textarea"
                    name="description"
                    id="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    className="h-100"
                    required
                  />
                </FloatingLabel>
                {formik.errors.description && (
                  <div className={cls.Error}>{formik.errors.description}</div>
                )}
                {formik.values.description && formik.values.description.length > 0 && (
                  <MdClose
                    className={`${cls.ClearButton} ${cls.ClearButtonDescription}`}
                    onClick={() => formik.setFieldValue('cost', 0)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={cls.SecondLine}>
          <div className={cls.FullDescriptionBlock} data-testid="full-description-block">
            <h4 className="title-4">Полное описание курса</h4>
            <EditorWrapper setText={setText} value={text} />
          </div>
          <div className={cls.ButtonsGroup}>
            <button
              type="submit"
              className={disabled ? `${cls.SaveButton} ${cls.DisabledSaveButton}` : cls.SaveButton}
              disabled={disabled}
            >
              {!isChange ? 'Добавить' : 'Сохранить'}
            </button>
            <Button text="Отмена" className={cls.CancelButton} onClick={() => formik.resetForm()} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateOrChangeCourseForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y_2mxFZJYYA1dcWoXDo_{padding-top:40px}.velTsjiXgtwRBIcZ3Mwg{grid-column:1/-1;display:flex;justify-content:space-between;align-items:center;height:fit-content}.hSMgQNB33uMdQMyMK7wM{font-size:3rem;line-height:1.5}.OJYMJ_N0DIjFTqohWKp8{grid-column:1/-1;height:calc(100vh - 40px - 3rem - 2rem - 1.5rem - 1rem)}.Nvwl4EviHA8tk_li19Xw{grid-column:1/-1;max-height:3rem;margin-top:2rem;display:flex;justify-content:flex-end;align-items:center}.NInsDPFkI9_eo2cdPmsm{grid-column:1/-1;margin-top:1.5rem}`, "",{"version":3,"sources":["webpack://./src/screens/UsersPage/UsersPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEF,sBACE,gBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CAEF,sBACE,gBAAA,CACA,uDAAA,CAEF,sBACE,gBAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,wBAAA,CACA,kBAAA,CAEF,sBACE,gBAAA,CACA,iBAAA","sourcesContent":[".Page {\n  padding-top: 40px;\n}\n.Header {\n  grid-column: 1/-1;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: fit-content;\n}\n.Title {\n  font-size: 3rem;\n  line-height: 1.5;\n}\n.Loader {\n  grid-column: 1/-1;\n  height: calc(100vh - 40px - 3rem - 2rem - 1.5rem - 1rem);\n}\n.Panel {\n  grid-column: 1/-1;\n  max-height: 3rem;\n  margin-top: 2rem;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n.List {\n  grid-column: 1/-1;\n  margin-top: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Page": `Y_2mxFZJYYA1dcWoXDo_`,
	"Header": `velTsjiXgtwRBIcZ3Mwg`,
	"Title": `hSMgQNB33uMdQMyMK7wM`,
	"Loader": `OJYMJ_N0DIjFTqohWKp8`,
	"Panel": `Nvwl4EviHA8tk_li19Xw`,
	"List": `NInsDPFkI9_eo2cdPmsm`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useBlockUserMutation } from '../../user/user.service';
import { toast } from 'react-toastify';
import cls from './LockUser.module.scss';

const LockUser = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [block] = useBlockUserMutation();
  const closeHandler = () => dispatch(closeModal());
  const blockHandler = async () => {
    try {
      await block(extra?.id as string).unwrap();
      toast.success('Пользователь успешно заблокирован');
      dispatch(closeModal());
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Повторите попытку позже');
        } else if (error.status === 403) {
          toast.error('Запрещено');
        } else if (error.status === 404) {
          toast.error('Пользователь не найден');
        }
      }
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>? {extra?.subSupportText}
          </p>
        </div>
        <div className={cls.Buttons}>
          <Button
            type="button"
            className={`${cls.Button} ${cls.DeleteButton}`}
            onClick={blockHandler}
          >
            {extra?.actionButtonText}
          </Button>
          <Button onClick={closeHandler} className={cls.CancelButton}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default LockUser;

export const coursesRoutes = {
  courses: () => '/courses',
  lessonsByCourseId: (courseId: string) => `/courses/${courseId}/lessons`,
  updateCourse: (courseId: string) => `/courses/${courseId}/update`,
  restoreCourse: (courseId: string) => `/courses/${courseId}/restore`,
  updateLesson: (courseId: string, lessonId: string) =>
    `/courses/${courseId}/lessons/${lessonId}/update`,
  archivingCourse: (courseId: string) => `/courses/${courseId}/archiving`,
  activatedCourse: (courseId: string) => `/courses/${courseId}/activate`,
  course: (courseId: string) => `/courses/${courseId}`,
  lesson: (courseId: string, lessonId: string) => `/courses/${courseId}/lessons/${lessonId}`,
  deleteLesson: (courseId: string, lessonId: string) => `/courses/${courseId}/lessons/${lessonId}`,
  courseFeedback: () => '/courses/feedbacks',
  uploadFile: () => '/courses/files',
  file: (fileId: string) => `/courses/files/${fileId}`,
};

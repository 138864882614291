// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hbfrWuTP1wx1KCU1JcOl{padding-top:40px}.y3ZBXgKCXqTtEViEmDDS{grid-column:1/-1;display:flex;justify-content:space-between;align-items:center;height:fit-content}._DZnTfXrFJCfmxivhMgE{font-size:3rem;line-height:1.5}.XuhIZyEhntiorgaIg3QB{grid-column:1/-1;height:calc(100vh - 40px - 3rem - 2rem - 1.5rem - 1rem)}.bKcrFwhOiwpU_olAcqKg{grid-column:1/-1;max-height:3rem;margin-top:2rem;display:flex;justify-content:space-between;align-items:center}.ym12UiG_UZ4F17XKMZPD{grid-column:1/-1;margin-top:1.5rem}`, "",{"version":3,"sources":["webpack://./src/screens/ConsumersPage/ConsumersPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEF,sBACE,gBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CAEF,sBACE,gBAAA,CACA,uDAAA,CAEF,sBACE,gBAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,gBAAA,CACA,iBAAA","sourcesContent":[".Page {\n  padding-top: 40px;\n}\n.Header {\n  grid-column: 1/-1;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: fit-content;\n}\n.Title {\n  font-size: 3rem;\n  line-height: 1.5;\n}\n.Loader {\n  grid-column: 1/-1;\n  height: calc(100vh - 40px - 3rem - 2rem - 1.5rem - 1rem);\n}\n.Panel {\n  grid-column: 1/-1;\n  max-height: 3rem;\n  margin-top: 2rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.List {\n  grid-column: 1/-1;\n  margin-top: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Page": `hbfrWuTP1wx1KCU1JcOl`,
	"Header": `y3ZBXgKCXqTtEViEmDDS`,
	"Title": `_DZnTfXrFJCfmxivhMgE`,
	"Loader": `XuhIZyEhntiorgaIg3QB`,
	"Panel": `bKcrFwhOiwpU_olAcqKg`,
	"List": `ym12UiG_UZ4F17XKMZPD`
};
export default ___CSS_LOADER_EXPORT___;

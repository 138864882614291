import { createSlice } from '@reduxjs/toolkit';

type OffcanvasState = {
  isOpened: boolean;
  type: string | null;
  extra: {
    id?: string;
  } | null;
};

const initialState: OffcanvasState = {
  isOpened: false,
  type: null,
  extra: null,
};

const offcanvasSlice = createSlice({
  name: 'offcanvas',
  initialState,
  reducers: {
    openOffcanvas: (state, { payload }) => {
      const { type, extra } = payload;
      state.isOpened = true;
      state.type = type;
      state.extra = extra ?? null;
    },
    closeOffcanvas: (state) => {
      state.isOpened = false;
      state.type = null;
      state.extra = null;
    },
  },
});

export const { openOffcanvas, closeOffcanvas } = offcanvasSlice.actions;

export default offcanvasSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q8yLyF4rkm4HylW_KMhs{color:var(--breadcrumbs-item-color);text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/UIComponents/Breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACA,oBAAA","sourcesContent":[".BreadCrumbLink {\n  color: var(--breadcrumbs-item-color);\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BreadCrumbLink": `Q8yLyF4rkm4HylW_KMhs`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WOMXxsflIo25rbKoJFTI{display:flex}.EcsIXVPbLs4npcvGxLI2{border-right:none;border-top-right-radius:0;border-bottom-right-radius:0}.EcsIXVPbLs4npcvGxLI2:hover,.EcsIXVPbLs4npcvGxLI2:focus,.EcsIXVPbLs4npcvGxLI2:active,.EcsIXVPbLs4npcvGxLI2:focus-visible{border-color:var(--bs-border-color);outline:none}.CZ4PAH_1IavXsvv12pT6{border-left:none;border-top-left-radius:0;border-bottom-left-radius:0;max-width:149px}.CZ4PAH_1IavXsvv12pT6:hover,.CZ4PAH_1IavXsvv12pT6:focus,.CZ4PAH_1IavXsvv12pT6:active,.CZ4PAH_1IavXsvv12pT6:focus-visible{cursor:pointer;border-color:var(--bs-border-color);outline:none}.CZ4PAH_1IavXsvv12pT6:target{box-shadow:none}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerSearch/ConsumerSearch.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEF,sBACE,iBAAA,CACA,yBAAA,CACA,4BAAA,CACA,yHAIE,mCAAA,CACA,YAAA,CAGJ,sBACE,gBAAA,CACA,wBAAA,CACA,2BAAA,CACA,eAAA,CACA,yHAIE,cAAA,CACA,mCAAA,CACA,YAAA,CAEF,6BACE,eAAA","sourcesContent":[".Search {\n  display: flex;\n}\n.SearchInput {\n  border-right: none;\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n  &:hover,\n  &:focus,\n  &:active,\n  &:focus-visible {\n    border-color: var(--bs-border-color);\n    outline: none;\n  }\n}\n.SearchSelect {\n  border-left: none;\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n  max-width: 149px;\n  &:hover,\n  &:focus,\n  &:active,\n  &:focus-visible {\n    cursor: pointer;\n    border-color: var(--bs-border-color);\n    outline: none;\n  }\n  &:target {\n    box-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Search": `WOMXxsflIo25rbKoJFTI`,
	"SearchInput": `EcsIXVPbLs4npcvGxLI2`,
	"SearchSelect": `CZ4PAH_1IavXsvv12pT6`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbWgLuzVggj0zaSVmbln{height:250px;max-height:250px;margin-bottom:1.5rem}.YEgL1QeADYftg8BFHXC6{height:100%;max-height:206px}`, "",{"version":3,"sources":["webpack://./src/features/editor/EditorWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,oBAAA,CAEF,sBACE,WAAA,CACA,gBAAA","sourcesContent":[".Wrapper {\n  height: 250px;\n  max-height: 250px;\n  margin-bottom: 1.5rem;\n}\n.Editor {\n  height: 100%;\n  max-height: 206px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": `rbWgLuzVggj0zaSVmbln`,
	"Editor": `YEgL1QeADYftg8BFHXC6`
};
export default ___CSS_LOADER_EXPORT___;

import React, { memo } from 'react';
import cls from './ConsumerBillingListHeader.module.scss';

const ConsumerBillingListHeader = memo(() => {
  return (
    <ul className={cls.Header}>
      <li className="regular-text">Дата операции</li>
      <li className="regular-text">Тип</li>
      <li className="regular-text">Сумма</li>
    </ul>
  );
});

export default ConsumerBillingListHeader;

import React, { FC } from 'react';
import { ConsumerMessage } from '../../message/message.types';
import CloseDialogButton from '../CloseDialogButton';
import OpenDialogButton from '../OpenDialogButton';

type ChatPanelProps = {
  id: string;
  setAllMessages: React.Dispatch<React.SetStateAction<ConsumerMessage[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  allMessages: ConsumerMessage[];
  finalizeMessage: boolean;
};

const ChatPanel: FC<ChatPanelProps> = ({
  finalizeMessage,
  setPage,
  setAllMessages,
  id,
  allMessages,
}) => {
  if (!finalizeMessage) {
    return (
      <CloseDialogButton
        setPage={setPage}
        setAllMessages={setAllMessages}
        id={id}
        allMessages={allMessages}
      />
    );
  }
  return <OpenDialogButton id={id} />;
};

export default ChatPanel;

import React, { FC, useEffect } from 'react';
import { Log, LogsSearch } from '../logs.types';
import Spinner from '../../../UIComponents/Spinner';
import LogsListHeader from '../LogsListHeader';
import LogPreviewItem from '../LogPreviewItem';
import cls from './LogsList.module.scss';
import { classNameMerge } from '../../../utils/classNameMerge';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { clearLogsFieldSearch } from '../../../store/slices/logsFilterSlice';

interface LogsListProps {
  scrollLogsList: Log[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  logsList: Log[] | undefined;
  setScrollLogsList: React.Dispatch<React.SetStateAction<Log[]>>;
  setSearch: React.Dispatch<React.SetStateAction<LogsSearch>>;
  search: LogsSearch;
  exportsSize: number;
  exportsPage: number;
  setExportsPage: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
}

const LogsList: FC<LogsListProps> = ({
  scrollLogsList,
  page,
  setPage,
  isLoading,
  logsList,
  setScrollLogsList,
  search,
  setSearch,
  exportsPage,
  exportsSize,
  setExportsPage,
  className,
}) => {
  const dispatch = useAppDispatch();

  const deleteFilterField = (field: keyof LogsSearch) => {
    if (field === 'startDateTime') {
      dispatch(clearLogsFieldSearch({ key: 'startDateTime' }));
      dispatch(clearLogsFieldSearch({ key: 'endDateTime' }));
    } else if (field === 'ip') {
      dispatch(clearLogsFieldSearch({ key: field }));
    } else if (field === 'objectId') {
      dispatch(clearLogsFieldSearch({ key: field }));
    }
  };
  const resetHandler = () => {
    deleteFilterField('startDateTime');
    deleteFilterField('endDateTime');
    deleteFilterField('ip');
    deleteFilterField('objectId');
    setSearch({ ...search, startDateTime: '', endDateTime: '', ip: '', objectId: '' });
    setPage(0);
    setScrollLogsList([]);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.logs-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      scrollLogsList
    ) {
      if (scrollLogsList.length >= exportsSize) setExportsPage(++exportsPage);
      setPage(++page);
      setSearch({ ...search });
    }
  };
  useEffect(() => {
    if (logsList) {
      const uniqueLogs = logsList.filter(
        (log) => !scrollLogsList.some((item) => item.id === log.id),
      );
      setScrollLogsList([...scrollLogsList, ...uniqueLogs]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsList]);
  if (isLoading) return <Spinner />;
  if (!isLoading && logsList?.length === 0 && scrollLogsList.length === 0)
    return (
      <div className={classNameMerge(cls.ListEmpty, className)}>
        <div className={cls.EmptyText}>
          <h2 className="title-2">Поиск не дал результатов</h2>
        </div>
        <button className={cls.Button} onClick={resetHandler} type="button">
          Сбросить поиск
        </button>
      </div>
    );
  return (
    <div className={classNameMerge(cls.Container, className)}>
      <LogsListHeader />
      <div className={`${cls.List} logs-list`} onScroll={scrollHandler} data-testid="logs-list">
        {scrollLogsList?.map((log) => (
          <LogPreviewItem key={log.id} log={log} />
        ))}
      </div>
    </div>
  );
};

export default LogsList;

import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import {
  AssistantToolType,
  AssistantType,
  OpenAIModelsType,
  UpdateAssistantType,
} from './settings.types';

type GetAssistantsResponse = {
  assistants: AssistantType[];
};

const settingsApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssistantById: builder.query<AssistantType, { id: string; type: AssistantToolType }>({
      query: ({ id, type }) => ({
        url: routes.api.operationWithAssistant(id, type),
      }),
      providesTags: ['Assistant'],
    }),
    updateAssistantById: builder.mutation<
      AssistantType,
      { id: string; type: AssistantToolType; data: UpdateAssistantType }
    >({
      query: ({ id, type, data }) => ({
        url: routes.api.operationWithAssistant(id, type),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Assistant'],
    }),
    getAllOpenAIModels: builder.query<OpenAIModelsType, void>({
      query: () => routes.api.allOpenAIModels(),
    }),
    getAllAssistants: builder.query<GetAssistantsResponse, { type: AssistantToolType }>({
      query: ({ type }) => ({
        url: routes.api.allOpenAIAssistants(type),
      }),
    }),
    checkAssistantVerifyById: builder.query<AssistantType, { id: string }>({
      query: ({ id }) => routes.api.checkVerifyAssistant(id),
    }),
  }),
});

export const {
  useGetAssistantByIdQuery,
  useUpdateAssistantByIdMutation,
  useGetAllOpenAIModelsQuery,
  useGetAllAssistantsQuery,
  useCheckAssistantVerifyByIdQuery,
} = settingsApi;

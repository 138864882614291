// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pDofYtr70z9x7jhl6wwq{display:flex;gap:1rem}.QmTPPAV6K7gzHzpQCUmy{text-decoration:none;color:inherit;min-width:213px;width:100%;padding:12px 28px 12px 24px;display:flex;align-items:center;justify-content:center;gap:6px;border-radius:8px;border:1px solid #e9e9ea;cursor:pointer;font-size:20px;font-weight:400;line-height:25.88px}.QmTPPAV6K7gzHzpQCUmy:hover{border-color:var(--button-hover-border-color);color:var(--button-hover-text-color)}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerActionButtons/ConsumerActionButtons.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CAEF,sBACE,oBAAA,CACA,aAAA,CACA,eAAA,CACA,UAAA,CACA,2BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,iBAAA,CACA,wBAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,4BACE,6CAAA,CACA,oCAAA","sourcesContent":[".Buttons {\n  display: flex;\n  gap: 1rem;\n}\n.Button {\n  text-decoration: none;\n  color: inherit;\n  min-width: 213px;\n  width: 100%;\n  padding: 12px 28px 12px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n  border-radius: 8px;\n  border: 1px solid rgba(233, 233, 234, 1);\n  cursor: pointer;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 25.88px;\n  &:hover {\n    border-color: var(--button-hover-border-color);\n    color: var(--button-hover-text-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Buttons": `pDofYtr70z9x7jhl6wwq`,
	"Button": `QmTPPAV6K7gzHzpQCUmy`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, useEffect, useState } from 'react';
import { FormControl, Form, FloatingLabel, FormSelect } from 'react-bootstrap';
import EditorWrapper from '../../editor';
import { useFormik } from 'formik';
import { AdditionalMaterialType, NewLesson, UpdateLesson } from '../courses.types';
import Button from '../../../UIComponents/Button';
import { MdClose, MdFilePresent } from 'react-icons/md';
import cls from './CreateLessonForm.module.scss';
import { toast } from 'react-toastify';
import routes from '../../../routes';
import { useNavigate } from 'react-router-dom';
type CreateLessonFormProps = {
  initialValues: NewLesson | UpdateLesson;
  submitHandler?: (lesson: NewLesson) => Promise<void>;
  updateHandler?: (lesson: UpdateLesson) => Promise<void>;
  disabled: boolean;
  className?: string;
  isChange?: boolean;
};

const CreateLessonForm: FC<CreateLessonFormProps> = ({
  initialValues,
  submitHandler,
  disabled,
  className,
  updateHandler,
  isChange,
}) => {
  const navigate = useNavigate();
  const [text, setText] = useState<string>(initialValues?.content ?? '');
  const [additionalMaterials, setAdditionalMaterials] = useState<AdditionalMaterialType[]>(
    initialValues?.additionalMaterials ?? [],
  );
  const returnHandler = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (submitHandler) {
        if (values.type === 'TEXT') {
          submitHandler({ ...values, content: text, additionalMaterials });
          returnHandler();
        } else {
          submitHandler({ ...values, additionalMaterials });
          returnHandler();
        }
      } else if (updateHandler && isChange) {
        if (values.type === 'TEXT') {
          const res = updateHandler({
            ...values,
            content: text,
            priority: 1,
            enableCommentsViewing: false,
            additionalMaterials,
          });
          returnHandler();
          console.log(res.then((res) => res));
        } else {
          updateHandler({
            ...values,
            priority: 1,
            enableCommentsViewing: false,
            additionalMaterials,
          });
          returnHandler();
        }
      }
    },
  });
  const clearImageHandler = async (id: string) => {
    try {
      await fetch(`${routes.api.basePath()}${routes.api.file(id)}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('banditto') || '{}').accessToken
          }`,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };
  const createAdditionalMaterial = async (
    e: React.ChangeEvent<HTMLInputElement>,
    response: Response,
  ) => {
    await response.json().then((res) => {
      setAdditionalMaterials([
        ...additionalMaterials,
        {
          fileId: res.id,
          name: res.fullName,
        },
      ]);
    });
  };
  const uploadImageHandler = async (
    e: React.ChangeEvent<HTMLInputElement>,
    cb: (e: React.ChangeEvent<HTMLInputElement>, response: Response) => Promise<void>,
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      if (file) {
        const accessToken = JSON.parse(localStorage.getItem('banditto') || '{}').accessToken;
        await fetch(`${routes.api.basePath()}${routes.api.uploadFile()}`, {
          method: 'POST',
          body: formData,
          mode: 'cors',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }).then((response) => cb(e, response));
      }
    }
  };
  useEffect(() => {
    if (initialValues.name) {
      formik.setFieldValue('name', initialValues.name);
      formik.setFieldValue('additionalInfo', initialValues.additionalInfo);
      formik.setFieldValue('type', initialValues.type);
      formik.setFieldValue('description', initialValues.description);
      formik.setFieldValue('content', initialValues.content);
      formik.setFieldValue('additionalMaterials', initialValues.additionalMaterials);
      setText(initialValues.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);
  return (
    <div className={className}>
      <Form className={cls.Form} onSubmit={formik.handleSubmit} data-testid="create-lesson-form">
        <div className={cls.FirstLine}>
          <div className={cls.InfoBlock}>
            <h4 className="title-4">Данные урока</h4>
            <div className={cls.InputWithClear}>
              <FloatingLabel
                label="Название урока *"
                className="w-100"
                data-testid="lesson-name-input"
              >
                <FormControl
                  type="text"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="w-100"
                />
              </FloatingLabel>
              {formik.values.name && (
                <MdClose
                  className={cls.ClearButton}
                  onClick={() => formik.setFieldValue('name', '')}
                />
              )}
              {formik.errors.name && <div className={cls.Error}>{formik.errors.name}</div>}
            </div>
            <div className={cls.Name}>
              <FloatingLabel
                label="Тип материала *"
                className="w-100"
                data-testid="lesson-type-select"
              >
                <FormSelect
                  name="type"
                  id="type"
                  onChange={formik.handleChange}
                  value={formik.values.type ?? 'TEXT'}
                >
                  <option>Выберите тип урока</option>
                  <option value="TEXT">Текст</option>
                  <option value="VIDEO">Видео</option>
                </FormSelect>
              </FloatingLabel>
              <div className={cls.InputWithClear}>
                <FloatingLabel label="Длительность материала (минут) *">
                  <FormControl
                    type="text"
                    name="additionalInfo"
                    id="additionalInfo"
                    onChange={formik.handleChange}
                    value={formik.values.additionalInfo}
                  />
                </FloatingLabel>

                {formik.values.additionalInfo && (
                  <MdClose
                    className={cls.ClearButton}
                    onClick={() => formik.setFieldValue('additionalInfo', '')}
                  />
                )}
              </div>
            </div>
            {formik.values.type === 'VIDEO' && (
              <div className={cls.InputWithClear}>
                <FloatingLabel label="Ссылка на видео *">
                  <FormControl
                    type="text"
                    name="content"
                    id="content"
                    onChange={formik.handleChange}
                    value={formik.values.content}
                  />
                </FloatingLabel>

                {formik.values.content && (
                  <MdClose
                    className={cls.ClearButton}
                    onClick={() => formik.setFieldValue('content', '')}
                  />
                )}
              </div>
            )}
            <div className={cls.DescriptionBlock}>
              <div className={cls.DescriptionWrapper}>
                <FloatingLabel
                  label="Краткое описание для превью *"
                  className="h-100"
                  data-testid="lesson-description-input"
                >
                  <FormControl
                    as="textarea"
                    name="description"
                    id="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    className="h-100"
                  />
                </FloatingLabel>
                {formik.errors.description && (
                  <div className={cls.Error}>{formik.errors.description}</div>
                )}
                {formik.values.description && formik.values.description.length > 0 && (
                  <MdClose
                    className={`${cls.ClearButton} ${cls.ClearButtonDescription}`}
                    onClick={() => formik.setFieldValue('cost', 0)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={cls.AdditionalMaterials}>
          <div className={cls.HeaderMaterials}>
            <h4 className="title-4">Файлы урока</h4>
            <label htmlFor="materials" className={cls.AddFileButton}>
              <span>Прикрепить файл</span> <MdFilePresent />
            </label>
            <input
              type="file"
              name="materials"
              id="materials"
              onChange={async (e) => {
                await uploadImageHandler(e, createAdditionalMaterial);
              }}
              hidden
            />
          </div>
          {
            <div className={cls.MaterialsList}>
              {additionalMaterials?.map((material) => (
                <div className={cls.Material} key={material.fileId}>
                  <div className="d-flex align-items-center gap-2">
                    <MdFilePresent size={20} /> <span>{material.name}</span>
                  </div>
                  <MdClose
                    onClick={() => {
                      clearImageHandler(material.fileId);
                      setAdditionalMaterials(
                        additionalMaterials.filter((m) => m.fileId !== material.fileId),
                      );
                    }}
                    className={cls.ClearAdditionalMaterialsButton}
                  />
                </div>
              ))}
            </div>
          }
        </div>
        <div className={cls.SecondLine}>
          {formik.values.type === 'TEXT' && (
            <div className={cls.FullDescriptionBlock} data-testid="lesson-content-input">
              <h4 className="title-4">Полное описание урока</h4>
              <EditorWrapper setText={setText} value={formik.values.content ?? ''} />
            </div>
          )}
          <div className={cls.ButtonsGroup}>
            <button
              type="submit"
              className={disabled ? `${cls.SaveButton} ${cls.DisabledSaveButton}` : cls.SaveButton}
              disabled={disabled}
            >
              {!isChange ? 'Добавить урок' : 'Сохранить изменения'}
            </button>
            <Button text="Отмена" className={cls.CancelButton} onClick={returnHandler} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateLessonForm;

import React, { FC } from 'react';
import { useFinalizeMessageMutation } from '../../message/message.service';
import { ConsumerMessage } from '../../message/message.types';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { setRefresh } from '../../../store/slices/chatSlice';
import { toast } from 'react-toastify';
import cls from './CloseDialogButton.module.scss';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';

type CloseDialogButtonProps = {
  id: string;
  setAllMessages: React.Dispatch<React.SetStateAction<ConsumerMessage[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  allMessages: ConsumerMessage[];
};

const CloseDialogButton: FC<CloseDialogButtonProps> = ({ id }) => {
  const [finalizeDialog] = useFinalizeMessageMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const closeDialogHandler = () => {
    finalizeDialog(id);
    dispatch(setRefresh({ refresh: true }));
    navigate(routes.pages.messagesPage());
    toast.success('Диалог завершен');
  };
  return (
    <button className={cls.Button} onClick={closeDialogHandler} title="Завершить">
      Завершить
    </button>
  );
};

export default CloseDialogButton;

import React, { FC, useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { UserShort } from '../user.types';
import * as yup from 'yup';
import cls from './UpdateUserForm.module.scss';

interface updateFormProps {
  initialValues: UserShort;
  onSubmit: (
    values: UserShort | Required<UserShort>,
    formikHelpers: FormikHelpers<UserShort | Required<UserShort>>,
  ) => void;
  disabled?: boolean;
  isModal?: boolean;
  closeHandler?: () => void;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateUserForm: FC<updateFormProps> = ({
  initialValues,
  onSubmit,
  disabled,
  isModal,
  closeHandler,
  setDisabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string>('');
  const validationSchema = yup
    .object()
    .strict()
    .shape({
      secondName: yup.string().max(32, 'максимальная длина 32 символа').trim(),
      firstName: yup.string().max(32, 'максимальная длина 32 символа').trim(),
      phone: yup.string().max(32, 'максимальная длина 12 символа').trim(),
    });
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit,
  });
  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
    setError('');
  };
  useEffect(() => {
    if (
      formik.values.secondName !== initialValues.secondName ||
      formik.values.firstName !== initialValues.firstName ||
      formik.values.phone !== initialValues.phone
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    formik.values,
    initialValues.firstName,
    initialValues.phone,
    initialValues.secondName,
    setDisabled,
  ]);
  return (
    <Form
      data-testid="update-user-form"
      className={cls.Form}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className={cls.InputWrapper}>
        <Form.Label htmlFor="secondName">Фамилия</Form.Label>
        <Form.Control
          name="secondName"
          id="secondName"
          value={formik.values.secondName}
          onChange={onChangeHandle}
          isInvalid={!!formik.errors.secondName}
          className={cls.Input}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.secondName}</Form.Control.Feedback>
      </div>
      <div className={cls.InputWrapper}>
        <Form.Label htmlFor="firstName">Имя</Form.Label>
        <Form.Control
          name="firstName"
          id="firstName"
          value={formik.values.firstName}
          onChange={onChangeHandle}
          isInvalid={!!formik.errors.firstName}
          className={cls.Input}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
      </div>
      <div className={cls.InputWrapper}>
        <Form.Label htmlFor="phone">Телефон</Form.Label>
        <Form.Control
          name="phone"
          id="phone"
          value={formik.values.phone}
          onChange={onChangeHandle}
          isInvalid={!!formik.errors.phone}
          className={cls.Input}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
      </div>
      {!isModal ? (
        <Button type="submit" disabled={disabled} className={cls.Button}>
          Сохранить
        </Button>
      ) : (
        <div className={cls.Buttons}>
          <Button
            type="submit"
            className={disabled ? `${cls.Button} ${cls.Disabled}` : `${cls.Button}`}
            disabled={disabled}
          >
            Сохранить
          </Button>
          <Button onClick={closeHandler} className={cls.CancelButton}>
            Отмена
          </Button>
        </div>
      )}
    </Form>
  );
};

export default UpdateUserForm;

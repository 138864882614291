import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import cls from './MakeInvoiceExpiredModal.module.scss';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { useUpdateInvoiceStatusMutation } from '../../invoices/invoices.service';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';

const MakeInvoiceExpiredModal = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [changeInvoiceStatus] = useUpdateInvoiceStatusMutation();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const expiredHandler = async () => {
    setDisabled(true);
    try {
      await changeInvoiceStatus({
        id: extra?.id as string,
        status: 'EXPIRED',
      }).unwrap();
      if (extra?.handler !== undefined) extra.handler();
      dispatch(closeModal());
      toast.success('Счет отменен');
    } catch (error) {
      setDisabled(false);
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Повторите попытку позже');
        }
      }
    } finally {
      setDisabled(false);
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Перевести в статус истёк срок оплаты?</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <p>Вы уверены что хотите перевести счёт в статус истёк срок оплаты?</p>
        <div className={cls.Buttons}>
          <Button
            type="button"
            className={cls.ActionButton}
            onClick={expiredHandler}
            disabled={disabled}
          >
            Подтвердить
          </Button>
          <Button className="w-100" variant="outline-dark" onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default MakeInvoiceExpiredModal;

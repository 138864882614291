import React, { FC, memo } from 'react';
import { Consumer } from '../consumer.types';
import cls from './ConsumerCardSubInfo.module.scss';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { openModal } from '../../../store/slices/modalSlice';
import Button from '../../../UIComponents/Button';
import { MdLockOpen, MdLock, MdOutlineCurrencyRuble } from 'react-icons/md';

type ConsumerCardSubInfoProps = {
  consumer: Consumer;
  balance: string;
  resetHandler: () => void;
  userRole: string;
};

const ConsumerCardSubInfo: FC<ConsumerCardSubInfoProps> = memo((props) => {
  const { consumer, balance, resetHandler, userRole } = props;
  const dispatch = useAppDispatch();
  const unBlockConsumerHandler = () =>
    dispatch(
      openModal({
        type: 'unLockConsumer',
        extra: {
          id: consumer?.id,
          title: 'Разблокировать клиента?',
          supportText: 'Вы уверены что хотите разблокировать',
          textInformation: consumer?.email,
          subSupportText: 'Данные клиента и доступ будут восстановлены.',
          actionButtonText: 'Разблокировать',
        },
      }),
    );
  const blockConsumerHandler = () =>
    dispatch(
      openModal({
        type: 'lockConsumer',
        extra: {
          id: consumer?.id,
          title: 'Заблокировать клиента?',
          supportText: 'Вы уверены что хотите заблокировать',
          textInformation: consumer?.email,
          subSupportText: 'Заблокированный аккаунт можно восстановить, данные будут сохранены.',
          actionButtonText: 'Заблокировать',
        },
      }),
    );
  const transactionHandler = () =>
    dispatch(
      openModal({
        type: 'makeTransaction',
        extra: {
          id: consumer?.id,
          handler: resetHandler,
          title: 'Выполнить транзакцию?',
          supportText: 'Произойдёт пополнение или списание средств для аккаунта ',
          textInformation: consumer?.email,
          actionButtonText: 'Выполнить',
        },
      }),
    );

  return (
    <div className={cls.ConsumerCardSubInfo}>
      <div className={cls.Info}>
        <div className={cls.Property}>
          <span className={cls.FieldName}>Статус</span>
          <span
            className={
              consumer.emailVerified
                ? `${cls.FieldValue} ${cls.FieldValueGreen}`
                : `${cls.FieldValue} ${cls.FieldValueRed}`
            }
          >
            {consumer.emailVerified ? 'Активно' : 'Не активно'}
          </span>
        </div>
        <div className={cls.Property}>
          <span className={cls.FieldName}>Дата Регистрации</span>
          <span className={cls.FieldValue}>
            {convertDateToNeedFormat({ date: consumer.createdAt, format: 'datetime' })}
          </span>
        </div>
        <div className={cls.Property}>
          <span className={cls.FieldName}>Часовой пояс</span>
          <span className={cls.FieldValue}>
            {consumer.zoneGMT ? `GMT+${consumer.zoneGMT}` : 'Не указан'}
          </span>
        </div>
        <div className={cls.Property}>
          <span className={cls.FieldName}>Счет</span>
          <span
            className={
              Number(balance) <= 0 ? `${cls.FieldValue} ${cls.FieldValueRed}` : cls.FieldValue
            }
          >
            {balance} &#x20bd;
          </span>
        </div>
      </div>
      <div className={cls.Buttons}>
        <Button
          clickHandler={consumer?.blocked ? unBlockConsumerHandler : blockConsumerHandler}
          Icon={consumer?.blocked ? <MdLockOpen /> : <MdLock />}
          text={consumer?.blocked ? 'Разблокировать' : 'Заблокировать'}
          className={cls.Button}
        />
        {userRole !== 'Пользователь' && (
          <Button
            clickHandler={transactionHandler}
            Icon={<MdOutlineCurrencyRuble />}
            text={'Транзакции'}
            className={cls.Button}
          />
        )}
      </div>
    </div>
  );
});

export default ConsumerCardSubInfo;

import React, { memo } from 'react';
import cls from './ConsumerCourseListHeader.module.scss';

const ConsumerCourseListHeader = memo(() => {
  return (
    <ul className={cls.Header} data-testid="header-list">
      <li className="regular-text">Название</li>
      <li className="regular-text">Стоимость</li>
    </ul>
  );
});

export default ConsumerCourseListHeader;

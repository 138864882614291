import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Col } from 'react-bootstrap';
import Spinner from '../../UIComponents/Spinner';
import { useGetAllLeadsQuery } from '../../features/lead/lead.service';
import LeadsList from '../../features/lead/LeadsList';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getLeadsSearch, getTheme, getUserRole } from '../../selectors/selectors';
import { Lead } from '../../features/lead/lead.types';
import InstrumentalPannel from '../../features/pannel/InstrumentalPannel';
import { SearchType } from '../../features/search/search.types';
import DatePickerRange from '../../UIComponents/DatePickerRange';
import SearchInfoPanel from '../../features/pannel/SearchInfoPanel';
import routes from '../../routes';
import { convertBinaryToLink } from '../../utils/converBinaryToLink';
import { clearLeadsSearch, updateLeadsSearch } from '../../store/slices/searchSlice';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const LeadsPage = () => {
  const [page, setPage] = useState<number>(0);
  const [scrollLeads, setScrollLeads] = useState<Lead[]>([]);
  const [infoPanelField, setInfoPanelField] = useState<string>('');
  const [infoPanelValue, setInfoPanelValue] = useState<string>('');
  const [field, setField] = useState<string>('period');
  const [userRole, setUserRole] = useState<string>('Пользователь');
  const role = useAppSelector(getUserRole) as string;
  const [showInfoPannel, setShowInfoPannel] = useState<boolean>(false);
  const [actualSearchText, setActualSearchText] = useState<string>('');
  const [exportsPage, setExportsPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const exportsSize = 11000;
  const [search, setSearch] = useState<SearchType>({
    size,
    sortOrder: 1,
    startDateTime: '',
    endDateTime: '',
    page,
  });
  const { data: leadsList, isLoading } = useGetAllLeadsQuery(search);
  const searchStore = useAppSelector(getLeadsSearch);
  useDocumentTitle('Заявки');

  const downloadLeadsHandler = () => {
    const download = `${routes.api.basePath()}${routes.api.leadsExport()}`;
    convertBinaryToLink(download, {
      ...search,
      page: exportsPage,
      size: exportsSize,
    });
  };

  useEffect(() => {
    if (window.screen.height >= 1070) {
      setSize(20);
      setSearch({ ...search, size });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);
  useLayoutEffect(() => {
    setUserRole(role);
  }, [role]);
  useEffect(() => {
    if (searchStore) {
      setScrollLeads([]);
      setSearch(searchStore);
      setShowInfoPannel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStore]);

  const theme = useAppSelector(getTheme);
  return (
    <Col className="h-100 py-3 px-5">
      <div className="d-flex justify-content-between align-items-center py-3">
        <h2>Заявки</h2>
        <DatePickerRange
          theme={theme}
          search={search}
          setSearch={setSearch}
          setScrollElement={setScrollLeads}
          showTodayPeriod={false}
          setInfoPannelField={setInfoPanelField}
          setInfoPannelValue={setInfoPanelValue}
          setShowInfoPannel={setShowInfoPannel}
          writeToStore={updateLeadsSearch}
          field={field}
          setField={setField}
        />
      </div>
      {showInfoPannel ? (
        <SearchInfoPanel
          field={infoPanelField}
          value={infoPanelValue}
          setShowInfoPanel={setShowInfoPannel}
          setScrollList={setScrollLeads}
          setSearch={setSearch}
          clearSearchState={clearLeadsSearch}
          setActualSearchText={setActualSearchText}
          actualSearchText={actualSearchText}
        />
      ) : null}
      {userRole !== 'Пользователь' && (
        <InstrumentalPannel theme={theme} title="заявки" downloadHandler={downloadLeadsHandler} />
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <LeadsList
          leads={leadsList}
          theme={theme}
          page={page}
          setPage={setPage}
          scrollLeads={scrollLeads}
          setScrollLeads={setScrollLeads}
          search={search}
          setSearch={setSearch}
          exportsPage={exportsPage}
          exportsSize={exportsSize}
          setExportsPage={setExportsPage}
          show={showInfoPannel}
        />
      )}
    </Col>
  );
};

export default LeadsPage;

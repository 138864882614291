import React, { FC, useEffect, useState } from 'react';
import { Lead } from '../lead.types';
import { themes } from '../../../store/slices/themeSlice';
import { NavLink } from 'react-router-dom';
import routes from '../../../routes';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import { SearchType } from '../../search/search.types';
import { createGravatar } from '../../../utils/createGravatar';

interface LeadsListProps {
  leads: Lead[] | undefined;
  theme: themes;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  scrollLeads: Lead[];
  setScrollLeads: React.Dispatch<React.SetStateAction<Lead[]>>;
  search: SearchType;
  setSearch: React.Dispatch<React.SetStateAction<SearchType>>;
  exportsSize: number;
  exportsPage: number;
  setExportsPage: React.Dispatch<React.SetStateAction<number>>;
  show: boolean;
}

interface LeadColor {
  email: string;
  url: string | null;
  color: string | null;
}

const LeadsList: FC<LeadsListProps> = ({
  leads,
  theme,
  page,
  setPage,
  scrollLeads,
  setScrollLeads,
  search,
  setSearch,
  exportsPage,
  exportsSize,
  setExportsPage,
  show,
}) => {
  const [leadsImage, setLeadsImage] = useState<LeadColor[]>([]);
  const findActualColor = (lead: Lead) => {
    const [item] = leadsImage.filter((leadColor: LeadColor) => leadColor.email === lead.email);
    return item;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.leads-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      scrollLeads
    ) {
      if (scrollLeads.length >= exportsSize) setExportsPage(++exportsPage);
      setPage(++page);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeOrderHandler = (event: any) => {
    const listItems = document.querySelectorAll('.consumers-list__item--fixed span');
    listItems.forEach((item) => {
      const actualText = item.innerHTML.split(' ')[0];
      item.textContent = actualText;
    });
    const sortField = event.target.id;
    const text = event.target.outerText.split(' ')[0];
    const sortOrder = search.sortOrder === 1 ? 0 : 1;
    const icon = sortOrder === 1 ? '&uarr;' : '&darr;';
    setPage(0);
    setScrollLeads([]);
    setSearch({ ...search, page, sortField, sortOrder });

    event.target.innerHTML = `${text} ${icon}`;
  };
  useEffect(() => {
    const generateImage = async () => {
      const leadsImages: Promise<{ url: string | null; color: string | null; email: string }>[] =
        scrollLeads?.map(async (lead: Lead) => {
          const color = await createGravatar(lead.email).then((data) => data);
          return color;
        });
      Promise.all(leadsImages as []).then((values) =>
        setLeadsImage(values as unknown as LeadColor[]),
      );
    };
    generateImage();
  }, [scrollLeads]);
  useEffect(() => {
    if (leads) {
      const uniqueLeads = leads.filter((lead) => !scrollLeads.some((item) => item.id === lead.id));
      setScrollLeads([...scrollLeads, ...uniqueLeads]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leads]);
  return (
    <>
      <ul className="p-0" data-testid="header-list">
        <li className="leads-list__item leads-list__item--fixed">
          <span
            id="name"
            className="cursor-pointer d-flex"
            onClick={(event) => changeOrderHandler(event)}
          >
            Имя &uarr;
          </span>
          <span id="email" onClick={(event) => changeOrderHandler(event)}>
            Электропочта
          </span>
          <span id="createdAt" onClick={(event) => changeOrderHandler(event)}>
            Дата создания
          </span>
        </li>
      </ul>
      <ul
        data-testid="leads-list"
        className={show ? 'leads-list leads-list--show-pannel' : 'leads-list'}
        onScroll={scrollHandler}
      >
        {scrollLeads?.length !== 0 ? (
          scrollLeads?.map((lead) => (
            <li key={lead.id} className={`leads-list__item ${theme}-item-hover`}>
              <NavLink
                className={`${theme}-link  text-decoration-none d-content`}
                to={routes.pages.leadpageById(lead.id)}
              >
                <div className="py-2 d-flex align-items-center">
                  {findActualColor(lead)?.url ? (
                    <img src={findActualColor(lead)?.url as string} className="about-user__img" />
                  ) : (
                    <>
                      <div
                        className="about-user__img"
                        data-testid="user-img"
                        style={{
                          backgroundColor: `#${findActualColor(lead)?.color}`,
                        }}
                      >
                        {lead.name[0].toUpperCase()}
                      </div>
                    </>
                  )}
                  <div className="users-list__link">{lead.name ? lead.name : 'Неизвестный'}</div>
                </div>
                <div className="py-2">
                  <div className="table-centered-td">{lead.email}</div>
                </div>
                <div className="py-2">
                  <div className="table-centered-td">
                    {convertDateToNeedFormat({ date: lead.createdAt, format: 'datetime' })}
                  </div>
                </div>
              </NavLink>
            </li>
          ))
        ) : (
          <li>Заявки не найдены</li>
        )}
      </ul>
    </>
  );
};

export default LeadsList;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lupCzuvTnaS_tBlsNnBJ{padding:40px;overflow-y:auto;height:100vh}.QmUY3CudkT5JWGEuEhe3{margin-bottom:1rem;grid-column:1/-1}.yjOkWTTVR21nw3wKDznH{grid-column:1/-1;margin-bottom:2rem;height:fit-content}.R72ctjjV8skONicPpFY8{grid-column:1/10}`, "",{"version":3,"sources":["webpack://./src/screens/NewCoursePage/NewCoursePage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,YAAA,CAEF,sBACE,kBAAA,CACA,gBAAA,CAEF,sBACE,gBAAA,CACA,kBAAA,CACA,kBAAA,CAEF,sBACE,gBAAA","sourcesContent":[".Container {\n  padding: 40px;\n  overflow-y: auto;\n  height: 100vh;\n}\n.BreadCrumbs {\n  margin-bottom: 1rem;\n  grid-column: 1/-1;\n}\n.Title {\n  grid-column: 1/-1;\n  margin-bottom: 2rem;\n  height: fit-content;\n}\n.CreateOrChangeCourseForm {\n  grid-column: 1/10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `lupCzuvTnaS_tBlsNnBJ`,
	"BreadCrumbs": `QmUY3CudkT5JWGEuEhe3`,
	"Title": `yjOkWTTVR21nw3wKDznH`,
	"CreateOrChangeCourseForm": `R72ctjjV8skONicPpFY8`
};
export default ___CSS_LOADER_EXPORT___;

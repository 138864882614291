// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Um72ah85x9v0wkXOUoe7{font-size:48px;font-weight:700;line-height:62.11px}`, "",{"version":3,"sources":["webpack://./src/screens/ApplicationSettingsPage/ApplicationSettingsPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":[".Title {\n  font-size: 48px;\n  font-weight: 700;\n  line-height: 62.11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": `Um72ah85x9v0wkXOUoe7`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC } from 'react';
import Button from '../../../UIComponents/Button';
import { MdOutlineModeComment, MdOutlineSettingsInputComponent } from 'react-icons/md';
import { GrDatabase } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { Consumer } from '../consumer.types';
import { openModal } from '../../../store/slices/modalSlice';
import { addChannel } from '../../../store/slices/messageChannelSlice';
import cls from './ConsumerActionButtons.module.scss';
import { closeOffcanvas } from '../../../store/slices/offcanvasSlice';
import { updateLogsSearch } from '../../../store/slices/searchSlice';

type ConsumerActionButtonsProps = {
  consumer: Consumer | undefined;
  userRole: string;
};

const ConsumerActionButtons: FC<ConsumerActionButtonsProps> = ({ consumer, userRole }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeCabinetsCountHandler = () =>
    dispatch(
      openModal({
        type: 'changeCabinetsCount',
        extra: {
          id: consumer?.id,
          cabinetsCount: consumer?.maxCountCabinets as number,
          title: 'Изменить количество рекламных кабинетов?',
          supportText: 'Изменить количество рекламных кабинетов для аккаунта',
          textInformation: consumer?.email,
          actionButtonText: 'Изменить',
        },
      }),
    );
  const sendMessageHandler = () => {
    dispatch(
      addChannel({
        email: consumer?.email as string,
        id: consumer?.id as string,
        name: consumer?.name as string,
      }),
    );
    dispatch(closeOffcanvas());
    navigate(routes.pages.messagePageByConsumerId(consumer?.id as string));
  };
  const redirectToLogsPage = () => {
    dispatch(closeOffcanvas());
    dispatch(updateLogsSearch({ size: 10, filterField: 'email', filter: consumer?.name }));
    navigate(routes.pages.logsPage());
  };
  return (
    <div className={cls.Buttons} data-testid="button-part">
      <Button
        clickHandler={sendMessageHandler}
        Icon={<MdOutlineModeComment />}
        text={'Написать'}
        className={cls.Button}
      />
      <Button
        clickHandler={changeCabinetsCountHandler}
        Icon={<MdOutlineSettingsInputComponent />}
        text={'Лимиты'}
        className={cls.Button}
      />
      {userRole !== 'Пользователь' && (
        <div className="link-logs text-center">
          <Button
            className={cls.Button}
            text={'Логи'}
            Icon={<GrDatabase />}
            clickHandler={redirectToLogsPage}
          />
        </div>
      )}
    </div>
  );
};

export default ConsumerActionButtons;

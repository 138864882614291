import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import { Log } from '../log/logs.types';
import { User, UserShort } from './user.types';

interface updateRoleRequest {
  id: string;
  updates: { role: string };
}

interface changePasswordRequest {
  id: string;
  updates: { newPassword: string };
}

interface updateDataRequest {
  id: string;
  updates: UserShort;
}
interface UserPaginationRequest {
  page: number;
  size: number;
}
interface LogsPaginarionRequest extends PaginationRequest {
  userId: string;
  subjectId?: string;
  objectId?: string;
  startDateTime?: string;
  endDateTime?: string;
}

interface ChangeMePasswordRequest {
  oldPassword: string;
  newPassword?: string;
}
interface ForgotPasswordRequest {
  email: string;
}
interface ForgotPasswordResponse {
  status: boolean;
}

const userApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    aboutUser: builder.query<User, void>({
      query: () => routes.api.aboutUser(),
      providesTags: ['User', 'Users'],
    }),
    changeMePassword: builder.mutation<User, ChangeMePasswordRequest>({
      query: (userData) => ({
        url: routes.api.changeMePassword(),
        method: 'PATCH',
        body: userData,
      }),
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
      query: (userData) => ({
        url: routes.api.forgotPassword(),
        method: 'PATCH',
        body: userData,
      }),
    }),
    restorePassword: builder.mutation<{ status: boolean }, { email: string; code: string }>({
      query: ({ email, code }) => ({
        url: routes.api.restorePassword(email, code),
        method: 'PATCH',
      }),
    }),
    getAllUsers: builder.query<User[], UserPaginationRequest>({
      query: ({ page = 0, size = 10 }) => ({
        url: routes.api.getAllUsers(),
        params: {
          page,
          size,
        },
      }),
      providesTags: ['Users', 'User'],
    }),
    getUserLogsById: builder.query<Log[], LogsPaginarionRequest>({
      query: (params) => ({
        url: routes.api.getLogsByUserId(params.userId),
        params,
      }),
      providesTags: ['Logs'],
    }),
    getAboutUserById: builder.query<User, string>({
      query: (id: string) => routes.api.aboutUserById(id),
      providesTags: ['Users', 'User'],
    }),
    updateUser: builder.mutation<User, UserShort>({
      query: (userData) => ({
        url: routes.api.aboutUser(),
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    blockUser: builder.mutation<User, string>({
      query: (id: string) => ({
        url: routes.api.lock(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    unblockUser: builder.mutation<User, string>({
      query: (id: string) => ({
        url: routes.api.unlock(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    updateUserRole: builder.mutation<User, updateRoleRequest>({
      query: ({ id, updates }) => ({
        url: routes.api.updateRole(id),
        method: 'PATCH',
        body: updates,
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    updateUserData: builder.mutation<User, updateDataRequest>({
      query: ({ id, updates }) => ({
        url: routes.api.changeUserData(id),
        method: 'PATCH',
        body: updates,
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    updateUserPassword: builder.mutation<User, changePasswordRequest>({
      query: ({ id, updates }) => ({
        url: routes.api.changeUserPassword(id),
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    generatePassword: builder.mutation<User, string>({
      query: (id) => ({
        url: routes.api.generatePassword(id),
        method: 'PUT',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    deleteUser: builder.mutation<User, string>({
      query: (id) => ({
        url: routes.api.deleteUser(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    restoreUser: builder.mutation<User, string>({
      query: (id) => ({
        url: routes.api.restoreUser(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['User', 'Users'],
    }),
  }),
});

export const {
  useAboutUserQuery,
  useGetAllUsersQuery,
  useUpdateUserMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useUpdateUserRoleMutation,
  useUpdateUserDataMutation,
  useGeneratePasswordMutation,
  useUpdateUserPasswordMutation,
  useDeleteUserMutation,
  useGetAboutUserByIdQuery,
  useRestoreUserMutation,
  useGetUserLogsByIdQuery,
  useChangeMePasswordMutation,
  useForgotPasswordMutation,
  useRestorePasswordMutation,
} = userApi;

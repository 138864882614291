import React, { FC, useState, useLayoutEffect } from 'react';
import UsersList from '../../features/user/UsersList';
import { useAppDispatch, useAppSelector } from '../../hooks/defaultHooks';
import { getUserInfo } from '../../selectors/selectors';
import routes from '../../routes';
import { convertBinaryToLink } from '../../utils/converBinaryToLink';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './UsersPage.module.scss';
import { openModal } from '../../store/slices/modalSlice';
import DownloadReportsButton from '../../UIComponents/DownloadReportsButton/DownloadReportsButton';

const UsersPage: FC = () => {
  const [page, setPage] = useState<number>(0);
  const [exportsPage, setExportsPage] = useState<number>(0);
  const [userRole, setUserRole] = useState<string>('Пользователь');
  const role = useAppSelector(getUserInfo)?.roleName;
  const dispatch = useAppDispatch();
  const exportsSize = 11000;

  const downloadUsersHandler = () => {
    const download = `${routes.api.basePath()}${routes.api.usersLogsExport()}`;
    convertBinaryToLink(download, {
      page: exportsPage,
      size: exportsSize,
    });
  };
  const openDownloadModalHandler = () => {
    dispatch(
      openModal({
        type: 'downloadReportsModal',
        extra: { title: 'сотрудники', handler: downloadUsersHandler },
      }),
    );
  };
  useLayoutEffect(() => {
    if (role) setUserRole(role);
  }, [role]);
  useDocumentTitle('Сотрудники');
  return (
    <div className={`${cls.Page} grid-container`}>
      <div className={cls.Header}>
        <h1 className={cls.Title}>Сотрудники</h1>
      </div>
      <div className={cls.Panel}>
        {userRole !== 'Пользователь' && (
          <DownloadReportsButton clickHandler={openDownloadModalHandler} />
        )}
      </div>
      <UsersList
        page={page}
        setPage={setPage}
        exportsPage={exportsPage}
        exportsSize={exportsSize}
        setExportsPage={setExportsPage}
        className={cls.List}
      />
    </div>
  );
};
export default UsersPage;

import React, { FC, memo, useEffect, useState } from 'react';
import { FaRegImage } from 'react-icons/fa6';
import { IoDocumentOutline } from 'react-icons/io5';
import { MdOutlineSmartDisplay } from 'react-icons/md';
import cls from './ChatFileItem.module.scss';
import { classNameMerge } from '../../../utils/classNameMerge';
import { convertBinaryToLink } from '../../../utils/converBinaryToLink';
import routes from '../../../routes';

type ChatFileItemProps = {
  file: {
    id?: string;
    fullName: string;
    size: number;
  };
  isConsumer: boolean;
};

const MessageIcon = memo(({ type }: { type: string }) => {
  switch (type) {
    case 'JPG':
    case 'JPEG':
    case 'PNG':
      return <FaRegImage />;
    case 'PDF':
    case 'XLSX':
    case 'DOCX':
      return <IoDocumentOutline />;
    case 'AVI':
    case 'MP4':
      return <MdOutlineSmartDisplay />;
  }
});

const ChatFileItem: FC<ChatFileItemProps> = memo((props) => {
  const { file, isConsumer } = props;
  const [fileType, setFileType] = useState<string>('');
  const downloadHandler = () => {
    convertBinaryToLink(`${routes.api.basePath()}${routes.api.getMessageFileById(file.id ?? '')}`);
  };
  const convertSize = (size: number) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size >= 1024 && size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else if (size >= 1024 * 1024) {
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    }
  };
  useEffect(() => {
    if (file.fullName) {
      const arr = file.fullName.split('.');
      setFileType(arr[arr.length - 1].toUpperCase());
    }
  }, [file.fullName]);
  const setFileContainerClass = (isConsumer: boolean) => {
    if (isConsumer) {
      return `${cls.File} ${cls.ConsumerFile}`;
    } else {
      return `${cls.File}`;
    }
  };
  if (!file.id)
    return (
      <div className={classNameMerge(`${setFileContainerClass(isConsumer)}`, cls.DeletedFile)}>
        <div className={`${cls.Img} ${cls.DeletedImg}`}>
          <MessageIcon type={fileType} />
        </div>
        <div>
          <p className={`${cls.FileName} ${cls.DeleteFileName}`}>{file.fullName}</p>
          <div className={cls.Size}>
            <span className={cls.DeletedFileType}>{fileType}</span>
            <span>Файл удалён</span>
          </div>
        </div>
      </div>
    );
  return (
    <div className={setFileContainerClass(isConsumer)} onClick={downloadHandler}>
      <div className={cls.Img}>
        <FaRegImage size="1.15rem" />
      </div>
      <div>
        <p className={cls.FileName}>{file.fullName}</p>
        <div className={cls.Size}>
          <span>{fileType}</span>
          <span>{convertSize(file.size)}</span>
        </div>
      </div>
    </div>
  );
});

export default ChatFileItem;

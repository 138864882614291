// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y7I0kNWPYjpoG0LStQb_{list-style:none;padding:1.5rem 0;display:grid;grid-template-columns:repeat(3, 213px);gap:1rem;border-bottom:2px solid #e9e9ea}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerBillingListHeader/ConsumerBillingListHeader.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA,CACA,YAAA,CACA,sCAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".Header {\n  list-style: none;\n  padding: 1.5rem 0;\n  display: grid;\n  grid-template-columns: repeat(3, 213px);\n  gap: 1rem;\n  border-bottom: 2px solid rgba(233, 233, 234, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Y7I0kNWPYjpoG0LStQb_`
};
export default ___CSS_LOADER_EXPORT___;

import { createSlice } from '@reduxjs/toolkit';

type invoicesFilterSliceState = {
  filter: {
    type: string;
    value: string;
    active: boolean;
  };
};

const initialState: invoicesFilterSliceState = {
  filter: {
    type: 'email',
    value: '',
    active: false,
  },
};

const invoicesFilterSlice = createSlice({
  name: 'consumerFilters',
  initialState,
  reducers: {
    setConsumerFilter(state, { payload }) {
      state.filter = payload;
    },
    deleteConsumerFilter(state) {
      state.filter = initialState.filter;
    },
  },
});

export default invoicesFilterSlice.reducer;
export const { setConsumerFilter, deleteConsumerFilter } = invoicesFilterSlice.actions;

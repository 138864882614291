import React, { FC } from 'react';
import { Consumer } from '../consumer.types';
import cls from './ConsumerInfo.module.scss';
import ConsumerIcon from '../../widget/Icon';
import ConsumerActionButtons from '../ConsumerActionButtons';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getUserRole } from '../../../selectors/selectors';
import { openModal } from '../../../store/slices/modalSlice';

type ConsumerInfoProps = {
  consumer: Consumer;
};

const ConsumerInfo: FC<ConsumerInfoProps> = ({ consumer }) => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getUserRole);
  const openDisableModal = () =>
    dispatch(
      openModal({
        type: 'disableConsumer2FA',

        extra: {
          id: consumer?.id,
          title: 'Отключить двухфакторную аутентификацию?',
          supportText: 'Двухфакторная аутентификация для аккаунта ',
          textInformation: consumer?.email,
          subSupportText: ' будет отключена.',
          actionButtonText: 'Отключить',
        },
      }),
    );
  return (
    <div className={cls.ConsumerInfo} data-testid="consumer-info">
      <div className={cls.Consumer}>
        <ConsumerIcon email={consumer?.email ?? ''} name={consumer?.name} />
        <div className={cls.Info}>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Имя</span>
            <span className={cls.FieldValue}>{consumer?.name ?? 'Не указано'}</span>
          </div>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Номер телефона</span>
            <span className={cls.FieldValue}>
              {consumer.phone && consumer?.phone.trim().length > 0 ? consumer.phone : 'Не указан'}
            </span>
          </div>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Email</span>
            <a href={`mailto:${consumer?.email}`} className={`${cls.FieldValue} ${cls.FieldLink}`}>
              {consumer?.email}
            </a>
          </div>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Двухфакторная аутентификация</span>
            <div className={cls.Using2FAContainer}>
              <span className={cls.FieldValue}>{consumer.using2FA ? 'Да' : 'Нет'}</span>
              {consumer.using2FA && (
                <button className={cls.Button} onClick={openDisableModal}>
                  Отключить
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConsumerActionButtons consumer={consumer} userRole={userRole ?? 'Пользователь'} />
    </div>
  );
};

export default ConsumerInfo;

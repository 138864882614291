import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useUnlockConsumerByIdMutation } from '../../consumer/consumer.service';
import { toast } from 'react-toastify';
import cls from './UnlockConsumer.module.scss';
const UnLockConsumer = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [unBlock] = useUnlockConsumerByIdMutation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values) => {
      try {
        await unBlock({ id: extra?.id as string, message: values.message }).unwrap();
        dispatch(closeModal());
        toast.success('Клиент успешно разблокирован');
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            toast.error('Повторите попытку позже');
          } else if (error.status === 403) {
            toast.error('Запрещено');
          } else if (error.status === 404) {
            toast.error('Пользователь не найден');
          }
        }
      }
    },
  });
  const closeHandler = () => dispatch(closeModal());
  useEffect(() => {
    if (formik.values.message.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formik.values.message]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody} data-testid="modal-body">
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>? {extra?.subSupportText}
          </p>
        </div>
        <Form onSubmit={formik.handleSubmit} className={cls.Form}>
          <div>
            <Form.Label htmlFor="message">Введите причину разблокировки</Form.Label>
            <Form.Control
              name="message"
              id="message"
              value={formik.values.message}
              onChange={formik.handleChange}
            />
          </div>
          <div className={cls.Buttons}>
            <Button type="submit" className={cls.Button} disabled={disabled}>
              {extra?.actionButtonText}
            </Button>
            <Button onClick={closeHandler} className={cls.CancelButton}>
              Отмена
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default UnLockConsumer;

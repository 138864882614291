import React, { FC, memo } from 'react';
import cls from './SendFileForMessage.module.scss';
import { CgAttachment } from 'react-icons/cg';
import routes from '../../../routes';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { toast } from 'react-toastify';

type SendFileForMessageProps = {
  consumerId: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setShowFileLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setFileInfo: React.Dispatch<
    React.SetStateAction<{
      name: string;
    }>
  >;
};

const SendFileForMessage: FC<SendFileForMessageProps> = memo((props) => {
  const { consumerId, setPage, setShowFileLoad, setFileInfo } = props;
  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('consumerId', consumerId);
        if (file) {
          const accessToken = JSON.parse(localStorage.getItem('banditto') || '{}').accessToken;

          setShowFileLoad(true);
          setFileInfo({ name: file.name });
          await fetch(`${routes.api.basePath()}${routes.api.uploadMessageFile()}`, {
            method: 'POST',
            body: formData,
            mode: 'cors',
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });
          setPage(0);
        }
      }
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Произошла ошибка');
        }
      }
    } finally {
      setShowFileLoad(false);
      setFileInfo({ name: '' });
    }
  };
  return (
    <div className={cls.SendFileForMessage}>
      <label htmlFor="send-file">
        <CgAttachment size="24px" fill="currentColor" />
      </label>
      <input
        type="file"
        id="send-file"
        className={cls.SendFile}
        onChange={(event) => uploadHandler(event)}
      />
    </div>
  );
});

export default SendFileForMessage;

import React, { FC, useEffect } from 'react';
import { Consumer } from '../../consumer/consumer.types';
import { SearchType } from '../../search/search.types';
import { Lead } from '../../lead/lead.types';
import { Log } from '../../log/logs.types';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../hooks/defaultHooks';

interface SearchInfoPanelProps {
  field: string;
  value: string;
  actualSearchText: string;
  setActualSearchText: React.Dispatch<React.SetStateAction<string>>;
  setShowInfoPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setScrollList:
    | React.Dispatch<React.SetStateAction<Consumer[]>>
    | React.Dispatch<React.SetStateAction<Lead[]>>
    | React.Dispatch<React.SetStateAction<Log[]>>;
  setSearch: React.Dispatch<React.SetStateAction<SearchType>>;
  clearSearchState: ActionCreatorWithoutPayload<
    'search/clearConsumersSearch' | 'search/clearLeadsSearch' | 'search/clearLogsSearch'
  >;
}

const SearchInfoPanel: FC<SearchInfoPanelProps> = ({
  field,
  value,
  actualSearchText,
  setActualSearchText,
  setShowInfoPanel,
  setScrollList,
  setSearch,
  clearSearchState,
}) => {
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    setScrollList([]);
    setShowInfoPanel(false);
    setSearch({ page: 0, size: 10 });
    dispatch(clearSearchState());
  };
  useEffect(() => {
    if (field.length !== 0) {
      switch (field) {
        case 'name':
          setActualSearchText(`Поиск: Имя содержит '${value}'`);
          break;
        case 'email':
          setActualSearchText(`Поиск: Электронная почта содержит '${value}'`);
          break;
        case 'phone':
          setActualSearchText(`Поиск: Телефон содержит '${value}'`);
          break;
        case 'period':
          setActualSearchText(`Поиск: Дата создания ${value}`);
          break;
        case 'start':
          setActualSearchText(`Поиск: Дата создания ${value}`);
          break;
        case 'end':
          setActualSearchText(`Поиск: Дата создания ${value}`);
          break;
        case 'today':
          setActualSearchText(`Поиск: Дата создания ${value}`);
          break;
        default:
          setActualSearchText('');
          break;
      }
    }
  }, [field, value, setActualSearchText]);
  return (
    <p className="info-panel" data-testid="info-panel">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-search"
        viewBox="0 0 16 16"
        data-testid="search-icon"
      >
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
      </svg>
      <span data-testid="info-text">{actualSearchText}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-x-square-fill info-panel__close-icon"
        viewBox="0 0 16 16"
        onClick={closeHandler}
        data-testid="close-icon"
      >
        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
      </svg>
    </p>
  );
};

export default SearchInfoPanel;

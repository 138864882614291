import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getTheme } from '../../../selectors/selectors';
import { Form, Modal } from 'react-bootstrap';
import { closeModal } from '../../../store/slices/modalSlice';
import Button from '../../../UIComponents/Button';
import { useFormik } from 'formik';
import { useChangeMePasswordMutation } from '../../user/user.service';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import * as yup from 'yup';
import { toast } from 'react-toastify';

interface initialState {
  generatePassword: boolean;
  oldPassword: string;
  newPassword?: string;
  repeatNewPassword?: string;
}

const ChangeMePassword = () => {
  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Поле должно быть заполнено'),
    newPassword: yup.string().min(8, 'Минимальная длина пароля 8 символов').trim(),
    repeatNewPassword: yup
      .string()
      .min(8, 'Минимальная длина пароля 8 символов')
      .test(
        'newPassword',
        'Пароли должны совпадать',
        (password, context) => password === context.parent.newPassword,
      ),
  });
  const theme = useAppSelector(getTheme);
  const [error, setError] = useState<string>('');
  const [changePassword] = useChangeMePasswordMutation();
  const dispatch = useAppDispatch();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const initialValues: initialState = {
    generatePassword: true,
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeTypeHandler = (event: any) => {
    const inputId = event.target.parentNode.htmlFor;
    const input = document.querySelector(`#${inputId}`);
    const oldType = input?.getAttribute('type');
    input?.setAttribute('type', oldType === 'password' ? 'text' : 'password');
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { oldPassword, newPassword, repeatNewPassword, generatePassword } = values;
      try {
        if (generatePassword) {
          const response = await changePassword({ oldPassword }).unwrap();
          if (response) {
            toast.success('Пароль отправлен на почту');
            closeHandler();
          }
        } else if (newPassword && repeatNewPassword) {
          const response = await changePassword({ oldPassword, newPassword }).unwrap();
          if (response) {
            closeHandler();
            toast.success('Пароль успешно изменен');
          }
        } else {
          throw new Error('wrongPassword');
        }
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            setError('Введите правильный пароль');
            toast.error('Введите правильный пароль');
          } else if (error.status === 401) {
            toast.error('Неверные данные');
            setError('Неверные данные');
          } else if (error.status === 403) {
            toast.error('Запрещено');
            setError('Запрещено');
          } else {
            setError('Пароли должны совпадать');
          }
        }
      }
    },
  });
  return (
    <>
      <Modal.Header className="bg-dark d-flex justify-content-center text-white">
        Сменить пароль
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
        <Form
          className="d-flex flex-column gap-3"
          noValidate
          onSubmit={formik.handleSubmit}
          data-testid="change-password"
        >
          {error && <div className="text-danger">{error}</div>}
          <Form.Group className="d-flex position-relative">
            <Form.Control
              type="password"
              name="oldPassword"
              id="oldPassword"
              className={`${theme}-input change-password__input--${theme}`}
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              placeholder="Введите старый пароль"
              isInvalid={!!formik.errors.oldPassword}
            />
            <Form.Label
              className={`show-password show-password--${theme}`}
              htmlFor="oldPassword"
              onClick={(event) => changeTypeHandler(event)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
            </Form.Label>
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.oldPassword}
            </Form.Control.Feedback>
          </Form.Group>
          {!formik.values.generatePassword ? (
            <>
              <Form.Group className="d-flex position-relative">
                <Form.Control
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  className={`${theme}-input change-password__input--${theme}`}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  placeholder="Введите новый пароль"
                  isInvalid={!!formik.errors.newPassword}
                />
                <Form.Label
                  className={`show-password show-password--${theme}`}
                  htmlFor="newPassword"
                  onClick={(event) => changeTypeHandler(event)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>
                </Form.Label>
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="d-flex position-relative">
                <Form.Control
                  type="password"
                  name="repeatNewPassword"
                  id="repeatNewPassword"
                  className={`${theme}-input change-password__input--${theme}`}
                  value={formik.values.repeatNewPassword}
                  onChange={formik.handleChange}
                  placeholder="Повторите новый пароль"
                  isInvalid={!!formik.errors.repeatNewPassword}
                />
                <Form.Label
                  className={`show-password show-password--${theme}`}
                  htmlFor="repeatNewPassword"
                  onClick={(event) => changeTypeHandler(event)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>
                </Form.Label>
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.repeatNewPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : null}
          <Form.Group className="d-flex gap-3 align-items-center justify-content-center">
            <Form.Check
              name="generatePassword"
              id="generatePassword"
              checked={formik.values.generatePassword}
              onChange={formik.handleChange}
              label="Создать автоматически и направить по email"
            />
          </Form.Group>
          <div className="d-flex justify-content-center gap-5">
            <Button text="Отмена" clickHandler={closeHandler} theme={theme} />
            <Button text="Сохранить" type="submit" theme={theme} />
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default ChangeMePassword;

import React, { FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useSendMessageMutation } from '../../message/message.service';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import cls from './SendMessage.module.scss';
import { RiSendPlane2Line } from 'react-icons/ri';
import { ConsumerMessage } from '../../message/message.types';

type initialValues = {
  text: string;
};

type SendMessageFormProps = {
  consumerId: string | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setAllMessages: React.Dispatch<React.SetStateAction<ConsumerMessage[]>>;
};

const SendMessageForm: FC<SendMessageFormProps> = ({ consumerId, setPage, setAllMessages }) => {
  const [sendMessage] = useSendMessageMutation();
  const initialValues: initialValues = {
    text: '',
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await sendMessage({ consumerId: consumerId as string, text: values.text }).unwrap();
        setPage(0);
        setAllMessages([]);
        values.text = '';
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          console.log(error.status);
        }
      }
    },
  });
  return (
    <Form data-testid="send-form" onSubmit={formik.handleSubmit} className={cls.Form}>
      <Form.Control
        type="text"
        placeholder="Сообщение"
        name="text"
        id="text"
        value={formik.values.text}
        onChange={formik.handleChange}
        className={cls.Input}
      />
      {formik.values.text.length !== 0 && (
        <Button type="submit" className={cls.Button}>
          <RiSendPlane2Line size="1.5rem" fill="currentColor" />
        </Button>
      )}
    </Form>
  );
};

export default SendMessageForm;

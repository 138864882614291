import React, { FC } from 'react';
import { ConsumerMessage } from '../../message/message.types';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import cls from './ChatMessage.module.scss';
import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from 'react-icons/io5';
import ChatFileItem from '../ChatFileItem';

type ChatMessageProps = {
  message: ConsumerMessage;
  consumerEmail: string;
  consumerName: string;
};

const ChatMessage: FC<ChatMessageProps> = ({ message, consumerName }) => {
  if (message.author === 'CONSUMER') {
    return (
      <div className={cls.Message} data-testid="message-container">
        <div className={cls.ConsumerMessage}>
          <h6 className={cls.ConsumerName}>{consumerName}</h6>
          {message.fileInfo && message.fileInfo.fullName && (
            <div className={cls.FileMessage}>
              {message.fileInfo && message.fileInfo.fullName && (
                <ChatFileItem file={message.fileInfo} isConsumer={true} />
              )}
              <div className={cls.Time} data-testid="message-time">
                <time dateTime={message.createdAt} className={cls.Time}>
                  {convertDateToNeedFormat({ date: message.createdAt, format: 'time' })}
                </time>
              </div>
            </div>
          )}
          {message.text && !message.fileInfo && (
            <div className={cls.Text}>
              <p className={cls.MessageText}>{message.text}</p>
              <time dateTime={message.createdAt} className={cls.Time}>
                {convertDateToNeedFormat({ date: message.createdAt, format: 'time' })}
              </time>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={cls.Message} data-testid="message-container">
        <div className={`${cls.SupportMessage}`}>
          {message.fileInfo && message.fileInfo.fullName && (
            <div className={cls.FileMessage}>
              {message.fileInfo && message.fileInfo.fullName && (
                <ChatFileItem file={message.fileInfo} isConsumer={false} />
              )}
              <div className={`${cls.Time} ${cls.SupportTime}`}>
                <time dateTime={message.createdAt} data-testid="message-time">
                  {convertDateToNeedFormat({ date: message.createdAt, format: 'time' })}
                </time>
                {message.status === 'SENT' && <IoCheckmarkSharp />}
                {message.status === 'READ' && <IoCheckmarkDoneSharp />}
              </div>
            </div>
          )}
          {message.text && !message.fileInfo && (
            <div className={cls.Text}>
              <p className={cls.MessageText} data-testid="message-text">
                {message.text}
              </p>
              <div className={`${cls.Time} ${cls.SupportTime}`}>
                <time dateTime={message.createdAt} data-testid="message-time">
                  {convertDateToNeedFormat({ date: message.createdAt, format: 'time' })}
                </time>
                {message.status === 'SENT' && <IoCheckmarkSharp />}
                {message.status === 'READ' && <IoCheckmarkDoneSharp />}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatMessage;

import React, { useEffect, useState } from 'react';
import cls from './SendInvoiceModal.module.scss';
import { Form, FormControl, FormGroup, FormSelect, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import Button from '../../../UIComponents/Button';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useResendInvoiceMutation } from '../../invoices/invoices.service';
import { modalExtra } from '../../../selectors/selectors';

const SendInvoiceModal = () => {
  const extra = useAppSelector(modalExtra);
  const [checkEdoSystem, setCheckEdoSystem] = useState(false);
  const [checkEmail, setCheckEmail] = useState(true);
  const [emailValue, setEmailValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const [resendInvoice] = useResendInvoiceMutation();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  const sendInvoiceHandler = async () => {
    setDisabled(true);
    try {
      await resendInvoice(extra?.id ?? '');
      toast.success('Счет был перевыслан');
      dispatch(closeModal());
    } catch (error) {
      setDisabled(false);
      if (isFetchBaseQueryError(error)) {
        if (error.status === 204) {
          toast.error('Данные не были найдены');
        }
        if (error.status === 403) {
          toast.error('Запрещено');
        }
      }
    } finally {
      setDisabled(false);
    }
  };
  useEffect(() => {
    if (emailValue.length > 0 && checkEmail) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [emailValue.length, checkEmail]);
  useEffect(() => {
    if (extra?.email) {
      setEmailValue(extra.email);
    }
  }, [extra?.email]);
  return (
    <>
      <Modal.Header className={cls.Header} closeButton>
        <h3 className="title-3">Отправить счет</h3>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center gap-3">
        <div className="d-flex flex-column gap-3">
          <FormGroup className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center gap-3">
              <Form.Check
                id="edo"
                name="edo"
                checked={checkEdoSystem}
                type="checkbox"
                onChange={() => {
                  toast.error('Функционал находится в разработке');
                  setCheckEdoSystem(false);
                }}
                label="Через систему ЭДО"
              />
            </div>
            <FormSelect disabled>
              <option>Контур.Диадок</option>
            </FormSelect>
          </FormGroup>
          <FormGroup className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center gap-3">
              <Form.Check
                id="emailCheck"
                name="emailCheck"
                checked={checkEmail}
                onChange={() => setCheckEmail((prev) => !prev)}
                type="checkbox"
                label="Email"
                disabled
              />
            </div>
            <FormControl
              id="email"
              type="email"
              name="email"
              value={emailValue}
              placeholder="onetwothree@mail.ru"
              onChange={(event) => setEmailValue(event.target.value)}
              disabled
            />
          </FormGroup>
        </div>
        <div className="d-flex gap-3">
          <button
            type="button"
            className={
              !disabled ? cls.ActionButton : `${cls.ActionButton} ${cls.ActionButtonDisabled}`
            }
            onClick={sendInvoiceHandler}
            disabled={disabled}
          >
            Отправить
          </button>
          <Button text="Отмена" clickHandler={closeHandler} className={cls.RevokeButton} />
        </div>
      </Modal.Body>
    </>
  );
};

export default SendInvoiceModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.td58hK1DRtUSIKO_xzFc{border:none}.CXVeUPh29dT0q9PqNpr9{display:flex;flex-direction:column;gap:1.5rem}.rObGgeUwPa0gcDtjligI{display:flex;flex-direction:column;gap:1rem}.z9EIT92yzLGvpWXnnEwc{display:flex;gap:14px;width:100%}`, "",{"version":3,"sources":["webpack://./src/features/modals/ConfirmInvoice/ConfirmInvoice.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".ModalHeader {\n  border: none;\n}\n.ModalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n.Form {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n.Buttons {\n  display: flex;\n  gap: 14px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalHeader": `td58hK1DRtUSIKO_xzFc`,
	"ModalBody": `CXVeUPh29dT0q9PqNpr9`,
	"Form": `rObGgeUwPa0gcDtjligI`,
	"Buttons": `z9EIT92yzLGvpWXnnEwc`
};
export default ___CSS_LOADER_EXPORT___;

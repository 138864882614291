// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xoDLmp3l8D4YEn9xXfAo{color:var(--black-color);text-decoration:none;cursor:pointer;display:block;max-height:55px}.xoDLmp3l8D4YEn9xXfAo:hover{background-color:#e9e9ea;border-radius:18px}.tmUF3XrNOhAFM_uBD2qg{color:#0b6efd}.tmUF3XrNOhAFM_uBD2qg:hover{background-color:rgba(0,0,0,0);cursor:not-allowed;pointer-events:all}.g6UAgt0LvQSm3kuepqX_{display:flex;flex-direction:column;justify-content:center;font-size:14px;align-items:center;line-height:20.7px;text-align:center;padding:.5rem;position:relative}.KRWxiBddxXMbTikORufc::after{content:"";position:absolute;width:10px;height:10px;background-color:red;top:7px;left:56%;border-radius:100%}`, "",{"version":3,"sources":["webpack://./src/features/layout/navigation/NavBarItem/NavBatItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,oBAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CACA,4BACE,wBAAA,CACA,kBAAA,CAGJ,sBACE,aAAA,CACA,4BACE,8BAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CACA,iBAAA,CAGA,6BACE,UAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,oBAAA,CACA,OAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".NavLink {\n  color: var(--black-color);\n  text-decoration: none;\n  cursor: pointer;\n  display: block;\n  max-height: 55px;\n  &:hover {\n    background-color: #e9e9ea;\n    border-radius: 18px;\n  }\n}\n.NavLinkActive {\n  color: #0b6efd;\n  &:hover {\n    background-color: transparent;\n    cursor: not-allowed;\n    pointer-events: all;\n  }\n}\n.Item {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  font-size: 14px;\n  align-items: center;\n  line-height: 20.7px;\n  text-align: center;\n  padding: 0.5rem;\n  position: relative;\n}\n.ItemUnclodes {\n  &::after {\n    content: '';\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    background-color: #ff0000;\n    top: 7px;\n    left: 56%;\n    border-radius: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavLink": `xoDLmp3l8D4YEn9xXfAo`,
	"NavLinkActive": `tmUF3XrNOhAFM_uBD2qg`,
	"Item": `g6UAgt0LvQSm3kuepqX_`,
	"ItemUnclodes": `KRWxiBddxXMbTikORufc`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import Button from '../../../UIComponents/Button';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { useSignUpMutation } from '../authorization.service';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { logIn } from '../../../store/slices/authSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { AuthFormsProps, initialValues } from '../authorization.types';
import { toast } from 'react-toastify';
import { MdOutlinePersonAddAlt } from 'react-icons/md';

const SignUpForm: FC<AuthFormsProps> = ({ navigate, theme }) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [signUp] = useSignUpMutation();
  const dispatch = useAppDispatch();
  const validationSchema = yup
    .object()
    .strict()
    .shape({
      email: yup
        .string()
        .email('Введен не корректный email')
        .required('Поле является обязательным')
        .trim(),
      password: yup
        .string()
        .min(8, 'Длина пароля должна быть не меньше 8 символов')
        .max(30, 'Длина пароля не должна быть больше 30 символов')
        .required('Поле является обязательным')
        .trim(),
    });
  const initialValues: initialValues = {
    email: '',
    password: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { email, password } = values;
      try {
        setDisabled(true);
        const response = await signUp({ email: email.toLocaleLowerCase(), password }).unwrap();
        toast.success('Вы успешно зарегистрированы');
        dispatch(logIn(response));
        navigate(routes.pages.mainPage());
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 409) {
            setError('Пользователь уже зарегистрирован');
            toast.error('Пользователь уже зарегистрирован');
          }
          if (error.status === 400) console.log('Ошибка 400');
          if (error.status === 403) {
            toast.error('Аккаунт временно заблокирован. Обратитесь к администратору');
            setError('Аккаунт временно заблокирован. Обратитесь к администратору');
          }
        }
      } finally {
        setDisabled(false);
      }
    },
  });

  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
    setError('');
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Form
        data-testid="sign-up-form"
        className="d-flex flex-column justify-content-center gap-3"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        {error && <div className="text-danger">{error}</div>}
        <Form.Group className="position-relative mt-3">
          <Form.Control
            type="email"
            id="email"
            className={`${theme}-input border-5 border-0`}
            name="email"
            placeholder="Email"
            required
            onChange={onChangeHandle}
            isInvalid={!!formik.errors.email}
            value={formik.values.email}
          />
          <Form.Control.Feedback type="invalid" tooltip>
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="position-relative">
          <Form.Control
            type="password"
            id="password"
            name="password"
            placeholder="Пароль"
            required
            min={8}
            max={30}
            onChange={onChangeHandle}
            isInvalid={!!formik.errors.password}
            value={formik.values.password}
            className={`${theme}-input border-5 border-0`}
          />
          <Form.Control.Feedback type="invalid" tooltip>
            {formik.errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          text="Зарегистрироваться"
          type="submit"
          Icon={<MdOutlinePersonAddAlt size="1.5em" />}
          disabled={disabled}
          theme={theme}
        />
        <div className="links__part text-center">
          <p>
            Уже есть аккаунт?{' '}
            <span>
              <Link to={routes.pages.signIn()}>Войти</Link>
            </span>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default SignUpForm;

import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import { GiftOrderFullType } from './giftOrders.types';

type UpdateGiftOrderByIdRequest = {
  giftOrderId: string;
  status: 'CREATED' | 'IN_PROGRESS' | 'SENT' | 'CANCELED';
  message: string | null;
};

type UpdateGiftOrderAddressRequest = {
  giftOrderId: string;
  recipientAddress: string;
  recipientName: string;
  recipientPhone: string;
};

type GetGiftOrdersWithPaginationAndSortingRequest = PaginationRequest & {
  consumerId?: string;
  status?: 'CREATED' | 'IN_PROGRESS' | 'SENT' | 'CANCELED';
  sortField?: string;
  sortOrder?: 0 | 1;
};

const giftOrdersApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    updateGiftOrderById: builder.mutation<GiftOrderFullType, UpdateGiftOrderByIdRequest>({
      query: ({ giftOrderId, status, message }) => ({
        url: routes.api.updateGiftOrderById(giftOrderId),
        method: 'PATCH',
        body: {
          status,
          message,
        },
      }),
      invalidatesTags: ['GiftOrder'],
    }),
    getGiftOrders: builder.query<
      { countAll: number; giftOrders: GiftOrderFullType[] },
      GetGiftOrdersWithPaginationAndSortingRequest
    >({
      query: ({ page = 0, size = 10, sortField = 'status', sortOrder }) => ({
        url: routes.api.getAllGiftOrders(),
        params: {
          page,
          size,
          sortField,
          sortOrder,
        },
      }),
      providesTags: ['GiftOrder'],
    }),
    UpdateGiftOrderAddress: builder.mutation<GiftOrderFullType, UpdateGiftOrderAddressRequest>({
      query: ({ giftOrderId, recipientAddress, recipientName, recipientPhone }) => ({
        url: routes.api.updateGiftOrderAddressById(giftOrderId),
        method: 'PATCH',
        body: {
          recipientAddress,
          recipientName,
          recipientPhone,
        },
      }),
      invalidatesTags: ['GiftOrder'],
    }),
  }),
});

export const {
  useUpdateGiftOrderByIdMutation,
  useGetGiftOrdersQuery,
  useUpdateGiftOrderAddressMutation,
} = giftOrdersApi;

export const userRoutes = {
  signIn: () => 'users/signup',
  logIn: () => 'users/login',
  refreshToken: () => 'users/refresh',
  lock: (userId: string) => `users/${userId}/lock`,
  unlock: (userId: string) => `users/${userId}/unlock`,
  updateRole: (userId: string) => `users/${userId}/role`,
  changeUserData: (userId: string) => `users/${userId}/update`,
  changeUserPassword: (userId: string) => `users/${userId}/psw`,
  generatePassword: (userId: string) => `users/${userId}/genpsw`,
  deleteUser: (userId: string) => `users/${userId}/delete`,
  restoreUser: (userId: string) => `users/${userId}/restore`,
  aboutUser: () => '/users/me',
  changeMePassword: () => '/users/me/password',
  forgotPassword: () => '/users/restore',
  restorePassword: (email: string, code: string) => `/users/${email}/restore/${code}`,
  aboutUserById: (userId: string) => `users/${userId}`,
  getLogsByUserId: (userId: string) => `users/${userId}/logs`,
  getAllUsers: () => '/users',
  getDeletedUsers: () => '/users/deleted',
  usersLogsExport: () => '/users/export',
};

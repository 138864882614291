// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hq3H3zLPDi_ZmQnrNVQ9{list-style:none;overflow-y:scroll;min-height:calc(90dvh - 660px);height:calc(90dvh - 660px);max-height:calc(90dvh - 660px);position:relative;padding:0}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerBillingList/ConsumerBillingList.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,8BAAA,CACA,0BAAA,CACA,8BAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".List {\n  list-style: none;\n  overflow-y: scroll;\n  min-height: calc(90dvh - 660px);\n  height: calc(90dvh - 660px);\n  max-height: calc(90dvh - 660px);\n  position: relative;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"List": `hq3H3zLPDi_ZmQnrNVQ9`
};
export default ___CSS_LOADER_EXPORT___;

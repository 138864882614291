import React, { FC, createRef, useEffect, useState, useLayoutEffect, memo } from 'react';
import ChatMessage from '../ChatMessage';
import SendMessageForm from '../SendMessageForm';
import { ConsumerMessage } from '../../message/message.types';
import Button from '../../../UIComponents/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getActualMessageChannel, getRefreshChat } from '../../../selectors/selectors';
import { setRefresh } from '../../../store/slices/chatSlice';
import { channelState } from '../../../store/slices/messageChannelSlice';
import cls from './ChatContainer.module.scss';
import ChatPanel from '../ChatPanel';
import { showDateFromTodayUpdate } from '../../../utils/showDateFromToday';
import SendFileForMessage from '../SendFileForMessage';
import { FaArrowDown, FaRegFile } from 'react-icons/fa';
import { openOffcanvas } from '../../../store/slices/offcanvasSlice';
import ShowLoadFileInfo from '../ShowLoadFileInfo';
import { Spinner } from 'react-bootstrap';

type ChatContainerProps = {
  consumerMessages: ConsumerMessage[];
  consumerId: string;
  setAllMessages: React.Dispatch<React.SetStateAction<ConsumerMessage[]>>;
  allMessages: ConsumerMessage[];
  setScrollTop: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const ChatContainer: FC<ChatContainerProps> = memo(
  ({
    consumerMessages,
    consumerId,
    setAllMessages,
    allMessages,
    setScrollTop,
    isLoading,
    page,
    setPage,
  }) => {
    const dispatch = useAppDispatch();
    const refresh = useAppSelector(getRefreshChat);
    const [finallMessage, setFinallMessage] = useState<boolean>(false);
    const [messagesGroupDate, setMessagesGroupDate] = useState<string[]>([]);
    const [channelData, setChannelData] = useState<channelState>();
    const activeChannel = useAppSelector(getActualMessageChannel);
    const outerDiv = createRef<HTMLDivElement>();
    const [showAddButton, setShowAddButton] = useState<boolean>(false);
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
    const [prevLength, setPrevLength] = useState<number>(10);
    const [showLoadFileInfo, setShowLoadFileInfo] = useState<boolean>(false);
    const [fileInfo, setFileInfo] = useState<{ name: string }>({
      name: '',
    });
    const scrollToBottomButton = () => {
      outerDiv.current?.scrollTo({
        top: outerDiv.current.scrollHeight,
        behavior: 'smooth',
      });
    };
    const uniqDate = (list: string[]) => Array.from(new Set(list));
    const scrollHandler = () => {
      if ((outerDiv.current?.scrollTop as number) < 70) {
        setShowAddButton(true);
      } else if (
        outerDiv.current?.scrollTop !==
        (outerDiv.current?.scrollHeight as number) - (outerDiv.current?.clientHeight as number)
      ) {
        return setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    const openConsumerOffcanvas = (id: string) => {
      dispatch(openOffcanvas({ type: 'consumerOffcanvas', extra: { id } }));
    };
    const addMessagesButtonHandler = () => {
      const actualPage = page + 1;
      setPage(actualPage);
      setScrollTop(true);
      setPrevLength(prevLength + 10);
      setShowAddButton(false);
    };
    useEffect(() => {
      if (activeChannel.email) {
        setChannelData(activeChannel);
      } else {
        setChannelData(JSON.parse(localStorage.getItem('activeChannel') as string));
      }
    }, [activeChannel]);
    useEffect(() => {
      outerDiv.current?.lastElementChild?.scrollIntoView();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMessages.length]);

    useEffect(() => {
      if (consumerMessages && !refresh) {
        const updateMessages = consumerMessages.filter(
          (message) =>
            !allMessages.some((item) => item.id === message.id && item.status === message.status),
        );
        const result = consumerMessages.filter(
          (message) => !allMessages.some((item) => item.id === message.id),
        );
        if (updateMessages.length !== 0) {
          updateMessages.forEach((item) => {
            const index = allMessages.findIndex((mesage) => mesage.id === item.id);
            if (index) {
              allMessages[index] = item;
            }
          });
        }
        if (result.length === 1 || result.length === 2) {
          setAllMessages([...Array.from(allMessages), ...(result.reverse() as ConsumerMessage[])]);
          outerDiv.current?.lastElementChild?.scrollIntoView();
          setScrollTop(false);
        } else {
          setAllMessages([...(result.reverse() as ConsumerMessage[]), ...Array.from(allMessages)]);
          setScrollTop(false);
        }
      } else if (consumerMessages && refresh) {
        setAllMessages([...consumerMessages]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consumerMessages, refresh, outerDiv.current?.lastElementChild]);
    useLayoutEffect(() => {
      if (refresh) {
        setAllMessages([]);
        setPage(0);
        dispatch(setRefresh({ refresh: false }));
        setPrevLength(10);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);
    useEffect(() => {
      if (allMessages.length !== 0 && allMessages) {
        const messagesDateInList = allMessages.map((message) =>
          showDateFromTodayUpdate(message.createdAt),
        );
        setMessagesGroupDate([...uniqDate(messagesDateInList)]);
      }
    }, [allMessages]);
    useLayoutEffect(() => {
      if (consumerMessages && consumerMessages.length > 0 && !isLoading) {
        setFinallMessage(consumerMessages[0].finalMessage);
      } else {
        setFinallMessage(false);
      }
    }, [consumerMessages, isLoading]);
    useLayoutEffect(() => {
      if (consumerMessages && consumerMessages.length > 0 && !isLoading) {
        outerDiv.current?.lastElementChild?.scrollIntoView();
      }
    }, [consumerMessages, isLoading, outerDiv]);
    return (
      <div className={cls.Container} data-testid="chat-container">
        <header className={cls.Header}>
          <div onClick={() => openConsumerOffcanvas(consumerId)} className={cls.Info}>
            {channelData?.name && <h5 className={`title-5 ${cls.Title}`}>{channelData?.name}</h5>}
            <p className={channelData?.name ? cls.Email : `title-5 ${cls.Title}`}>
              {channelData?.email}
            </p>
          </div>
          <ChatPanel
            id={consumerId}
            setAllMessages={setAllMessages}
            setPage={setPage}
            allMessages={allMessages}
            finalizeMessage={finallMessage}
          />
        </header>
        <div
          className={cls.Body}
          data-testid="chat-section"
          ref={outerDiv}
          onScroll={scrollHandler}
        >
          {showAddButton && (
            <Button
              text="Загрузить еще"
              clickHandler={addMessagesButtonHandler}
              className={cls.LoadMoreButton}
            />
          )}
          {allMessages &&
            [...messagesGroupDate].map((date) => (
              <>
                <h3 className={cls.Date}>{date}</h3>
                {allMessages
                  ?.filter((message) => showDateFromTodayUpdate(message.createdAt) === date)
                  .map((message, index) => (
                    <div
                      key={index}
                      className={`chat__message-container  chat__message-container--${message.author.toLocaleLowerCase()}`}
                      data-testid="message-container"
                    >
                      <ChatMessage
                        message={message}
                        consumerEmail={channelData?.email as string}
                        consumerName={channelData?.name as string}
                      />
                    </div>
                  ))}
              </>
            ))}
          {showScrollButton && (
            <button className={cls.ScrollBottom} onClick={scrollToBottomButton}>
              <FaArrowDown />
            </button>
          )}
        </div>
        {showLoadFileInfo && (
          <ShowLoadFileInfo>
            <div className={cls.LoadFileInfo}>
              <div className={cls.LoadFile}>
                <div className={cls.LoadFileIcon}>
                  <FaRegFile size="15px" />
                </div>
                <span className={cls.LoadFileText}>{fileInfo.name}</span>
              </div>
              <Spinner size="sm" color="currentColor" />
            </div>
          </ShowLoadFileInfo>
        )}
        {!showLoadFileInfo && (
          <div className={cls.Footer}>
            <SendFileForMessage
              consumerId={channelData?.id as string}
              setPage={setPage}
              setShowFileLoad={setShowLoadFileInfo}
              setFileInfo={setFileInfo}
            />
            <SendMessageForm
              consumerId={channelData?.id as string}
              setPage={setPage}
              setAllMessages={setAllMessages}
            />
          </div>
        )}
      </div>
    );
  },
);

export default ChatContainer;

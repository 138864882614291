import React, { FC } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import cls from './ConsumerSearch.module.scss';
import { useFormik } from 'formik';
import { Consumer } from '../consumer.types';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { setConsumerFilter } from '../../../store/slices/consumerFilterSlice';

type ConsumerSearchProps = {
  setSearch: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number;
    }>
  >;
  search: {
    [key: string]: string | number;
  };
  setScrollConsumers: React.Dispatch<React.SetStateAction<Consumer[]>>;
};
const ConsumerSearch: FC<ConsumerSearchProps> = (props) => {
  const { search, setSearch, setScrollConsumers } = props;
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      filterField: search.filterField,
      filterValue: search.filterValue,
    },
    onSubmit: (values) => {
      setScrollConsumers([]);
      dispatch(
        setConsumerFilter({ type: values.filterField, value: values.filterValue, active: true }),
      );
      setSearch({ ...search, ...values });
    },
  });
  return (
    <form className={cls.Search} onSubmit={formik.handleSubmit}>
      <FormControl
        placeholder="Поиск"
        className={cls.SearchInput}
        id="filterValue"
        name="filterValue"
        onChange={formik.handleChange}
      />
      <Form.Select
        value={formik.values.filterField}
        onChange={formik.handleChange}
        name="filterField"
        id="filterField"
        className={cls.SearchSelect}
      >
        <option value="email">Email</option>
        <option value="name">Имя</option>
        <option value="phone">Номер телефона</option>
      </Form.Select>
    </form>
  );
};

export default ConsumerSearch;

import React, { FC, memo, useEffect, useState } from 'react';
import { navElementList } from '../navElementList';
import NavBarItem from '../NavBarItem/NavBarItem';
import logo from './logo.svg';
import cls from './NavBar.module.scss';
import SupportCabinet from '../SupportCabinet';

interface NavBarProps {
  unClosed: boolean;
}

const NavBar: FC<NavBarProps> = memo(({ unClosed }) => {
  const [active, setActive] = useState<boolean>(false);
  useEffect(() => {
    const links = Array.from(document.querySelectorAll('a'));
    const activeLinks = links.filter((link) => link.classList.contains('active-navBar-link'));
    const rootIndex = document.querySelector('#rootIndex');
    if (activeLinks.length === 0) {
      rootIndex?.setAttribute('aria-current', 'page');
      rootIndex?.setAttribute('class', 'active-navBar-link');
      setActive(false);
    }
    if (active) {
      rootIndex?.removeAttribute('aria-current');
      rootIndex?.removeAttribute('class');
    }
    return;
  }, [active]);
  return (
    <div data-testid="navbar-list" className={cls.NavBar}>
      <img src={logo} alt="Banditto logo" />
      <nav className={cls.NavBarList} role="navigation">
        {navElementList.map((element) => (
          <NavBarItem
            navElement={element}
            unClosed={unClosed}
            setActive={setActive}
            key={element.title}
          />
        ))}
      </nav>
      <SupportCabinet />
    </div>
  );
});

export default NavBar;

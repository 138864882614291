import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form, FormControl, Modal } from 'react-bootstrap';
import cls from './ChangeOrderStatusToSend.module.scss';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '../../../UIComponents/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import { useUpdateGiftOrderByIdMutation } from '../giftOrders.service';
import { modalExtra } from '../../../selectors/selectors';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { toast } from 'react-toastify';

const ChangeOrderStatusToSend = () => {
  const extra = useAppSelector(modalExtra);
  const [sendWithoutTrack, setSendWithoutTrack] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const validationSchema = yup.object().shape({
    track: !sendWithoutTrack ? yup.string().required('Поле является обязательным') : yup.string(),
  });
  const [updateOrder] = useUpdateGiftOrderByIdMutation();
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  const formik = useFormik({
    initialValues: {
      isTrack: sendWithoutTrack,
      track: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (formik.values.isTrack) {
        try {
          updateOrder({
            giftOrderId: extra?.id ?? '',
            status: 'SENT',
            message: '',
          }).unwrap();
        } catch (error) {
          if (isFetchBaseQueryError(error)) {
            if (error.status === 400) {
              toast.error('Неверно введены данные');
            }
            if (error.status === 403) {
              toast.error('Запрещено');
            }
            if (error.status === 404) {
              toast.error('Заказ не найден');
            }
          }
        }
      } else {
        try {
          updateOrder({
            giftOrderId: extra?.id ?? '',
            status: 'SENT',
            message: values.track,
          }).unwrap();
        } catch (error) {
          if (isFetchBaseQueryError(error)) {
            if (error.status === 400) {
              toast.error('Неверно введены данные');
            }
            if (error.status === 403) {
              toast.error('Запрещено');
            }
            if (error.status === 404) {
              toast.error('Заказ не найден');
            }
          }
        }
      }
    },
  });
  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
  };
  useEffect(() => {
    if (sendWithoutTrack || formik.values.track.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formik.values.track, sendWithoutTrack]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Введите трек-номер</h3>
      </Modal.Header>
      <Modal.Body>
        <Form className={cls.Form} onSubmit={formik.handleSubmit}>
          <div className={cls.InputsContainer}>
            <div className={cls.SwitcherContainer}>
              <label htmlFor="isTrack" className={cls.Label}>
                Отправление без трек-номера
              </label>
              <Form.Check type="switch" onChange={() => setSendWithoutTrack(!sendWithoutTrack)} />
            </div>
            {!sendWithoutTrack && (
              <div>
                <FloatingLabel label="Трек-номер*">
                  <FormControl
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Трек-номер*"
                    onChange={onChangeHandle}
                    isInvalid={!!formik.errors.track}
                    value={formik.values.track}
                    className={cls.Input}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.track}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            )}
          </div>
          <div className={cls.Buttons}>
            <button type="submit" className={cls.Button} disabled={isDisabled}>
              Сохранить
            </button>
            <Button
              type="button"
              className={cls.CancelButton}
              text="Отмена"
              clickHandler={closeHandler}
            />
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default ChangeOrderStatusToSend;

import React from 'react';
import { useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { ModalBody, ModalHeader } from 'react-bootstrap';

const ShowTextInformation = () => {
  const extra = useAppSelector(modalExtra);
  return (
    <>
      <ModalHeader closeButton>Полное описание курса</ModalHeader>
      <ModalBody>
        <section dangerouslySetInnerHTML={{ __html: extra?.textInformation as string }} />
      </ModalBody>
    </>
  );
};

export default ShowTextInformation;

import {
  MdBadge,
  MdSupervisorAccount,
  MdChat,
  MdDescription,
  MdMonetizationOn,
  MdSchool,
  MdSettings,
  MdOutlineCollectionsBookmark,
  MdEmail,
  MdBuild,
  MdLocalShipping,
} from 'react-icons/md';
import { LiaRobotSolid } from 'react-icons/lia';
import { NavElement } from '../../../type';
import routes from '../../../routes';

export const navElementList: NavElement[] = [
  {
    route: routes.pages.consumers(),
    icon: MdSupervisorAccount,
    title: 'Клиенты',
    closed: false,
  },
  {
    route: routes.pages.messagesPage(),
    icon: MdChat,
    title: 'Сообщения',
    closed: false,
  },
  {
    route: routes.pages.invoicments(),
    icon: MdDescription,
    title: 'Счета',
    closed: false,
  },
  {
    route: routes.pages.leadsPage(),
    icon: MdEmail,
    title: 'Заявки',
    closed: false,
  },
  {
    route: routes.pages.giftOrders(),
    icon: MdLocalShipping,
    title: 'Заказы',
    closed: false,
  },
  {
    route: routes.pages.tariffsPage(),
    icon: MdMonetizationOn,
    title: 'Тарифы',
    closed: false,
  },
  {
    route: routes.pages.coursesPage(),
    icon: MdSchool,
    title: 'Курсы',
    closed: false,
  },
  {
    route: routes.pages.solutionsPage(),
    icon: LiaRobotSolid,
    title: 'Роботы',
    closed: false,
  },
  {
    route: routes.pages.toolsPage(),
    icon: MdBuild,
    title: 'Инструменты',
    closed: false,
  },
  {
    route: routes.pages.logsPage(),
    icon: MdOutlineCollectionsBookmark,
    title: 'Логи',
    closed: true,
  },
  {
    route: routes.pages.users(),
    icon: MdBadge,
    title: 'Сотрудники',
    closed: false,
  },
  {
    route: routes.pages.applicationSettingsPage(),
    icon: MdSettings,
    title: 'Настройки',
    closed: true,
  },
];

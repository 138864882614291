// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e1LqgwvwOdlmxs64EfpD{height:48px;padding:8px 28px 8px 24px;border-radius:8px;border:1px solid var(--messages-panel-border-color);background-color:rgba(0,0,0,0);display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/features/chat/OpenDialogButton/OpenDialogButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,yBAAA,CACA,iBAAA,CACA,mDAAA,CACA,8BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".Button {\n  height: 48px;\n  padding: 8px 28px 8px 24px;\n  border-radius: 8px;\n  border: 1px solid var(--messages-panel-border-color);\n  background-color: transparent;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `e1LqgwvwOdlmxs64EfpD`
};
export default ___CSS_LOADER_EXPORT___;

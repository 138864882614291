import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import Button from '../../../UIComponents/Button';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getTheme, modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { AdditionalMaterialType } from '../../courses/courses.types';

const AddNewAdditionalMaterial = () => {
  const theme = useAppSelector(getTheme);
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const formik = useFormik({
    initialValues: { name: '', url: '' },
    onSubmit: async (values) => {
      const { name, url } = values;
      if (extra && extra.materials && extra.materialHandler) {
        extra.materialHandler([...extra.materials, { name, url }] as AdditionalMaterialType[]);
        closeHandler();
      }
    },
  });
  return (
    <>
      <Modal.Header className="bg-dark d-flex justify-content-center text-white">
        Добавить новый материал
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
        <Form
          className="d-flex flex-column gap-3"
          noValidate
          onSubmit={formik.handleSubmit}
          data-testid="add-ips"
        >
          <Form.Group className="d-flex position-relative align-items-center gap-2">
            <Form.Label htmlFor="name" className="add-ips__label">
              Название материала
            </Form.Label>
            <Form.Control
              name="name"
              id="name"
              className={`${theme}-input`}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Form.Group className="d-flex position-relative align-items-center gap-2">
            <Form.Label htmlFor="url" className="add-ips__label">
              Ссылка на материал
            </Form.Label>
            <Form.Control
              name="url"
              id="url"
              className={`${theme}-input`}
              value={formik.values.url}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <div className="d-flex justify-content-center gap-5">
            <Button text="Отмена" clickHandler={closeHandler} theme={theme} />
            <Button text="Сохранить" type="submit" theme={theme} />
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default AddNewAdditionalMaterial;

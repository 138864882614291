import React, { FC, memo, useState } from 'react';
import cls from './TariffSetSolutionsLimit.module.scss';
import { Button, FormControl, OverlayTrigger, Popover } from 'react-bootstrap';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { TariffSolutionLimitsType } from '../tariff.types';

type TariffSetSolutionsLimitProps = {
  solutions: TariffSolutionLimitsType[];
  setSolutionsLimits: React.Dispatch<React.SetStateAction<TariffSolutionLimitsType[]>>;
};

const TariffSetSolutionsLimit: FC<TariffSetSolutionsLimitProps> = memo((props) => {
  const { solutions, setSolutionsLimits } = props;
  const [countForAll, setCountForAll] = useState(-1);
  return (
    <div className={cls.Container}>
      <div className={cls.Header}>
        <h2 className="title-4">Роботы</h2>
        <p className={cls.Subtitle}>Введите -1 для неограниченного количества</p>
      </div>
      <div className={cls.Connection}>
        <label htmlFor="countForAll" className="regular-text">
          Установить для всех
        </label>
        <FormControl
          type="text"
          id="countForAll"
          value={countForAll}
          className={cls.ConnectionInput}
          onChange={(e) => {
            if (
              typeof Math.abs(Number(e.target.value)) === 'number' &&
              !isNaN(Math.abs(Number(e.target.value)))
            ) {
              setCountForAll(Number(e.target.value));
              setSolutionsLimits(
                solutions.map((p) => {
                  return { ...p, limit: Number(e.target.value) };
                }),
              );
            }
          }}
        />
      </div>
      <div className={cls.Connections}>
        {solutions.map((robot) => (
          <div key={robot.name} className={cls.Connection}>
            <label className={cls.AboutIntegration}>
              <span className={cls.SolutionName}>{robot.name}</span>
              <OverlayTrigger
                key={robot.name}
                placement="top"
                trigger="hover"
                overlay={
                  <Popover id="tooltip-top" className={cls.Popover}>
                    {robot.image && (
                      <img src={robot.image} alt={robot.name} className={cls.ToolImage} />
                    )}
                    <div>
                      <h5>{robot.name}</h5>
                      <p className={cls.PopoverDescription}>{robot.description}</p>
                    </div>
                  </Popover>
                }
              >
                <Button className={cls.HelpButton}>
                  <IoIosHelpCircleOutline size="1.5rem" />
                </Button>
              </OverlayTrigger>
            </label>
            <FormControl
              type="text"
              value={robot.limit}
              id={robot.name}
              className={cls.ConnectionInput}
              onChange={(e) => {
                if (
                  typeof Math.abs(Number(e.target.value)) === 'number' &&
                  !isNaN(Math.abs(Number(e.target.value)))
                ) {
                  setSolutionsLimits(
                    solutions.map((p) =>
                      p.name === robot.name ? { ...p, limit: Number(e.target.value) } : p,
                    ),
                  );
                } else {
                  return;
                }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default TariffSetSolutionsLimit;

export const solutionsRoutes = {
  solutionsFabrics: () => '/solutions/fabrics',
  operationWithFabrics: (fabricId: string) => `/solutions/fabrics/${fabricId}`,
  switchSolutionEnableOrDisable: (fabricId: string, switcher: 'enable' | 'disable') =>
    `/solutions/fabrics/${fabricId}/${switcher}`,
  operationWithStrategy: (solutionId: string, strategyId: string) =>
    `/solutions/fabrics/${solutionId}/strategies/${strategyId}`,
  enableStrategyById: (solutionId: string, strategyId: string) =>
    `/solutions/fabrics/${solutionId}/strategies/${strategyId}/enable`,
  disableStrategyById: (solutionId: string, strategyId: string) =>
    `/solutions/fabrics/${solutionId}/strategies/${strategyId}/disable`,
};

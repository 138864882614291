import { createSlice } from '@reduxjs/toolkit';

const unreadChannelsSlice = createSlice({
  name: 'unreadChannels',
  initialState: {
    unreadChannels: 0,
  },
  reducers: {
    setUnreadChannels: (state, action) => {
      state.unreadChannels = action.payload;
    },
  },
});

export const { setUnreadChannels } = unreadChannelsSlice.actions;

export default unreadChannelsSlice.reducer;

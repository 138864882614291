import React, { FC, memo, useLayoutEffect, useState } from 'react';
import { User } from '../user.types';
import cls from './UserCardInfoBlock.module.scss';
import Icon from '../../widget/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { openModal } from '../../../store/slices/modalSlice';
import { getUserInfo } from '../../../selectors/selectors';
import { closeOffcanvas, openOffcanvas } from '../../../store/slices/offcanvasSlice';
import { updateLogsSearch } from '../../../store/slices/searchSlice';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';
import Button from '../../../UIComponents/Button';
import { GrDatabase } from 'react-icons/gr';
import { MdLockReset, MdOutlineEdit } from 'react-icons/md';

type UserCardInfoBlockProps = {
  user: User;
};

const UserCardInfoBlock: FC<UserCardInfoBlockProps> = memo((props) => {
  const { user } = props;
  const [name, setName] = useState('Неизвестный Бобер');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(getUserInfo)?.role;
  const changeUserDataHandler = () =>
    dispatch(openOffcanvas({ type: 'updateUserOffcanvas', extra: { id: user?.id } }));
  const changeUserPasswordHandler = () =>
    dispatch(
      openModal({ type: 'changeUserPassword', extra: { email: user?.email, id: user?.id } }),
    );
  const redirectToLogsPage = () => {
    dispatch(closeOffcanvas());
    dispatch(updateLogsSearch({ size: 10, filterField: 'email', filter: user?.email }));
    navigate(routes.pages.logsPage());
  };
  useLayoutEffect(() => {
    if (user.firstName && user.secondName) {
      setName(`${user.firstName} ${user.secondName}`);
    } else if (user.firstName && !user.secondName) {
      setName(`${user.firstName} Бобер`);
    } else if (!user.firstName && user.secondName) {
      setName(`Неизвестный ${user.secondName}`);
    } else {
      setName('Неизвестный Бобер');
    }
  }, [user.firstName, user.secondName]);
  return (
    <div className={cls.UserInfo}>
      <div className={cls.User}>
        <Icon email={user.email} name={name} />
        <div className={cls.Info}>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Имя</span>
            <span className={cls.FieldValue}>
              {!user?.firstName ? 'Не указано' : user?.firstName}
            </span>
          </div>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Фамилия</span>
            <span className={cls.FieldValue}>
              {!user?.secondName ? 'Не указано' : user?.secondName}
            </span>
          </div>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Email</span>
            <a href={`mailto:${user?.email}`} className={`${cls.FieldValue} ${cls.FieldLink}`}>
              {user.email}
            </a>
          </div>
          <div className={cls.Property}>
            <span className={cls.FieldName}>Номер телефона</span>
            <span className={cls.FieldValue}>{!user?.phone ? 'Не указано' : user?.phone}</span>
          </div>
        </div>
      </div>
      <div className={cls.Buttons}>
        <Button
          className={cls.Button}
          text={'Редактировать'}
          Icon={<MdOutlineEdit />}
          clickHandler={changeUserDataHandler}
        />
        <Button
          className={cls.Button}
          text={'Сбросить пароль'}
          Icon={<MdLockReset />}
          clickHandler={changeUserPasswordHandler}
        />
        {userRole !== 'Пользователь' && (
          <div className="link-logs text-center">
            <Button
              className={cls.Button}
              text={'Посмотреть логи'}
              Icon={<GrDatabase />}
              clickHandler={redirectToLogsPage}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default UserCardInfoBlock;

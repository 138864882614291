import { useFormik } from 'formik';
import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useUpdateSecureIpMutation } from '../../ip/secure.service';
import cls from './AddSecureIPs.module.scss';
import { RiDeleteBin5Line } from 'react-icons/ri';

const AddSecureIPs = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const [updateIps] = useUpdateSecureIpMutation();
  const initialValues = {
    newIp: '',
  };
  console.log(extra);
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const regEx =
        /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/g;
      try {
        if (!regEx.test(values.newIp)) {
          toast.error('Неверный формат IP');
        } else {
          await updateIps({
            controlEnabled: extra?.data?.controlEnabled ?? true,
            ips: Array.from(new Set([...(extra?.data?.ips ?? []), values.newIp])),
          }).unwrap();
          toast.success('Контроль за IP адресами выключен');
        }
        closeHandler();
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          toast.error('Неверный формат IP');
        }
      }
    },
  });
  const deleteIpHandler = async (ip: string) => {
    const newIps = extra?.data?.ips?.filter((i) => i !== ip);
    try {
      await updateIps({
        controlEnabled: extra?.data?.controlEnabled ?? true,
        ips: newIps ?? [],
      });
      closeHandler();
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error('Не удалось удалить IP адрес');
      }
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Список разрешённых IP адресов</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <form className={cls.Form} onSubmit={formik.handleSubmit} data-testid="add-ips">
          <Form.Control
            type="text"
            name="newIp"
            onChange={formik.handleChange}
            value={formik.values.newIp}
            placeholder="Добавьте новый IP адрес"
            className={cls.Input}
          />
          <Button className={cls.Button} type="submit">
            Добавить
          </Button>
        </form>
        {extra?.data?.ips && extra?.data?.ips.length !== 0 && (
          <div className={cls.List}>
            <div className={cls.IpHeader}>
              <span>IP адрес</span>
            </div>
            <div>
              {extra?.data?.ips.map((ip: string) => (
                <div key={ip} className={cls.Ip}>
                  <span>{ip}</span>
                  <span className={cls.DeleteIcon} onClick={() => deleteIpHandler(ip)}>
                    <RiDeleteBin5Line />
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {extra?.data?.ips && extra?.data?.ips?.length === 0 && (
          <div className={`${cls.List} ${cls.EmptyList}`}>
            <h4 className="title-4">Адресов пока нет</h4>
          </div>
        )}
      </Modal.Body>
    </>
  );
};

export default AddSecureIPs;

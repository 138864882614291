import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes';
import {
  useCreateNewLessonMutation,
  useGetCourseByIdQuery,
} from '../../features/courses/courses.service';
import CreateLessonForm from '../../features/courses/CreateLessonForm.tsx';
import { NewLesson } from '../../features/courses/courses.types';
import { isFetchBaseQueryError } from '../../utils/helpers';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './NewLessonPage.module.scss';
import Breadcrumbs from '../../UIComponents/Breadcrumbs';

const NewLessonPage = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { data: course } = useGetCourseByIdQuery(courseId as string);
  const [createNewLesson] = useCreateNewLessonMutation();
  const initialValues = {
    name: '',
    type: 'TEXT' as const,
    description: '',
    content: '',
    imageId: '',
    additionalInfo: '',
    additionalMaterials: [],
  };
  useDocumentTitle('Добавить урок');
  const submitHandler = async (lesson: NewLesson) => {
    setDisabled(true);
    try {
      await createNewLesson({
        lesson: lesson,
        courseId: courseId as string,
      }).unwrap();
      toast.success('Урок успешно создан');
      navigate(routes.pages.coursePageById(courseId as string));
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) toast.error('Повторите попытку позже');
        if (error.status === 401) toast.error('Не верные данные');
      }
    } finally {
      setDisabled(false);
    }
  };
  return (
    <div className={`${cls.Container} grid-container`}>
      <Breadcrumbs
        elements={[
          { name: 'Курсы', link: routes.pages.coursesPage(), active: false },
          { name: course?.name ?? '', link: '', active: true },
          { name: 'Добавить урок', link: '', active: true },
        ]}
        className={cls.BreadCrumbs}
      />
      <h2 className={`${cls.Title} title-1`}>Добавить урок</h2>
      <CreateLessonForm
        initialValues={initialValues}
        submitHandler={submitHandler}
        disabled={disabled}
        className={cls.CreateLessonForm}
      />
    </div>
  );
};

export default NewLessonPage;

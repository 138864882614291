import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import routes from '../../../routes';
import Spinner from '../../../UIComponents/Spinner';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import { createGravatar } from '../../../utils/createGravatar';
import { useAppSelector } from '../../../hooks/defaultHooks';
import { getTheme } from '../../../selectors/selectors';
import { Lead } from '../lead.types';
import { Link } from 'react-router-dom';

type LeadCardProps = {
  lead: Lead | undefined;
  isLoading: boolean;
};

const LeadCard: FC<LeadCardProps> = ({ lead, isLoading }) => {
  const theme = useAppSelector(getTheme);
  const [leadImage, setLeadImage] = useState<{ url: string | null; color: string | null }>({
    url: null,
    color: '#eee',
  });
  useEffect(() => {
    const generateImage = async () => {
      const color = await createGravatar(lead?.email as string).then((data) => data);
      setLeadImage(color);
    };
    generateImage();
  }, [lead?.email]);
  return (
    <>
      <Row className="m-0" data-testid="lead-card">
        <Col xxl={{ offset: 1 }} xl={{ offset: 1 }} lg={{ offset: 1 }} className="ove">
          <Breadcrumb className="mt-3" data-testid="bread-crumbs">
            <Breadcrumb.Item linkAs="span">
              <Link to={routes.pages.leadsPage()} className="user-dropdown__link">
                Заявки
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className={`breadcrumb-item--${theme} user-dropdown__link`} active>
              {lead?.email}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {isLoading ? (
        <Spinner />
      ) : (
        <Row className="justify-content-center py-5 flex-wrap m-0">
          <Col
            className="about-user-container__about-part d-flex gap-5"
            xxl={{ span: 4 }}
            xl={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <div
              data-testid="left-part"
              className="left-part d-flex flex-column align-items-center gap-3"
            >
              {leadImage?.url ? (
                <img
                  src={leadImage?.url as string}
                  className="about-user__img left-part__img-block"
                />
              ) : (
                <>
                  <div
                    className="about-user__img left-part__img-block"
                    data-testid="user-img"
                    style={{
                      backgroundColor: `#${leadImage?.color}`,
                    }}
                  >
                    {lead?.name[0].toUpperCase()}
                  </div>
                </>
              )}
            </div>
            <div data-testid="right-part" className="right-part__text-block">
              <p className="d-flex gap-3">
                <span>Имя:</span>
                <span>{lead?.name ?? 'Неизвестный'}</span>
              </p>
              <p className="d-flex gap-3">
                <span>Дата регистрации заявки:</span>
                <span>
                  {convertDateToNeedFormat({ date: lead?.createdAt ?? '', format: 'datetime' })}
                </span>
              </p>
              <p className="d-flex gap-3">
                <span>Email:</span>
                <a href={`mailto:${lead?.email}`}>{lead?.email}</a>
              </p>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default LeadCard;

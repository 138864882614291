import React from 'react';
import MessagesContainer from '../../features/message/MessagesContainer';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './MessagesPage.module.scss';

const MessagesPage = () => {
  useDocumentTitle('Сообщения');
  return (
    <div data-testid="messages-container" className={cls.Container}>
      <h2 className={`${cls.Title} title-1`}>Сообщения</h2>
      <div className={cls.MessagesContainer}>
        <MessagesContainer />
      </div>
    </div>
  );
};

export default MessagesPage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H2L1L0_9LwszjtAe5Ekz{display:flex;justify-content:center;align-items:center;padding:8px 28px 8px 24px;border-radius:8px;border:1px solid var(--download-button-border-color);background-color:var(--download-button-bg-color);font-size:20px;font-weight:400;line-height:25.88px;gap:6px}.H2L1L0_9LwszjtAe5Ekz:hover{border:1px solid var(--download-button-hover-border-color);background-color:var(--download-button-hover-bg-color);color:var(--download-button-hover-text-color)}`, "",{"version":3,"sources":["webpack://./src/UIComponents/DownloadReportsButton/DownloadReportsButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,iBAAA,CACA,oDAAA,CACA,gDAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,OAAA,CACA,4BACE,0DAAA,CACA,sDAAA,CACA,6CAAA","sourcesContent":[".DownloadButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 8px 28px 8px 24px;\n  border-radius: 8px;\n  border: 1px solid var(--download-button-border-color);\n  background-color: var(--download-button-bg-color);\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 25.88px;\n  gap: 6px;\n  &:hover {\n    border: 1px solid var(--download-button-hover-border-color);\n    background-color: var(--download-button-hover-bg-color);\n    color: var(--download-button-hover-text-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DownloadButton": `H2L1L0_9LwszjtAe5Ekz`
};
export default ___CSS_LOADER_EXPORT___;

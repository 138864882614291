import React, { FC, memo, useState } from 'react';
import cls from './TariffSetToolsLimit.module.scss';
import { Button, FormCheck, OverlayTrigger, Popover } from 'react-bootstrap';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { TariffToolLimitsType } from '../tariff.types';

const TariffSetToolsLimitItem = ({
  name,
  img,
  description,
  show,
  onChange,
}: {
  name: string;
  img: string;
  description: string;
  show: boolean;
  onChange: (event: React.ChangeEvent) => void;
}) => {
  return (
    <div className={cls.Connection}>
      <label className={cls.AboutIntegration}>
        <span className="regular-text">{name}</span>
        <OverlayTrigger
          key={name}
          placement="top"
          trigger="hover"
          overlay={
            <Popover id="tooltip-top" className={cls.Popover}>
              {img && <img src={img} alt={name} className={cls.ToolImage} />}
              <div>
                <h5>{name}</h5>
                <p className={cls.PopoverDescription}>{description}</p>
              </div>
            </Popover>
          }
        >
          <Button className={cls.HelpButton}>
            <IoIosHelpCircleOutline size="1.5rem" />
          </Button>
        </OverlayTrigger>
      </label>
      <FormCheck type="switch" id={name} name={name} checked={show} onChange={onChange} />
    </div>
  );
};

type TariffSetToolsLimitProps = {
  tools: TariffToolLimitsType[];
  setToolsLimits: React.Dispatch<React.SetStateAction<TariffToolLimitsType[]>>;
};

const TariffSetToolsLimit: FC<TariffSetToolsLimitProps> = memo((props) => {
  const { tools, setToolsLimits } = props;
  const [countForAll, setCountForAll] = useState(false);
  const changeHandler = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const checked = target.checked;
    setToolsLimits(
      tools.map((item) =>
        name === item.name
          ? {
              ...item,
              included: checked,
            }
          : item,
      ),
    );
  };
  return (
    <div className={cls.Container}>
      <div className={cls.Header}>
        <h2 className="title-4">Инструменты</h2>
      </div>
      <div className={cls.Connection}>
        <label htmlFor="countForAll" className="regular-text">
          Установить для всех
        </label>
        <FormCheck
          type="switch"
          id="countForAll"
          name="countForAll"
          onChange={() => {
            setCountForAll(!countForAll);
            setToolsLimits(tools.map((item) => ({ ...item, included: !countForAll })));
          }}
          checked={countForAll}
        />
      </div>
      <div className={cls.Connections}>
        {tools.map((tool) => (
          <TariffSetToolsLimitItem
            key={tool.name}
            name={tool.name}
            img={tool.image}
            description={tool.description}
            show={tool.included}
            onChange={changeHandler}
          />
        ))}
      </div>
    </div>
  );
});

export default TariffSetToolsLimit;

const imagesPath = window.location.origin;
export const staticImagesRelativePath: {
  integrationIcons: {
    [key: string]: () => string;
  };
} = {
  integrationIcons: {
    SENLER: () => `${imagesPath}/static/integration-40-40/senler_logo.svg`,
    VK_SOCIAL: () => `${imagesPath}/static/integration-40-40/vk_logo.svg`,
    VK_ADS: () => `${imagesPath}/static/integration-40-40/vk_ads_logo.svg`,
    DIRECT: () => `${imagesPath}/static/integration-40-40/yandex_direct_logo.svg`,
    METRIKA: () => `${imagesPath}/static/integration-40-40/yandex_metrika_logo.svg`,
    GOOGLE_ANALYTICS: () => `${imagesPath}/static/integration-40-40/google_analytics_logo.svg`,
    GOOGLE_SHEETS: () => `${imagesPath}/static/integration-40-40/google_sheets_logo.svg`,
    GETCOURSE: () => `${imagesPath}/static/integration-40-40/get_course_logo.svg`,
    AVITO: () => `${imagesPath}/static/integration-40-40/avito_logo.svg`,
    TELEGRAM: () => `${imagesPath}/static/integration-40-40/telegram_logo.svg`,
    TILDA: () => `${imagesPath}/static/integration-40-40/tilda_logo.svg`,
    CHAT_GPT: () => `${imagesPath}/static/integration-40-40/chat_gpt_logo.svg`,
    DEFAULT: () => '',
  },
};

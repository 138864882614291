import React, { FC, memo } from 'react';
import { ConsumerCabinet } from '../consumer.types';
import Spinner from '../../../UIComponents/Spinner';

interface ConsumerCabinetListProps {
  cabinetsList: ConsumerCabinet[] | undefined;
  isLoading: boolean;
}

const ConsumerCabinetList: FC<ConsumerCabinetListProps> = memo(({ cabinetsList, isLoading }) => {
  if (isLoading) return <Spinner />;
  if (!isLoading && cabinetsList?.length === 0)
    return (
      <h5 data-testid="without-cabinets" className="title-5 mt-3 p-0">
        Кабинеты еще не были зарегистрированны
      </h5>
    );
  return (
    <>
      <ul className="p-0 cabinet-list" data-testid="header-list">
        <li className="py-4">ID</li>
        <li className="py-4">Номер</li>
        <li className="py-4">Тип</li>
        <li className="py-4">Инфо</li>
        <li className="py-4">Email</li>
        <li className="py-4">Телефон</li>
        <li className="py-4">Баланс</li>
      </ul>
      <ul className="p-0" data-testid="cabinets-list">
        {cabinetsList?.map((cabinet) => (
          <li key={cabinet.cabinetId} className="cabinet-list">
            <span>{cabinet.cabinetId}</span>
            <span>{cabinet.number}</span>
            <span>{cabinet.cabinetType}</span>
            <span>{cabinet.cabinetInfo}</span>
            <span>{cabinet.email}</span>
            <span>{cabinet.phone}</span>
            <span className={(cabinet.balance as number) > 0 ? 'bill__accrual' : 'bill__write'}>
              {((cabinet.balance as number) / 100).toFixed(2)} &#x20bd;
            </span>
          </li>
        ))}
      </ul>
    </>
  );
});

export default ConsumerCabinetList;

import { getColorsTheme } from './../../utils/getColorsTheme';
import { createSlice } from '@reduxjs/toolkit';

export type themes = 'light' | 'dark';

interface themeState {
  theme: themes;
}
const storageColorSchema = getColorsTheme();

const initialState: themeState = {
  theme: storageColorSchema ?? 'light',
};

const themeSlice = createSlice({
  name: 'themes',
  initialState,
  reducers: {
    changeTheme: (state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      state.theme = newTheme;
      localStorage.setItem('theme', JSON.stringify(state.theme));
    },
  },
});

export const { changeTheme } = themeSlice.actions;
export default themeSlice.reducer;

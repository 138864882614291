import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useUpdateUserRoleMutation } from '../../user/user.service';
import { toast } from 'react-toastify';
import cls from './UpdateRole.module.scss';

interface initialValues {
  role: string;
}

const UpdateRole = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [updateRole] = useUpdateUserRoleMutation();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const [initialValues, setInitialValues] = useState<initialValues>({
    role: 'ROLE_USER',
  });
  const [disabled, setDisabled] = useState<boolean>(false);
  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { role } = values;
      try {
        await updateRole({ id: extra?.id as string, updates: { role } }).unwrap();
        dispatch(closeModal());
        toast.success('Роль успешно изменена');
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            toast.error('Повторите попытку позже');
          } else if (error.status === 403) {
            toast.error('Запрещено');
          } else if (error.status === 404) {
            toast.error('Пользователь не найден');
          }
        }
      }
    },
  });
  useEffect(() => {
    if (formik.values.role === extra?.userRole) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [extra?.userRole, formik.values.role]);
  useEffect(() => {
    if (extra?.userRole) {
      setInitialValues({ role: extra?.userRole });
      formik.setValues({ role: extra?.userRole });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra?.userRole]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>?
          </p>
        </div>
        <Form onSubmit={formik.handleSubmit} data-testid="change-role">
          <Form.Label htmlFor="role">Выберите новую роль</Form.Label>
          <Form.Select
            onChange={(event) => formik.handleChange(event)}
            name="role"
            id="role"
            value={formik.values.role}
          >
            <option>Выбрать новую роль</option>
            <option value="ROLE_ADMIN" id="ROLE_ADMIN">
              Администратор
            </option>
            <option value="ROLE_USER">Пользователь</option>
          </Form.Select>
          <div className={cls.Buttons}>
            <Button
              type="submit"
              className={disabled ? `${cls.Button} ${cls.Disabled}` : `${cls.Button}`}
              disabled={disabled}
            >
              {extra?.actionButtonText}
            </Button>
            <Button onClick={closeHandler} className={cls.CancelButton}>
              Отмена
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default UpdateRole;

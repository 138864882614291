import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import cls from './ActionsWithSolutionStrategy.module.scss';

const ActionsWithSolutionStrategy = () => {
  const extraModal = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const actionHandler = () => {
    if (extraModal?.handler) {
      extraModal?.handler();
      closeHandler();
    }
    if (extraModal?.handler) {
      extraModal?.handler();
      closeHandler();
    }
  };
  const setButtonClass = () => {
    if (extraModal?.actionButtonColor === 'primary') {
      return cls.Button;
    } else if (extraModal?.actionButtonColor === 'danger') {
      return cls.DeleteButton;
    } else {
      return '';
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">{extraModal?.title}</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div>
          <p>
            {extraModal?.supportText}{' '}
            <strong className={cls.TextInformation}>{extraModal?.textInformation}</strong>{' '}
            {extraModal?.subSupportText && <>{extraModal?.subSupportText}</>}
          </p>
        </div>
        <div className={cls.Buttons}>
          <Button type="button" className={`${setButtonClass()}`} onClick={actionHandler}>
            {extraModal?.actionButtonText}
          </Button>
          <Button className={cls.CancelButton} onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default ActionsWithSolutionStrategy;

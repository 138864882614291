import React, { FC, useState, useEffect } from 'react';
import Button from '../../../UIComponents/Button';
import { Col, Row } from 'react-bootstrap';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import UpdateUserForm from '../UpdateUserForm';
import { useAboutUserQuery, useUpdateUserMutation } from '../user.service';
import { UserShort } from '../user.types';
import { createIconByFullName } from '../../../utils/createIconByFullName';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getTheme } from '../../../selectors/selectors';
import Spinner from '../../../UIComponents/Spinner';
import { createGravatar } from '../../../utils/createGravatar';
import { openModal } from '../../../store/slices/modalSlice';
import { MdUpdate, MdPassword } from 'react-icons/md';
const AboutUser: FC = () => {
  const { data: user, isLoading } = useAboutUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const [changeUser, setChangeUser] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<{ url: string | null; color: string | null }>({
    url: null,
    color: '#eee',
  });
  const dispatch = useAppDispatch();
  const theme = useAppSelector(getTheme);
  const shortUserInfo = {
    firstName: user?.firstName,
    secondName: user?.secondName,
    phone: user?.phone,
  };
  const handleChangeClick = () => setChangeUser(true);
  const handleChangePassword = () =>
    dispatch(openModal({ type: 'changeMePassword', extra: { email: user?.email, id: user?.id } }));

  const onSubmit = async (values: UserShort | Required<UserShort>) => {
    try {
      await updateUser(values);
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        console.log(error.status);
      }
    } finally {
      setDisabled(false);
      setChangeUser(false);
    }
  };
  useEffect(() => {
    const generateImage = async () => {
      const color = await createGravatar(user?.email as string).then((data) => data);
      setUserImage(color);
    };
    generateImage();
  }, [user?.email]);
  return (
    <div data-testid="about-user" className="about-user-container">
      {!isLoading ? (
        <>
          <Row className="justify-content-center py-5 flex-wrap">
            <Col
              className="about-user-container__left-part d-flex flex-column align-items-center gap-4 text-center"
              data-testid="about-user__left-part"
              xxl={{ span: 2 }}
              xl={{ span: 2 }}
              lg={{ span: 2 }}
            >
              {userImage?.url ? (
                <img
                  src={userImage?.url as string}
                  className="about-user__img left-part__img-block"
                />
              ) : (
                <>
                  <div
                    className="about-user__img left-part__img-block"
                    data-testid="user-img"
                    style={{
                      backgroundColor: `#${userImage?.color}`,
                    }}
                  >
                    {user
                      ? createIconByFullName(user.firstName, user.secondName)
                      : createIconByFullName()}
                  </div>
                </>
              )}
              <div className="left-part__text-block">
                <p>{user?.email}</p>
                <p>{user?.roleName}</p>
              </div>
            </Col>
            <Col
              xxl={{ span: 4 }}
              xl={{ span: 5 }}
              lg={{ span: 5 }}
              className="about-user-container__right-part"
              data-testid="about-user__right-part"
            >
              {!changeUser ? (
                <>
                  <div className="d-flex gap-5">
                    <div className="user-info" data-testid="user-info">
                      <p>{user?.secondName ?? ''}</p>
                      <p>{user?.firstName ?? ''}</p>
                      <p>{user?.phone ?? ''}</p>
                    </div>
                    <div className="d-flex flex-column gap-3 px-5">
                      <Button
                        text="Изменить"
                        Icon={<MdUpdate size="1.5rem" />}
                        clickHandler={handleChangeClick}
                        theme={theme}
                        data-testid="change-data"
                      />
                      <Button
                        text="Изменить пароль"
                        theme={theme}
                        Icon={<MdPassword size="1.5rem" />}
                        data-testid="change-password"
                        clickHandler={handleChangePassword}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <UpdateUserForm
                  initialValues={shortUserInfo}
                  onSubmit={onSubmit}
                  disabled={disabled}
                  setDisabled={setDisabled}
                />
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default AboutUser;

import React, { useLayoutEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getOffcanvasExtra } from '../../../selectors/selectors';
import { useGetAboutUserByIdQuery, useUpdateUserDataMutation } from '../../user/user.service';
import UpdateUserForm from '../../user/UpdateUserForm';
import { openOffcanvas } from '../../../store/slices/offcanvasSlice';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { UserShort } from '../../user/user.types';
import cls from './UpdateUserOffcanvas.module.scss';
import Icon from '../../widget/Icon';

const UpdateUserOffcanvas = () => {
  const offcanvasExtra = useAppSelector(getOffcanvasExtra);
  const { data: user } = useGetAboutUserByIdQuery(offcanvasExtra?.id as string);
  const [changeUserData] = useUpdateUserDataMutation();
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState('Неизвестный Бобер');
  const dispatch = useAppDispatch();
  const closeHandler = (): void => {
    dispatch(openOffcanvas({ type: 'userOffcanvas', extra: { id: user?.id ?? '' } }));
  };
  const shortUserInfo = {
    firstName: user?.firstName,
    secondName: user?.secondName,
    phone: user?.phone,
  };
  const onSubmit = async (values: UserShort | Required<UserShort>) => {
    try {
      await changeUserData({ id: user?.id ?? '', updates: values }).unwrap();
      dispatch(openOffcanvas({ type: 'userOffcanvas', extra: { id: user?.id ?? '' } }));
      toast.success('Данные успешно изменены');
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Попробуйте позже');
        } else if (error.status === 403) {
          toast.error('Недостаточно прав');
        } else if (error.status === 404) {
          toast.error('Пользователь не найден');
        }
      }
    }
  };
  useLayoutEffect(() => {
    if (user?.firstName && user?.secondName) {
      setName(`${user.firstName} ${user.secondName}`);
    } else if (user?.firstName && !user?.secondName) {
      setName(`${user.firstName} Бобер`);
    } else if (!user?.firstName && user?.secondName) {
      setName(`Неизвестный ${user.secondName}`);
    } else {
      setName('Неизвестный Бобер');
    }
  }, [user]);
  return (
    <div className={cls.Container}>
      <div className={cls.UpdateFormField}>
        <Icon email={user?.email ?? ''} name={name} className={cls.Icon} />
        <UpdateUserForm
          initialValues={shortUserInfo}
          onSubmit={onSubmit}
          closeHandler={closeHandler}
          isModal={true}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </div>
    </div>
  );
};

export default UpdateUserOffcanvas;

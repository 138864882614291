import React from 'react';

const Spinner = () => {
  return (
    <div className="box" data-testid="spinner-container">
      <div className="loader" data-testid="spinner-element"></div>
    </div>
  );
};

export default Spinner;

import React, { FC, memo } from 'react';
import { Consumer } from '../consumer.types';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { openOffcanvas } from '../../../store/slices/offcanvasSlice';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import Icon from '../../widget/Icon';
import cls from './ConsumerPreviewCard.module.scss';
import { MdOutlineDone, MdOutlineQuestionMark } from 'react-icons/md';

type ConsumerPreviewCardProps = {
  consumer: Consumer;
};

const ConsumerStatus = ({ status }: { status: boolean }) => {
  if (!status) {
    return (
      <div className={`${cls.Status}`}>
        <span className={`${cls.StatusIcon} ${cls.ActiveStatus}`}></span>
        <span>Активен</span>
      </div>
    );
  } else {
    return (
      <div className={cls.Status}>
        <span className={`${cls.StatusIcon} ${cls.DisabledStatus}`}></span>
        <span>Заблокирован</span>
      </div>
    );
  }
};

const ConsumerEmailVerified = ({
  emailVerified,
  email,
}: {
  emailVerified: boolean;
  email: string;
}) => {
  if (emailVerified) {
    return (
      <div className={cls.Email}>
        <span>{email}</span>
        <MdOutlineDone className={cls.EmailVerified} />
      </div>
    );
  } else {
    return (
      <div className={cls.Email}>
        <span>{email}</span>
        <MdOutlineQuestionMark className={cls.EmailUnverified} />
      </div>
    );
  }
};

const ConsumerPreviewCard: FC<ConsumerPreviewCardProps> = memo((props) => {
  const { consumer } = props;
  const dispatch = useAppDispatch();
  const openConsumerOffcanvas = (id: string) => {
    dispatch(openOffcanvas({ type: 'consumerOffcanvas', extra: { id } }));
  };
  const updateConsumerPhone = (phone: string | undefined) => {
    if (!phone || phone.trim() === '') {
      return 'Не указан';
    }
    return phone;
  };
  return (
    <div key={consumer.id} onClick={() => openConsumerOffcanvas(consumer.id)} className={cls.Item}>
      <div className={cls.Name}>
        <Icon email={consumer.email} name={consumer.name} className={cls.Icon} />
        <span>{consumer.name ? consumer.name : 'Не указано'}</span>
      </div>
      <ConsumerEmailVerified emailVerified={consumer.emailVerified} email={consumer.email} />
      <span>{updateConsumerPhone(consumer.phone)}</span>
      <ConsumerStatus status={consumer.blocked} />
      <span>{convertDateToNeedFormat({ date: consumer.createdAt, format: 'date' })}</span>
    </div>
  );
});

export default ConsumerPreviewCard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V7SYnfAGUI3lYrktOMdF{width:178px;height:178px;border-radius:128px;display:flex;justify-content:center;align-items:center;font-size:40px;font-weight:700;line-height:51.76px;color:var(--consumer-icon-text-color)}`, "",{"version":3,"sources":["webpack://./src/features/widget/Icon/Icon.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,mBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,qCAAA","sourcesContent":[".Icon {\n  width: 178px;\n  height: 178px;\n  border-radius: 128px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 40px;\n  font-weight: 700;\n  line-height: 51.76px;\n  color: var(--consumer-icon-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Icon": `V7SYnfAGUI3lYrktOMdF`
};
export default ___CSS_LOADER_EXPORT___;

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/defaultHooks';
import { createGravatar } from '../../../../utils/createGravatar';
import cls from './SupportCabinet.module.scss';
import { Toast } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import routes from '../../../../routes';
import { RxExit } from 'react-icons/rx';
import { MdOutlineSettings } from 'react-icons/md';
import { getUserInfo } from '../../../../selectors/selectors';
import CabinetIconPreview from '../../../../UIComponents/CabinetIconPreview';
import { logOut } from '../../../../store/slices/authSlice';

const SupportCabinet = memo(() => {
  const [show, setShow] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<{ url: string | null; color: string | null }>({
    url: null,
    color: '#eee',
  });
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserInfo);
  const exitHandler = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);
  const toastHandler = useCallback(() => {
    setShow(!show);
  }, [setShow, show]);
  useEffect(() => {
    const generateImage = async () => {
      const color = await createGravatar(user?.email as string).then((data) => data);
      setUserImage(color);
    };
    generateImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, user?.roleName]);
  return (
    <div className={cls.UserInfoContainer} onClick={toastHandler} data-testid="cabinet">
      <div className={cls.Wrapper}>
        <CabinetIconPreview
          url={userImage?.url}
          color={userImage?.color}
          firstName={user?.firstName}
          secondName={user?.secondName}
        />
        {user?.firstName && <p data-testid="user-name">{user.firstName}</p>}
      </div>
      <Toast
        onClose={toastHandler}
        show={show}
        className={cls.Toast}
        autohide={false}
        delay={3000}
        data-testid="toast"
      >
        <Toast.Header closeButton={true} className={cls.ToastHeader} />
        <Toast.Body className={cls.ToastBody}>
          <div className={cls.Wrapper}>
            <CabinetIconPreview
              url={userImage?.url}
              color={userImage?.color}
              firstName={user?.firstName}
              secondName={user?.secondName}
            />
            {user?.firstName && <p data-testid="user-name">{user.firstName}</p>}
            <p className={cls.UserEmail}>{user?.email}</p>
          </div>
          <div className={cls.Menu}>
            <NavLink to={routes.pages.settings()} className={cls.SettingsLink}>
              <MdOutlineSettings size="20px" className={cls.SettingsIcon} />
              <span>Настройки</span>
            </NavLink>
            <a href="#" className={cls.SettingsLink} onClick={exitHandler}>
              <RxExit size="1rem" className={cls.SettingsIcon} />
              <span>Выход</span>
            </a>
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
});

export default SupportCabinet;

import { User } from './../../features/user/user.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserState = {
  user: User | null;
};
const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserInfo: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    removeUserInfo: (state) => {
      state.user = null;
    },
  },
});

export default userSlice.reducer;
export const { addUserInfo, removeUserInfo } = userSlice.actions;

import React, { useLayoutEffect, useState } from 'react';
import { Offcanvas as RootCanvas } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/defaultHooks';
import { getIsOffcanvasOpened, getOffcanvasType } from '../../selectors/selectors';
import { closeOffcanvas } from '../../store/slices/offcanvasSlice';
import ConsumerOffcanvas from './ConsumerOffcanvas';
import UserOffcanvas from './UserOffcanvas';
import UpdateUserOffcanvas from './UpdateUserOffcanvas';
const mapping = {
  consumerOffcanvas: ConsumerOffcanvas,
  userOffcanvas: UserOffcanvas,
  updateUserOffcanvas: UpdateUserOffcanvas,
};
const OffCanvas = () => {
  const [title, setTitle] = useState<string>('');
  const dispatch = useAppDispatch();
  const isOpened = useAppSelector(getIsOffcanvasOpened);
  const offcanvasType = useAppSelector(getOffcanvasType);
  const OffCanvasBody = mapping[offcanvasType as keyof typeof mapping];
  const closeOffcanvasHandler = () => {
    dispatch(closeOffcanvas());
  };
  useLayoutEffect(() => {
    if (isOpened && offcanvasType === 'consumerOffcanvas') {
      setTitle('Профиль клиента');
    } else if (isOpened && offcanvasType === 'userOffcanvas') {
      setTitle('Профиль сотрудника');
    } else if (isOpened && offcanvasType === 'updateUserOffcanvas') {
      setTitle('Редактирование профиля');
    }
  }, [isOpened, offcanvasType]);
  return (
    <RootCanvas
      show={isOpened}
      onHide={closeOffcanvasHandler}
      placement="end"
      style={{ width: '800px' }}
      backdrop={title === 'Редактирование профиля' ? 'static' : true}
    >
      <RootCanvas.Header closeButton>
        <RootCanvas.Title className="title-2" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          {title}
        </RootCanvas.Title>
      </RootCanvas.Header>
      {OffCanvasBody && <OffCanvasBody />}
    </RootCanvas>
  );
};

export default OffCanvas;

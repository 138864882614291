import React, { FC, useEffect } from 'react';
import { Course } from '../../courses/courses.types';
import { Spinner } from 'react-bootstrap';
import ConsumerCourseListHeader from '../ConsumerCourseListHeader';
import cls from './ConsumerCoursesList.module.scss';

type ConsumerCoursesListProps = {
  coursesList: Course[] | undefined;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setScrollCourses: React.Dispatch<React.SetStateAction<Course[]>>;
  scrollCourses: Course[];
  consumerCoursesCount: number;
};

const ConsumerCoursesList: FC<ConsumerCoursesListProps> = ({
  coursesList,
  isLoading,
  page,
  setPage,
  scrollCourses,
  setScrollCourses,
  consumerCoursesCount,
}) => {
  const convertCourseCost = (cost: number) => {
    if (cost === 0) return 'Бесплатно';
    return `${Math.round(cost / 100).toFixed(2)} ₽`;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.course-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      page < consumerCoursesCount
    ) {
      setPage(++page);
    }
  };
  useEffect(() => {
    if (coursesList) {
      setScrollCourses([...scrollCourses, ...coursesList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesList]);
  if (isLoading) return <Spinner />;

  if (!isLoading && (coursesList?.length as number) === 0)
    return (
      <h5 data-testid="without-courses" className="title-5 mt-3">
        Курсы не были куплены
      </h5>
    );
  return (
    <div>
      <ConsumerCourseListHeader />
      <ul className={`${cls.List} course-list`} data-testid="courses-list" onScroll={scrollHandler}>
        {coursesList?.map((course) => (
          <li key={course.id} className={cls.Item}>
            <span>{course.name}</span>
            <span>{convertCourseCost(course.cost)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConsumerCoursesList;

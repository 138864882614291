import React, { useEffect } from 'react';
import { NavLink, useRouteError } from 'react-router-dom';
import routes from '../../routes';
import { Container, Row } from 'react-bootstrap';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getTheme } from '../../selectors/selectors';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ErrorPage = () => {
  const error = useRouteError();
  useDocumentTitle('Oшибка приложения');
  const [errorText, setErrorText] = React.useState('');
  const theme = useAppSelector(getTheme);
  useEffect(() => {
    if (error) {
      setErrorText(JSON.stringify(error, null, 2));
    }
  }, [error]);
  return (
    <Container className={`${theme}-root-container`} fluid>
      <Row>
        <p>
          Во время работы приложения возникла не предвиденная ошибка - {errorText}. Пожалуйста,
          обратитесь к администратору
        </p>
      </Row>
      <Row>
        <NavLink to={routes.pages.mainPage()}>Вернуться на главную страницу</NavLink>
      </Row>
    </Container>
  );
};

export default ErrorPage;

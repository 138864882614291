import React, { FC, memo } from 'react';
import { Log, LogsTypes } from '../logs.types';
import cls from './LogDescription.module.scss';
import { CustomOverlay } from '../LogPreviewItem/LogPreviewItem';

type LogDescriptionProps = {
  log: Log;
  logsType: LogsTypes[];
  description: string;
  setObjectIdHandler: ({ id, email }: { id: string; email: string }) => void;
};

const LogDescription: FC<LogDescriptionProps> = memo(
  ({ log, logsType, description, setObjectIdHandler }) => {
    const actualLogTemplate = Object.values(logsType).find((elem) => elem?.type === log.type);
    if (actualLogTemplate) {
      const subjectLink = actualLogTemplate.subjectLink;
      const objectLink = actualLogTemplate.objectLink;
      const template = actualLogTemplate.template.split(' ');
      if (subjectLink && objectLink && description) {
        const indexSubject = template.findIndex((elem) => elem === '${subjectName}');
        const indexObject = template.findIndex((elem) => elem === '${objectName}');
        const descriptionIndex = template.findIndex((elem) => elem === '${description}');
        return (
          <div className={`${cls.Description} regular-text`}>
            {template.slice(0, indexSubject).join(' ')}
            <CustomOverlay
              name={log.subjectName ?? ''}
              link={subjectLink}
              id={log.subjectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.subjectId ?? '', email: log.subjectName ?? '' })
              }
            />
            {template.slice(indexSubject + 1, indexObject).join(' ')}
            <CustomOverlay
              name={log.objectName ?? ''}
              link={objectLink}
              id={log.objectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.objectId ?? '', email: log.objectName ?? '' })
              }
            />
            {template.slice(indexObject + 1, descriptionIndex).join(' ')} {description}
          </div>
        );
      } else if (subjectLink && !objectLink && description) {
        const indexSubject = template.findIndex((elem) => elem === '${subjectName}');
        const descriptionIndex = template.findIndex((elem) => elem === '${description}');
        return (
          <div className={`${cls.Description} regular-text`}>
            {template.slice(0, indexSubject).join(' ')}
            <CustomOverlay
              name={log.subjectName ?? ''}
              link={subjectLink}
              id={log.subjectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.subjectId ?? '', email: log.subjectName ?? '' })
              }
            />
            {template.slice(indexSubject + 1, descriptionIndex).join(' ')} {description}
            {template.slice(descriptionIndex + 1).join(' ')}
          </div>
        );
      } else if (subjectLink && objectLink && !description) {
        const indexSubject = template.findIndex((elem) => elem === '${subjectName}');
        const indexObject = template.findIndex((elem) => elem === '${objectName}');
        return (
          <div className={`${cls.Description} regular-text`}>
            {template.slice(0, indexSubject).join(' ')}
            <CustomOverlay
              name={log.subjectName ?? ''}
              link={subjectLink}
              id={log.subjectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.subjectId ?? '', email: log.subjectName ?? '' })
              }
            />
            {template.slice(indexSubject + 1, indexObject).join(' ')}
            <CustomOverlay
              name={log.objectName ?? ''}
              link={objectLink}
              id={log.objectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.objectId ?? '', email: log.objectName ?? '' })
              }
            />
            {template.slice(indexObject + 1).join(' ')}
          </div>
        );
      } else if (!subjectLink && objectLink && description) {
        const indexObject = template.findIndex((elem) => elem === '${objectName}');
        const descriptionIndex = template.findIndex((elem) => elem === '${description}');
        return (
          <div className={`${cls.Description} regular-text`}>
            {template.slice(0, indexObject).join(' ')}
            <CustomOverlay
              name={log.objectName ?? ''}
              link={objectLink}
              id={log.objectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.objectId ?? '', email: log.objectName ?? '' })
              }
            />
            {template.slice(indexObject + 1, descriptionIndex).join(' ')} {description}
          </div>
        );
      } else if (!log.subjectName && objectLink && description) {
        const indexSubject = template.findIndex((elem) => elem === '${subjectName}');
        const indexObject = template.findIndex((elem) => elem === '${objectName}');
        const descriptionIndex = template.findIndex((elem) => elem === '${description}');
        return (
          <div className={`${cls.Description} regular-text`}>
            {template.slice(0, indexSubject).join(' ')}
            <CustomOverlay
              name={log.subjectName ?? ''}
              link={subjectLink}
              id={log.subjectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.subjectId ?? '', email: log.subjectName ?? '' })
              }
            />
            {template.slice(indexSubject + 1, indexObject).join(' ')}
            <CustomOverlay
              name={log.objectName ?? ''}
              link={objectLink}
              id={log.objectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.objectId ?? '', email: log.objectName ?? '' })
              }
            />
            {template.slice(indexObject + 1, descriptionIndex).join(' ')} {description}
          </div>
        );
      } else if (subjectLink) {
        const indexSubject = template.findIndex((elem) => elem === '${subjectName}');
        return (
          <div className={`${cls.Description} regular-text`}>
            {template.slice(0, indexSubject).join(' ')}
            <CustomOverlay
              name={log.subjectName ?? ''}
              link={subjectLink}
              id={log.subjectId ?? ''}
              clickHandler={() =>
                setObjectIdHandler({ id: log.subjectId ?? '', email: log.subjectName ?? '' })
              }
            />
            {template.slice(indexSubject + 1).join(' ')}
          </div>
        );
      }
    }
  },
);

export default LogDescription;

import React, { useEffect, useState } from 'react';
import cls from './UpdateGiftOrderAddressModal.module.scss';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import Button from '../../../UIComponents/Button';
import { modalExtra } from '../../../selectors/selectors';
import { useUpdateGiftOrderAddressMutation } from '../giftOrders.service';
import * as yup from 'yup';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import { FloatingLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';

const UpdateGIftOrderAddressModal = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const extraModal = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  const [updateOrderAddress] = useUpdateGiftOrderAddressMutation();
  const validationSchema = yup.object().shape({
    address: yup
      .string()
      .min(10, 'Минимальная длина 10')
      .max(256, 'Максимальная длина 256')
      .required('Поле является обязательным'),

    firstAndLastName: yup
      .string()
      .min(10, 'Минимальная длина 5')
      .max(256, 'Максимальная длина 256')
      .required('Поле является обязательным'),
    phone: yup
      .string()
      .required('Поле является обязательным')
      .matches(/^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d\- ]{7,10}$/, 'Некорректный номер'),
  });
  const formik = useFormik({
    initialValues: {
      address: extraModal?.giftOrderInfo?.recipientAddress ?? '',
      firstAndLastName: extraModal?.giftOrderInfo?.recipientName ?? '',
      phone: extraModal?.giftOrderInfo?.recipientPhone ?? '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      setDisabled(true);
      const { address, firstAndLastName, phone } = values;
      try {
        await updateOrderAddress({
          giftOrderId: extraModal?.id ?? '',
          recipientAddress: address,
          recipientName: firstAndLastName,
          recipientPhone: phone,
        }).unwrap();
        toast.success('Данные успешно обновлены');
        closeHandler();
      } catch (error) {
        setDisabled(false);
        if (isFetchBaseQueryError(error)) {
          if (error.status === 400) {
            toast.error('Неверно введены данные');
          }
          if (error.status === 403) {
            toast.error('Доступ запрещён');
          }
          if (error.status === 404) {
            toast.error('Заказ не найден');
          }
        }
      } finally {
        setDisabled(false);
      }
    },
  });
  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
  };
  useEffect(() => {
    if (
      (formik.values.address.length !== 0 &&
        formik.values.address !== extraModal?.giftOrderInfo?.recipientAddress) ||
      (formik.values.firstAndLastName.length !== 0 &&
        formik.values.firstAndLastName !== extraModal?.giftOrderInfo?.recipientName) ||
      (formik.values.phone.length !== 0 &&
        formik.values.phone !== extraModal?.giftOrderInfo?.recipientPhone)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formik.values.address, formik.values.firstAndLastName, formik.values.phone, extraModal]);
  return (
    <div>
      <h3>Укажите почтовый адрес</h3>
      <Form onSubmit={formik.handleSubmit} className={cls.Form}>
        <FormGroup className="position-relative">
          <FloatingLabel label="Индекс, Город, улица, дом, квартира*">
            <FormControl
              type="text"
              placeholder={
                formik.values.address.length !== 0 ? '' : 'Индекс, Город, улица, дом, квартира*'
              }
              required
              name="address"
              onChange={onChangeHandle}
              id="address"
              value={formik.values.address}
            />
          </FloatingLabel>
          {formik.errors.address && (
            <FormControl.Feedback type="invalid">{formik.errors.address}</FormControl.Feedback>
          )}
          {formik.values.address.length !== 0 && (
            <button
              type="button"
              className={cls.CloseButton}
              onClick={() => formik.setFieldValue('address', '')}
            >
              <IoIosClose />
            </button>
          )}
        </FormGroup>
        <FormGroup className="position-relative">
          <FloatingLabel label="ФИО получателя* ">
            <FormControl
              type="text"
              placeholder={formik.values.firstAndLastName.length !== 0 ? '' : 'ФИО получателя* '}
              required
              name="firstAndLastName"
              onChange={onChangeHandle}
              id="firstAndLastName"
              value={formik.values.firstAndLastName}
            />
          </FloatingLabel>
          {formik.errors.firstAndLastName && (
            <FormControl.Feedback type="invalid">
              {formik.errors.firstAndLastName}
            </FormControl.Feedback>
          )}
          {formik.values.firstAndLastName.length !== 0 && (
            <button
              type="button"
              className={cls.CloseButton}
              onClick={() => formik.setFieldValue('firstAndLastName', '')}
            >
              <IoIosClose />
            </button>
          )}
        </FormGroup>
        <FormGroup className="position-relative">
          <FloatingLabel label="Номер телефона*">
            <FormControl
              type="text"
              placeholder={formik.values.phone.length !== 0 ? '' : 'Номер телефона*'}
              required
              name="phone"
              onChange={onChangeHandle}
              id="phone"
              value={formik.values.phone}
            />
          </FloatingLabel>
          {formik.errors.phone && (
            <FormControl.Feedback type="invalid">{formik.errors.phone}</FormControl.Feedback>
          )}
          {formik.values.phone.length !== 0 && (
            <button
              type="button"
              className={cls.CloseButton}
              onClick={() => formik.setFieldValue('phone', '')}
            >
              <IoIosClose />
            </button>
          )}
        </FormGroup>
        <div className={cls.ButtonsWrapper}>
          <Button
            disabled={disabled}
            type="submit"
            text="Сохранить изменения"
            variant="primary"
            className={cls.Button}
          />
          <Button
            variant="secondary"
            onClick={closeHandler}
            type="button"
            text="Отмена"
            className={cls.Button}
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdateGIftOrderAddressModal;

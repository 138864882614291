import React, { FC, memo } from 'react';
import cls from './ConsumersListHeader.module.scss';
import { MdOutlineSwapVert } from 'react-icons/md';

type ConsumersListHeaderProps = {
  changeOrderHandler: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const ConsumersListHeader: FC<ConsumersListHeaderProps> = memo((props) => {
  const { changeOrderHandler } = props;
  return (
    <ul className={cls.ListHeader} data-testid="header-list">
      <li id="name" className={cls.ListItem} onClick={(event) => changeOrderHandler(event)}>
        <span>Имя</span>
        <MdOutlineSwapVert className={cls.ListItemIcon} />
      </li>
      <li className={cls.ListItem} id="email" onClick={(event) => changeOrderHandler(event)}>
        <span>Email</span>
        <MdOutlineSwapVert className={cls.ListItemIcon} />
      </li>
      <li className={cls.ListItem} id="phone" onClick={(event) => changeOrderHandler(event)}>
        <span>Телефон</span>
        <MdOutlineSwapVert className={cls.ListItemIcon} />
      </li>
      <li className={cls.ListItem} id="phone" onClick={(event) => changeOrderHandler(event)}>
        <span>Статус</span>
        <MdOutlineSwapVert className={cls.ListItemIcon} />
      </li>
      <li className={cls.ListItem} id="createdAt" onClick={(event) => changeOrderHandler(event)}>
        <span>Дата регистрации</span>
        <MdOutlineSwapVert className={cls.ListItemIcon} />
      </li>
    </ul>
  );
});

export default ConsumersListHeader;

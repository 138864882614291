// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dCLoAUwoKwhiZ0DjUPOA{display:grid;grid-template-columns:repeat(3, 164px) 1fr;align-items:center;gap:1rem;padding:0 2rem .5rem 2rem;border-bottom:2px solid var(--logs-list-border-color)}.xMbiMZNebx_FtWkx0dWj{font-size:20px;line-height:25.88px}`, "",{"version":3,"sources":["webpack://./src/features/log/LogsListHeader/LogsListHeader.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0CAAA,CACA,kBAAA,CACA,QAAA,CACA,yBAAA,CACA,qDAAA,CAEF,sBACE,cAAA,CACA,mBAAA","sourcesContent":[".Header {\n  display: grid;\n  grid-template-columns: repeat(3, 164px) 1fr;\n  align-items: center;\n  gap: 1rem;\n  padding: 0 2rem 0.5rem 2rem;\n  border-bottom: 2px solid var(--logs-list-border-color);\n}\n.Item {\n  font-size: 20px;\n  line-height: 25.88px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `dCLoAUwoKwhiZ0DjUPOA`,
	"Item": `xMbiMZNebx_FtWkx0dWj`
};
export default ___CSS_LOADER_EXPORT___;

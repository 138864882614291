import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import cls from './NavBatItem.module.scss';
import { NavElement } from '../../../../type';
import { useAppSelector } from '../../../../hooks/defaultHooks';
import { getUserInfo } from '../../../../selectors/selectors';

type NavBarItemProps = {
  navElement: NavElement;
  unClosed?: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBarItem: FC<NavBarItemProps> = memo(({ navElement, unClosed }) => {
  const user = useAppSelector(getUserInfo);
  if (user?.role === 'USER' && navElement.closed) return null;
  if (navElement.title === 'Сообщения' && unClosed)
    return (
      <NavLink
        to={navElement.route}
        className={({ isActive }) =>
          isActive ? `${cls.NavLink} ${cls.NavLinkActive}` : cls.NavLink
        }
        data-testid="navBar-link"
      >
        <div className={`${cls.Item} ${cls.ItemUnclodes}`}>
          <navElement.icon data-testid="navBar-icon" size="1.5rem" width={24} height={24} />
          <span data-testid="navBar-text">{navElement.title}</span>
        </div>
      </NavLink>
    );
  return (
    <NavLink
      to={navElement.route}
      className={({ isActive }) => (isActive ? `${cls.NavLink} ${cls.NavLinkActive}` : cls.NavLink)}
      data-testid="navBar-link"
    >
      <div className={cls.Item}>
        <navElement.icon data-testid="navBar-icon" size="1.5rem" width={24} height={24} />
        <span data-testid="navBar-text">{navElement.title}</span>
      </div>
    </NavLink>
  );
});

export default NavBarItem;

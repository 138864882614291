import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useUpdateInvoiceStatusMutation } from '../../invoices/invoices.service';
import cls from './RestoreInvoice.module.scss';

const RerstoreInvoice = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [changeInvoiceStatus] = useUpdateInvoiceStatusMutation();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  const cancelHandler = async () => {
    setDisabled(true);
    try {
      await changeInvoiceStatus({
        id: extra?.id as string,
        status: 'NEW',
      }).unwrap();
      dispatch(closeModal());
      if (extra?.handler !== undefined) extra.handler();
      toast.success('Счет успешно восстановлен');
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Повторите попытку позже');
        }
      }
    } finally {
      setDisabled(false);
    }
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Перевести в статус ожидает оплаты?</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <p>Вы уверены что хотите перевести счёт в статус ожидает оплаты?</p>
        <div className={cls.Buttons}>
          <Button
            type="button"
            className="w-100"
            variant="primary"
            disabled={disabled}
            onClick={cancelHandler}
          >
            Перевести
          </Button>
          <Button className="w-100" variant="outline-dark" onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};
export default RerstoreInvoice;

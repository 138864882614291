import React from 'react';
import cls from './DeleteGiftModal.module.scss';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { useDeleteGiftByIdMutation } from '../gifts.service';
import { toast } from 'react-toastify';
import { isFetchBaseQueryError } from '../../../utils/helpers';

const DeleteGiftModal = () => {
  const dispatch = useAppDispatch();
  const extraModal = useAppSelector(modalExtra);
  const [deleteGift] = useDeleteGiftByIdMutation();

  const deleteGiftHandler = async () => {
    try {
      await deleteGift(extraModal?.id ?? '').unwrap();
      if (extraModal?.handler) {
        extraModal.handler();
      }
      dispatch(closeModal());
      toast.success('Подарок успешно удален');
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          toast.error('Неверно введены данные');
        }
        if (error.status === 403) {
          toast.error('Запрещено');
        }
        if (error.status === 404) {
          toast.error('Подарок не найден');
        }
      }
    }
  };
  const closeHandler = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} data-testid="modal-title" closeButton>
        <h3 className="title-3">Удалить подарок?</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <p>Подарок нельзя будет восстановить, только создать заново</p>
        <div className={cls.Buttons}>
          <Button type="button" className={cls.DeleteButton} onClick={deleteGiftHandler}>
            Удалить
          </Button>
          <Button className={cls.CancelButton} onClick={closeHandler}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default DeleteGiftModal;

import React, { FC } from 'react';
import cls from './ShowLoadFileInfo.module.scss';

type ShowLoadFileInfoProps = {
  children: React.ReactNode;
};

const ShowLoadFileInfo: FC<ShowLoadFileInfoProps> = ({ children }) => {
  return <div className={cls.Container}>{children}</div>;
};

export default ShowLoadFileInfo;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wbojVVQ2WKdUnVwKMVws{display:grid;grid-template-columns:repeat(5, 309px);column-gap:1rem;border-bottom:1px solid var(--users-list-header-border-bottom-color);padding-bottom:1rem;padding-left:1rem;padding-right:1rem;margin-bottom:.5rem;list-style:none}.GZNa1ebppfdJks6i1V8o{cursor:pointer;font-size:20px;font-weight:400;line-height:25.88px;text-align:left}.U008ZGONbVxItArd3gNQ{color:var(--user-list-header-icon-default-color)}`, "",{"version":3,"sources":["webpack://./src/features/user/UsersListHeader/UsersListHeader.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sCAAA,CACA,eAAA,CACA,oEAAA,CACA,mBAAA,CACA,iBAAA,CACA,kBAAA,CACA,mBAAA,CACA,eAAA,CAEF,sBACE,cAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,eAAA,CAEF,sBACE,gDAAA","sourcesContent":[".ListHeader {\n  display: grid;\n  grid-template-columns: repeat(5, 309px);\n  column-gap: 1rem;\n  border-bottom: 1px solid var(--users-list-header-border-bottom-color);\n  padding-bottom: 1rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  margin-bottom: 0.5rem;\n  list-style: none;\n}\n.ListItem {\n  cursor: pointer;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 25.88px;\n  text-align: left;\n}\n.ListItemIcon {\n  color: var(--user-list-header-icon-default-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListHeader": `wbojVVQ2WKdUnVwKMVws`,
	"ListItem": `GZNa1ebppfdJks6i1V8o`,
	"ListItemIcon": `U008ZGONbVxItArd3gNQ`
};
export default ___CSS_LOADER_EXPORT___;

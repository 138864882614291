// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y9rD9I3dksp2E4iUYI0m{display:flex;align-items:center;gap:1rem}.JhAOvn7Q1m_b6RNfHTX2{display:flex;align-items:center;gap:4px;border-radius:2rem;padding:.25rem .75rem .25rem 1rem;outline:2px solid var(--active-filter-border)}.JhAOvn7Q1m_b6RNfHTX2:hover{outline:2px solid var(--active-filter-border)}.qpLKZ2dbpsE5lRQYQFOw{background-color:rgba(0,0,0,0);border:none;outline:none}.qpLKZ2dbpsE5lRQYQFOw:hover{cursor:pointer;border:none;outline:none}`, "",{"version":3,"sources":["webpack://./src/features/log/LogsFilters/LogsFilters.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CACA,iCAAA,CACA,6CAAA,CACA,4BACE,6CAAA,CAGJ,sBACE,8BAAA,CACA,WAAA,CACA,YAAA,CACA,4BACE,cAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".LogsFilters {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n.Item {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  border-radius: 2rem;\n  padding: 0.25rem 0.75rem 0.25rem 1rem;\n  outline: 2px solid var(--active-filter-border);\n  &:hover {\n    outline: 2px solid var(--active-filter-border);\n  }\n}\n.DeleteBtn {\n  background-color: transparent;\n  border: none;\n  outline: none;\n  &:hover {\n    cursor: pointer;\n    border: none;\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LogsFilters": `Y9rD9I3dksp2E4iUYI0m`,
	"Item": `JhAOvn7Q1m_b6RNfHTX2`,
	"DeleteBtn": `qpLKZ2dbpsE5lRQYQFOw`
};
export default ___CSS_LOADER_EXPORT___;

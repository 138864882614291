import React, { FC, memo, useLayoutEffect, useState } from 'react';
import { User } from '../user.types';
import cls from './UserPreviewItem.module.scss';
import Icon from '../../widget/Icon';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { openOffcanvas } from '../../../store/slices/offcanvasSlice';

type UserPreviewItemProps = {
  user: User;
};

const UserStatus = ({ blocked, archived }: { blocked: boolean; archived: boolean }) => {
  if (archived) {
    return (
      <div className={cls.Status}>
        <span className={`${cls.StatusIcon} ${cls.StatusIconDeleted}`}></span>
        <span>Архивирован</span>
      </div>
    );
  } else if (blocked) {
    return (
      <div className={cls.Status}>
        <span className={`${cls.StatusIcon} ${cls.StatusIconBlocked}`}></span>
        <span>Заблокирован</span>
      </div>
    );
  } else {
    return (
      <div className={cls.Status}>
        <span className={`${cls.StatusIcon} ${cls.StatusIconActive}`}></span>
        <span>Активен</span>
      </div>
    );
  }
};

const UserPreviewItem: FC<UserPreviewItemProps> = memo((props) => {
  const { user } = props;
  const [name, setName] = useState<string>('Неизвестный Бобер');
  const dispatch = useAppDispatch();
  const openOffcanvasHandler = (id: string) => {
    dispatch(openOffcanvas({ type: 'userOffcanvas', extra: { id } }));
  };
  useLayoutEffect(() => {
    if (user.firstName && user.secondName) {
      setName(`${user.firstName} ${user.secondName}`);
    } else if (user.firstName && !user.secondName) {
      setName(`${user.firstName} Бобер`);
    } else if (!user.firstName && user.secondName) {
      setName(`Неизвестный ${user.secondName}`);
    } else {
      setName('Неизвестный Бобер');
    }
  }, [user.firstName, user.secondName]);
  return (
    <div
      className={user.archived ? `${cls.Item} ${cls.ItemArchived}` : cls.Item}
      onClick={() => openOffcanvasHandler(user.id)}
    >
      <div className={cls.Name}>
        <Icon email={user.email} name={name} className={cls.Icon} />
        <span>{name}</span>
      </div>
      <span>{user.email}</span>
      <span>{user.phone ? user.phone : 'Не указан'}</span>
      <span>{user.roleName}</span>
      <UserStatus blocked={user.blocked} archived={user.archived} />
    </div>
  );
});
export default UserPreviewItem;

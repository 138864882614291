import React, { FC, memo, useEffect, useState } from 'react';
import cls from './DatePickerTodayAndRange.module.scss';
import { Log, LogsSearch } from '../../log/logs.types';
import { Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';

type DatePickerTodayAndRangeProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setScrollList: React.Dispatch<React.SetStateAction<Log[]>>;
  search: LogsSearch;
  setSearch: React.Dispatch<React.SetStateAction<LogsSearch>>;
  setDateCb: (startDateTime: string, endDateTime: string) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const DatePickerTodayAndRange: FC<DatePickerTodayAndRangeProps> = memo((props) => {
  const { show, setShow, setScrollList, search, setSearch, setDateCb, setPage } = props;
  const [period, setPeriod] = useState<'DAY' | 'PERIOD'>('DAY');
  const [day, setDay] = useState<string>(new Date().toISOString().slice(0, 10));
  const [periodStart, setPeriodStart] = useState<string>('');
  const [periodEnd, setPeriodEnd] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const setOneDayHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      search.startDateTime !== convertDateToNeedFormat({ date: day, format: 'startRange' }) ||
      search.endDateTime !== convertDateToNeedFormat({ date: day, format: 'endRange' })
    ) {
      setPage(0);
      setScrollList([]);
      setSearch({
        ...search,
        startDateTime: convertDateToNeedFormat({ date: day, format: 'startRange' }),
        endDateTime: convertDateToNeedFormat({ date: day, format: 'endRange' }),
      });
      setPage(0);
      setDateCb(
        convertDateToNeedFormat({ date: day, format: 'startRange' }),
        convertDateToNeedFormat({ date: day, format: 'endRange' }),
      );
      setShow(false);
    }
  };
  const setPeriodHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      search.startDateTime !==
        convertDateToNeedFormat({ date: periodStart, format: 'startRange' }) ||
      search.endDateTime !== convertDateToNeedFormat({ date: periodEnd, format: 'endRange' })
    ) {
      setScrollList([]);
      setSearch({
        ...search,
        startDateTime: convertDateToNeedFormat({ date: periodStart, format: 'startRange' }),
        endDateTime: convertDateToNeedFormat({ date: periodEnd, format: 'endRange' }),
      });
      setPage(0);
      setDateCb(
        convertDateToNeedFormat({ date: periodStart, format: 'startRange' }),
        convertDateToNeedFormat({ date: periodEnd, format: 'endRange' }),
      );
      setShow(false);
    }
  };
  useEffect(() => {
    if (period === 'PERIOD' && periodStart && periodEnd) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [period, periodStart, periodEnd]);
  if (!show) return null;
  return (
    <div className={cls.Container}>
      <div className={cls.ActionsButton}>
        <button
          onClick={() => setPeriod('DAY')}
          className={
            period === 'DAY' ? `${cls.ActionButton} ${cls.ActionActionButton}` : cls.ActionButton
          }
        >
          День
        </button>
        <button
          className={
            period === 'PERIOD' ? `${cls.ActionButton} ${cls.ActionActionButton}` : cls.ActionButton
          }
          onClick={() => setPeriod('PERIOD')}
        >
          Период
        </button>
      </div>
      {period === 'DAY' && (
        <Form onSubmit={(e) => setOneDayHandler(e)} className={cls.Form}>
          <div>
            <FormLabel>Выберите день</FormLabel>
            <FormControl
              type="date"
              onChange={(e) => setDay(e.target.value)}
              value={day}
              name="date"
              className={cls.Input}
              id="date"
            />
          </div>
          <div className={cls.Buttons}>
            <Button type="submit" className={`${cls.Button} ${cls.SendButton}`}>
              Применить фильтр
            </Button>
            <Button
              type="button"
              className={`${cls.Button} ${cls.CancelButton}`}
              onClick={() => setShow(false)}
            >
              Отмена
            </Button>
          </div>
        </Form>
      )}
      {period === 'PERIOD' && (
        <Form onSubmit={(e) => setPeriodHandler(e)} className={cls.Form}>
          <div className={cls.FormLine}>
            <div>
              <FormLabel>Выберите начало периода</FormLabel>
              <FormControl
                type="date"
                onChange={(e) => setPeriodStart(e.target.value)}
                value={periodStart}
                name="date"
                className={cls.Input}
                id="date"
              />
            </div>
            <div>
              <FormLabel>Выберите конец периода</FormLabel>
              <FormControl
                type="date"
                onChange={(e) => setPeriodEnd(e.target.value)}
                value={periodEnd}
                name="date"
                className={cls.Input}
                id="date"
              />
            </div>
          </div>
          <div className={cls.Buttons}>
            <Button
              type="submit"
              disabled={disabled}
              className={
                disabled ? `${cls.Button} ${cls.DisabledButton}` : `${cls.Button} ${cls.SendButton}`
              }
            >
              Применить фильтр
            </Button>
            <Button
              type="button"
              className={`${cls.Button} ${cls.CancelButton}`}
              onClick={() => setShow(false)}
            >
              Отмена
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
});

export default DatePickerTodayAndRange;

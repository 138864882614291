import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { secureIP } from './secure.types';

const secureApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSecureIps: builder.query<secureIP, void>({
      query: () => routes.api.settingsIp(),
      providesTags: ['SecureIPS'],
    }),
    updateSecureIp: builder.mutation<secureIP, secureIP>({
      query: (secureIp) => ({
        url: routes.api.settingsIp(),
        method: 'PATCH',
        body: secureIp,
      }),
      invalidatesTags: ['SecureIPS'],
    }),
  }),
});

export const { useGetAllSecureIpsQuery, useUpdateSecureIpMutation } = secureApi;

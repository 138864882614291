// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.igbYcbvD_g5qWHVBHZYJ{padding:.5rem 2rem}`, "",{"version":3,"sources":["webpack://./src/features/chat/ShowLoadFileInfo/ShowLoadFileInfo.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".Container {\n  padding: 0.5rem 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `igbYcbvD_g5qWHVBHZYJ`
};
export default ___CSS_LOADER_EXPORT___;

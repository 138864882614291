import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SignInForm from '../../features/autorization/SignInForm';
import { useAppDispatch, useAppSelector } from '../../hooks/defaultHooks';
import { getTheme } from '../../selectors/selectors';
import ImagePreview from '../../UIComponents/ImagePreview';
import image from './signIn-preview.svg';
import Logo from '../../UIComponents/Logo';
import { openModal } from '../../store/slices/modalSlice';
import Modal from '../../features/modals/Modals';
import { ToastContainer } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const SignInPage = () => {
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const theme = useAppSelector(getTheme);
  const dispatch = useAppDispatch();
  const forgotPasswordHandler = () => {
    setError('');
    dispatch(openModal({ type: 'forgotPassword', extra: { email: userEmail, id: null } }));
  };
  useDocumentTitle('Войти');
  return (
    <Container className={`${theme}-root-container`} fluid>
      <Row className="align-items-center justify-content-around h-100">
        <Col
          xs={4}
          md={4}
          lg={4}
          className={`${theme}-secondary-container d-flex flex-column align-items-center justify-content-center h-100`}
        >
          <Logo />
          <h2 className="my-5">Войти</h2>
          <SignInForm
            navigate={navigate}
            theme={theme}
            setForgotPassword={setForgotPassword}
            setUserEmail={setUserEmail}
            error={error}
            setError={setError}
          />
          {forgotPassword && (
            <div className="d-flex gap-2">
              <span>Забыли пароль?</span>
              <a href="#" className={`${theme}-link`} onClick={forgotPasswordHandler}>
                Сбросить
              </a>
            </div>
          )}
        </Col>
        <Col xs={8} md={8} lg={8} className="d-flex align-items-center justify-content-center">
          <ImagePreview url={image} />
        </Col>
      </Row>
      <Modal />
      <ToastContainer autoClose={5000} theme={theme} />
    </Container>
  );
};

export default SignInPage;

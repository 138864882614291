// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Syxf6rBrpo1DlVKzzN8o{padding:40px}.i8zKLwvIa9l4lsNYovBU{margin-bottom:1.5rem;grid-column:1/-1}.FyVXy5vXlIfKvjzq54ww{grid-column:1/-1;margin-bottom:2rem}.PwLyHbq7Poy9bLODCpVU{grid-column:1/9}`, "",{"version":3,"sources":["webpack://./src/screens/NewTariffFearurePage/NewTariffFeaturePage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEF,sBACE,oBAAA,CACA,gBAAA,CAGF,sBACE,gBAAA,CACA,kBAAA,CAEF,sBACE,eAAA","sourcesContent":[".Container {\n  padding: 40px;\n}\n.BreadCrumbs {\n  margin-bottom: 1.5rem;\n  grid-column: 1/-1;\n}\n\n.Title {\n  grid-column: 1/-1;\n  margin-bottom: 2rem;\n}\n.Form {\n  grid-column: 1/9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `Syxf6rBrpo1DlVKzzN8o`,
	"BreadCrumbs": `i8zKLwvIa9l4lsNYovBU`,
	"Title": `FyVXy5vXlIfKvjzq54ww`,
	"Form": `PwLyHbq7Poy9bLODCpVU`
};
export default ___CSS_LOADER_EXPORT___;

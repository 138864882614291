import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import ChangePasswordForm from '../../user/ChangePasswordForm';
import cls from './ChangeUserPassword.module.scss';

const ChangeUserPassword = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = (): void => {
    dispatch(closeModal());
  };
  return (
    <>
      <Modal.Header className={`${cls.ModalHeader} m-0`} closeButton data-testid="modal-title">
        <p>Логин</p>
        <h4 className="title-4">{extra?.email}</h4>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <ChangePasswordForm closeHandler={closeHandler} id={extra?.id} />
      </Modal.Body>
    </>
  );
};

export default ChangeUserPassword;

import React, { memo } from 'react';
import cls from './ApplicationSettingsNavigation.module.scss';
import { NavLink } from 'react-router-dom';
import routes from '../../../routes';

const ApplicationSettingsNavigation = memo(() => {
  return (
    <nav className={cls.Navigation}>
      <NavLink
        to={routes.pages.applicationSettingsPageForPortal()}
        className={({ isActive }) => (isActive ? `${cls.Link} ${cls.ActiveLink}` : cls.Link)}
      >
        Портал
      </NavLink>
      <NavLink
        to={routes.pages.applicationSettingsPageForAdmin()}
        className={({ isActive }) => (isActive ? `${cls.Link} ${cls.ActiveLink}` : cls.Link)}
      >
        Админпанель
      </NavLink>
      <NavLink
        to={routes.pages.applicationsAssistantSettingsPage()}
        className={({ isActive }) => (isActive ? `${cls.Link} ${cls.ActiveLink}` : cls.Link)}
      >
        Ассистенты
      </NavLink>
      <NavLink
        to={routes.pages.applicationsReferallSettingsPage()}
        className={({ isActive }) => (isActive ? `${cls.Link} ${cls.ActiveLink}` : cls.Link)}
      >
        Подарки
      </NavLink>
    </nav>
  );
});

export default ApplicationSettingsNavigation;

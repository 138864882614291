import React, { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getOffcanvasExtra } from '../../../selectors/selectors';
import { useGetAboutUserByIdQuery } from '../../user/user.service';
import { closeOffcanvas } from '../../../store/slices/offcanvasSlice';
import Spinner from '../../../UIComponents/Spinner/Spinner';
import UserCard from '../../user/UserCard';

const UserOffcanvas = () => {
  const offcanvasExtra = useAppSelector(getOffcanvasExtra);
  const { data: user, isLoading } = useGetAboutUserByIdQuery(offcanvasExtra?.id ?? '');
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    if (!isLoading && !user) dispatch(closeOffcanvas());
  });
  if (isLoading) return <Spinner />;
  if (!user) return null;
  return <UserCard user={user} />;
};

export default UserOffcanvas;

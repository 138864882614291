import React, { FC, memo, useEffect, useState } from 'react';
import { createGravatar } from '../../../utils/createGravatar';
import { createConsumerIcon } from '../../../utils/createConsumerIcon';
import cls from './Icon.module.scss';
import { classNameMerge } from '../../../utils/classNameMerge';

type IconProps = {
  email: string;
  name: string;
  className?: string;
};

const Icon: FC<IconProps> = memo((props) => {
  const { email, name, className } = props;
  const [image, setImage] = useState<{ url: string | null; color: string | null }>({
    url: null,
    color: '#eee',
  });
  useEffect(() => {
    if (email.length !== 0) {
      const generateImage = async () => {
        const color = await createGravatar(email).then((data) => data);
        setImage(color);
      };
      generateImage();
    }
  }, [email]);
  if (image.url)
    return (
      <img
        className={classNameMerge(cls.Icon, className)}
        src={image?.url as string}
        data-testid="user-img"
      />
    );
  return (
    <div
      data-testid="consumer-img"
      className={classNameMerge(cls.Icon, className)}
      style={{
        backgroundColor: `#${image?.color}`,
      }}
    >
      {createConsumerIcon(name)}
    </div>
  );
});

export default Icon;

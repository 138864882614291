import React, { FC } from 'react';
import { themes } from '../../../store/slices/themeSlice';
import { Modal } from 'react-bootstrap';
import Button from '../../../UIComponents/Button';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { toast } from 'react-toastify';

interface ExportLogsProps {
  theme: themes;
}

const ExportLogs: FC<ExportLogsProps> = ({ theme }) => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const downloadHandler = () => {
    if (extra?.handler !== undefined) {
      extra?.handler();
      toast.success('Данные успешно скачены');
      closeHandler();
    }
  };
  return (
    <>
      <Modal.Header
        className="bg-dark d-flex justify-content-center text-white"
        data-testid="modal-title"
      >
        Экспортировать {extra?.title as never as string}
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          className="bi bi-exclamation-triangle"
          viewBox="0 0 16 16"
        >
          <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
          <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
        </svg>
        <div className="text-part" data-testid="text-part">
          <p>Для экспорта выбранных данных нажмите кнопку сохранить</p>
        </div>
        <div className="d-flex gap-5">
          <Button text="Отмена" clickHandler={closeHandler} theme={theme} />
          <Button
            theme={theme}
            clickHandler={downloadHandler}
            className={`${theme}-export-link btn`}
            text="Скачать"
          />
        </div>
      </Modal.Body>
    </>
  );
};

export default ExportLogs;

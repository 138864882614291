// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EogltZbbg1UVfKBKUJK5{padding:8px 20px;border-radius:32px;background-color:rgba(0,0,0,0);color:var(--badge-color);font-size:18px;font-weight:400;line-height:23.29px;text-align:left;cursor:pointer}.L6eIbriZS3WXGAwuI_ad{background-color:var(--active-badge-bg-color);color:var(--active-badge-text-color)}`, "",{"version":3,"sources":["webpack://./src/UIComponents/CustomBadge/CustomBadge.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,kBAAA,CACA,8BAAA,CACA,wBAAA,CAEA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,eAAA,CACA,cAAA,CAEF,sBACE,6CAAA,CACA,oCAAA","sourcesContent":[".Badge {\n  padding: 8px 20px;\n  border-radius: 32px;\n  background-color: transparent;\n  color: var(--badge-color);\n\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 23.29px;\n  text-align: left;\n  cursor: pointer;\n}\n.ActiveBadge {\n  background-color: var(--active-badge-bg-color);\n  color: var(--active-badge-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Badge": `EogltZbbg1UVfKBKUJK5`,
	"ActiveBadge": `L6eIbriZS3WXGAwuI_ad`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import cls from './UsersListHeader.module.scss';

const UsersListHeader = () => {
  return (
    <ul className={cls.ListHeader} data-testid="users-list">
      <li className={cls.ListItem} id="name">
        <span>Имя</span>
      </li>
      <li className={cls.ListItem} id="email">
        <span>Email</span>
      </li>
      <li className={cls.ListItem} id="phone">
        <span>Телефон</span>
      </li>
      <li className={cls.ListItem} id="role">
        <span>Роль</span>
      </li>
      <li className={cls.ListItem}>
        <span>Статус</span>
      </li>
    </ul>
  );
};

export default UsersListHeader;

import React, { FC, memo, useLayoutEffect, useState } from 'react';
import { User } from '../user.types';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { openModal } from '../../../store/slices/modalSlice';
import cls from './UserCardSubInfoBlock.module.scss';
import Button from '../../../UIComponents/Button';
import { MdLock, MdLockReset, MdManageAccounts } from 'react-icons/md';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';

type UserCardSubInfoBlockProps = {
  user: User;
  isActualUser: boolean;
};

const UserStatus = ({ blocked, archived }: { blocked: boolean; archived: boolean }) => {
  if (archived) {
    return <span className={`${cls.FieldValue} ${cls.FieldValueDeleted}`}>Архивирован</span>;
  } else if (blocked) {
    return <span className={`${cls.FieldValue} ${cls.FieldValueRed}`}>Заблокирован</span>;
  } else {
    return <span className={`${cls.FieldValue} ${cls.FieldValueGreen}`}>Активен</span>;
  }
};

const UserCardSubInfoBlock: FC<UserCardSubInfoBlockProps> = memo((props) => {
  const { user, isActualUser } = props;
  const [name, setName] = useState<string>('Неизвестный Бобер');
  useLayoutEffect(() => {
    if (user.firstName && user.secondName) {
      setName(`${user.firstName} ${user.secondName}`);
    } else if (user.firstName && !user.secondName) {
      setName(`${user.firstName} Бобер`);
    } else if (!user.firstName && user.secondName) {
      setName(`Неизвестный ${user.secondName}`);
    } else {
      setName('Неизвестный Бобер');
    }
  }, [user.firstName, user.secondName]);
  const dispatch = useAppDispatch();
  const unBlockUserHandler = () =>
    dispatch(
      openModal({
        type: 'unLockUser',
        extra: {
          email: user?.email,
          id: user?.id,
          title: 'Разблокировать сотрудника?',
          supportText: 'Вы уверены что хотите разблокировать',
          textInformation: name,
          subSupportText: 'Данные будут восстановлены.',
          actionButtonText: 'Разблокировать',
        },
      }),
    );
  const blockHandler = () =>
    dispatch(
      openModal({
        type: 'lockUser',
        extra: {
          email: user?.email,
          id: user?.id,
          title: 'Заблокировать сотрудника?',
          supportText: 'Вы уверены что хотите заблокировать',
          textInformation: name,
          subSupportText: 'Заблокированный аккаунт можно восстановить, данные будут сохранены.',
          actionButtonText: 'Заблокировать',
        },
      }),
    );
  const updateRoleHandler = () =>
    dispatch(
      openModal({
        type: 'updateRole',
        extra: {
          email: user?.email,
          id: user?.id,
          title: 'Изменить роль?',
          supportText: 'Изменить роль сотрудника',
          textInformation: name,
          actionButtonText: 'Выполнить',
          userRole: user.role,
        },
      }),
    );

  const deleteUserHandler = () =>
    dispatch(
      openModal({
        type: 'deleteUser',
        extra: {
          email: user?.email,
          id: user?.id,
          title: 'Архивировать сотрудника?',
          supportText: 'Вы уверены что хотите архивировать',
          textInformation: name,
          subSupportText: 'Подтвердите архивирование вводом email сотрудника ',
          actionButtonText: 'Архивировать',
        },
      }),
    );
  const restoreUserHandler = () =>
    dispatch(
      openModal({
        type: 'restoreUser',
        extra: {
          email: user?.email,
          id: user?.id,
          title: 'Восстановить сотрудника?',
          supportText: 'Вы уверены что хотите восстановить',
          textInformation: name,
          subSupportText: 'Подтвердите восстановление вводом email сотрудника ',
          actionButtonText: 'Восстановить',
        },
      }),
    );
  return (
    <div className={cls.CardSubInfo}>
      <div className={cls.Info}>
        <div className={cls.Property}>
          <span className={cls.FieldName}>Статус</span>
          <UserStatus blocked={user?.blocked} archived={user?.archived} />
        </div>
        <div className={cls.Property}>
          <span className={cls.FieldName}>Роль</span>
          <span className={cls.FieldValue}>{user.roleName}</span>
        </div>
      </div>
      {!isActualUser && (
        <div className={cls.Buttons}>
          <Button
            clickHandler={updateRoleHandler}
            Icon={<MdManageAccounts />}
            text="Изменить роль"
            className={cls.Button}
          />
          <Button
            clickHandler={user?.blocked ? unBlockUserHandler : blockHandler}
            Icon={user?.blocked ? <MdLockReset /> : <MdLock />}
            text={user?.blocked ? 'Разблокировать' : 'Заблокировать'}
            className={cls.Button}
          />
          <Button
            clickHandler={user?.archived ? restoreUserHandler : deleteUserHandler}
            Icon={user?.archived ? <BiArchiveOut /> : <BiArchiveIn />}
            text={user?.archived ? 'Восстановить' : 'Архивировать'}
            className={cls.Button}
          />
        </div>
      )}
    </div>
  );
});

export default UserCardSubInfoBlock;

import React, { memo } from 'react';
import cls from './LogsListHeader.module.scss';

const LogsListHeader = memo(() => {
  return (
    <div className={cls.Header} data-testid="header-list">
      <span className={cls.Item} id="number">
        Номер
      </span>
      <span className={cls.Item} id="createdAt">
        Дата
      </span>
      <span className={cls.Item} id="ip">
        IP адрес
      </span>
      <span className={cls.Item} id="description">
        Событие
      </span>
    </div>
  );
});

export default LogsListHeader;

import { AuthRequest, AuthResponce } from './authorization.types';
import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';

const authorizationApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation<AuthResponce, AuthRequest>({
      query: (userData) => ({
        url: routes.api.signIn(),
        method: 'POST',
        body: userData,
      }),
    }),
    signIn: builder.mutation<AuthResponce, AuthRequest>({
      query: (userData) => ({
        url: routes.api.logIn(),
        method: 'POST',
        body: userData,
      }),
    }),
  }),
});

export const { useSignUpMutation, useSignInMutation } = authorizationApi;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useDeleteUserMutation } from '../../user/user.service';
import { toast } from 'react-toastify';
import cls from './DeleteUser.module.scss';

interface initialValues {
  email: string;
}

const DeleteUser = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [deleteUser] = useDeleteUserMutation();
  const closeHandler = () => dispatch(closeModal());
  const [disabled, setDisabled] = useState<boolean>(false);
  const initialValues: initialValues = {
    email: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (extra?.email === values.email.toLocaleLowerCase()) {
        try {
          deleteUser(extra.id as string);
          dispatch(closeModal());
          toast.success('Пользователь успешно удален');
        } catch (error) {
          if (isFetchBaseQueryError(error)) {
            if (error.status === 400) {
              toast.error('Повторите попытку позже');
            } else if (error.status === 403) {
              toast.error('Запрещено');
            } else if (error.status === 404) {
              toast.error('Пользователь не найден');
            }
          }
        }
      } else {
        toast.error('Неверная почта');
      }
    },
  });
  useEffect(() => {
    if (formik.values.email === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formik.values.email]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3" data-testid="modal-title">
          {extra?.title}
        </h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div data-testid="text-part">
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>? {extra?.subSupportText}
          </p>
        </div>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className={cls.Form}
          data-testid="delete-user"
        >
          <div>
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          <div className={cls.Buttons}>
            <Button
              type="submit"
              className={disabled ? `${cls.DeleteButton} ${cls.Disabled}` : `${cls.DeleteButton}`}
              disabled={disabled}
            >
              {extra?.actionButtonText}
            </Button>
            <Button onClick={closeHandler} className={cls.CancelButton}>
              Отмена
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};

export default DeleteUser;

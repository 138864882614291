// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IbGM_9HEUyDlVnURDLqK{width:40px;height:40px;padding:8px;border-radius:64px;background-color:var(--messenger-input-bg-color);display:flex;justify-content:center;align-items:center;cursor:pointer;color:var(--messenger-input-placeholder-color)}.wnSd9BmzOVO1FzdhdQcz{display:none}`, "",{"version":3,"sources":["webpack://./src/features/chat/SendFileForMessage/SendFileForMessage.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,kBAAA,CACA,gDAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,8CAAA,CAEF,sBACE,YAAA","sourcesContent":[".SendFileForMessage {\n  width: 40px;\n  height: 40px;\n  padding: 8px;\n  border-radius: 64px;\n  background-color: var(--messenger-input-bg-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  color: var(--messenger-input-placeholder-color);\n}\n.SendFile {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SendFileForMessage": `IbGM_9HEUyDlVnURDLqK`,
	"SendFile": `wnSd9BmzOVO1FzdhdQcz`
};
export default ___CSS_LOADER_EXPORT___;

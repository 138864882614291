import React from 'react';
import LeadCard from '../../features/lead/LeadCard';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useParams } from 'react-router-dom';
import { useGetLeadByIdQuery } from '../../features/lead/lead.service';

const LeadPage = () => {
  const { leadId } = useParams();
  const { data: lead, isLoading } = useGetLeadByIdQuery(leadId as string);
  useDocumentTitle(`Заявка ${lead?.email}`);
  return <LeadCard lead={lead} isLoading={isLoading} />;
};

export default LeadPage;

export const consumerRoutes = {
  getAllConsumers: () => '/consumers',
  aboutConsumerById: (consumerId: string) => `/consumers/${consumerId}`,
  unlockConsumerById: (consumerId: string) => `/consumers/${consumerId}/unlock`,
  lockConsumerById: (consumerId: string) => `/consumers/${consumerId}/lock`,
  consumerMessages: (consumerId: string) => `/consumers/${consumerId}/messages`,
  consumerOperation: (consumerId: string) => `/consumers/${consumerId}/operations`,
  consumerBalance: (consumerId: string) => `/consumers/${consumerId}/balance`,
  consumerCabinets: (consumerId: string) => `/consumers/${consumerId}/cabinets`,
  consumerIntegrations: (consumerId: string) => `/consumers/${consumerId}/integrations`,
  resetConsumer2FAById: (consumerId: string) => `/consumers/${consumerId}/reset2fa`,
  changeConsumerCabinetCount: (consumerId: string) => `/consumers/${consumerId}/patch_cabinets`,
  getLogsByConsumerId: (consumerId: string) => `/consumers/${consumerId}/logs`,
  consumersExport: () => '/consumers/export',
  consumersSettings: () => '/consumers/settings',
  consumersSettingsById: (settingsId: string) => `/consumers/settings/${settingsId}`,
};

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import cls from './DisableConsumer2FA.module.scss';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';
import { toast } from 'react-toastify';
import { useReset2FAForConsumerByIdMutation } from '../../consumer/consumer.service';

const DisableConsumer2FA = () => {
  const extra = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const [resetTwoFactorAuth] = useReset2FAForConsumerByIdMutation();
  const disableTwoFactorAuth = async () => {
    if (extra && extra?.id) {
      await resetTwoFactorAuth({ consumerId: extra?.id ?? '' }).unwrap();
      toast.success('Данные успешно изменены');
      dispatch(closeModal());
    }
  };
  const closeHandler = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3">{extra?.title}</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div>
          <p>
            {extra?.supportText} <strong>{extra?.textInformation}</strong>
            {extra?.subSupportText}
          </p>
        </div>
        <div className={cls.Buttons}>
          <Button type="button" className={cls.Button} onClick={disableTwoFactorAuth}>
            {extra?.actionButtonText}
          </Button>
          <Button onClick={closeHandler} className={cls.CancelButton}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default DisableConsumer2FA;

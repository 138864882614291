// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imtq4OT6eTL90ip5b563{display:grid;grid-template-columns:repeat(5, 295px);column-gap:1rem;border-bottom:1px solid var(--consumer-list-header-border-bottom-color);padding-bottom:1rem;padding-left:1rem;padding-right:1rem;margin-bottom:.5rem;list-style:none}.Df_g2BxgYKiw_1cgQsK4{cursor:pointer;font-size:20px;font-weight:400;line-height:25.88px;text-align:left}.bZkdvxd7ssZhzttwyTOE{color:var(--consumer-list-header-icon-default-color)}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumersListHeader/ConsumersListHeader.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sCAAA,CACA,eAAA,CACA,uEAAA,CACA,mBAAA,CACA,iBAAA,CACA,kBAAA,CACA,mBAAA,CACA,eAAA,CAEF,sBACE,cAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,eAAA,CAEF,sBACE,oDAAA","sourcesContent":[".ListHeader {\n  display: grid;\n  grid-template-columns: repeat(5, 295px);\n  column-gap: 1rem;\n  border-bottom: 1px solid var(--consumer-list-header-border-bottom-color);\n  padding-bottom: 1rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  margin-bottom: 0.5rem;\n  list-style: none;\n}\n.ListItem {\n  cursor: pointer;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 25.88px;\n  text-align: left;\n}\n.ListItemIcon {\n  color: var(--consumer-list-header-icon-default-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListHeader": `imtq4OT6eTL90ip5b563`,
	"ListItem": `Df_g2BxgYKiw_1cgQsK4`,
	"ListItemIcon": `bZkdvxd7ssZhzttwyTOE`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, memo } from 'react';
import { Log } from '../logs.types';
import cls from './LogPreviewItem.module.scss';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { getAllLogsTypes } from '../../../selectors/selectors';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { openOffcanvas } from '../../../store/slices/offcanvasSlice';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';
import { setLogsFieldSearch } from '../../../store/slices/logsFilterSlice';
import LogDescription from '../LogDescription';
import { OverlayTrigger, Popover } from 'react-bootstrap';

type LogPreviewItemProps = {
  log: Log;
};

export const CustomOverlay = ({
  name,
  link,
  id,
  clickHandler,
}: {
  name: string;
  link: string;
  id: string;
  clickHandler: (id: string, email: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const consumerOffcanvas = (id: string) => {
    dispatch(openOffcanvas({ type: 'consumerOffcanvas', extra: { id } }));
  };
  const userOffcanvas = (id: string) => {
    dispatch(openOffcanvas({ type: 'userOffcanvas', extra: { id } }));
  };
  const navigate = useNavigate();
  const openOffcanvasHandler = (id: string) => {
    if (!link) return;
    if (link.includes('users')) {
      userOffcanvas(id);
    } else if (link.includes('consumers')) {
      consumerOffcanvas(id);
    } else if (link.includes('courses')) {
      navigate(routes.pages.coursePageById(id));
    }
  };
  return (
    <OverlayTrigger
      key={name}
      placement="top"
      delay={{ show: 300, hide: 700 }}
      trigger="hover"
      overlay={
        <Popover id="tooltip-top" className={cls.Popover}>
          <div className={cls.OverlayText} onClick={() => openOffcanvasHandler(id)}>
            Посмотреть профиль <MdOutlineNavigateNext />
          </div>
        </Popover>
      }
    >
      <strong
        id={id}
        className="cursor-pointer"
        onClick={() => {
          clickHandler(id, link);
        }}
      >
        {name}
      </strong>
    </OverlayTrigger>
  );
};

const LogPreviewItem: FC<LogPreviewItemProps> = memo((props) => {
  const { log } = props;
  const logsType = useAppSelector(getAllLogsTypes);
  const dispatch = useAppDispatch();
  const setIpHandler = (ip: string) => {
    dispatch(setLogsFieldSearch({ key: 'ip', value: ip }));
  };
  const setObjectIdHandler = ({ id, email }: { id: string; email: string }) => {
    dispatch(setLogsFieldSearch({ key: 'objectId', value: id }));
    dispatch(setLogsFieldSearch({ key: 'objectEmail', value: email }));
  };
  return (
    <div className={cls.Item}>
      <span className="regular-text">{log.id}</span>
      <span className="regular-text">
        {convertDateToNeedFormat({ date: log.createdAt, format: 'datetime' })}
      </span>
      <span className="regular-text cursor-pointer" onClick={() => setIpHandler(log.ip)}>
        {log.ip}
      </span>
      <LogDescription
        log={log}
        logsType={logsType}
        description={log.description}
        setObjectIdHandler={setObjectIdHandler}
      />
    </div>
  );
});

export default LogPreviewItem;

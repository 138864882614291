import React, { FC, memo, useState } from 'react';
import cls from './LogsPanel.module.scss';
import DatePickerTodayAndRange from '../../widget/DatePickerTodayAndRange';
import { Log, LogsSearch } from '../logs.types';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { setLogsFieldSearch } from '../../../store/slices/logsFilterSlice';
import { getLogsFilters } from '../../../selectors/selectors';
import LogsFilters from '../LogsFilters';

type LogsPanelProps = {
  setScrollLogsList: React.Dispatch<React.SetStateAction<Log[]>>;
  search: LogsSearch;
  setSearch: React.Dispatch<React.SetStateAction<LogsSearch>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const LogsPanel: FC<LogsPanelProps> = memo((props) => {
  const { search, setScrollLogsList, setSearch, setPage } = props;
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const logsFilters = useAppSelector(getLogsFilters);
  const dispatch = useAppDispatch();
  const setDateCb = (startDateTime: string, endDateTime: string) => {
    dispatch(setLogsFieldSearch({ key: 'startDateTime', value: startDateTime }));
    dispatch(setLogsFieldSearch({ key: 'endDateTime', value: endDateTime }));
  };
  return (
    <div className={cls.LogsPanel}>
      {logsFilters.startDateTime === '' && logsFilters.endDateTime === '' && (
        <div className={cls.DatePicker}>
          <button onClick={() => setShowPicker(!showPicker)} className={cls.DatePickerBtn}>
            Фильтр по дате
          </button>
          <DatePickerTodayAndRange
            show={showPicker}
            setShow={setShowPicker}
            setScrollList={setScrollLogsList}
            setPage={setPage}
            search={search}
            setSearch={setSearch}
            setDateCb={setDateCb}
          />
        </div>
      )}
      <LogsFilters filters={logsFilters} />
    </div>
  );
});

export default LogsPanel;

import React, { FC } from 'react';
import { Modal as RootModal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/defaultHooks';
import selectors from '../../selectors';
import { closeModal } from '../../store/slices/modalSlice';
import ChangeUserPassword from './ChangeUserPassword';
import DeleteUser from './DeleteUser';
import LockUser from './lockUser';
import UnLockUser from './UnlockUser';
import UpdateRole from './UpdateRole';
import { getTheme } from '../../selectors/selectors';
import LockConsumer from './LockConsumer';
import UnLockConsumer from './UnLockConsumer';
import RestoreUser from './RestoreUser';
import MakeConsumerTransaction from './makeConsumerTransaction';
import ChangeCabinetsCount from './ChangeCabinetsCount';
import ExportLogs from './ExportLogs';
import ChangeMePassword from './ChangeMePassword';
import AddSecureIPs from './AddSecureIPs';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ShowTextInformation from './ShowTextInformation';
import AddNewAdditionalMaterial from './AddNewAdditionalMaterial';
import ConfirmInvoice from './ConfirmInvoice/ConfirmInvoice';
import CancelInvoice from './CancelInvoice/CancelInvoice';
import RestoreInvoice from './RestoreInvoice';
import DeleteFeatureModal from './DeleteFeatureModal';
import DownloadReportsModal from './DownloadReportsModal/DownloadReportsModal';
import SendInvoiceModal from './SendInvoiceModal/SendInvoiceModal';
import MakeInvoiceExpiredModal from './MakeInvoiceExpiredModal';
import DeleteAnnouncementModal from './DeleteAnnouncementModal';
import DangerActionsWithCourseOrLesson from './DangerActionsWithCourseOrLesson';
import ActionWithSettings from './ActionWithSettings';
import DisableConsumer2FA from './DisableConsumer2FA';
import ChangeSolutionImage from './ChangeSolutionImage';
import ActionsWithSolutionStrategy from './ActionsWithSolutionStrategy';
import ActionWithTool from './ActionWithTool';
import ChangeToolImage from './ChangeToolImage';
import ActionWithSolution from './ActionWithSolution';
import UpdateAssistantInfoModal from '../settings/UpdateAssistantInfoModal';
import { giftsModals } from '../gifts/gifts.modals';
import { giftOrdersModals } from '../giftOrders/giftorders.modals';

const mapping = {
  lockUser: LockUser,
  unLockUser: UnLockUser,
  updateRole: UpdateRole,
  changeMePassword: ChangeMePassword,
  addSecureIPs: AddSecureIPs,
  changeUserPassword: ChangeUserPassword,
  deleteUser: DeleteUser,
  lockConsumer: LockConsumer,
  unLockConsumer: UnLockConsumer,
  restoreUser: RestoreUser,
  forgotPassword: ForgotPassword,
  makeTransaction: MakeConsumerTransaction,
  changeCabinetsCount: ChangeCabinetsCount,
  exportLogs: ExportLogs,
  showTextInformation: ShowTextInformation,
  addNewAdditionalMaterial: AddNewAdditionalMaterial,
  confirmInvoice: ConfirmInvoice,
  cancelInvoice: CancelInvoice,
  restoreInvoice: RestoreInvoice,
  deleteFeatureModal: DeleteFeatureModal,
  downloadReportsModal: DownloadReportsModal,
  sendInvoiceModal: SendInvoiceModal,
  makeInvoiceExpiredModal: MakeInvoiceExpiredModal,
  deleteAnnouncementModal: DeleteAnnouncementModal,
  dangerActionsWithCourseOrLesson: DangerActionsWithCourseOrLesson,
  actionWithSettings: ActionWithSettings,
  disableConsumer2FA: DisableConsumer2FA,
  changeSolutionImage: ChangeSolutionImage,
  actionsWithSolutionStrategy: ActionsWithSolutionStrategy,
  actionWithTool: ActionWithTool,
  changeToolImage: ChangeToolImage,
  actionWithSolution: ActionWithSolution,
  updateAssistantInfoModal: UpdateAssistantInfoModal,
  ...giftsModals,
  ...giftOrdersModals,
};

const Modal: FC = () => {
  const dispatch = useAppDispatch();
  const isOpened = useAppSelector(selectors.modalIsOpened);
  const theme = useAppSelector(getTheme);
  const modalType = useAppSelector(selectors.modalType);
  const ModalBody = mapping[modalType as keyof typeof mapping];
  const handleClose = () => {
    dispatch(closeModal());
  };
  if (
    modalType === 'updateTariff' ||
    modalType === 'showTextInformation' ||
    modalType === 'updateCourse' ||
    modalType === 'updateLesson'
  ) {
    return (
      <RootModal
        show={isOpened}
        onHide={handleClose}
        centered
        data-testid="root-modal-body"
        size="xl"
      >
        {ModalBody && <ModalBody theme={theme} />}
      </RootModal>
    );
  }
  return (
    <RootModal show={isOpened} onHide={handleClose} centered data-testid="root-modal-body">
      {ModalBody && <ModalBody theme={theme} />}
    </RootModal>
  );
};

export default Modal;

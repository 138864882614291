import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getTheme } from '../../selectors/selectors';
import { Container, Row, Col } from 'react-bootstrap';
import image from './signIn-preview.svg';
import ImagePreview from '../../UIComponents/ImagePreview';
import Logo from '../../UIComponents/Logo';
import Button from '../../UIComponents/Button';
import { useRestorePasswordMutation } from '../../features/user/user.service';
import { Link, useParams } from 'react-router-dom';
import { isFetchBaseQueryError } from '../../utils/helpers';
import routes from '../../routes';
import { ToastContainer } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ForgotPasswordPage = () => {
  const theme = useAppSelector(getTheme);
  const params = useParams();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [successAnswer, setSuccessAnswer] = useState<boolean>(false);

  const [error, setError] = useState('');
  const [restorePassword] = useRestorePasswordMutation();
  useDocumentTitle('Восстановление пароля');
  const restorePasswordHandler = async () => {
    const { email, code } = params;
    try {
      setDisabled(true);
      const response = await restorePassword({
        email: email as string,
        code: code as string,
      }).unwrap();
      if (response.status) {
        setSuccessAnswer(true);
      }
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 400) {
          setError('Письмо уже было выслано вам на почту. Проверьте почту или попробуйте позже');
        }
        if (error.status === 404) {
          setError('Такой пользователь не найден. Обратитесь пожалуйста к администратору');
        }
        if (error.status === 412) {
          setError('Пароль не может быть изменен. Обратитесь пожалуйста к администратору');
        }
      }
    } finally {
      setDisabled(false);
    }
  };
  return (
    <Container className={`${theme}-root-container`} fluid>
      <Row className="align-items-center justify-content-around h-100">
        <Col
          xs={4}
          md={4}
          lg={4}
          className={`${theme}-secondary-container d-flex flex-column align-items-center justify-content-center h-100 gap-5`}
        >
          <Logo />
          {error && <p className="text-center">{error}</p>}
          {!successAnswer ? (
            <div className="d-flex flex-column w-50 text-center gap-5">
              <span>
                Чтобы сгенерировать новый пароль и получить его на email нажмите кнопку «Сбросить
                пароль»
              </span>
              <Button
                theme={theme}
                text="Сбросить пароль"
                clickHandler={restorePasswordHandler}
                disabled={disabled}
              />
            </div>
          ) : (
            <>
              <span>Новый пароль поступит вам на почту.</span>
              <span>
                <Link to={routes.pages.signIn()}>Вернуться на страницу входа</Link>
              </span>
            </>
          )}
        </Col>
        <Col xs={8} md={8} lg={8} className="d-flex align-items-center justify-content-center">
          <ImagePreview url={image} />
        </Col>
      </Row>
      <ToastContainer autoClose={5000} theme={theme} />
    </Container>
  );
};

export default ForgotPasswordPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type channelState = {
  id: string | null;
  email: string | null;
  name: string | null;
};

const initialState: channelState = {
  id: null,
  email: null,
  name: null,
};

const messageChannelSlice = createSlice({
  name: 'messageChannel',
  initialState,
  reducers: {
    addChannel: (state, action: PayloadAction<channelState>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      localStorage.setItem('activeChannel', JSON.stringify(action.payload));
    },
  },
});

export default messageChannelSlice.reducer;
export const { addChannel } = messageChannelSlice.actions;

import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import { NewTariff, NewTariffFeature, Tariff, TariffFeature } from './tariff.types';

type TariffRequest = PaginationRequest & { status: 'DRAFT' | 'ACTIVE' | 'ARCHIVED' };
type ManipulationsWithTariffResponse = { id: string; status: string };

type FeaturesPaginationRequest = PaginationRequest & {
  tariffId: string;
};

type UpdateTariffLimitsRequest = {
  tariffIntegrationLimits: {
    [key: string]: number;
  };
  tariffSolutionLimits: {
    [key: string]: number;
  };
  tariffToolLimits: {
    [key: string]: boolean;
  };
};

const ratesApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTariffs: builder.query<Tariff[], TariffRequest>({
      query: ({ page, size, status }) => ({
        url: routes.api.tariffs(),
        params: {
          page,
          size,
          status,
        },
      }),
      providesTags: ['Tariff'],
    }),
    createNewTariff: builder.mutation<Tariff, NewTariff>({
      query: (tariff) => ({
        url: routes.api.tariffs(),
        method: 'POST',
        body: tariff,
      }),
      invalidatesTags: ['Tariff'],
    }),
    updateTariffById: builder.mutation<Tariff, { tariff: NewTariff; id: string }>({
      query: ({ tariff, id }) => ({
        url: routes.api.updateTariffById(id),
        method: 'PATCH',
        body: tariff,
      }),
      invalidatesTags: ['Tariff'],
    }),
    restoreTariffById: builder.mutation<ManipulationsWithTariffResponse, string>({
      query: (id) => ({
        url: routes.api.restoreTariffById(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['Tariff'],
    }),
    archivingTariffById: builder.mutation<ManipulationsWithTariffResponse, string>({
      query: (id) => ({
        url: routes.api.archiveTariffById(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['Tariff'],
    }),
    activateTariffById: builder.mutation<ManipulationsWithTariffResponse, string>({
      query: (id) => ({
        url: routes.api.activatedTariffById(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['Tariff'],
    }),
    getTariffById: builder.query<Tariff, string>({
      query: (id) => ({
        url: routes.api.tariffById(id),
      }),
      providesTags: ['Tariff'],
    }),
    deleteTariffById: builder.mutation<ManipulationsWithTariffResponse, string>({
      query: (id) => ({
        url: routes.api.tariffById(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Tariff'],
    }),
    getAllTariffFeatures: builder.query<TariffFeature[], FeaturesPaginationRequest>({
      query: ({ page, size, tariffId }) => ({
        url: routes.api.tariffFeatures(tariffId),
        params: {
          page,
          size,
        },
      }),
      providesTags: ['TariffFeature'],
    }),
    createNewTariffFeature: builder.mutation<
      TariffFeature,
      { feature: NewTariffFeature; tariffId: string }
    >({
      query: ({ feature, tariffId }) => ({
        url: routes.api.tariffFeatures(tariffId),
        method: 'POST',
        body: feature,
      }),
      invalidatesTags: ['TariffFeature'],
    }),
    getFeatureById: builder.query<TariffFeature, { tariffId: string; featureId: string }>({
      query: ({ tariffId, featureId }) => ({
        url: routes.api.tariffFeaturesByid(tariffId, featureId),
      }),
    }),
    deleteFeatureById: builder.mutation<TariffFeature, { tariffId: string; featureId: string }>({
      query: ({ tariffId, featureId }) => ({
        url: routes.api.tariffFeaturesByid(tariffId, featureId),
        method: 'DELETE',
      }),
      invalidatesTags: ['TariffFeature'],
    }),
    updateFeatureById: builder.mutation<
      TariffFeature,
      { feature: NewTariffFeature; tariffId: string; featureId: string }
    >({
      query: ({ feature, tariffId, featureId }) => ({
        url: routes.api.tariffFeaturesByid(tariffId, featureId),
        method: 'PATCH',
        body: feature,
      }),
      invalidatesTags: ['TariffFeature'],
    }),
    setDefaultTariff: builder.mutation<Tariff, string>({
      query: (id) => ({
        url: routes.api.tariffDefault(id),
        method: 'PATCH',
      }),
      invalidatesTags: ['Tariff'],
    }),
    deleteDefaultTariff: builder.mutation<Tariff, string>({
      query: (id) => ({
        url: routes.api.tariffDefault(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Tariff'],
    }),
    updateTariffLimits: builder.mutation<Tariff, { limits: UpdateTariffLimitsRequest; id: string }>(
      {
        query: ({ id, limits }) => ({
          url: routes.api.updateTariffLimits(id),
          method: 'PATCH',
          body: limits,
        }),
      },
    ),
    updateTariffBySuperadmin: builder.mutation<
      Tariff,
      { name: string; description: string; id: string; label?: string | null; teaser: string }
    >({
      query: ({ name, description, label, teaser, id }) => ({
        url: routes.api.updateTariffByAdmin(id),
        method: 'PATCH',
        body: { name, description, label, teaser },
      }),
      invalidatesTags: ['Tariff'],
    }),
  }),
});

export const {
  useLazyGetAllTariffsQuery,
  useCreateNewTariffMutation,
  useUpdateTariffByIdMutation,
  useRestoreTariffByIdMutation,
  useArchivingTariffByIdMutation,
  useActivateTariffByIdMutation,
  useGetTariffByIdQuery,
  useDeleteTariffByIdMutation,
  useGetAllTariffFeaturesQuery,
  useLazyGetAllTariffFeaturesQuery,
  useCreateNewTariffFeatureMutation,
  useGetFeatureByIdQuery,
  useDeleteFeatureByIdMutation,
  useUpdateFeatureByIdMutation,
  useSetDefaultTariffMutation,
  useDeleteDefaultTariffMutation,
  useUpdateTariffLimitsMutation,
  useUpdateTariffBySuperadminMutation,
} = ratesApi;

import React, { FC, useEffect, useState } from 'react';
import { Message } from '../message.types';
import { showDateFromTodayUpdate } from '../../../utils/showDateFromToday';
import { createGravatar } from '../../../utils/createGravatar';
import cls from './MessageItem.module.scss';

type MessageProps = {
  message: Message;
};

const MessageItem: FC<MessageProps> = ({ message }) => {
  const [consumerImage, setConsumerImage] = useState<{ url: string | null; color: string | null }>({
    url: null,
    color: '#eee',
  });
  useEffect(() => {
    const generateImage = async () => {
      if (message) {
        const color = await createGravatar(message?.consumerEmail as string).then((data) => data);
        setConsumerImage(color);
      }
    };
    generateImage();
  }, [message]);
  return (
    <div data-testid="message-item-container" className={cls.Message}>
      <div className={cls.User}>
        <div>
          {consumerImage?.url ? (
            <img
              src={consumerImage?.url as string}
              alt={message.consumerName}
              className={cls.UserImg}
            />
          ) : (
            <div
              data-testid="user-img"
              style={{
                backgroundColor: `#${consumerImage?.color}`,
              }}
              className={cls.UserImg}
            >
              {message.consumerName !== null ? message.consumerName[0].toUpperCase() : 'Λ'}
            </div>
          )}
        </div>
        <div className={cls.Info}>
          <h5 data-testid="user-name" className={`title-5 ${cls.Name}`}>
            {message.consumerName ?? message.consumerEmail}
          </h5>
          <p className={cls.Text}>{message.lastMessage}</p>
        </div>
      </div>
      <div className={cls.DateContainer}>
        <p className={cls.Date}>{showDateFromTodayUpdate(message.lastCreatedAt)}</p>
        {message.countUnread !== 0 && !message.finalMessage && (
          <span className={cls.Unanswered}>{message.countUnread}</span>
        )}
        {!message.finalMessage && message.countUnread === 0 && (
          <span className={cls.Unanswered}></span>
        )}
      </div>
    </div>
  );
};

export default MessageItem;

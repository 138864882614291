export const apiTags = [
  'User',
  'Users',
  'Consumer',
  'Consumers',
  'Message',
  'Messages',
  'Leads',
  'Lead',
  'Operation',
  'Cabinet',
  'Logs',
  'Integrations',
  'SecureIPS',
  'Tariff',
  'TariffFeature',
  'Course',
  'Courses',
  'Lesson',
  'Lessons',
  'Feedback',
  'Invoices',
  'Invoice',
  'Announcements',
  'Announcement',
  'Settings',
  'Setting',
  'Solutions',
  'Solution',
  'Tools',
  'Tool',
  'Strategy',
  'Assistant',
  'Assistants',
  'Gifts',
  'Gift',
  'GiftOrder',
];

import React, { FC, memo } from 'react';
import { FloatingLabel, FormControl, FormGroup, FormSelect } from 'react-bootstrap';
import EditorWrapper from '../../editor';
import cls from './CreateTariffFormValuePart.module.scss';

type CreateTariffFormValuePartProps = {
  nameValue: string;
  costValue: number;
  labelValue: string | undefined | null;
  nameError: string | undefined;
  typeValue: string;
  teaser: string | undefined;
  teaserError: string | undefined;
  typeError: string | undefined;
  descriptionValue: string | undefined;
  setText: React.Dispatch<React.SetStateAction<string>>;
  onChange: (event: React.ChangeEvent) => void;
  isNew: boolean;
};

const CreateTariffFormValuePart: FC<CreateTariffFormValuePartProps> = memo((props) => {
  const {
    nameValue,
    nameError,
    onChange,
    costValue,
    isNew,
    typeValue,
    typeError,
    descriptionValue,
    setText,
    labelValue,
    teaser,
    teaserError,
  } = props;
  return (
    <div className={cls.TariffData}>
      <h4 className={`title-4 ${cls.Title}`}>Данные тарифа</h4>
      <div>
        <div className="d-flex justify-content-between gap-3 mb-3">
          <FormGroup className={`${cls.InputWrapper} w-50`}>
            <FloatingLabel controlId="name" label="Название тарифа*">
              <FormControl
                name="name"
                onChange={onChange}
                value={nameValue}
                isInvalid={!!nameError}
                className={cls.FormField}
              />
            </FloatingLabel>
            {nameError && <span className={cls.Feedback}>{nameError}</span>}
          </FormGroup>
          <FormGroup className={`${cls.InputWrapper} w-25`}>
            <FloatingLabel controlId="cost" label="Стоимость">
              <FormControl
                type="number"
                name="cost"
                onChange={onChange}
                value={isNew ? costValue : (Number(costValue) / 100).toFixed(2)}
                disabled={!isNew}
                className={cls.FormField}
              />
            </FloatingLabel>
          </FormGroup>
          <FormGroup className={`${cls.InputWrapper} w-25`}>
            <FloatingLabel controlId="cost" label="Лейбл">
              <FormControl
                type="text"
                name="label"
                onChange={onChange}
                value={labelValue ?? ''}
                className={cls.FormField}
              />
            </FloatingLabel>
          </FormGroup>
          <FormGroup className={`${cls.InputWrapper} w-25`}>
            <FloatingLabel controlId="type" label="Тип тарифа*">
              <FormSelect
                name="type"
                onChange={onChange}
                value={typeValue}
                isInvalid={!!typeError}
                disabled={!isNew}
                className={cls.FormField}
              >
                <option>Выберите тип</option>
                <option value="USER">Пользователь</option>
                <option value="SYSTEM">Системный</option>
              </FormSelect>
            </FloatingLabel>
            {typeError && <span className={cls.Feedback}>{typeError}</span>}
          </FormGroup>
        </div>
        <div>
          <FormGroup className="position-relative">
            <FloatingLabel controlId="teaser" label="Короткое описание для превью*">
              <FormControl
                type="text"
                name="teaser"
                onChange={onChange}
                value={teaser}
                isInvalid={!!teaserError}
                className={`${cls.FormField} ${cls.FormFieldTeaser}`}
                as="textarea"
              />
            </FloatingLabel>
            {teaserError && <span className={cls.Feedback}>{teaserError}</span>}
          </FormGroup>
        </div>
        <div className="mt-3">
          <h4 className={`title-4 ${cls.Title}`}>Описание</h4>
          <EditorWrapper setText={setText} value={descriptionValue ?? ''} />
        </div>
      </div>
    </div>
  );
});

export default CreateTariffFormValuePart;

import { AuthResponce } from './../../features/autorization/authorization.types';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';
import { logIn, logOut } from '../slices/authSlice';
import routes from '../../routes';
import { Mutex } from 'async-mutex';
import { apiTags } from '../apiTags';

const mutex = new Mutex();

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: routes.api.basePath(),
    prepareHeaders: (headers, { endpoint }) => {
      const accessToken = JSON.parse(localStorage.getItem('banditto') as string)?.accessToken;
      const refreshToken = JSON.parse(localStorage.getItem('banditto') as string)?.refreshToken;
      if (endpoint === 'refresh' && refreshToken) {
        headers.set('authorization', `Bearer ${refreshToken}`);
      } else if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  {
    maxRetries: 0,
  },
);

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 504) {
    const result = await baseQuery(
      args,
      { ...api, signal: new AbortController().signal },
      { maxRetries: 10 },
    );
    if (result.data) {
      return result;
    }
  }
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: routes.api.refreshToken(),
          },
          { ...api, endpoint: 'refresh' },
          extraOptions,
        );
        if (refreshResult.data) {
          api.dispatch(logIn(refreshResult?.data as AuthResponce));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const bandittoApi = createApi({
  reducerPath: 'bandittoApi',
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  tagTypes: apiTags,
  endpoints: () => ({}),
});

import React, { FC, memo } from 'react';
import { LogsSearch } from '../logs.types';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import cls from './LogsFilters.module.scss';
import { MdOutlineClose } from 'react-icons/md';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { clearLogsFieldSearch } from '../../../store/slices/logsFilterSlice';

type LogsFiltersProps = {
  filters: LogsSearch & {
    objectEmail: string;
  };
};

const LogsFilters: FC<LogsFiltersProps> = memo((props) => {
  const { filters } = props;
  const dispatch = useAppDispatch();
  const checkDateEqual = (start: string, end: string) => {
    if (start !== '' && end !== '') {
      const startWithoutTime = start.split('T')[0];
      const endWithoutTime = end.split('T')[0];
      return startWithoutTime === endWithoutTime;
    }
    return;
  };
  const deleteFilterField = (field: keyof LogsSearch) => {
    if (field === 'startDateTime') {
      dispatch(clearLogsFieldSearch({ key: 'startDateTime' }));
      dispatch(clearLogsFieldSearch({ key: 'endDateTime' }));
    } else if (field === 'ip') {
      dispatch(clearLogsFieldSearch({ key: field }));
    } else if (field === 'objectId') {
      dispatch(clearLogsFieldSearch({ key: field }));
    }
  };
  return (
    <div className={cls.LogsFilters}>
      {checkDateEqual(filters.startDateTime, filters.endDateTime) && filters.startDateTime && (
        <div className={cls.Item}>
          <span>
            Дата: {convertDateToNeedFormat({ date: filters.startDateTime, format: 'date' })}
          </span>
          <button
            className={cls.DeleteBtn}
            onClick={() => deleteFilterField('startDateTime')}
            type="button"
          >
            <MdOutlineClose />
          </button>
        </div>
      )}
      {!checkDateEqual(filters.startDateTime, filters.endDateTime) && filters.startDateTime && (
        <div className={cls.Item}>
          <span>
            Временной период:{' '}
            {convertDateToNeedFormat({ date: filters.startDateTime, format: 'date' })} -{' '}
            {convertDateToNeedFormat({ date: filters.endDateTime, format: 'date' })}
          </span>
          <button
            className={cls.DeleteBtn}
            onClick={() => deleteFilterField('startDateTime')}
            type="button"
          >
            <MdOutlineClose />
          </button>
        </div>
      )}
      {filters.ip && (
        <div className={cls.Item}>
          <span>IP адрес: {filters.ip}</span>
          <button className={cls.DeleteBtn} onClick={() => deleteFilterField('ip')} type="button">
            <MdOutlineClose />
          </button>
        </div>
      )}
      {filters.objectId && (
        <div className={cls.Item}>
          <span>Аккаунт: {filters.objectEmail}</span>
          <button
            className={cls.DeleteBtn}
            onClick={() => deleteFilterField('objectId')}
            type="button"
          >
            <MdOutlineClose />
          </button>
        </div>
      )}
    </div>
  );
});

export default LogsFilters;

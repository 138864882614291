import React, { FC, useState, useEffect } from 'react';
import { useGetAllUsersQuery } from '../user.service';
import { User } from '../user.types';
import Spinner from '../../../UIComponents/Spinner';
import { classNameMerge } from '../../../utils/classNameMerge';
import cls from './UsersList.module.scss';
import UsersListHeader from '../UsersListHeader';
import UserPreviewItem from '../UserPreviewItem';

type UsersListProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setExportsPage: React.Dispatch<React.SetStateAction<number>>;
  exportsSize: number;
  exportsPage: number;
  className?: string;
};

const UsersList: FC<UsersListProps> = ({
  page,
  setPage,
  setExportsPage,
  exportsSize,
  exportsPage,
  className,
}) => {
  const [size, setSize] = useState<number>(10);
  const { data: users, isLoading } = useGetAllUsersQuery({ page, size });
  const [actualList, setActualList] = useState<User[]>([]);
  useEffect(() => {
    if (window.screen.height >= 1070) {
      setSize(20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);
  useEffect(() => {
    if (users) {
      const uniqueUsers = users.filter((user) => !actualList.some((item) => item.id === user.id));
      setActualList([...actualList, ...uniqueUsers]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.users-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      users
    ) {
      if (actualList.length >= exportsSize) setExportsPage(++exportsPage);
      setPage(++page);
    }
  };
  if (isLoading)
    return (
      <div className={classNameMerge(cls.List, className)}>
        <Spinner />
      </div>
    );
  if (!isLoading && users?.length === 0 && actualList.length === 0)
    return (
      <div className={classNameMerge(cls.ListEmpty, className)}>
        <div className={cls.EmptyText}>
          <h2 className="title-2">Поиск не дал результатов</h2>
        </div>
      </div>
    );
  return (
    <div className={classNameMerge(cls.List, className)}>
      <UsersListHeader />
      <div data-testid="users-list" className={`${cls.Users} users-list`} onScroll={scrollHandler}>
        {actualList.map((user) => (
          <UserPreviewItem key={user.id} user={user} />
        ))}
      </div>
    </div>
  );
};

export default UsersList;

import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import routes from '../../../routes';
import { logIn } from '../../../store/slices/authSlice';
import Button from '../../../UIComponents/Button';
import { isFetchBaseQueryError } from '../../../utils/helpers';
import { useSignInMutation } from '../authorization.service';
import { AuthFormsProps, initialValues } from '../authorization.types';
import { toast } from 'react-toastify';
import { MdLogin } from 'react-icons/md';

interface SignInFormProps extends AuthFormsProps {
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  error: string;
}

const SignInForm: FC<SignInFormProps> = ({
  navigate,
  theme,
  setForgotPassword,
  setUserEmail,
  setError,
  error,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [signIn] = useSignInMutation();
  const dispatch = useAppDispatch();
  const validationSchema = yup
    .object()
    .strict()
    .shape({
      email: yup
        .string()
        .email('Введен не корректный email')
        .required('Поле является обязательным')
        .trim(),
      password: yup
        .string()
        .min(8, 'Длина пароля должна быть не меньше 8 символов')
        .max(30, 'Длина пароля не должна быть больше 30 символов')
        .required('Поле является обязательным')
        .trim(),
    });
  const initialValues: initialValues = {
    email: '',
    password: '',
  };
  const notification = () => toast.success('Добро пожаловать');
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { email, password } = values;
      try {
        setDisabled(true);
        const response = await signIn({ email: email.toLocaleLowerCase(), password }).unwrap();
        notification();
        toast.success('Добро пожаловать');
        dispatch(logIn(response));
        navigate(routes.pages.mainPage());
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          if (error.status === 401) {
            setError('Вы ввели неверные логин или пароль');
            toast.error('Вы ввели неверные логин или пароль');
            setUserEmail(email);
            setForgotPassword(true);
          }
          if (error.status === 403) {
            setError('Аккаунт временно заблокирован. Обратитесь к администратору');
            toast.error('Аккаунт временно заблокирован. Обратитесь к администратору');
          }
          if (error.status === 404) {
            setError('Пользователь с такими данными не найден');
            toast.error('Пользователь с такими данными не найден');
          }
          if (error.status === 423) {
            setError('Использование данного IP запрещено, обратитесь к администратору');
            toast.error('Использование данного IP запрещено, обратитесь к администратору');
          }
        }
      } finally {
        setDisabled(false);
      }
    },
  });

  const onChangeHandle = (event: React.ChangeEvent) => {
    formik.handleChange(event);
    formik.setErrors({});
    setError('');
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Form
        data-testid="sign-in-form"
        className="d-flex flex-column justify-content-center gap-3"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        {error && <div className="text-danger">{error}</div>}
        <Form.Group className="position-relative mt-3">
          <Form.Control
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={onChangeHandle}
            className={`${theme}-input border-5 border-0`}
            isInvalid={!!formik.errors.email}
            value={formik.values.email}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="position-relative">
          <Form.Control
            type="password"
            name="password"
            id="password"
            className={`${theme}-input border-5 border-0`}
            placeholder="Пароль"
            onChange={onChangeHandle}
            isInvalid={!!formik.errors.password}
            value={formik.values.password}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
        </Form.Group>
        <Button
          text="Войти"
          Icon={<MdLogin size="1.5em" />}
          type="submit"
          disabled={disabled}
          theme={theme}
        />
        <div className="links__part text-center">
          <p>
            Нет аккаунта?{' '}
            <span>
              <Link to={routes.pages.signUp()}>Регистрация</Link>
            </span>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default SignInForm;

import React, { useState, useEffect } from 'react';
import LogsList from '../../features/log/LogsList';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getLogsFilters } from '../../selectors/selectors';
import { useGetAllLogsQuery } from '../../features/log/logs.service';
import { Log, LogsSearch } from '../../features/log/logs.types';
import routes from '../../routes';
import { convertBinaryToLink } from '../../utils/converBinaryToLink';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './LogsPage.module.scss';
import DownloadReportsButton from '../../UIComponents/DownloadReportsButton/DownloadReportsButton';
import LogsPanel from '../../features/log/LogsPanel';

const LogsPage = () => {
  const [page, setPage] = useState(0);
  const searchStore = useAppSelector(getLogsFilters);
  const [scrollLogsList, setScrollLogsList] = useState<Log[]>([]);
  const [exportsPage, setExportsPage] = useState<number>(0);
  const size = window.screen.height >= 1070 ? 20 : 10;
  const exportsSize = 11000;
  const [search, setSearch] = useState<LogsSearch>({
    startDateTime: '',
    endDateTime: '',
    objectId: '',
    ip: '',
  });
  const { data: logsList, isLoading } = useGetAllLogsQuery({
    page,
    size,
    ...search,
  });
  useDocumentTitle('Журнал логов');
  const downloadLogsHandler = () => {
    const download = `${routes.api.basePath()}${routes.api.logsExport()}`;
    convertBinaryToLink(download, {
      ...search,
      page: exportsPage,
      size: exportsSize,
    });
  };
  useEffect(() => {
    if (searchStore) {
      setScrollLogsList([]);
      setSearch(searchStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStore]);
  return (
    <div data-testid="logs-container" className={`${cls.Container} grid-container`}>
      <h2 className={`${cls.Title} title-1`}>Логи</h2>
      <div className={cls.InfoPanel}>
        <LogsPanel
          search={search}
          setSearch={setSearch}
          setScrollLogsList={setScrollLogsList}
          setPage={setPage}
        />
        <DownloadReportsButton clickHandler={downloadLogsHandler} />
      </div>
      <LogsList
        scrollLogsList={scrollLogsList}
        setScrollLogsList={setScrollLogsList}
        logsList={logsList}
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        setSearch={setSearch}
        search={search}
        exportsPage={exportsPage}
        exportsSize={exportsSize}
        setExportsPage={setExportsPage}
        className={cls.Content}
      />
    </div>
  );
};

export default LogsPage;

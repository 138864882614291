// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n7IJVy7gVjESi_sh1OBN{display:flex;gap:.5rem}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerPanel/ConsumerPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,SAAA","sourcesContent":[".Panel {\n  display: flex;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Panel": `n7IJVy7gVjESi_sh1OBN`
};
export default ___CSS_LOADER_EXPORT___;

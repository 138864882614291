import React, { useEffect, useState } from 'react';
import cls from './ChangeToolImage.module.scss';
import { Modal, FloatingLabel, FormControl, Button } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useAppSelector, useAppDispatch } from '../../../hooks/defaultHooks';
import { modalExtra } from '../../../selectors/selectors';
import { closeModal } from '../../../store/slices/modalSlice';

const ChangeToolImage = () => {
  const extraModal = useAppSelector(modalExtra);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const [image, setImage] = useState<string>(extraModal?.link ?? '');
  const [disabled, setDisabled] = useState<boolean>(true);
  const saveHandler = () => {
    if (extraModal?.imagesHandler) {
      extraModal.imagesHandler(image);
      closeHandler();
    }
  };
  useEffect(() => {
    if (extraModal?.link === image || image === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [extraModal?.link, image]);
  return (
    <>
      <Modal.Header className={cls.ModalHeader} closeButton>
        <h3 className="title-3">{extraModal?.title}</h3>
      </Modal.Header>
      <Modal.Body className={cls.ModalBody}>
        <div className={cls.InputWithClear}>
          <FloatingLabel label="Ссылка на изображение" className="w-100">
            <FormControl
              type="text"
              name="name"
              id="name"
              onChange={(event) => setImage(event.target.value)}
              value={image}
              className="w-100"
            />
          </FloatingLabel>
          {extraModal?.link && <MdClose className={cls.ClearButton} onClick={() => setImage('')} />}
        </div>
        <div className={cls.Buttons}>
          <Button
            type="button"
            onClick={saveHandler}
            className={disabled ? `${cls.Button} ${cls.DisabledButton}` : cls.Button}
            disabled={disabled}
          >
            {extraModal?.actionButtonText}
          </Button>
          <Button onClick={closeHandler} className={cls.CancelButton}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default ChangeToolImage;

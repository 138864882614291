import React, { FC, memo } from 'react';
import { ConsumerBilling } from '../consumer.types';
import cls from './ConsumerOperationItem.module.scss';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';

type ConsumerOperationItemProps = {
  operation: ConsumerBilling;
};

const ConsumerOperationItem: FC<ConsumerOperationItemProps> = memo((props) => {
  const { operation } = props;
  return (
    <li className={cls.OperationItem}>
      <span>{convertDateToNeedFormat({ date: operation.createdAt, format: 'datetime' })}</span>
      <span
        className={operation.type === 'INCOMING' ? cls.IncomingOperation : cls.OutgoingOperations}
      >
        {operation.type === 'INCOMING' ? 'Пополнение' : 'Списание'}
      </span>
      <span>
        {operation.type === 'INCOMING' ? '+' : '-'}
        {(operation.amount / 100).toFixed(2)} &#x20bd;
      </span>
    </li>
  );
});

export default ConsumerOperationItem;

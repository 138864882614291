// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baK8459039ChUGwpz61E{padding-top:2.5rem;padding-left:2rem;padding-right:2rem;padding-bottom:2rem;background-color:var(--offcanvas-bg-color);display:flex;flex-direction:column;gap:1rem;height:120%;overflow-y:auto}.oo40Pp2HNzLPKVsvvi3Q{height:fit-content}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerCard/ConsumerCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,mBAAA,CACA,0CAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CAEF,sBACE,kBAAA","sourcesContent":[".Container {\n  padding-top: 2.5rem;\n  padding-left: 2rem;\n  padding-right: 2rem;\n  padding-bottom: 2rem;\n  background-color: var(--offcanvas-bg-color);\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  height: 120%;\n  overflow-y: auto;\n}\n.EmptyList {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `baK8459039ChUGwpz61E`,
	"EmptyList": `oo40Pp2HNzLPKVsvvi3Q`
};
export default ___CSS_LOADER_EXPORT___;

import { LogsSearch } from '../../features/log/logs.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { [key in keyof LogsSearch]: string } & {
  objectEmail: string;
} = {
  objectId: '',
  ip: '',
  startDateTime: '',
  endDateTime: '',
  objectEmail: '',
};

const logsFilterSlice = createSlice({
  name: 'logsFilter',
  initialState,
  reducers: {
    setLogsFieldSearch: (state, action: PayloadAction<{ key: string; value: string }>) => {
      if (action.payload.key === 'startDateTime') {
        state.startDateTime = action.payload.value;
      } else if (action.payload.key === 'endDateTime') {
        state.endDateTime = action.payload.value;
      } else if (action.payload.key === 'objectId') {
        state.objectId = action.payload.value;
      } else if (action.payload.key === 'ip') {
        state.ip = action.payload.value;
      } else if (action.payload.key === 'objectEmail') {
        state.objectEmail = action.payload.value;
      }
    },
    clearLogsFieldSearch: (state, action: PayloadAction<{ key: string }>) => {
      state[action.payload.key as keyof typeof state] = '';
    },
  },
});

export const { setLogsFieldSearch, clearLogsFieldSearch } = logsFilterSlice.actions;

export default logsFilterSlice.reducer;

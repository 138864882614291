import React, { FC, memo } from 'react';
import { FormGroup, FormLabel, FormCheck } from 'react-bootstrap';
import cls from './CreateTariffFormSettings.module.scss';
type CreateTariffFormSettingsProps = {
  isNew: boolean;
  onChange: (event: React.ChangeEvent) => void;
  refundableValue: boolean;
  convertibleValue: boolean;
  renewableValue: boolean;
};

const CreateTariffFormSettings: FC<CreateTariffFormSettingsProps> = memo((props) => {
  const { isNew, onChange, refundableValue, convertibleValue, renewableValue } = props;
  return (
    <div className={cls.Container}>
      <h4 className="title-4">Настройки тарифа</h4>
      <div className={cls.SettingsSwitches}>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <FormLabel htmlFor="refundable" className="input-title-text">
            Возвратный
          </FormLabel>
          <FormCheck
            type="switch"
            name="refundable"
            id="refundable"
            onChange={onChange}
            checked={refundableValue}
            disabled={!isNew}
          />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <FormLabel htmlFor="convertable" className="input-title-text">
            Конвертируемый
          </FormLabel>
          <FormCheck
            type="switch"
            name="convertable"
            id="convertable"
            onChange={onChange}
            checked={convertibleValue}
            disabled={!isNew}
          />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <FormLabel htmlFor="renewable" className="input-title-text">
            Возобновляемый
          </FormLabel>
          <FormCheck
            type="switch"
            name="renewable"
            id="renewable"
            onChange={onChange}
            checked={renewableValue}
            disabled={!isNew}
          />
        </FormGroup>
      </div>
    </div>
  );
});

export default CreateTariffFormSettings;

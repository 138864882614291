// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t6m_ASN1cKdwptLgNhGg{display:flex;height:40px;align-items:center;padding:.25rem .75rem .25rem 1rem;min-width:fit-content;gap:2px}.t6m_ASN1cKdwptLgNhGg:hover{cursor:pointer;outline:2px solid var(--hovered-filter-border);border-radius:2rem}.RLdPtVcf7wTWXb4lRSGM{display:flex;align-items:center}.RLdPtVcf7wTWXb4lRSGM p{margin:0;padding:0}.ML8QhbtwcsJcKfmMyp7U{border-radius:2rem;outline:2px solid var(--active-filter-border)}.ML8QhbtwcsJcKfmMyp7U:hover{outline:2px solid var(--active-filter-border)}.Pycsp9yCsuMaug22nGgi{white-space:nowrap}.PJeMEMz1DuhDZxHGWt7y{background-color:rgba(0,0,0,0);border:none;outline:none}.PJeMEMz1DuhDZxHGWt7y:hover{cursor:pointer;border:none;outline:none}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerPanelItem/ConsumerPanelItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CACA,iCAAA,CACA,qBAAA,CACA,OAAA,CACA,4BACE,cAAA,CACA,8CAAA,CACA,kBAAA,CAGJ,sBACE,YAAA,CACA,kBAAA,CACA,wBACE,QAAA,CACA,SAAA,CAGJ,sBACE,kBAAA,CACA,6CAAA,CACA,4BACE,6CAAA,CAGJ,sBACE,kBAAA,CAEF,sBACE,8BAAA,CACA,WAAA,CACA,YAAA,CACA,4BACE,cAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".ConsumerItem {\n  display: flex;\n  height: 40px;\n  align-items: center;\n  padding: 0.25rem 0.75rem 0.25rem 1rem;\n  min-width: fit-content;\n  gap: 2px;\n  &:hover {\n    cursor: pointer;\n    outline: 2px solid var(--hovered-filter-border);\n    border-radius: 2rem;\n  }\n}\n.Item {\n  display: flex;\n  align-items: center;\n  & p {\n    margin: 0;\n    padding: 0;\n  }\n}\n.ConsumerPanelItemActive {\n  border-radius: 2rem;\n  outline: 2px solid var(--active-filter-border);\n  &:hover {\n    outline: 2px solid var(--active-filter-border);\n  }\n}\n.NoBreakWord {\n  white-space: nowrap;\n}\n.DeleteBtn {\n  background-color: transparent;\n  border: none;\n  outline: none;\n  &:hover {\n    cursor: pointer;\n    border: none;\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConsumerItem": `t6m_ASN1cKdwptLgNhGg`,
	"Item": `RLdPtVcf7wTWXb4lRSGM`,
	"ConsumerPanelItemActive": `ML8QhbtwcsJcKfmMyp7U`,
	"NoBreakWord": `Pycsp9yCsuMaug22nGgi`,
	"DeleteBtn": `PJeMEMz1DuhDZxHGWt7y`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import CreateOrChangeTariffFeatureForm from '../../features/tariff/CreateOrChangeTariffFeatureForm';
import {
  useCreateNewTariffFeatureMutation,
  useGetTariffByIdQuery,
} from '../../features/tariff/tariff.service';
import { NewTariffFeature } from '../../features/tariff/tariff.types';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import routes from '../../routes';
import { isFetchBaseQueryError } from '../../utils/helpers';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './NewTariffFeaturePage.module.scss';
import Breadcrumbs from '../../UIComponents/Breadcrumbs';
const NewTariffFeaturePage = () => {
  const { tariffId } = useParams();
  const initialValues = {
    name: '',
    description: '',
    priority: 0,
    show: false,
    icon: '',
    colorIcon: '',
  };
  const navigate = useNavigate();
  const [createNewFeature] = useCreateNewTariffFeatureMutation();
  const { data: tariff } = useGetTariffByIdQuery(tariffId as string);
  const createFeatureHandler = async (feature: NewTariffFeature) => {
    console.log('feature');
    try {
      await createNewFeature({ feature: feature, tariffId: tariffId as string }).unwrap();
      toast.success('Фича успешно создана');
      navigate(routes.pages.tariffPageById(tariffId as string));
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 404) toast.error('Тариф не найден');
        if (error.status === 422) toast.error('Не получилось добавить новую фичу');
        if (error.status === 401) toast.error('Повторите попытку позже');
      }
    }
  };
  useDocumentTitle('Создать новую фичу');
  return (
    <div data-testid="new-tariff-feature" className={`${cls.Container} grid-container`}>
      <Breadcrumbs
        elements={[
          { name: 'Тарифы', link: routes.pages.tariffsPage(), active: false },
          {
            name: tariff?.name ?? '',
            link: routes.pages.tariffPageById(tariffId ?? ''),
            active: false,
          },
          { name: 'Добавить фичу', link: '', active: true },
        ]}
        className={cls.BreadCrumbs}
      />
      <h2 className={`title-1 ${cls.Title}`} data-testid="new-tariff-feature-title">
        Добавить фичу
      </h2>
      <CreateOrChangeTariffFeatureForm
        initialValues={initialValues}
        submitHandler={createFeatureHandler}
        className={cls.Form}
      />
    </div>
  );
};

export default NewTariffFeaturePage;

import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SignUpForm from '../../features/autorization/SignUpForm/SignUpForm';
import { useAppSelector } from '../../hooks/defaultHooks';
import { getTheme } from '../../selectors/selectors';
import ImagePreview from '../../UIComponents/ImagePreview';
import Logo from '../../UIComponents/Logo';
import image from './signUp-preview.svg';
import { ToastContainer } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const SignUpPage: FC = () => {
  const navigate = useNavigate();
  const theme = useAppSelector(getTheme);
  useDocumentTitle('Регистрация');
  return (
    <Container className={`${theme}-root-container`} fluid>
      <Row className="align-items-center justify-content-around h-100">
        <Col
          xs={4}
          md={4}
          lg={4}
          className={`${theme}-secondary-container d-flex flex-column align-items-center justify-content-center h-100`}
        >
          <Logo />
          <h2 className="my-5">Зарегистрироваться</h2>
          <SignUpForm navigate={navigate} theme={theme} />
        </Col>
        <Col xs={8} md={8} lg={8} className="d-flex align-items-center justify-content-center">
          <ImagePreview url={image} />
        </Col>
      </Row>
      <ToastContainer autoClose={5000} theme={theme} />
    </Container>
  );
};

export default SignUpPage;

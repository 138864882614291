import React, { FC, memo } from 'react';
import { Consumer } from '../consumer.types';
import cls from './ConsumerPanelItem.module.scss';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { deleteConsumerFilter } from '../../../store/slices/consumerFilterSlice';
import { MdOutlineClose } from 'react-icons/md';

type ConsumerPanelItemProps = {
  filter: {
    type: string | null;
    value: string | null;
    active: boolean | null;
  };
  setSearch: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number;
    }>
  >;
  search: { [key: string]: string | number };
  setScrollConsumers: React.Dispatch<React.SetStateAction<Consumer[]>>;
};

const ConsumerPanelItem: FC<ConsumerPanelItemProps> = memo((props) => {
  const { filter, setSearch, search, setScrollConsumers } = props;
  const dispatch = useAppDispatch();
  const deleteFilterField = (value: string) => {
    if (value) {
      const filteredSearch = { ...search, filterField: 'email', filter: '' };
      setSearch({ ...filteredSearch });
      setScrollConsumers([]);
      dispatch(deleteConsumerFilter());
    }
  };
  const convertFilterToText = (type: string) => {
    switch (type) {
      case 'email':
        return 'Email';
      case 'name':
        return 'Имя';
      case 'phone':
        return 'Номер телефона';
    }
  };
  if (!filter.value) return null;
  return (
    <div
      className={
        filter.active ? `${cls.ConsumerItem} ${cls.ConsumerPanelItemActive}` : cls.ConsumerItem
      }
    >
      <div className={cls.Item}>
        <p>{convertFilterToText(filter.type ?? '')}:&nbsp;</p>
        <p className={cls.NoBreakWord}>{filter.value}</p>
      </div>
      {filter.active && (
        <button
          className={cls.DeleteBtn}
          onClick={() => deleteFilterField(filter?.value ?? '')}
          type="button"
        >
          <MdOutlineClose />
        </button>
      )}
    </div>
  );
});

export default ConsumerPanelItem;

import React, { useEffect, useState, FC } from 'react';
import {
  FloatingLabel,
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import { NewTariffFeature } from '../tariff.types';
import EditorWrapper from '../../editor';
import * as yup from 'yup';
import cls from './CreateOrChangeTariffFeatureForm.module.scss';
import { useNavigate } from 'react-router-dom';

type CreateOrChangeTariffFeatureFormProps = {
  initialValues: {
    name: string;
    description: string;
    priority: number;
    show: boolean;
    icon: string;
    colorIcon: string;
  };
  submitHandler: (feature: NewTariffFeature) => Promise<void>;
  className: string;
};

const CreateOrChangeTariffFeatureForm: FC<CreateOrChangeTariffFeatureFormProps> = ({
  initialValues,
  submitHandler,
  className,
}) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const [text, setText] = useState<string>(initialValues.description ?? '');
  const [type, setType] = useState<'success' | 'error' | ''>('');
  const validationSchema = yup.object().shape({
    name: yup.string().required('Поле является обязательным'),
    priority: yup.number().min(0, 'Минимальное значение 0'),
  });
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (e: any) => {
      if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener('focusin', handler);
    return () => document.removeEventListener('focusin', handler);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (type === 'success') {
        const newFeature = {
          ...values,
          priority: Number(values.priority),
          description: text,
          icon: 'success',
          colorIcon: 'green',
        };
        submitHandler(newFeature);
      } else if (type === 'error') {
        const newFeature = {
          ...values,
          priority: Number(values.priority),
          description: text,
          icon: 'error',
          colorIcon: 'red',
        };
        submitHandler(newFeature);
      }
    },
  });
  useEffect(() => {
    if (
      formik.values.name === initialValues.name &&
      formik.values.priority === initialValues.priority &&
      text === initialValues.description &&
      type === initialValues.icon &&
      formik.values.show === initialValues.show
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    formik.values.name,
    formik.values.priority,
    formik.values.show,
    text,
    type,
    initialValues,
    setDisabled,
  ]);
  useEffect(() => {
    if (initialValues.icon === 'success') {
      setType('success');
    } else if (initialValues.icon === 'error') {
      setType('error');
    }
  }, [initialValues.icon]);
  return (
    <Form
      className={`${className} py-3 pr-5 d-flex flex-column gap-3`}
      onSubmit={formik.handleSubmit}
      data-testid="feature-form"
      noValidate
    >
      <div className={cls.InputsWrapper}>
        <div className={cls.InputWrapper}>
          <FloatingLabel controlId="name" label="Название фичи*">
            <FormControl
              type="text"
              onChange={formik.handleChange}
              name="name"
              value={formik.values.name}
              data-testid="feature-preview"
              isInvalid={!!formik.errors.name}
            />
          </FloatingLabel>
          {formik.errors.name && (
            <span data-testid="feature-name-error" className={cls.Feedback}>
              {formik.errors.name}
            </span>
          )}
        </div>

        <div className={cls.InputWrapper}>
          <FloatingLabel controlId="priority" label="Приоритет">
            <FormControl
              type="text"
              onChange={formik.handleChange}
              name="priority"
              value={formik.values.priority}
              data-testid="feature-priority"
              isInvalid={!!formik.errors.priority}
            />
          </FloatingLabel>
          {formik.errors.priority && (
            <span data-testid="feature-name-error" className={cls.Feedback}>
              Значение должно быть числом
            </span>
          )}
        </div>
        <FloatingLabel controlId="type" label="Тип фичи*" className={cls.InputWrapper}>
          <FormSelect
            name="type"
            onChange={(e) => setType(e.target.value as 'success' | 'error')}
            value={type}
          >
            <option>Выберите тип</option>
            <option value="success">Позитивная</option>
            <option value="error">Негативная</option>
          </FormSelect>
        </FloatingLabel>
        <FormGroup className={cls.Switcher}>
          <FormCheck
            type="switch"
            checked={formik.values.show}
            onChange={() => formik.setFieldValue('show', !formik.values.show)}
            data-testid="feature-show"
            id="show"
          />
          <FormLabel htmlFor="show" className="regular-text mt-2">
            Будет видно в карточке
          </FormLabel>
        </FormGroup>
      </div>
      <div className={cls.TextareaWrapper}>
        <EditorWrapper setText={setText} value={text} />
      </div>
      <div className="d-flex gap-3">
        <button
          className={
            !disabled ? cls.ActionButton : `${cls.ActionButton} ${cls.ActionButtonDisabled}`
          }
          type="submit"
          disabled={disabled}
        >
          Сохранить
        </button>
        <button
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          className={cls.RevokeButton}
        >
          Отмена
        </button>
      </div>
    </Form>
  );
};

export default CreateOrChangeTariffFeatureForm;

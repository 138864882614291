import React, { FC } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { themes } from '../../store/slices/themeSlice';
import { IconType } from 'react-icons';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  clickHandler?: () => void;
  disabled?: boolean;
  Icon?: React.ReactComponentElement<IconType, IconType>;
  theme?: themes;
  variant?: string;
}

export const Button: FC<ButtonProps> = ({
  text,
  clickHandler,
  disabled,
  type = 'button',
  theme,
  Icon,
  className,
  variant,
  ...props
}) => {
  return (
    <BootstrapButton
      variant={variant ?? 'none'}
      className={`${theme}-button ${className ? className : ''} ${Icon ? 'button--with-icon' : ''}`}
      onClick={clickHandler}
      disabled={disabled}
      type={type}
      {...props}
    >
      <span className="button__icon">{Icon}</span>
      <span className="text-center">{text}</span>
    </BootstrapButton>
  );
};

export default Button;

import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import { CreateOrUpdateGiftType, GiftType } from './gifts.types';

type UpdateGiftRequest = {
  id: string;
  gift: CreateOrUpdateGiftType;
};

type GetGiftsWithPaginationAndSortingRequest = PaginationRequest & {
  name?: string;
  enabled?: boolean;
  sortField?: string;
  sortOrder?: 0 | 1;
};

const giftsApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getGiftById: builder.query<GiftType, string>({
      query: (id) => routes.api.getGiftById(id),
      providesTags: ['Gift'],
    }),
    updateGift: builder.mutation<GiftType, UpdateGiftRequest>({
      query: ({ id, gift }) => ({
        url: routes.api.updateGiftById(id),
        method: 'PUT',
        body: gift,
      }),
      invalidatesTags: ['Gift'],
    }),
    getGifts: builder.query<
      { countAll: number; gifts: GiftType[] },
      GetGiftsWithPaginationAndSortingRequest
    >({
      query: ({
        page = 0,
        size = 10,
        name = '',
        enabled = true,
        sortField = 'cost',
        sortOrder = 1,
      }) => ({
        url: routes.api.getAllGifts(),
        params: {
          page,
          size,
          name,
          enabled,
          sortField,
          sortOrder,
        },
      }),
      providesTags: ['Gifts'],
    }),
    createNewGift: builder.mutation<GiftType, CreateOrUpdateGiftType>({
      query: (gift) => ({
        url: routes.api.createNewGift(),
        method: 'POST',
        body: gift,
      }),
      invalidatesTags: ['Gift'],
    }),
    enableGiftById: builder.mutation<GiftType, string>({
      query: (giftId) => ({
        url: routes.api.enableGiftById(giftId),
        method: 'PATCH',
      }),
      invalidatesTags: ['Gift'],
    }),
    disableGiftById: builder.mutation<GiftType, string>({
      query: (giftId) => ({
        url: routes.api.disableGiftById(giftId),
        method: 'PATCH',
      }),
      invalidatesTags: ['Gift'],
    }),
    deleteGiftById: builder.mutation<{ success: boolean }, string>({
      query: (giftId) => ({
        url: routes.api.deleteGiftById(giftId),
        method: 'DELETE',
      }),
      invalidatesTags: ['Gift'],
    }),
  }),
});

export const {
  useGetGiftByIdQuery,
  useUpdateGiftMutation,
  useLazyGetGiftsQuery,
  useGetGiftsQuery,
  useCreateNewGiftMutation,
  useEnableGiftByIdMutation,
  useDisableGiftByIdMutation,
  useDeleteGiftByIdMutation,
} = giftsApi;

import { LogsTypes } from './../../features/log/logs.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LogsTypesState {
  types: LogsTypes[];
}

const initialState: LogsTypesState = {
  types: [],
};

const logsTypesSlice = createSlice({
  name: 'logsTypes',
  initialState,
  reducers: {
    setLogsTypes: (state, action: PayloadAction<LogsTypes[]>) => {
      state.types = action.payload;
    },
  },
});

export const { setLogsTypes } = logsTypesSlice.actions;

export default logsTypesSlice.reducer;

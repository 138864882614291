import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type TariffState = {
  name: string;
};

const initialState: TariffState = {
  name: '',
};

const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    setTariffName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    removeTariffInfo: (state) => {
      state.name = '';
    },
  },
});

export const { setTariffName, removeTariffInfo } = tariffSlice.actions;
export default tariffSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G1Lt4rhhoL3iCFUSKABc{display:flex;gap:.15rem;text-align:center;margin-bottom:0;width:max-content}.IghfsG7_VrVmjN48zBfF{width:max-content;margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/features/log/LogDescription/LogDescription.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CAEF,sBACE,iBAAA,CACA,eAAA","sourcesContent":[".Description {\n  display: flex;\n  gap: 0.15rem;\n  text-align: center;\n  margin-bottom: 0;\n  width: max-content;\n}\n.Field {\n  width: max-content;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Description": `G1Lt4rhhoL3iCFUSKABc`,
	"Field": `IghfsG7_VrVmjN48zBfF`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, memo } from 'react';
import { classNameMerge } from '../../utils/classNameMerge';
import cls from './CustomBadge.module.scss';

type CustomBadgeProps = {
  text: string;
  className?: string;
  handler?: () => void;
  isActive?: boolean;
};

const CustomBadge: FC<CustomBadgeProps> = memo((props) => {
  const { text, className, handler, isActive } = props;
  if (text.length === 0) return null;
  return (
    <span
      className={
        isActive
          ? classNameMerge(`${cls.Badge} ${cls.ActiveBadge}`, className)
          : classNameMerge(cls.Badge, className)
      }
      onClick={handler}
    >
      {text}
    </span>
  );
});

export default CustomBadge;

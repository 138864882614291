// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DOtdrgtHXUCPEAn3IPs_{padding:40px;overflow-y:auto;height:100vh}.NPdswOHHrJ0BIv2ghcR5{margin-bottom:1rem;grid-column:1/-1}.AO3fn61AJ3o57lGPewXM{grid-column:1/-1;margin-bottom:2rem;height:fit-content}._dwtPOYdPxY0VIJXQAFK{grid-column:1/8}`, "",{"version":3,"sources":["webpack://./src/screens/NewLessonPage/NewLessonPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,YAAA,CAEF,sBACE,kBAAA,CACA,gBAAA,CAEF,sBACE,gBAAA,CACA,kBAAA,CACA,kBAAA,CAEF,sBACE,eAAA","sourcesContent":[".Container {\n  padding: 40px;\n  overflow-y: auto;\n  height: 100vh;\n}\n.BreadCrumbs {\n  margin-bottom: 1rem;\n  grid-column: 1/-1;\n}\n.Title {\n  grid-column: 1/-1;\n  margin-bottom: 2rem;\n  height: fit-content;\n}\n.CreateLessonForm {\n  grid-column: 1/8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `DOtdrgtHXUCPEAn3IPs_`,
	"BreadCrumbs": `NPdswOHHrJ0BIv2ghcR5`,
	"Title": `AO3fn61AJ3o57lGPewXM`,
	"CreateLessonForm": `_dwtPOYdPxY0VIJXQAFK`
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef } from 'react';

function useDocumentTitle(title: string, prevailOnMount = false) {
  const defaultTitle = useRef(document.title);
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(
    () => () => {
      if (!prevailOnMount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnMount],
  );
}

export default useDocumentTitle;

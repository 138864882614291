import CreateGiftModal from './CreateGiftModal';
import DeleteGiftModal from './DeleteGiftModal';
import DisableOrEnableGiftModal from './DisableOrEnableGiftModal';
import UpdateGiftModal from './UpdateGiftModal';

export const giftsModals = {
  createGiftModal: CreateGiftModal,
  updateGiftModal: UpdateGiftModal,
  disableOrEnableGiftModal: DisableOrEnableGiftModal,
  deleteGiftModal: DeleteGiftModal,
};

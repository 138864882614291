import routes from '../../routes';
import { bandittoApi } from '../../store/api/api';
import { PaginationRequest } from '../../type';
import { Log, LogsSearch, LogsTypes } from './logs.types';

type GetLogsRequest = PaginationRequest & LogsSearch;

const logsApi = bandittoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLogs: builder.query<Log[], GetLogsRequest>({
      query: (params) => ({
        url: routes.api.getAllLogs(),
        params: params,
      }),
      providesTags: ['Logs'],
    }),
    getLogsTypes: builder.query<LogsTypes[], void>({
      query: () => routes.api.getLogsTypes(),
    }),
  }),
});

export const { useGetAllLogsQuery, useGetLogsTypesQuery } = logsApi;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mosJUWVNWiem7N4QdMpa{display:flex;gap:1rem;margin-bottom:40px}.vk_K4c0hMX4JmsSKJTyh{font-size:32px;font-weight:700;line-height:41.41px;text-align:left;color:rgba(9,12,20,.5);text-decoration:none}.H4sz0SzLcqAcz_l3Cmjh{color:#090c14}`, "",{"version":3,"sources":["webpack://./src/features/layout/ApplicationSettingsNavigation/ApplicationSettingsNavigation.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,mBAAA,CACA,eAAA,CACA,sBAAA,CACA,oBAAA,CAEF,sBACE,aAAA","sourcesContent":[".Navigation {\n  display: flex;\n  gap: 1rem;\n  margin-bottom: 40px;\n}\n.Link {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 41.41px;\n  text-align: left;\n  color: rgba(9, 12, 20, 0.5);\n  text-decoration: none;\n}\n.ActiveLink {\n  color: rgba(9, 12, 20, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navigation": `mosJUWVNWiem7N4QdMpa`,
	"Link": `vk_K4c0hMX4JmsSKJTyh`,
	"ActiveLink": `H4sz0SzLcqAcz_l3Cmjh`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jOB6_Mo8yIHv_eq3O7M9{background-color:#fcfdff;list-style:none;height:100vh;display:flex;flex-direction:column;justify-content:space-between;align-items:center;padding:1rem .5rem;width:100%;max-width:132px}.Kew9KnfnX9K0sn3QYgpu{display:flex;flex-direction:column;gap:.5rem}`, "",{"version":3,"sources":["webpack://./src/features/layout/navigation/NavBar/NavBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,eAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,SAAA","sourcesContent":[".NavBar {\n  background-color: #fcfdff;\n  list-style: none;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1rem 0.5rem;\n  width: 100%;\n  max-width: 132px;\n}\n.NavBarList {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavBar": `jOB6_Mo8yIHv_eq3O7M9`,
	"NavBarList": `Kew9KnfnX9K0sn3QYgpu`
};
export default ___CSS_LOADER_EXPORT___;

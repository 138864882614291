import React from 'react';
import cls from './DownloadReportsModal.module.scss';
import { Modal } from 'react-bootstrap';
import Button from '../../../UIComponents/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks/defaultHooks';
import { closeModal } from '../../../store/slices/modalSlice';
import { modalExtra } from '../../../selectors/selectors';
import { toast } from 'react-toastify';
const DownloadReportsModal = () => {
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());
  const extra = useAppSelector(modalExtra);
  const downloadHandler = () => {
    if (extra?.handler !== undefined) {
      extra?.handler();
      toast.success('Данные успешно скачены');
      closeHandler();
    }
  };
  return (
    <>
      <Modal.Header className={cls.Header} closeButton data-testid="modal-title">
        <h3 className="title-3">Экспортировать {extra?.title as never as string}</h3>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center gap-3">
        <div className="regular-text" data-testid="text-part">
          <p>Для экспорта выбранных данных нажмите кнопку сохранить</p>
        </div>
        <div className="d-flex gap-3">
          <Button
            text="Скачать"
            type="button"
            className={cls.ActionButton}
            clickHandler={downloadHandler}
          />
          <Button text="Отмена" clickHandler={closeHandler} className={cls.RevokeButton} />
        </div>
      </Modal.Body>
    </>
  );
};

export default DownloadReportsModal;

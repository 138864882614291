import React from 'react';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';
import App from './features/App';
const store = setupStore({});
export default async () => (
  <Provider store={store}>
    <App />
  </Provider>
);

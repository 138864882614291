export const tariffsRoutes = {
  tariffs: () => '/tariffs',
  updateTariffById: (tariffId: string) => `/tariffs/${tariffId}/update`,
  restoreTariffById: (tariffId: string) => `/tariffs/${tariffId}/restore`,
  archiveTariffById: (tariffId: string) => `/tariffs/${tariffId}/archiving`,
  activatedTariffById: (tariffId: string) => `/tariffs/${tariffId}/activate`,
  tariffById: (tariffId: string) => `/tariffs/${tariffId}`,
  tariffFeatures: (tariffId: string) => `/tariffs/${tariffId}/features`,
  tariffFeaturesByid: (tariffId: string, featureId: string) =>
    `/tariffs/${tariffId}/features/${featureId}`,
  tariffDefault: (tariffId: string) => `/tariffs/${tariffId}/default`,
  updateTariffByAdmin: (tariffId: string) => `/tariffs/${tariffId}/admin/update`,
  updateTariffLimits: (tariffId: string) => `/tariffs/${tariffId}/update/limits`,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LpU5kNtoWVqnlYb8Ujhv{border:none;margin-bottom:-1rem;display:flex;flex-direction:column;text-align:left;align-items:start;gap:0;padding-top:0;padding-bottom:0}.LpU5kNtoWVqnlYb8Ujhv p{margin-bottom:0}.LH9u3AZ2QsNrWOBXz4W0{font-size:18px;font-weight:400;line-height:0;text-align:center;color:rgba(9,12,20,.5);margin-top:0}.phgA0qUIIWZRklT1jAL4{display:flex;flex-direction:column;gap:1.5rem;color:#090c14}`, "",{"version":3,"sources":["webpack://./src/features/modals/ChangeUserPassword/ChangeUserPassword.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,iBAAA,CACA,KAAA,CACA,aAAA,CACA,gBAAA,CAEF,wBACE,eAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,sBAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,aAAA","sourcesContent":[".ModalHeader {\n  border: none;\n  margin-bottom: -1rem;\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  align-items: start;\n  gap: 0;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n.ModalHeader p {\n  margin-bottom: 0;\n}\n.HeaderLabel {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 0;\n  text-align: center;\n  color: rgba(9, 12, 20, 0.5);\n  margin-top: 0;\n}\n.ModalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  color: rgba(9, 12, 20, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalHeader": `LpU5kNtoWVqnlYb8Ujhv`,
	"HeaderLabel": `LH9u3AZ2QsNrWOBXz4W0`,
	"ModalBody": `phgA0qUIIWZRklT1jAL4`
};
export default ___CSS_LOADER_EXPORT___;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SearchType } from '../../features/search/search.types';
interface SearchState {
  consumers: SearchType | null;
  leads: SearchType | null;
  logs: SearchType | null;
}

const initialState: SearchState = {
  consumers: null,
  leads: null,
  logs: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateConsumersSearch: (state, action: PayloadAction<SearchType>) => {
      state.consumers = action.payload;
    },
    updateLogsSearch: (state, action: PayloadAction<SearchType>) => {
      state.logs = action.payload;
    },
    updateLeadsSearch: (state, action: PayloadAction<SearchType>) => {
      state.leads = action.payload;
    },
    clearConsumersSearch: (state) => {
      state.consumers = null;
    },
    clearLogsSearch: (state) => {
      state.logs = null;
    },
    clearLeadsSearch: (state) => {
      state.leads = null;
    },
  },
});

export const {
  updateConsumersSearch,
  updateLeadsSearch,
  updateLogsSearch,
  clearConsumersSearch,
  clearLogsSearch,
  clearLeadsSearch,
} = searchSlice.actions;
export default searchSlice.reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InvoicesFilterType, InvoiceStatus } from '../../features/invoices/invoices.types';

const initialState: InvoicesFilterType & { consumerEmail: string } = {
  consumerId: '',
  businessName: '',
  businessInn: '',
  status: '',
  startDateTime: '',
  endDateTime: '',
  consumerEmail: '',
};

const invoicesFilterSlice = createSlice({
  name: 'invoicesFilters',
  initialState,
  reducers: {
    setInvoicesFilter(state, action: PayloadAction<{ key: string; value: string }>) {
      if (action.payload.key === 'consumerId') {
        state.consumerId = action.payload.value;
      } else if (action.payload.key === 'businessName') {
        state.businessName = action.payload.value;
      } else if (action.payload.key === 'businessInn') {
        state.businessInn = action.payload.value;
      } else if (action.payload.key === 'status') {
        state.status = action.payload.value as InvoiceStatus;
      } else if (action.payload.key === 'startDateTime') {
        state.startDateTime = action.payload.value;
      } else if (action.payload.key === 'endDateTime') {
        state.endDateTime = action.payload.value;
      } else if (action.payload.key === 'consumerEmail') {
        state.consumerEmail = action.payload.value;
      }
    },
    deleteInvoicesFilter(state, action: PayloadAction<{ key: string }>) {
      state[action.payload.key as keyof typeof state] = '';
    },

    clearInvoicesFilter(state) {
      state.businessInn = '';
      state.businessName = '';
      state.consumerId = '';
      state.endDateTime = '';
      state.startDateTime = '';
      state.status = '';
    },
  },
});

export default invoicesFilterSlice.reducer;
export const { setInvoicesFilter, deleteInvoicesFilter, clearInvoicesFilter } =
  invoicesFilterSlice.actions;

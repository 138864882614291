import React, { FC } from 'react';
import { useUnfinalizeMessageMutation } from '../../message/message.service';
import { useAppDispatch } from '../../../hooks/defaultHooks';
import { setRefresh } from '../../../store/slices/chatSlice';
import { toast } from 'react-toastify';
import cls from './OpenDialogButton.module.scss';

type OpenDialogButtonProps = {
  id: string;
};

const OpenDialogButton: FC<OpenDialogButtonProps> = ({ id }) => {
  const [openDialog] = useUnfinalizeMessageMutation();
  const dispatch = useAppDispatch();
  const openDialogHandler = () => {
    openDialog(id);
    toast.success('Диалог открыт');
    dispatch(setRefresh({ refresh: true }));
  };
  return (
    <button className={cls.Button} onClick={openDialogHandler} title="Открыть">
      Возобновить
    </button>
  );
};

export default OpenDialogButton;

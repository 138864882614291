// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I9DNEBe6zG23uPFGnyYB{display:grid;grid-template-columns:repeat(12, 124px);gap:1.25rem;padding:2rem 40px 1rem 40px}.B8UYSDuMe9pF2b5WzZzW{height:min-content;grid-column:1/-1}.JDx7MnlE9UqwCbGjCS8N{grid-column:1/11}`, "",{"version":3,"sources":["webpack://./src/screens/MessagesPage/MessagesPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,uCAAA,CACA,WAAA,CACA,2BAAA,CAEF,sBACE,kBAAA,CACA,gBAAA,CAEF,sBACE,gBAAA","sourcesContent":[".Container {\n  display: grid;\n  grid-template-columns: repeat(12, 124px);\n  gap: 1.25rem;\n  padding: 2rem 40px 1rem 40px;\n}\n.Title {\n  height: min-content;\n  grid-column: 1/-1;\n}\n.MessagesContainer {\n  grid-column: 1/11;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `I9DNEBe6zG23uPFGnyYB`,
	"Title": `B8UYSDuMe9pF2b5WzZzW`,
	"MessagesContainer": `JDx7MnlE9UqwCbGjCS8N`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AUfJtEL9Zrpxwnw3i9vq{display:grid;grid-auto-flow:row;list-style:none;overflow:auto;min-height:calc(90dvh - 540px);height:calc(90dvh - 540px);max-height:calc(90dvh - 540px);position:relative;padding:0;grid-template-rows:max-content;grid-auto-rows:max-content}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerIntegrationsList/ConsumerIntegrationsList.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,aAAA,CACA,8BAAA,CACA,0BAAA,CACA,8BAAA,CACA,iBAAA,CACA,SAAA,CACA,8BAAA,CACA,0BAAA","sourcesContent":[".List {\n  display: grid;\n  grid-auto-flow: row;\n  list-style: none;\n  overflow: auto;\n  min-height: calc(90dvh - 540px);\n  height: calc(90dvh - 540px);\n  max-height: calc(90dvh - 540px);\n  position: relative;\n  padding: 0;\n  grid-template-rows: max-content;\n  grid-auto-rows: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"List": `AUfJtEL9Zrpxwnw3i9vq`
};
export default ___CSS_LOADER_EXPORT___;

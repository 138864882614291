// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lFYV4Ssq8JoY7WWNsnqV{display:grid;grid-template-columns:repeat(3, 213px);padding:4px;gap:1rem;font-size:18px;font-weight:400;line-height:23.29px}.UnUpIcthMk3Bkg1Ym9Qi{color:var(--field-green-color)}.URTZENGRtVabQu9Cyp8x{color:var(--field-red-color)}`, "",{"version":3,"sources":["webpack://./src/features/consumer/ConsumerOperationItem/ConsumerOperationItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sCAAA,CACA,WAAA,CACA,QAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CAEF,sBACE,8BAAA,CAEF,sBACE,4BAAA","sourcesContent":[".OperationItem {\n  display: grid;\n  grid-template-columns: repeat(3, 213px);\n  padding: 4px;\n  gap: 1rem;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 23.29px;\n}\n.IncomingOperation {\n  color: var(--field-green-color);\n}\n.OutgoingOperations {\n  color: var(--field-red-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OperationItem": `lFYV4Ssq8JoY7WWNsnqV`,
	"IncomingOperation": `UnUpIcthMk3Bkg1Ym9Qi`,
	"OutgoingOperations": `URTZENGRtVabQu9Cyp8x`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import ConsumersList from '../../features/consumer/ConsumersList';
import { useAppDispatch, useAppSelector } from '../../hooks/defaultHooks';
import { getConsumerFilters, getUserInfo } from '../../selectors/selectors';
import ConsumerSearch from '../../features/consumer/ConsumerSearch';
import { useGetAllConsumersQuery } from '../../features/consumer/consumer.service';
import { Consumer } from '../../features/consumer/consumer.types';
import routes from '../../routes';
import { convertBinaryToLink } from '../../utils/converBinaryToLink';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import cls from './ConsumersPage.module.scss';
import DownloadReportsButton from '../../UIComponents/DownloadReportsButton/DownloadReportsButton';
import { openModal } from '../../store/slices/modalSlice';
import ConsumerPanel from '../../features/consumer/ConsumerPanel';

const ConsumersPage = () => {
  const userRole = useAppSelector(getUserInfo)?.role;
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [exportsPage, setExportsPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const exportsSize = 11000;
  const filter = useAppSelector(getConsumerFilters);
  const [search, setSearch] = useState<{ [key: string]: string | number }>({
    sortField: 'createdAt',
    sortOrder: 1,
    filterField: filter.type ?? 'email',
    filter: filter.value ?? '',
  });
  const [scrollConsumers, setScrollConsumers] = useState<Consumer[]>([]);
  const { data: consumers, isLoading } = useGetAllConsumersQuery({ page, size, ...search });
  useDocumentTitle('Список клиентов');
  const downloadConsumersHandler = () => {
    const download = `${routes.api.basePath()}${routes.api.consumersExport()}`;
    convertBinaryToLink(download, {
      ...search,
      page: exportsPage,
      size: exportsSize,
    });
  };
  useEffect(() => {
    if (window.screen.height >= 1070) {
      setSize(20);
      setSearch({ ...search, size });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);
  useEffect(() => {
    if (filter.value) {
      setSearch({ ...search, filter: filter.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.value]);
  const openDownloadModalHandler = () => {
    dispatch(
      openModal({
        type: 'downloadReportsModal',
        extra: { title: 'клиенты', handler: downloadConsumersHandler },
      }),
    );
  };
  return (
    <div className={`${cls.Page} grid-container`}>
      <div className={cls.Header}>
        <h1 className={cls.Title}>Клиенты</h1>
        <ConsumerSearch
          search={search}
          setSearch={setSearch}
          setScrollConsumers={setScrollConsumers}
        />
      </div>
      <div className={cls.Panel}>
        <ConsumerPanel
          search={search}
          setSearch={setSearch}
          setScrollConsumers={setScrollConsumers}
        />
        {userRole !== 'Пользователь' && (
          <DownloadReportsButton clickHandler={openDownloadModalHandler} />
        )}
      </div>
      <ConsumersList
        consumers={consumers}
        setSearch={setSearch}
        search={search}
        scrollConsumers={scrollConsumers}
        setScrollConsumers={setScrollConsumers}
        setPage={setPage}
        page={page}
        exportsPage={exportsPage}
        exportsSize={exportsSize}
        setExportsPage={setExportsPage}
        isLoading={isLoading}
        className={cls.List}
      />
    </div>
  );
};

export default ConsumersPage;

type Format = 'date' | 'time' | 'datetime' | 'UTC' | 'startRange' | 'endRange';

export const convertDateToNeedFormat = ({
  date,
  format = 'time',
}: {
  date: string;
  format?: Format;
}) => {
  const needDate = new Date(date) ?? new Date();
  const hours = needDate.getHours() < 10 ? `0${needDate.getHours()}` : needDate.getHours();
  const minutes = needDate.getMinutes() < 10 ? `0${needDate.getMinutes()}` : needDate.getMinutes();
  const day = needDate.getDate() < 10 ? `0${needDate.getDate()}` : needDate.getDate();
  const month =
    needDate.getMonth() + 1 < 10 ? `0${needDate.getMonth() + 1}` : needDate.getMonth() + 1;
  const year = needDate.getFullYear();
  switch (format) {
    case 'date':
      return `${day}.${month}.${year}`;
    case 'time': {
      return `${hours}:${minutes}`;
    }
    case 'datetime': {
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
    case 'UTC': {
      const utcDate = Date.UTC(
        needDate.getUTCFullYear(),
        needDate.getUTCMonth(),
        needDate.getUTCDate(),
        needDate.getUTCHours(),
        needDate.getUTCMinutes(),
      );
      return new Date(utcDate).toISOString();
    }
    case 'startRange': {
      const utcDate = Date.UTC(
        needDate.getUTCFullYear(),
        needDate.getUTCMonth(),
        needDate.getUTCDate(),
        0,
        0,
        0,
      );
      return new Date(utcDate).toISOString();
    }
    case 'endRange': {
      const utcDate = Date.UTC(
        needDate.getUTCFullYear(),
        needDate.getUTCMonth(),
        needDate.getUTCDate(),
        23,
        59,
        59,
      );
      return new Date(utcDate).toISOString();
    }
  }
};

import React, { FC, useEffect } from 'react';
import { ConsumerBilling } from '../consumer.types';
import Spinner from '../../../UIComponents/Spinner';
import cls from './ConsumerBillingList.module.scss';
import ConsumerOperationItem from '../ConsumerOperationItem';
import ConsumerBillingListHeader from '../ConsumerBillingListHeader';

interface ConsumerBillingListProps {
  consumerBillingList: ConsumerBilling[] | undefined;
  consumerBillingListLoading: boolean;
  operationsPage: number;
  setOperationsPage: React.Dispatch<React.SetStateAction<number>>;
  scrollOperationsList: ConsumerBilling[] | [];
  setScrollOperationsList: React.Dispatch<React.SetStateAction<ConsumerBilling[]>>;
}

const ConsumerBillingList: FC<ConsumerBillingListProps> = ({
  consumerBillingList,
  consumerBillingListLoading,
  operationsPage,
  setOperationsPage,
  scrollOperationsList,
  setScrollOperationsList,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollHandler = (event: any) => {
    if (
      document.querySelector('.transactions-list')?.clientHeight + event.target.scrollTop ===
        event.target.scrollHeight &&
      scrollOperationsList
    ) {
      setOperationsPage(++operationsPage);
    }
  };
  console.log(scrollOperationsList);
  useEffect(() => {
    if (consumerBillingList) {
      setScrollOperationsList([...scrollOperationsList, ...consumerBillingList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerBillingList]);
  if (consumerBillingListLoading) return <Spinner />;
  if (!consumerBillingListLoading && scrollOperationsList.length === 0)
    return (
      <h5 data-testid="without-billing" className="title-5 mt-3">
        Клиент не совершил еще никаких операций
      </h5>
    );
  return (
    <div>
      <ConsumerBillingListHeader />
      <ul
        data-testid="transactions-list"
        onScroll={scrollHandler}
        className={`${cls.List} transactions-list`}
      >
        {scrollOperationsList?.map((bill) => (
          <ConsumerOperationItem key={bill.id} operation={bill} />
        ))}
      </ul>
    </div>
  );
};

export default ConsumerBillingList;

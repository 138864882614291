import React, { FC } from 'react';
import { Image } from 'react-bootstrap';

interface ImagePreviewProps {
  url: string;
}

export const ImagePreview: FC<ImagePreviewProps> = ({ url }) => {
  return (
    <>
      <Image src={url} alt="Парень работает за столом" fluid />
    </>
  );
};

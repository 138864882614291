import React, { FC, memo } from 'react';
import { ConsumerIntegration } from '../consumer.types';
import { convertDateToNeedFormat } from '../../../utils/convertDateHandlers';
import cls from './ConsumerIntegrationItem.module.scss';

type ConsumerIntegrationItemProps = {
  integration: ConsumerIntegration;
};

const ConsumerIntegrationItem: FC<ConsumerIntegrationItemProps> = memo(({ integration }) => {
  return (
    <div className={cls.Item} data-testid="integration-container">
      <span className="integration__type" data-testid="integration-type">
        {integration.service}
      </span>
      <span className="integration__created" data-testid="integration-created">
        {convertDateToNeedFormat({ date: integration.createdAt, format: 'datetime' })}
      </span>
      <span className="integration__expiration" data-testid="integration-expiration">
        {integration.expirationAt
          ? convertDateToNeedFormat({ date: integration.createdAt, format: 'datetime' })
          : 'Бессрочный'}
      </span>
      <span className={cls.Account} data-testid="integration-account">
        {integration.account}
      </span>
    </div>
  );
});

export default ConsumerIntegrationItem;
